import React, { useRef, useState, useEffect } from "react"
import { Stack } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"

const StyledStack = styled(Stack)(() => ({
	position: "absolute",
	top: 0,
	right: -22,
	width: 10,
	height: "100%",
	cursor: "col-resize",
}))

interface ResizableProps {
	resizable?: boolean
	onResize: (newWidth: number) => void
	children: React.ReactNode
}
export default function Resizable({ resizable, onResize, children }: ResizableProps) {
	const resizeHandleRef = useRef<HTMLDivElement>(null)
	const [isResizing, setIsResizing] = useState(false)
	const xRef = useRef(0)
	const wRef = useRef(0)
	const theme = useTheme()

	const handleMouseUp = () => {
		if (isResizing) {
			setIsResizing(false)
		}
	}

	const handleMouseMove = (e: MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()
		if (isResizing) {
			const paddingCol = parseInt(theme.spacing(4)) ? parseInt(theme.spacing(4)) : 0 //Check for NaN
			const newWidth = e.clientX + wRef.current - xRef.current + paddingCol
			const minWidth = parseInt(theme.spacing(16.5)) ? parseInt(theme.spacing(16.5)) : 0 //Check for NaN

			onResize(Math.max(newWidth, minWidth))
		}
	}

	const handleMouseDown = (e: React.MouseEvent<Element, MouseEvent>) => {
		e.preventDefault()
		e.stopPropagation()
		setIsResizing(true)
		xRef.current = e.clientX
		let currentElement = resizeHandleRef.current
		if (currentElement === null) {
			return
		}

		while (
			currentElement !== null &&
			currentElement.tagName !== "TH" &&
			currentElement.parentNode instanceof HTMLDivElement
		) {
			currentElement = currentElement.parentNode
		}

		wRef.current = currentElement.offsetWidth
	}

	useEffect(() => {
		document.addEventListener("mousemove", handleMouseMove)
		document.addEventListener("mouseup", handleMouseUp)

		return () => {
			document.removeEventListener("mousemove", handleMouseMove)
			document.removeEventListener("mouseup", handleMouseUp)
		}
	}, [isResizing])

	return (
		<Stack sx={{ position: "relative" }}>
			{resizable ? (
				<Stack sx={{ position: "relative" }}>
					{children}
					<StyledStack ref={resizeHandleRef} onClick={(e) => e.stopPropagation()} onMouseDown={handleMouseDown} />
				</Stack>
			) : (
				<>{children}</>
			)}
		</Stack>
	)
}
