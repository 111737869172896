import { SvgIcon, SvgIconProps } from "@mui/material"

export default function TSLogoNoTextIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 588 400" {...props}>
			<g transform="matrix(4.16667,0,0,4.16667,0,0)">
				<path d="M25.628,0.947 L50.862,0.947 L25.273,85.436 L0.04,85.436 L25.628,0.947 " />
				<path d="M62.28,0.947L87.513,0.947L61.925,85.436L36.691,85.436L62.28,0.947Z" />
				<path d="M98.932,0.947L124.165,0.947L98.576,85.436L73.343,85.436L98.932,0.947Z" />
				<path d="M115.839,62.239L140.884,62.239L127.702,20.561L115.839,62.239Z" />
			</g>
		</SvgIcon>
	)
}
