import { Stack, Typography } from "@mui/material"
import { PvtHeatPumpDhwMode } from "generated/graphql"
import { useEffect, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { StepperProps } from "../index"
import NumberInput from "inputs/NumberInput"
import MoreInfo from "components/MoreInfo"
import BooleanInput from "inputs/BooleanInput"
import { isPvtHeatPumpFirmwareNewerOrEqual, PVT_HEAT_PUMP_V1_1 } from "settings/firmwareVersions"
import RadioInput from "inputs/RadioInput"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"
import { getStaticLables } from "tools/getStaticLabels"
import i18n from "i18next"

const CELCIUS = "°C"

enum BOILER_STORAGE {
	BOILER = "BOILER",
	BATTERY = "BATTERY",
	CUSTOM = "CUSTOM",
}

export default function BoilerSettings({
	error,
	data,
	set,
	loading,
	submit,
	finishStep,
	register,
	fields,
	steps,
	BottomNavigation,
	nextStep,
	backStep,
	step,
}: StepperProps) {
	const { t } = useTranslation(["heatPumpSettingsPage", "general"])
	const [selectedStorage, setSelectedStorage] = useState<string | null>(BOILER_STORAGE.BOILER)
	const [customTempStorage, setCustomTempStorage] = useState<boolean>(false)
	const { interfaceId } = useParams<"interfaceId">()

	if (!set || !fields || !register || !steps || !interfaceId) return

	const READABLE_BOILER_STORAGE = {
		[BOILER_STORAGE.BOILER]: t("Boiler"),
		[BOILER_STORAGE.BATTERY]: t("Battery"),
		[BOILER_STORAGE.CUSTOM]: t("Custom"),
	}

	const { READABLE_DHW_MODE, READABLE_DHW_MODE_HIDDEN, HIDDEN_SETTING_TEXT } = useMemo(
		() => getStaticLables(t),
		[i18n.language],
	)

	const hpHasBoost = isPvtHeatPumpFirmwareNewerOrEqual(
		data?.interface?.pvtHeatPump?.firmwareVersion ?? Infinity,
		PVT_HEAT_PUMP_V1_1,
	)

	const handleDHWAutoTemp = (storageValue: string | null) => {
		setCustomTempStorage(false)

		switch (storageValue) {
			case BOILER_STORAGE.BOILER:
				set({ dhwAutoTemp: 53.5 })
				break
			case BOILER_STORAGE.BATTERY:
				set({ dhwAutoTemp: 62 })
				break
			case BOILER_STORAGE.CUSTOM:
				set({ dhwAutoTemp: 53 })
				setCustomTempStorage(true)
				break
			default:
				break
		}

		setSelectedStorage(storageValue)
	}

	// Prefill Radio select of "DHW Temperature For" depending on value of field "dhwAutoTemp", since "DHW Temperature For" is not being saved in backend to preselect
	const preSelectDHWAutoTemp = () => {
		const currentDhwAutoTemp = fields.dhwAutoTemp

		switch (currentDhwAutoTemp) {
			case 53.5:
				setSelectedStorage(BOILER_STORAGE.BOILER)
				break
			case 62:
				setSelectedStorage(BOILER_STORAGE.BATTERY)
				break
			case 53:
				setSelectedStorage(BOILER_STORAGE.CUSTOM)
				break
			default:
				break
		}
	}

	useEffect(() => {
		preSelectDHWAutoTemp()
	}, [fields.dhwAutoTemp])

	if (error && interfaceId) {
		return <ErrorCard error={error} interfaceId={interfaceId} />
	}

	return (
		<>
			<Typography variant="h6">{steps[5]}</Typography>
			<RadioInput
				label={t("heatPumpSettingsPage:DHWMode")}
				options={READABLE_DHW_MODE}
				hiddenOptions={READABLE_DHW_MODE_HIDDEN}
				hiddenOptionHelperText={HIDDEN_SETTING_TEXT}
				disabled={loading}
				row
				{...register("dhwMode")}
			/>
			{fields.dhwMode !== PvtHeatPumpDhwMode.Off && (
				<>
					<RadioInput
						label={`${t("DHWTemperatureFor")}:`}
						value={selectedStorage}
						options={READABLE_BOILER_STORAGE}
						row
						onChange={(value) => handleDHWAutoTemp(value)}
					/>

					<Stack width="100%" direction="row" alignItems="center">
						<NumberInput
							label={t("heatPumpSettingsPage:DesiredDHWTemp")}
							disabled={loading || !customTempStorage}
							unit={CELCIUS}
							{...register("dhwAutoTemp", { required: true, min: 30, max: 70 })}
						/>

						<MoreInfo>
							<Typography>{t("heatPumpSettingsPage:Tooltips.IdealBoilerTempMessage")}</Typography>
						</MoreInfo>
					</Stack>
				</>
			)}
			{hpHasBoost && (
				<>
					<Stack direction="row" alignItems="center">
						<BooleanInput
							label={t("heatPumpSettingsPage:DHWBoost")}
							disabled={loading}
							{...register("dhwBoostEnabled")}
						/>
						<MoreInfo>
							<Trans i18nKey="MsgBoostDescription" ns="heatPumpSettingsPage" components={{ italic: <i /> }} />
						</MoreInfo>
					</Stack>
					{fields.dhwBoostEnabled && (
						<>
							<Typography color="primary">{t("heatPumpSettingsPage:MsgBoostConsumption")}</Typography>
							<NumberInput
								label={t("heatPumpSettingsPage:BoostSourceTemp")}
								disabled={loading}
								unit={CELCIUS}
								{...register("boostSourceTemp", { required: true })}
							/>
						</>
					)}
				</>
			)}

			{BottomNavigation && (
				<BottomNavigation
					steps={steps}
					step={step}
					backStep={backStep}
					finishStep={finishStep}
					nextStep={nextStep}
					loading={loading}
					submit={submit}
				/>
			)}
		</>
	)
}
