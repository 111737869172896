import { Navigate, useNavigate, useParams } from "react-router-dom"
import {
	useReadHeatPumpSettingsQuery,
	ReadHeatPumpSettingsDocument,
	Role,
	InterfacesFieldsFragment,
} from "generated/graphql"
import EditableInterfaceName from "components/EditableInterfaceName"
import PageWithStatusBar from "components/PageWithStatusBar"
import HeatPumpInfoDisplay from "components/HeatPumpInfoDisplay"
import { CircularProgress, Stack, Typography, Grid, Badge, Fab, styled } from "@mui/material"
import { HEAT_PUMP, INSTALLATION_HELP, NOT_FOUND } from "settings/url"
import useSize from "hooks/useSize"
import useHasRole from "hooks/useHasRole"
import { useTranslation } from "react-i18next"
import GeneralSettingsFields from "pages/HeatPumpSettingsPage/components/GeneralSettingsFields"
import { StatusMessageProps } from "components/BatchUpdate"

const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		zIndex: theme.zIndex.fab + 1,
	},
}))

type Props = {
	interfaces?: InterfacesFieldsFragment[]
	defaultInterfaceId?: string
	handleToggleSavingType?: (id: string) => void
	isPartialSave?: Record<string, boolean>
	handleSavingMessages?: (updateStatus: StatusMessageProps[]) => void
	handleSaveLoading?: (isLoading: boolean) => void
}

export default function heatPumpSettingsPage({
	interfaces,
	defaultInterfaceId,
	handleToggleSavingType,
	isPartialSave,
	handleSavingMessages,
	handleSaveLoading,
}: Props) {
	const isBatchUpdate = Array.isArray(interfaces) && interfaces.length > 0

	const { t } = useTranslation(["heatPumpSettingsPage", "heatPumpStatus", "general"])
	const navigate = useNavigate()
	const smallScreen = useSize("down", "md")
	const { interfaceId } = useParams<"interfaceId">()
	const isAtLeastExpert = useHasRole([Role.Admin, Role.Mechanic, Role.Expert])

	if (!isBatchUpdate && !interfaceId) return <Navigate to={`/${NOT_FOUND}`} /> // should never happen, but just in case, navigate to not found page
	if (isBatchUpdate && !defaultInterfaceId) return

	const {
		data,
		loading: readLoading,
		error,
		refetch,
	} = useReadHeatPumpSettingsQuery({
		variables: { interfaceId: isBatchUpdate ? defaultInterfaceId ?? "" : interfaceId ?? "" },
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-first",
		errorPolicy: isBatchUpdate ? "all" : "none", // get data even if one of the interface has error
	})

	if (isBatchUpdate) {
		return (
			<Grid container spacing={smallScreen ? 0 : 1} alignItems="center" direction="column" marginBottom={1}>
				{readLoading ? (
					<CircularProgress size={30} />
				) : (
					<GeneralSettingsFields
						data={data}
						error={error}
						readLoading={readLoading}
						refetch={refetch}
						isPartialSave={isPartialSave?.["generalSettings"]}
						interfaces={interfaces}
						defaultInterfaceId={defaultInterfaceId}
						handleSaveLoading={handleSaveLoading}
						handleSavingMessages={handleSavingMessages}
						handleToggleSavingType={handleToggleSavingType}
					/>
				)}
			</Grid>
		)
	}

	return (
		<PageWithStatusBar
			navigationName="settings"
			interfaceId={!isBatchUpdate ? interfaceId || "" : ""}
			backButton
			noPadding={smallScreen}
		>
			<HeatPumpInfoDisplay
				titleCard={
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Typography variant="h4">
							<EditableInterfaceName
								shouldRender
								interfaceId={!isBatchUpdate ? interfaceId || "" : ""}
								prefix={`${t("SettingsOf")} `}
							/>
						</Typography>
						{(data || error) && readLoading && <CircularProgress size={30} />}

						{isAtLeastExpert && (
							<StyledBadge badgeContent="Beta" color="secondary">
								<Fab
									variant="extended"
									sx={{ p: 2, ml: 2 }}
									color="primary"
									size="small"
									disabled={readLoading && !data}
									onClick={() => navigate(`/${HEAT_PUMP}/${interfaceId}/${INSTALLATION_HELP}`)}
								>
									{t("AssistanceWithSetup")}
								</Fab>
							</StyledBadge>
						)}
					</Stack>
				}
				error={error}
				isLoading={readLoading && !data}
				interfaceId={!isBatchUpdate ? interfaceId || "" : ""}
				fetchOnClaim={ReadHeatPumpSettingsDocument}
			>
				<GeneralSettingsFields
					data={data}
					error={error}
					readLoading={readLoading}
					refetch={refetch}
					defaultInterfaceId={interfaceId}
				/>
			</HeatPumpInfoDisplay>
		</PageWithStatusBar>
	)
}
