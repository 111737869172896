import { Backdrop, CircularProgress } from "@mui/material"

type Props = {
	onClick?: React.MouseEventHandler<HTMLElement>
}
export default function LoadingBackdrop({ onClick }: Props) {
	return (
		<Backdrop open sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} onClick={onClick}>
			<CircularProgress color="inherit" />
		</Backdrop>
	)
}
