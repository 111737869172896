import { MouseEventHandler } from "react"
import { styled, useTheme } from "@mui/material/styles"
import { Fab, Tooltip } from "@mui/material"
import { Add } from "@mui/icons-material"
import useSize from "hooks/useSize"

const StyledFab = styled(Fab)(({ theme }) => ({
	position: "fixed",
	bottom: theme.spacing(4),
	right: theme.spacing(4),
}))

type TooltipPlacement =
	| "bottom"
	| "left"
	| "right"
	| "top"
	| "bottom-end"
	| "bottom-start"
	| "left-end"
	| "left-start"
	| "right-end"
	| "right-start"
	| "top-end"
	| "top-start"

type Props = {
	offset?: number
	onClick: MouseEventHandler
	absolute?: boolean
	positionLabel?: TooltipPlacement
	children: React.ReactChild | React.ReactFragment | React.ReactPortal
}

export default function AddFab({ offset = 3, onClick, absolute = false, children, positionLabel = "top" }: Props) {
	const isSmall = useSize("down", "lg")
	const theme = useTheme()

	return (
		<Tooltip title={children} placement={positionLabel}>
			<StyledFab
				onClick={onClick}
				color="primary"
				size={isSmall ? "small" : "medium"}
				sx={{
					...(isSmall ? { bottom: theme.spacing(offset), right: theme.spacing(3) } : {}),
					...(absolute ? { position: "absolute" } : {}),
				}}
			>
				<Add />
			</StyledFab>
		</Tooltip>
	)
}
