import {
	SignalCellular0BarRounded,
	SignalCellular1BarRounded,
	SignalCellular2BarRounded,
	SignalCellular3BarRounded,
	SignalCellular4BarRounded,
} from "@mui/icons-material"

type Props = {
	receptionStrenght: number
}
export default function ReceptionIcon({ receptionStrenght }: Props) {
	switch (receptionStrenght) {
		case 1:
			return <SignalCellular1BarRounded fontSize="small" />
		case 2:
			return <SignalCellular2BarRounded fontSize="small" />
		case 3:
			return <SignalCellular3BarRounded fontSize="small" />
		case 4:
			return <SignalCellular4BarRounded fontSize="small" />
		default:
			return <SignalCellular0BarRounded fontSize="small" />
	}
}
