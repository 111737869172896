import { RestartAlt } from "@mui/icons-material"
import { Fab, ListItem, ListItemText, Stack, Typography } from "@mui/material"
import {
	ReadHeatPumpErrorsDocument,
	PvtHeatPumpStatusFieldsFragment,
	useResetPvtHeatPumpErrorsMutation,
} from "generated/graphql"
import useSize from "hooks/useSize"
import { useMessage } from "providers/MessageProvider"

import HeatPumpInfoMessage from "../HeatPumpInfoMessage"
import NamedListItem from "../NamedListItem"
import appendErrorMessage from "tools/appendErrorMessage"
import i18next from "i18next"
import { useTranslation } from "react-i18next"

type Props = {
	data?: Pick<PvtHeatPumpStatusFieldsFragment, "id" | "errors"> | null
}

function listErrors(errors: number[]) {
	return (
		<ListItemText>
			{errors.map((errorNumber) => {
				return <HeatPumpInfoMessage errorCode={errorNumber} key={`hp-error-${errorNumber}`} />
			})}
		</ListItemText>
	)
}
type ResetButtonProps = {
	handleClick: () => void
	disabled: boolean
}

function ResetErrorButton({ handleClick, disabled }: ResetButtonProps) {
	return (
		<Fab variant="extended" sx={{ zIndex: 0 }} color="primary" onClick={handleClick} disabled={disabled} size="small">
			<RestartAlt />
			{i18next.t("heatPumpStatus:ResetErrors")}
		</Fab>
	)
}

export default function HeatPumpErrors({ data }: Props) {
	const { t } = useTranslation(["heatPumpStatus", "general"])

	const interfaceId = data?.id
	const message = useMessage()
	const smallScreen = useSize("down", "md")
	const [resetPVTHeatPumpErrors, { loading: resetLoading }] = useResetPvtHeatPumpErrorsMutation({
		refetchQueries: [{ query: ReadHeatPumpErrorsDocument, variables: { interfaceId } }],
		awaitRefetchQueries: true,
	})

	if (!data) return null

	async function resetErrors() {
		try {
			if (!interfaceId) throw t("general:InterfaceIDNotFound")
			await resetPVTHeatPumpErrors({ variables: { interfaceIds: [interfaceId] } })
			message.success(t("ErrorsHaveBeenReset"))
		} catch (error) {
			message.error(appendErrorMessage(t("Errors.ResetingErrors"), error))
		}
	}
	if (!data.errors?.length) {
		return <NamedListItem key="no-errors-list-item" name={t("Messages")} value={t("NoErrors")} />
	}
	if (smallScreen) {
		return (
			<>
				<ListItem>
					<ListItemText>
						<Typography sx={{ fontWeight: "Bold" }}>{t("Messages")}</Typography>
					</ListItemText>
				</ListItem>
				<Stack direction="column">
					{listErrors(data.errors)}
					<ResetErrorButton handleClick={resetErrors} disabled={resetLoading} />
				</Stack>
			</>
		)
	}
	return (
		<ListItem>
			<ListItemText>
				<Typography sx={{ fontWeight: "Bold" }}>{t("Messages")}</Typography>
			</ListItemText>
			<Stack direction="column">
				{listErrors(data.errors)}
				<ResetErrorButton handleClick={resetErrors} disabled={resetLoading} />
			</Stack>
		</ListItem>
	)
}
