import { FormControl, FormHelperText, InputLabel, Select, MenuItem, Stack } from "@mui/material"
import { styled } from "@mui/material/styles"
import InputReset from "components/InputReset"

interface StyledProps {
	fieldIsChanged: boolean | undefined
}

const StyledDivContainer = styled("div")<StyledProps>(({ theme, fieldIsChanged }) => {
	return {
		marginTop: theme.spacing(2),
		["& fieldset"]: {
			borderColor: fieldIsChanged && theme.palette.secondary.main,
		},
		["& .MuiOutlinedInput-root"]: {
			["&.Mui-focused fieldset"]: {
				borderColor: fieldIsChanged && theme.palette.secondary.main,
			},
		},
	}
})

type OptionMap<T extends string> = { [key in T]?: string }

export interface Props<T extends string> {
	reset?: () => void
	fieldIsChanged?: boolean
	size?: "medium" | "small"
	fullWidth?: boolean
	value?: T | null
	onChange: (value: T | null) => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
	options: OptionMap<T>
	hiddenOptions?: OptionMap<T>
	hiddenOptionHelperText?: string
	row?: boolean
	disabled?: boolean
}

export default function SelectInput<T extends string>({
	reset,
	fieldIsChanged,
	size = "medium",
	fullWidth = true,
	value,
	error,
	required,
	onChange,
	label,
	options,
	hiddenOptions,
	hiddenOptionHelperText,
	helperText,
	disabled,
}: Props<T>) {
	const hiddenOptionSelected = hiddenOptions && value != null && Object.keys(hiddenOptions).includes(value)
	const currentOptions = hiddenOptionSelected ? { ...options, ...hiddenOptions } : options

	const isDisabled = hiddenOptionSelected || disabled

	return (
		<StyledDivContainer fieldIsChanged={fieldIsChanged ? fieldIsChanged : false}>
			<FormControl fullWidth={fullWidth} disabled={isDisabled}>
				<InputLabel disabled={isDisabled} required={required}>
					{label}
				</InputLabel>
				<Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
					<Select
						size={size}
						fullWidth
						value={value ?? ""}
						label={label}
						onChange={(e) => onChange(e.target.value as T)}
						disabled={isDisabled}
					>
						{Object.entries(currentOptions).map(([value, label]) => {
							if (typeof label !== "string") return
							return (
								<MenuItem value={value} key={value}>
									{label}
								</MenuItem>
							)
						})}
					</Select>
					{reset && fieldIsChanged && <InputReset reset={reset} isFieldChanged={fieldIsChanged} />}
				</Stack>

				<FormHelperText error={error}>{helperText}</FormHelperText>
				{hiddenOptionSelected && hiddenOptionHelperText && <FormHelperText>{hiddenOptionHelperText}</FormHelperText>}
			</FormControl>
		</StyledDivContainer>
	)
}
