import { IconButton, Grid, Tooltip } from "@mui/material"
import { Add } from "@mui/icons-material"

type Props = {
	onClick: () => void
	children: NonNullable<React.ReactNode>
}

export default function AddButton({ onClick, children }: Props) {
	return (
		<Grid container justifyContent="flex-end">
			<Grid item>
				<Tooltip title={children}>
					<IconButton onClick={onClick} size="large">
						<Add />
					</IconButton>
				</Tooltip>
			</Grid>
		</Grid>
	)
}
