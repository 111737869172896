import { ApolloCache, InMemoryCache } from "@apollo/client"
import { Save } from "@mui/icons-material"
import { LinearProgress, Stack, Typography } from "@mui/material"
import {
	PvtHeatPumpAdminSettingDhwFragment,
	PvtHeatPumpAdminSettingDhwFragmentDoc,
	PvtHeatPumpDhwMode,
	PvtHeatPumpInput,
	UpdatePvtHeatPumpSettingsMutation,
	UpdatePvtHeatPumpSettingsMutationVariables,
	useReadHeatPumpDhwSettingsQuery,
	useUpdatePvtHeatPumpSettingsMutation,
} from "generated/graphql"
import useForm from "hooks/useForm"
import NumberInput from "inputs/NumberInput"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"
import { useMessage } from "providers/MessageProvider"
import { Trans, useTranslation } from "react-i18next"
import onlyChangedValues from "tools/onlyChangedValues"
import RadioInput from "inputs/RadioInput"
import BooleanInput from "inputs/BooleanInput"
import MoreInfo from "components/MoreInfo"
import { PvtHeatPumpData } from "../index"
import appendErrorMessage from "tools/appendErrorMessage"
import FloatingFab from "components/FloatingFab"
import InterfaceSaveTypeAlert from "components/InterfaceSaveTypeAlert"
import { StatusMessageProps } from "components/BatchUpdate"
import handleSettingsError from "tools/handleSettingsError"
import { GraphQLError } from "graphql/error"

const CELCIUS = "°C"

function updatePvtHeatPumpCache(
	cache: ApolloCache<InMemoryCache>,
	{ data, errors }: { data?: UpdatePvtHeatPumpSettingsMutation | null; errors?: readonly GraphQLError[] | undefined },
	{ variables }: { variables?: UpdatePvtHeatPumpSettingsMutationVariables },
) {
	if (!data?.updatePvtHeatPump || !variables) return
	const { pvtHeatPumpdata: inputData, interfaceIds } = variables
	const ids = Array.isArray(interfaceIds) && interfaceIds.length > 0 ? interfaceIds : []

	const interfaceIdsWithErrors = errors
		?.filter((error) => typeof error.extensions.interfaceID === "string")
		.map((error) => error.extensions.interfaceID)

	const onlineInterfaceIds = ids.filter((id) => !interfaceIdsWithErrors?.includes(id))

	onlineInterfaceIds.forEach((defaultInterfaceId) => {
		const id = `PvtHeatPumpData:${defaultInterfaceId}`
		const oldPvtHeatPump = cache.readFragment<PvtHeatPumpAdminSettingDhwFragment>({
			id,
			fragment: PvtHeatPumpAdminSettingDhwFragmentDoc,
			fragmentName: "PvtHeatPumpAdminSettingDHW",
		})
		if (!oldPvtHeatPump) return

		const newPvtHeatPump = (Object.keys(oldPvtHeatPump) as Array<keyof PvtHeatPumpAdminSettingDhwFragment>).reduce(
			(acc, key) => ({ ...acc, [key]: inputData[key as keyof PvtHeatPumpInput] ?? oldPvtHeatPump[key] }),
			{} as PvtHeatPumpAdminSettingDhwFragment,
		)

		cache.writeFragment<PvtHeatPumpAdminSettingDhwFragment>({
			id,
			fragment: PvtHeatPumpAdminSettingDhwFragmentDoc,
			fragmentName: "PvtHeatPumpAdminSettingDHW",
			data: newPvtHeatPump,
		})
	})
}

type Props = {
	defaultInterfaceId: string
	batchInterfaceIds?: string[]
	isPartialSave?: boolean
	handleSavingMessages?: (updateStatus: StatusMessageProps[]) => void | undefined
	handleSaveLoading?: (isLoading: boolean) => void
}

export default function DHWSettings({
	defaultInterfaceId,
	batchInterfaceIds,
	isPartialSave,
	handleSavingMessages,
	handleSaveLoading,
}: Props) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage", "general", "heatPumpSettingsPage", "heatPumpErrorCard"])
	const message = useMessage()
	const { register, submit, reset, fields, set } = useForm<PvtHeatPumpInput>({}, handleSave)

	const isBatchUpdate = Array.isArray(batchInterfaceIds) && batchInterfaceIds.length > 0

	const { data, loading, error } = useReadHeatPumpDhwSettingsQuery({
		variables: { interfaceId: defaultInterfaceId },
		onCompleted: (data) => {
			if (data.interface?.pvtHeatPump) {
				const { id: _id, __typename, ...rest } = data.interface.pvtHeatPump
				reset(rest)
			}
		},
	})

	const [updateSettings, { loading: loadingSave }] = useUpdatePvtHeatPumpSettingsMutation({
		update: updatePvtHeatPumpCache,
	})

	const changedFields = onlyChangedValues(data?.interface?.pvtHeatPump ?? {}, fields)

	const resetField = (field: string) => {
		if (!field || !data?.interface?.pvtHeatPump) return

		const pvtHeatPumpData: PvtHeatPumpData = data.interface.pvtHeatPump
		const value = pvtHeatPumpData[field]

		set({
			[field]: value,
		})
	}

	async function handleSave() {
		if ((isPartialSave || isPartialSave === undefined) && Object.entries(changedFields).length === 0) {
			message.info(t("heatPumpSettingsPage:Errors.HPHasTheseSettings"))
			return
		}

		if (isBatchUpdate) {
			message.info(t("general:SavingSettingsPleaseWait"))
		}

		try {
			const response = await updateSettings({
				variables: {
					interfaceIds: isBatchUpdate ? batchInterfaceIds : [defaultInterfaceId],
					pvtHeatPumpdata: isBatchUpdate ? (isPartialSave ? changedFields : fields) : changedFields,
					controllerSettings: {},
					isExpert: true,
					isAdmin: false,
				},
				errorPolicy: isBatchUpdate ? "all" : "none", // If 1 or more interface is offline, the mutation will continue, This will ignore the error catch for GraphQL
			})

			if (handleSavingMessages) {
				handleSavingMessages([{ success: true }])
			}

			// Since we use errorPolicy: "all", the error won't return at catch error
			if (response?.errors) {
				handleSettingsError({ errorResponse: response.errors, handleSavingMessages, t })
			}
		} catch (e) {
			message.error(appendErrorMessage(t("heatPumpSettingsPage:Errors.UnkownSaving"), e))
		}

		message.success(t("heatPumpSettingsPage:SettingsSaved"))

		if (handleSaveLoading) {
			handleSaveLoading(loadingSave)
		}
	}

	if (loading) {
		return <LinearProgress />
	}

	if (error || data?.interface == null) {
		return <ErrorCard error={error} interfaceId={defaultInterfaceId} />
	}

	const READABLE_DHW_OPTIONS = {
		OFF: t("general:Off"),
		AUTO: t("general:On"),
	}

	const READABLE_DHW_LEGIONELLA_DAY_OPTIONS = {
		MONDAY: t("general:Monday"),
		TUESDAY: t("general:Tuesday"),
		WEDNESDAY: t("general:Wednesday"),
		THURSDAY: t("general:Thursday"),
		FRIDAY: t("general:Friday"),
		SATURDAY: t("general:Saturday"),
		SUNDAY: t("general:Sunday"),
	}

	return (
		<>
			{batchInterfaceIds && batchInterfaceIds.length > 0 && <InterfaceSaveTypeAlert isPartialSave={isPartialSave} />}

			<RadioInput
				label={t("DomesticHotWaterMode")}
				row
				options={READABLE_DHW_OPTIONS}
				disabled={loading}
				{...register("dhwMode", { required: true })}
				fieldIsChanged={"dhwMode" in changedFields}
				reset={() => resetField("dhwMode")}
			/>

			{fields.dhwMode !== PvtHeatPumpDhwMode.Off && (
				<NumberInput
					label={t("DomesticHotWaterTemperature")}
					{...register("dhwAutoTemp", { required: true, min: 30, max: 70 })}
					unit="°C"
					disabled={loading}
					fieldIsChanged={"dhwAutoTemp" in changedFields}
					reset={() => resetField("dhwAutoTemp")}
				/>
			)}

			<Stack direction="row" alignItems="center">
				<NumberInput
					label={t("HysteresisForDomesticHotWaterTankHeating")}
					{...register("dhwHysteresisLowerBound", { required: true, min: 0.1, max: 15 })}
					unit={CELCIUS}
					disabled={loading}
					fieldIsChanged={"dhwHysteresisLowerBound" in changedFields}
					reset={() => resetField("dhwHysteresisLowerBound")}
				/>
				<MoreInfo>
					<Typography gutterBottom>{t("TheNumberOfDegreesBy")}</Typography>
				</MoreInfo>
			</Stack>

			<Stack direction="row" alignItems="center">
				<NumberInput
					label={t("HysteresisForDomesticHotWaterTankCooling")}
					{...register("dhwHysteresisUpperBound", { required: true, min: 1, max: 10 })}
					unit={CELCIUS}
					disabled={loading}
					fieldIsChanged={"dhwHysteresisUpperBound" in changedFields}
					reset={() => resetField("dhwHysteresisUpperBound")}
				/>
				<MoreInfo>
					<Typography gutterBottom>{t("TheNumberOfDegreesTheTemperatureOfTheTapWaterTankMayDecrease")}</Typography>
				</MoreInfo>
			</Stack>

			<Stack direction="row" alignItems="center" mt={2}>
				<div>
					<BooleanInput
						label={t("EnablePassiveMode")}
						disabled={loading}
						{...register("dhwPassiveEnable")}
						fieldIsChanged={"dhwPassiveEnable" in changedFields}
						reset={() => resetField("dhwPassiveEnable")}
					/>
					<BooleanInput
						label={t("EnableCombiMode")}
						disabled={loading}
						{...register("dhwCombinedEnable")}
						fieldIsChanged={"dhwCombinedEnable" in changedFields}
						reset={() => resetField("dhwCombinedEnable")}
					/>
					<BooleanInput
						label={t("EnableActiveMode")}
						disabled={loading}
						{...register("dhwActiveEnable")}
						fieldIsChanged={"dhwActiveEnable" in changedFields}
						reset={() => resetField("dhwActiveEnable")}
					/>
					<BooleanInput
						label={t("EnableBackup")}
						disabled={loading}
						{...register("dhwBackupEnable")}
						fieldIsChanged={"dhwBackupEnable" in changedFields}
						reset={() => resetField("dhwBackupEnable")}
					/>
					<BooleanInput
						label={t("EnableLegionella")}
						disabled={loading}
						{...register("dhwLegionelaEnable")}
						fieldIsChanged={"dhwLegionelaEnable" in changedFields}
						reset={() => resetField("dhwLegionelaEnable")}
					/>
				</div>

				<MoreInfo>
					<Typography gutterBottom>
						<Trans
							i18nKey="TheHeatPumpCanHeatDomesticHotWaterInTheFollowingWays"
							ns="pvtHeatPumpAdminPage"
							components={{ strong: <strong /> }}
						/>
					</Typography>
				</MoreInfo>
			</Stack>

			{fields.dhwLegionelaEnable && (
				<>
					<Stack direction="row" alignItems="center">
						<NumberInput
							label={t("LegionellaTemperature")}
							{...register("dhwLegionelaTemp", { required: true, min: 30, max: 80 })}
							unit={CELCIUS}
							disabled={loading}
							fieldIsChanged={"dhwLegionelaTemp" in changedFields}
							reset={() => resetField("dhwLegionelaTemp")}
						/>
						<MoreInfo>
							<Typography gutterBottom>{t("DesiredTargetTemperature")}</Typography>
						</MoreInfo>
					</Stack>

					<RadioInput
						label={t("LegionellaDay")}
						row
						options={READABLE_DHW_LEGIONELLA_DAY_OPTIONS}
						disabled={loading}
						moreInfo={t("SelectTheDayOnWhichTheTemperatureInTheDomesticHotWaterTankIsRaised")}
						{...register("dhwLegionelaDay", { required: true })}
						fieldIsChanged={"dhwLegionelaDay" in changedFields}
						reset={() => resetField("dhwLegionelaDay")}
					/>

					<Stack direction="row" alignItems="center">
						<NumberInput
							label={t("LegionellaMaximumCondenserTemperature")}
							{...register("dhwLegionellaMaxCondensationTemp", { required: true, min: 50, max: 70 })}
							unit={CELCIUS}
							disabled={loading}
							fieldIsChanged={"dhwLegionellaMaxCondensationTemp" in changedFields}
							reset={() => resetField("dhwLegionellaMaxCondensationTemp")}
						/>
						<MoreInfo>
							<Typography gutterBottom>
								{t("TheMaximumTemperatureAtTheCondenserSideDuringHotWaterOperation")}
							</Typography>
						</MoreInfo>
					</Stack>
				</>
			)}

			<FloatingFab submit={submit} loading={loading} fitBottom={isBatchUpdate} batchLoading={loadingSave}>
				<Save />
				<Typography variant="button">{t("SaveDomesticHotWaterSettings")}</Typography>
			</FloatingFab>
		</>
	)
}
