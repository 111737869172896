import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Button, CircularProgress, MobileStepper, Stack, Theme, useMediaQuery } from "@mui/material"
import { HEAT_PUMP } from "settings/url"
import { styled } from "@mui/material/styles"

const StyledDiv = styled("div")(({ theme }) => ({
	minWidth: theme.spacing(12.5),
}))

const StyledButton = styled(Button)<{
	step?: number
}>(({ step }) => ({
	marginLeft: step === 0 ? "auto" : "0",
	minWidth: "auto",
	justifyContent: "flex-start",
	":last-child": {
		justifyContent: "flex-end",
	},
}))

export interface NavigationProps {
	steps?: string[]
	step?: number
	backStep?: () => void
	finishStep?: () => void
	nextStep?: () => void
	submit?: () => void
	loading?: boolean | undefined
	interfaceId?: string
	disabled?: boolean
}
export default function BottomNavigation({
	steps,
	step,
	backStep,
	finishStep,
	nextStep,
	submit,
	loading,
	interfaceId,
	disabled,
}: NavigationProps) {
	const { t } = useTranslation("general")
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
	const navigate = useNavigate()

	if (!nextStep || !steps) return

	return isMobile ? (
		<MobileStepper
			variant="dots"
			steps={steps.length}
			sx={{ mt: 2, p: 0, background: "transparent" }}
			position="static"
			activeStep={step}
			nextButton={
				step === steps.length - 1 ? (
					<Button size="small" variant="contained" onClick={() => navigate(`/${HEAT_PUMP}/${interfaceId}`)}>
						{t("CloseWizard")}
					</Button>
				) : finishStep ? (
					loading ? (
						<CircularProgress size={30} />
					) : (
						<StyledButton onClick={submit} variant="contained">
							{t("Finish")}
						</StyledButton>
					)
				) : (
					<StyledButton size="small" onClick={nextStep}>
						{t("Next")}
					</StyledButton>
				)
			}
			backButton={
				step !== 0 ? (
					<StyledButton size="small" onClick={backStep} disabled={step === 0}>
						{t("Back")}
					</StyledButton>
				) : (
					<StyledDiv />
				)
			}
		/>
	) : (
		<Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
			{step !== 0 && (
				<StyledButton color="inherit" onClick={backStep}>
					{t("Back")}
				</StyledButton>
			)}

			{finishStep ? (
				loading ? (
					<CircularProgress size={30} />
				) : (
					<StyledButton onClick={submit} variant="contained">
						{t("Finish")}
					</StyledButton>
				)
			) : step === steps.length - 1 ? (
				<StyledButton
					step={step}
					variant="outlined"
					onClick={() => navigate(`/${HEAT_PUMP}/${interfaceId}`)}
					disabled={disabled}
				>
					{t("CloseWizard")}
				</StyledButton>
			) : (
				<StyledButton
					onClick={() => {
						nextStep()
					}}
					disabled={disabled}
					step={step}
					variant="outlined"
				>
					{t("Next")}
				</StyledButton>
			)}
		</Stack>
	)
}
