import MoreInfo from "./MoreInfo"
import { useTranslation } from "react-i18next"
import Markdown from "markdown-to-jsx"
import Image from "components/Image"

export default function SGReadyMoreInfo() {
	const { t } = useTranslation("heatPumpSettingsPage")
	return (
		<MoreInfo>
			<>
				<Markdown>{t("SGReadyMoreInfo")}</Markdown>
				<Image src="/images/sg_ready.jpg" />
			</>
		</MoreInfo>
	)
}
