import { Typography } from "@mui/material"
import {
	InterfaceGroupFieldsFragment,
	UserGroupFieldsFragment,
	useRemoveInterfaceFromGroupMutation,
	useGroupQuery,
	GroupsDocument,
} from "generated/graphql"
import { useTranslation } from "react-i18next"
import QuickTable, { Label } from "components/Table"
import { TFunction } from "i18next"
import { ErrCanNotRemoveOwner } from "resources/backendErrors"
import appendErrorMessage from "tools/appendErrorMessage"
import { useMessage } from "providers/MessageProvider"
import { useState } from "react"
import AddButton from "components/AddButton"
import GroupAddInterface from "components/GroupAddInterface"
import { useConfirm } from "providers/ConfirmProvider"
import { HEAT_PUMP, SETTINGS, STATUS } from "settings/url"
import { useNavigate } from "react-router-dom"

type Props = {
	group: UserGroupFieldsFragment
}

function getLabelsInterfaces(t: TFunction<["general"]>): Label<InterfaceGroupFieldsFragment>[] {
	return [
		{
			key: "name",
			name: t("Name"),
			sortable: true,
			resolve: (group) => group.name,
		},
		{
			key: "id",
			name: t("InterfaceID"),
			sortable: true,
		},
	]
}

export default function ClaimedInterfaces({ group }: Props) {
	const message = useMessage()
	const confirm = useConfirm()
	const navigate = useNavigate()

	const { t } = useTranslation(["general", "groupManagement", "serviceRequests"])
	const [dialogOpenInterfaces, setDialogOpenUsersInterfaces] = useState(false)

	const labelsInterfaces = getLabelsInterfaces(t)

	if (!group?.id) {
		return <Typography>{t("None")}</Typography>
	}

	const { data, loading, error } = useGroupQuery({
		variables: {
			groupId: group?.id,
		},
	})

	const [removeInterface] = useRemoveInterfaceFromGroupMutation({
		refetchQueries: [{ query: GroupsDocument }],
		awaitRefetchQueries: true,
	})

	const handleRemoveInterfaceFromGroup = async (id: string) => {
		if (!group.id) {
			return
		}

		await confirm(t("groupManagement:ConfirmRemoveInterfaceFromGroup", { groupName: group.name }))

		try {
			await removeInterface({ variables: { groupId: group.id, interfaceId: id } })
			message.success(t("groupManagement:HeatPumpRemoved"))
		} catch (e) {
			if (e instanceof Error && e.message.includes(ErrCanNotRemoveOwner)) {
				message.error(t("groupManagement:Errors.TransferAdminRightsBeforeRemovingHP"))
				return
			}
			message.error(appendErrorMessage(t("groupManagement:Errors.UnkownErrorRemovingHP"), e))
		}
	}

	const handleOpen = (id: string) => {
		if (!id) return
		navigate(`/${HEAT_PUMP}/${id}/${STATUS}?ref=${SETTINGS}`)
	}

	return (
		<>
			{loading && <Typography>{t("groupManagement:FetchingGroup")}</Typography>}
			{error && <Typography>{t("groupManagement:Errors.FetchingGroup")}</Typography>}

			<AddButton onClick={() => setDialogOpenUsersInterfaces(true)}>{t("Add")}</AddButton>
			{data?.group?.interfaces && data.group.interfaces.length > 0 ? (
				<QuickTable
					onClickAction={handleRemoveInterfaceFromGroup}
					defaultItemsPerPage={20}
					rows={data?.group.interfaces}
					labels={labelsInterfaces}
					onClickRow={handleOpen}
				/>
			) : (
				<Typography>{t("None")}</Typography>
			)}

			{dialogOpenInterfaces && data?.group && (
				<GroupAddInterface group={data?.group} onClose={() => setDialogOpenUsersInterfaces(false)} />
			)}
		</>
	)
}
