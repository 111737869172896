import { Typography } from "@mui/material"
import RadioInput from "inputs/RadioInput"
import { Trans, useTranslation } from "react-i18next"
import SelectInput from "inputs/SelectInput"
import {
	INTERFACE_V1_2_1_TIMESTAMP,
	isPvtHeatPumpFirmwareNewerOrEqual,
	PVT_HEAT_PUMP_V490_1,
} from "settings/firmwareVersions"
import { useParams } from "react-router-dom"
import { StepperProps } from "../index"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"
import { useMemo } from "react"
import { getStaticLables } from "tools/getStaticLabels"
import i18n from "i18next"

export default function TypeInstallation({
	error,
	loading,
	data,
	register,
	fields,
	BottomNavigation,
	nextStep,
	backStep,
	step,
	steps,
}: StepperProps) {
	const { t } = useTranslation(["heatPumpSettingsPage", "general", "heatPumpStatus"])
	const { interfaceId } = useParams<"interfaceId">()

	if (!fields || !register || !steps || !interfaceId) return

	const {
		READABLE_ROOM_TEMP_CONTROL_WITH_COOLING,
		READABLE_ROOM_TEMP_CONTROL_NO_COOLING,
		READABLE_ROOM_TEMP_CONTROL_NO_COOLING_HIDDEN,
		HIDDEN_SETTING_TEXT,
		includesHeating,
		includesCooling,
		validateRoomControlType,
		getReadableRoomControlType,
	} = useMemo(() => getStaticLables(t), [i18n.language])

	const interfaceHasOTCooling = new Date(data?.interface?.firmwareVersion.timestamp ?? 0) >= INTERFACE_V1_2_1_TIMESTAMP
	const hasHeating = includesHeating(fields.roomTemperatureControl)
	const hasCooling = includesCooling(fields.roomTemperatureControl)

	const readableRoomControlType = getReadableRoomControlType(
		hasHeating,
		hasCooling,
		data?.interface?.firmwareVersion.timestamp,
	)

	const hpHasMinSinkTempSetp = isPvtHeatPumpFirmwareNewerOrEqual(
		data?.interface?.pvtHeatPump?.firmwareVersion ?? Infinity,
		PVT_HEAT_PUMP_V490_1,
	)

	if (error && interfaceId) {
		return <ErrorCard error={error} interfaceId={interfaceId} />
	}

	return (
		<>
			<Typography variant="h6">{steps[3]}</Typography>
			<RadioInput
				label={t("HeatingMode")}
				options={
					interfaceHasOTCooling ? READABLE_ROOM_TEMP_CONTROL_WITH_COOLING : READABLE_ROOM_TEMP_CONTROL_NO_COOLING
				}
				hiddenOptions={!interfaceHasOTCooling ? READABLE_ROOM_TEMP_CONTROL_NO_COOLING_HIDDEN : null}
				hiddenOptionHelperText={HIDDEN_SETTING_TEXT}
				disabled={loading}
				row
				message={t("HeatPumpControlledType")}
				{...register("roomTemperatureControl")}
			/>
			{!hpHasMinSinkTempSetp && hasCooling && (
				<>
					<Typography color="error" gutterBottom>
						{t("Errors.FirmwareNeedsUpdate", {
							firmwareVersion: data?.interface?.pvtHeatPump?.firmwareVersion
								? `V${data?.interface?.pvtHeatPump?.firmwareVersion}`
								: "van deze installatie",
						})}
					</Typography>
					{fields.sinkMinShTemp != null && fields.sinkMinShTemp < 15 && (
						<Typography color="error" gutterBottom>
							<Trans i18nKey="MsgCondensationWarning" ns="heatPumpSettingsPage" components={{ italic: <i /> }} />
						</Typography>
					)}
				</>
			)}
			{hasCooling && (
				<Typography color="primary" gutterBottom>
					{t("MsgCoolingSinkTypeWarning")}
				</Typography>
			)}
			<SelectInput
				label={t("ControlType")}
				options={readableRoomControlType}
				disabled={loading}
				{...register("extendedControlType", {
					validate: validateRoomControlType(data?.interface?.pvtHeatPump?.firmwareVersion ?? Infinity), // if unknown, pick high firmware version number
				})}
			/>
			{BottomNavigation && (
				<BottomNavigation disabled={loading} steps={steps} step={step} backStep={backStep} nextStep={nextStep} />
			)}
		</>
	)
}
