import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton, Stack } from "@mui/material"
import TSDialog from "./TSDialog"
import { useInterfaceOwnerQuery } from "generated/graphql"
import { useTranslation } from "react-i18next"

type Props = {
	interfaceId: string
	onClose: () => void
}
export default function InterfaceClaimedDialog({ interfaceId, onClose }: Props) {
	const { data, loading } = useInterfaceOwnerQuery({ variables: { interfaceId } })
	const { t } = useTranslation(["searchHeatPump", "general"])
	return (
		<TSDialog autoFullScreen open fullWidth onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{t("IsClaimedDialog.HPClaimedByOther")}</DialogTitle>
			<DialogContent>
				<Stack direction="row" spacing={0.25}>
					<DialogContentText gutterBottom>{t("IsClaimedDialog.CurOwnerIs")}</DialogContentText>
					<DialogContentText>
						<i>{loading ? <Skeleton width={150} /> : data?.interfaceOwner?.name ?? t("general:unknown")}.</i>
					</DialogContentText>
				</Stack>
				<DialogContentText>{t("IsClaimedDialog.ContactOwnerForAccess")}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary" variant="contained">
					{t("general:Understood")}
				</Button>
			</DialogActions>
		</TSDialog>
	)
}
