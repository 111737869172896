import { DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from "@mui/material"
import TSDialog from "components/TSDialog"
import {
	InterfaceFieldsFragment,
	ReadHeatPumpGroupSettingsDocument,
	useInviteUserToInterfaceMutation,
	Role,
} from "generated/graphql"
import useForm from "hooks/useForm"
import TextInput from "inputs/TextInput"
import { useMessage } from "providers/MessageProvider"
import appendErrorMessage from "tools/appendErrorMessage"
import LoadingBackdrop from "components/LoadingBackdrop"
import useHasRole from "hooks/useHasRole"
import { ErrInviteToSelf, ErrUserWithoutAcc } from "resources/backendErrors"
import { useTranslation } from "react-i18next"

type Props = {
	iface: Pick<InterfaceFieldsFragment, "name" | "id">
	onClose: () => void
}
export default function InviteUserToInterfaceDialog({ iface, onClose }: Props) {
	const { t } = useTranslation(["heatPumpManagement", "general"])
	const isInternal = useHasRole([Role.Admin, Role.Mechanic])
	const { register, submit } = useForm<{ userEmail: string }>({ userEmail: "" }, handleAddUser)
	const [inviteUser, { loading }] = useInviteUserToInterfaceMutation({
		refetchQueries: [{ query: ReadHeatPumpGroupSettingsDocument, variables: { interfaceId: iface.id, isInternal } }],
		awaitRefetchQueries: true,
	})
	const message = useMessage()

	async function handleAddUser({ userEmail }: { userEmail: string }) {
		try {
			await inviteUser({ variables: { interfaceId: iface.id, email: userEmail } })
			message.success(t("InviteUser"))
			onClose()
		} catch (e) {
			if (e instanceof Error && e.message.includes("interface is already in this group")) {
				message.error(t("general:UserAlreadyHasAccessToInterface"))
				return
			}
			if (e instanceof Error && e.message.includes("email not valid")) {
				message.error(t("general:ThisEmailIsInvalid"))
				return
			}
			if (e instanceof Error && e.message.includes(ErrInviteToSelf)) {
				message.error(t("Errors.InviteToSelf"))
				return
			}
			if (e instanceof Error && e.message.includes(ErrUserWithoutAcc)) {
				message.error(t("Errors.UserWithoutAcc"))
				return
			}
			message.error(appendErrorMessage(t("Errors.UnkownErrorInviting"), e))
		}
	}
	return (
		<TSDialog open onClose={onClose}>
			<form onSubmit={submit}>
				<DialogTitle>{t("InviteNewUser")}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t("MsgInviteDialog")}</DialogContentText>
					<TextInput
						autoFocus
						{...register("userEmail")}
						label={t("general:EmailAdress")}
						defaultValue="info@triplesolar.eu"
						type="email"
					/>
					{loading && <LoadingBackdrop onClick={onClose} />}
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose} variant="outlined">
						{t("general:Cancel")}
					</Button>
					<Button type="submit" color="success" variant="contained" disabled={loading}>
						{t("GrantAccess")}
					</Button>
				</DialogActions>
			</form>
		</TSDialog>
	)
}
