import i18n, { t } from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { i18nextPlugin } from "translation-check"
import { format as formatDate, isDate, isToday, isTomorrow, isYesterday, Locale } from "date-fns"
import { enGB, nl, de } from "date-fns/locale"
import { locizePlugin } from "locize"

const buildDate = import.meta.env.VITE_BUILD_DATE

i18n
	.use(i18nextPlugin)
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(locizePlugin)
	.init({
		ns: [
			"general",
			"accountCreation",
			"accountSettings",
			"groupManagement",
			"heatPumpErrorCard",
			"heatPumpList",
			"heatPumpManagement",
			"heatPumpMonitoring",
			"heatPumpSettingsPage",
			"heatPumpStates",
			"heatPumpStatus",
			"pvtHeatPumpAdminPage",
			"newInterfaceClaimedDialog",
			"notFoundPage",
			"serviceRequests",
			"searchHeatPump",
			"signInPage",
		],
		defaultNS: "general",
		fallbackLng: "en",
		debug: import.meta.env.DEV,
		react: {
			bindI18n: "languageChanged editorSaved",
		},
		interpolation: {
			format: (value, format, lng) => {
				if (isDate(value)) {
					if (isToday(value)) {
						return t("today")
					}
					if (isYesterday(value)) {
						return t("yesterday")
					}
					if (isTomorrow(value)) {
						return t("tomorrow")
					}
					let locale: Locale
					switch (lng) {
						case "en":
							locale = enGB
							break
						case "nl":
							locale = nl
							break
						case "de":
							locale = de
							break
						default:
							locale = enGB
					}
					return formatDate(value, format ?? "PPP", { locale })
				}
				return value
			},
			escapeValue: false,
		},
		detection: {
			cookieMinutes: 5259600,
			caches: ["cookie"],
			lookupCookie: "lang",
			cookieOptions: { path: "/", sameSite: "strict" },
		},
		appendNamespaceToCIMode: true,
		backend: {
			loadPath: `/locales/{{lng}}/{{ns}}.json?v=${buildDate}`,
		},
		supportedLngs: ["nl", "en", "de"],
	})

export default i18n
