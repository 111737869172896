import { LinearProgress } from "@mui/material"
import { useReadHeatPumpAdminStatusQuery } from "generated/graphql"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"
import NamedListItem from "pages/HeatPumpStatusPage/components/NamedListItem"

type Props = {
	interfaceId: string
}

export default function AdminStatus({ interfaceId }: Props) {
	const { data, loading, error } = useReadHeatPumpAdminStatusQuery({ variables: { interfaceId } })
	if (loading) {
		return <LinearProgress />
	}
	if (error || data?.interface == null) {
		return <ErrorCard error={error} interfaceId={interfaceId} />
	}
	return <NamedListItem name="IoT Hub ID" value={data.interface.ioTHubID} />
}
