import {
	GetCurrentUpdateStatusHeatPumpListQuery,
	GetCurrentUpdateStatusListQuery,
	UpdateDevice,
	UpdateProgress,
} from "generated/graphql"
import { Alert } from "@mui/material"
import { useTranslation } from "react-i18next"

interface Props {
	currentStatus: GetCurrentUpdateStatusListQuery | GetCurrentUpdateStatusHeatPumpListQuery | undefined
}
export default function UpdatingDeviceAlert({ currentStatus }: Props) {
	const { t } = useTranslation()

	const isInProgress = currentStatus?.interfaces.some((iface) => {
		return iface.updateStatus?.progress === UpdateProgress.InProgress
	})

	if (!isInProgress) return

	const updatingDevice = currentStatus?.interfaces.find(
		(iface) => iface.updateStatus?.progress === UpdateProgress.InProgress,
	)?.updateStatus?.device

	switch (updatingDevice) {
		case UpdateDevice.Interface:
			return (
				<Alert severity="warning" sx={{ mb: 2 }}>
					{t("InterfaceIsCurrentlyUpdating")}
				</Alert>
			)
		case UpdateDevice.Mantova:
			return (
				<Alert severity="warning" sx={{ mb: 2 }}>
					{t("HeatPumpIsCurrentlyUpdating")}
				</Alert>
			)
		default:
			return null
	}
}
