import { useTranslation } from "react-i18next"

type UserOrGroup = {
	name: string
	isDeleted: boolean
}

type Props = {
	children: UserOrGroup
	italic?: boolean
}

export function FormatUserOrGroup({ children: userOrGroup, italic }: Props) {
	const { t } = useTranslation()
	const name = userOrGroup.isDeleted ? t("DeletedUser") : userOrGroup.name
	if (italic || userOrGroup.isDeleted) return <i>{name}</i>
	return <span>{name}</span>
}

export function useFormatUserOrGroupString() {
	const { t } = useTranslation()
	return (userOrGroup: UserOrGroup | undefined) => {
		if (!userOrGroup) {
			return t("unknown")
		}
		return userOrGroup.isDeleted ? t("DeletedUser") : userOrGroup.name
	}
}
