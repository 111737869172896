import { styled } from "@mui/material/styles"
import { Autocomplete } from "@mui/material"
import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const StyledTextField = styled(TextField)`
	width: 100%;
	max-width: 800px;
`

type Props = {
	value?: string[] | null
	onChange: (value: string[] | null) => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
}

export default function MultipleTextField({ value, onChange, helperText, error, required, label }: Props) {
	const { t } = useTranslation()
	const [text, setText] = useState("")
	if (!label) label = t("Installations")
	// this solves a strange bug where you could not enter anything if the value is null/undefined
	useEffect(() => {
		if (value == null) {
			onChange([])
		}
	})

	const handleChange = (_: unknown, value: string) => setText(value)

	const handleBlur = () => {
		if (text !== "" && !value?.includes(text)) {
			onChange([...(value ?? []), text])
		}
	}

	return (
		<Autocomplete
			onInputChange={handleChange}
			onBlur={handleBlur}
			value={value ?? []}
			options={value ?? []}
			multiple
			freeSolo
			clearOnBlur
			getOptionLabel={(option) => option}
			onChange={(_, val) => onChange(val)}
			ListboxProps={{ style: { display: "none" } }}
			renderInput={(params) => (
				<StyledTextField
					{...params}
					label={label}
					variant="outlined"
					margin="normal"
					helperText={helperText ?? t("MsgMultipleOptionsPossible")}
					error={error}
					required={required}
					fullWidth
				/>
			)}
		/>
	)
}
