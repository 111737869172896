import { Button, CardMedia, CircularProgress, Grid, Typography } from "@mui/material"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { StepperProps } from "../index"
import { styled } from "@mui/material/styles"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import EditableInterfaceName from "components/EditableInterfaceName"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"
import { BackupHeaterType } from "generated/graphql"
import UpsertCascade from "./UpsertCascade"
import useSize from "hooks/useSize"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"

const StyledHeatpumpPlaceholder = styled("div")(({ theme }) => ({
	width: "108px",
	height: "168px",
	border: "2px dashed",
	borderColor: theme.palette.grey[400],
	borderRadius: "10px",
	background: theme.palette.grey[100],
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	color: theme.palette.grey[500],
	cursor: "pointer",
	marginLeft: "auto",
	marginRight: "auto",
	marginBottom: theme.spacing(2),
	transition: ".2s",
	"& svg": {
		fontSize: "36px",
		transition: ".2s",
	},
	"&:hover": {
		borderColor: theme.palette.primary.light,
		"& svg": {
			color: theme.palette.primary.light,
		},
	},
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
	marginLeft: theme.spacing(12),
	textAlign: "center",

	[theme.breakpoints.down("sm")]: {
		marginLeft: 0,
		marginTop: theme.spacing(4),
	},
}))

const StyledCardMedia = styled(CardMedia)<StyledCardMediaProps>(({ theme }) => ({
	objectFit: "contain",
	marginBottom: theme.spacing(-1.5),
	height: 200,
}))

interface StyledCardMediaProps {
	component?: string
	image: string
}
export default function HeatPumps({
	error,
	steps,
	dataSlave,
	BottomNavigation,
	nextStep,
	backStep,
	step,
	loading,
	register,
	fields,
	data,
	set,
}: StepperProps) {
	const { t } = useTranslation(["heatPumpSettingsPage", "general", "groupManagement"])
	const smallScreen = useSize("down", "md")
	const { interfaceId } = useParams<"interfaceId">()

	if (!set || !fields || !register || !steps || !interfaceId) return

	const [addInterfaceOpen, setAddInterfaceOpen] = useState(false)

	const handleSetSlave = async (slaveId: string) => {
		if (!slaveId) return

		set({
			slaveId: slaveId,
			extendedBackupHeater: "CASCADE",
		})
	}

	const removeSlave = async () => {
		set({ slaveId: null, extendedBackupHeater: BackupHeaterType.None })
	}

	const removeBoiler = () => {
		set({ extendedBackupHeater: BackupHeaterType.None })
	}

	const handleAddInterface = (data: boolean) => {
		setAddInterfaceOpen(data)
	}

	const handleClose = () => {
		setAddInterfaceOpen(false)

		set({
			extendedBackupHeater: fields.extendedBackupHeater ? undefined : data?.interface?.controller.backupHeater,
			slaveId: dataSlave?.interface?.slave?.id ?? null,
		})
	}

	if (error && interfaceId) {
		return <ErrorCard error={error} interfaceId={interfaceId} />
	}

	return (
		<>
			<Typography variant="h6" gutterBottom>
				{steps[1]}
			</Typography>

			<Grid container mt={6} spacing={smallScreen ? 0 : 4} justifyContent="center">
				<Grid sx={{ textAlign: "center" }}>
					<StyledCardMedia component="img" image="/images/heatpump.svg" />
					<Typography variant="h6" gutterBottom sx={{ ml: "55px" }}>
						<EditableInterfaceName shouldRender interfaceId={interfaceId} />
					</Typography>
				</Grid>
				<StyledGrid>
					{loading ? (
						<CircularProgress size={30} sx={{ mt: 7 }} />
					) : fields.slaveId ? (
						<>
							<StyledCardMedia component="img" image="/images/heatpump.svg" />
							<Typography variant="h6" gutterBottom sx={{ ml: "55px" }}>
								<EditableInterfaceName shouldRender interfaceId={fields.slaveId} />
							</Typography>
							<Button startIcon={<CloseIcon />} onClick={removeSlave}>
								{t("general:Delete")}
							</Button>
						</>
					) : fields.extendedBackupHeater === BackupHeaterType.Opentherm ? (
						<>
							<StyledCardMedia component="img" image="/images/gasBoiler.svg" />
							<Typography variant="h6" gutterBottom>
								{t("OTBoiler")}
							</Typography>
							<Button startIcon={<CloseIcon />} onClick={removeBoiler}>
								{t("general:Delete")}
							</Button>
						</>
					) : (
						<>
							<StyledHeatpumpPlaceholder onClick={() => setAddInterfaceOpen(true)}>
								<AddCircleIcon />
							</StyledHeatpumpPlaceholder>
							<Typography variant="body2" gutterBottom sx={{ whiteSpace: "pre-line" }}>
								{t("AddCascadeSetup")}
							</Typography>
						</>
					)}

					{addInterfaceOpen && (
						<UpsertCascade
							onClose={handleClose}
							register={register}
							fields={fields}
							data={data}
							loading={loading}
							handleSetSlave={handleSetSlave}
							handleAddInterface={handleAddInterface}
						/>
					)}
				</StyledGrid>
			</Grid>

			{BottomNavigation && <BottomNavigation steps={steps} step={step} backStep={backStep} nextStep={nextStep} />}
		</>
	)
}
