import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Typography,
	useTheme,
} from "@mui/material"
import { InfoOutlined } from "@mui/icons-material"
import TSDialog from "../../../components/TSDialog"
import Markdown from "markdown-to-jsx"
import AdvancedErrorView from "./AdvancedErrorView"
import { styled } from "@mui/material/styles"

type Props = {
	errorCode: number
}

const StyledErrorDiv = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	minHeight: theme.spacing(4.8),
}))

const getErrorMessage = (ErrorData: string) => {
	const lines = ErrorData.split("\n")
	return lines[0].replace(/^#\s*/, "").trim()
}

const getFatalType = (ErrorData: string) => {
	const lines = ErrorData.split("\n")
	return lines[1]
}

const getDialogTitle = (ErrorData: string) => {
	const pattern = /##\s*DialogTitle\s*[\n\r]+-\s*(.+)$/im
	const matches = ErrorData.match(pattern)

	if (matches && matches[1]) {
		return matches[1].trim()
	}
}

const getShortDescriptionLabel = (ErrorData: string) => {
	const pattern = /^#{4}\s+(.*)$/m
	const matches = ErrorData.match(pattern)

	if (matches && matches[1]) {
		return matches[1].trim()
	}
}

const getShortDescriptionContent = (ErrorData: string) => {
	const startPattern = /^###\s*Short/m
	const endPattern = /^###\s*Long/m

	const startMatch = ErrorData.match(startPattern)

	if (startMatch) {
		const content = ErrorData.slice((startMatch.index || 0) + (startMatch[0]?.length || 0))
		const endMatch = content.match(endPattern)

		if (endMatch) {
			return content.slice(0, endMatch.index)
		}
	}

	return ""
}

const getLongDescription = (ErrorData: string) => {
	const regex = /#### (\w+)\n(-[\s\S]*?)(?=\n\n#### (\w+|\w+\s\w+)|\n\n$)/g
	let match
	const results = []

	while ((match = regex.exec(ErrorData)) !== null) {
		const subsectionTitle = match[1]

		if (!["Short", "Long"].includes(subsectionTitle)) {
			const items = match[2].replace(/(?<=\n)- /g, "- ")
			results.push({ title: subsectionTitle, items: items.split("\n").map((item) => item) })
		}
	}

	return results
}

const getTabsArray = (ErrorData: string) => {
	const itemsArray = []
	const tabsSectionContent = ErrorData.match(/## Tabs([\s\S]*?)(?=\n##|\n$)/)
	if (tabsSectionContent && tabsSectionContent[1]) {
		itemsArray.push(
			tabsSectionContent[1]
				.trim()
				.split("\n")
				.map((item) => item.trim().replace(/^\d+\.\s*/, "")) // Remove numbers from the item
		)
	}

	return itemsArray
}

interface ErrorObjectType {
	shortDescription: string
	shortDescriptionLabel: string
	longDescription: { title: string; items: string[] }[]
	tabs: string[]
	dialogTitle: string
	errorName: string
}

const importErrorMarkdown = async (
	language: string,
	errorCode: number,
	handleUnknownErrorMessage: (message: string) => void
) => {
	let url
	switch (language) {
		case "nl":
			url = `/ErrorDescriptions/nl/Error${errorCode}.md`
			break
		case "de":
			url = `/ErrorDescriptions/de/Error${errorCode}.md`
			break
		default:
			url = `/ErrorDescriptions/en/Error${errorCode}.md`
			break
	}

	try {
		const response = await fetch(url)
		if (response.ok) {
			return await response.text()
		} else {
			throw new Error(`Failed to load ${url}: ${response.statusText}`)
		}
	} catch {
		const fallbackUrl = `/ErrorDescriptions/en/Error${errorCode}.md`
		try {
			const response = await fetch(fallbackUrl)
			if (response.ok) {
				return await response.text()
			} else {
				throw new Error(`Failed to load fallback URL ${fallbackUrl}: ${response.statusText}`)
			}
		} catch (fallbackError) {
			handleUnknownErrorMessage(`Unknown errorcode ${errorCode}`)
		}
	}
}

export default function ErrorComponent({ errorCode }: Props) {
	const { t, i18n } = useTranslation(["heatPumpErrors", "general"])

	const [errorMessages, setErrorMessages] = useState<Array<string>>([])
	const [isOpen, setIsOpen] = useState(false)
	const [isMoreInfo, setIsMoreInfo] = useState(false)
	const theme = useTheme()

	const [errorObject, setErrorObject] = useState<ErrorObjectType>({
		shortDescription: "",
		shortDescriptionLabel: "",
		longDescription: [],
		tabs: [],
		dialogTitle: "",
		errorName: "",
	})

	const handleUnknownErrorMessage = (message: string) => {
		setErrorMessages([message])
	}

	const checkError = async () => {
		const ErrorMarkdownImport = await importErrorMarkdown(
			i18n.language.split("-").at(0) || "en",
			errorCode,
			handleUnknownErrorMessage
		)
		const ErrorMarkdown = ErrorMarkdownImport ?? ""

		setErrorMessages((prevErrorMessages) => {
			const errorArray = [...prevErrorMessages]

			getFatalType(ErrorMarkdown)
			getDialogTitle(ErrorMarkdown)
			getLongDescription(ErrorMarkdown)
			setErrorObject({
				shortDescriptionLabel: getShortDescriptionLabel(ErrorMarkdown) ?? "",
				shortDescription: getShortDescriptionContent(ErrorMarkdown) ?? "",
				longDescription: getLongDescription(ErrorMarkdown),
				tabs: getTabsArray(ErrorMarkdown).flat(),
				dialogTitle: getDialogTitle(ErrorMarkdown) ?? "",
				errorName: getErrorMessage(ErrorMarkdown) ?? "",
			})
			errorArray.push(getErrorMessage(ErrorMarkdown))

			return errorArray
		})
	}

	useEffect(() => {
		;(async () => {
			try {
				await checkError()
			} catch (error) {
				console.error(error)
			}
		})()

		return () => {
			setErrorMessages([])
		}
	}, [errorCode, i18n.language])

	return (
		<StyledErrorDiv>
			{errorMessages.map((el: string) => (
				<Typography
					key={`message-${el}`}
					sx={{ fontStyle: "italic", textAlign: "right", pr: "2px" }}
					color="error"
					alignSelf="center"
				>
					{el}
				</Typography>
			))}
			{/*check for shortdescription*/}
			{errorObject.shortDescription.trim().length > 0 && (
				<IconButton
					onClick={() => {
						setIsOpen(true)
					}}
				>
					<InfoOutlined />
				</IconButton>
			)}
			{isOpen && (
				<TSDialog
					autoFullScreen
					open
					aria-labelledby="form-dialog-title"
					fullWidth
					sx={{ zIndex: theme.zIndex.drawer + 1 }}
				>
					<DialogTitle id="form-dialog-title">
						{t("InfoAbout")} {errorObject.errorName}
					</DialogTitle>
					<DialogContent>
						<DialogContentText component="span">
							{isMoreInfo ? (
								<AdvancedErrorView errorObject={errorObject} />
							) : (
								<>
									<DialogContentText variant="h5" sx={{ mb: 2 }}>
										{errorObject.dialogTitle}
									</DialogContentText>
									<Markdown>{errorObject.shortDescription}</Markdown>
								</>
							)}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								setIsOpen(false)
								setIsMoreInfo(false)
							}}
							variant="outlined"
						>
							{t("general:Back")}
						</Button>

						{errorObject.longDescription.length > 0 && (
							<Button
								onClick={() => {
									setIsMoreInfo(!isMoreInfo)
								}}
								variant="contained"
							>
								{isMoreInfo ? t("Summary") : t("MoreInfo")}
							</Button>
						)}
					</DialogActions>
				</TSDialog>
			)}
		</StyledErrorDiv>
	)
}
