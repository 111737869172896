import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { StepperProps } from "../index"
import { styled } from "@mui/material/styles"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"

const StyledDiv = styled("div")(() => ({
	whiteSpace: "pre-line",
}))

export default function IntroWizard({
	error,
	steps,
	BottomNavigation,
	nextStep,
	backStep,
	step,
	loading,
}: StepperProps) {
	const { t } = useTranslation(["heatPumpSettingsPage"])
	const { interfaceId } = useParams<"interfaceId">()

	if (!interfaceId) return

	if (error && interfaceId) {
		return <ErrorCard error={error} interfaceId={interfaceId} />
	}

	return (
		<Box>
			<Typography variant="h6" gutterBottom>
				{t("WelcomeToWizard")}
			</Typography>
			<StyledDiv>{t("WizardIntro")}</StyledDiv>

			{BottomNavigation && (
				<BottomNavigation steps={steps} step={step} backStep={backStep} nextStep={nextStep} loading={loading} />
			)}
		</Box>
	)
}
