import {
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Stack,
	Switch,
	FormControlLabel,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from "@mui/material"
import TSDialog from "components/TSDialog"
import useForm from "hooks/useForm"
import appendErrorMessage from "tools/appendErrorMessage"
import { useMessage } from "providers/MessageProvider"
import Markdown from "markdown-to-jsx"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { styled } from "@mui/material/styles"
import { ExpandMore } from "@mui/icons-material"
import DateInput from "inputs/DateInput"
import SelectInput from "inputs/SelectInput"
import {
	Language,
	NotificationInput,
	useUpsertNotificationMutation,
	NotificationType,
	NotificationSeverity,
	UserNotificationFieldsFragment,
	AdminNotificationsDocument,
	useDeleteNotificationMutation,
} from "generated/graphql"
import TranslatedTextInput from "inputs/TranslatedTextInput"
import { Translation, languageOrder } from "tools/translation"
import { useConfirm } from "providers/ConfirmProvider"

type Props = {
	onClose: () => void
	notification?: UserNotificationFieldsFragment | null
}

const StyledContent = styled("div")(({ theme }) => ({
	minHeight: theme.spacing(25),
	padding: `${theme.spacing(2)} ${theme.spacing(1.75)}`,
	marginTop: theme.spacing(1.75),
	marginBottom: theme.spacing(1.75),
	paddingTop: theme.spacing(2),
	["& ul"]: {
		paddingLeft: theme.spacing(2),
		marginBottom: theme.spacing(0.5),
	},
	["& li"]: {
		marginBottom: theme.spacing(0.5),
		listStyle: "disc",
		["& p"]: {
			margin: 0,
		},
	},
	["& img"]: {
		maxWidth: 200,
	},
	["& strong"]: {
		fontWeight: 500,
	},
}))

export function formatMarkDown(data: string) {
	return data
		.split("\n")
		.map((line) => line.trim())
		.join("\n")
}

function validateTranslation(value: Translation | undefined) {
	if (!value?.en) return "English is required"

	return null
}

export default function UpsertNotifications({ onClose, notification: editNotification }: Props) {
	const confirm = useConfirm()
	const message = useMessage()
	const { t } = useTranslation("general")
	const [preview, setPreview] = useState(false)
	const [expanded, setExpanded] = React.useState<Language | null>(Language.En)

	const [upsertNotification] = useUpsertNotificationMutation({
		refetchQueries: [
			{
				query: AdminNotificationsDocument,
				variables: { types: [NotificationType.Notification, NotificationType.Banner] },
			},
		],
		awaitRefetchQueries: true,
	})

	const [deleteNotification] = useDeleteNotificationMutation({
		refetchQueries: [{ query: AdminNotificationsDocument, variables: { includeExpired: false } }],
		awaitRefetchQueries: true,
	})

	// translage only once at first render
	const READABLE_SEVERITY_TYPES = useMemo(
		() => ({
			success: t("SeverityTypes.Success"),
			warning: t("SeverityTypes.Warning"),
			error: t("SeverityTypes.Error"),
			info: t("SeverityTypes.Info"),
		}),
		[],
	)

	const { register, fields, set, submit } = useForm<NotificationInput>(
		editNotification ?? {
			severity: NotificationSeverity.Info,
			type: NotificationType.Notification,
		},
		handleSave,
		["severity", "type"],
	)

	const isBanner = fields?.type === NotificationType.Banner

	async function handleSave(dataNotification: NotificationInput) {
		try {
			await upsertNotification({ variables: { notification: dataNotification } })
			message.success("Notification opgeslagen")
			onClose()
		} catch (e) {
			message.error(appendErrorMessage("Opslaan mislukt", e))
		}
	}

	const handleChangeAccordion = (lang: Language) => (_: React.SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? lang : null)
	}

	const handleDelete = async (id: string) => {
		await confirm("Are u sure u want to delete this notification?")
		try {
			await deleteNotification({ variables: { notificationId: id } })
			message.success("Notification verwijderd")
			onClose()
		} catch (e) {
			message.error(appendErrorMessage("Verwijderen mislukt", e))
		}
	}

	return (
		<TSDialog
			autoFullScreen
			open
			onClose={onClose}
			aria-labelledby="form-dialog-title"
			fullScreenDown="md"
			maxWidth="md"
		>
			<DialogTitle id="form-dialog-title">{t("Notifications")}</DialogTitle>

			<DialogContent>
				<DialogContentText mb={2}>{t("FillMessageBelow")}</DialogContentText>
				{languageOrder.map((language) => (
					<Accordion key={language} expanded={expanded === language} onChange={handleChangeAccordion(language)}>
						<AccordionSummary expandIcon={<ExpandMore />}>
							<Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
								<Stack direction="row" justifyContent="space-between" spacing={2}>
									<Typography sx={{ fontWeight: "500", textTransform: "uppercase" }} variant="subtitle1">
										{language}
									</Typography>
									{language === "en" && (
										<Typography sx={{ color: "text.secondary", fontStyle: "italic" }} variant="subtitle1">
											{t("Required")}
										</Typography>
									)}
								</Stack>
								<Typography sx={{ color: "text.secondary" }}>
									{fields.type === NotificationType.Banner ? t("Banner") : t("Notification")}
								</Typography>
							</Stack>
						</AccordionSummary>

						<AccordionDetails>
							<TranslatedTextInput
								language={language}
								label={t("Title")}
								{...register("title", {
									required: language === Language.En,
									validate: language === Language.En ? validateTranslation : undefined,
								})}
							/>

							{preview && fields.content?.[language] ? (
								<StyledContent>
									<Markdown>{formatMarkDown(fields.content[language] ?? "")}</Markdown>
								</StyledContent>
							) : (
								<>
									<TranslatedTextInput
										language={language}
										fullWidth
										rows={8}
										label={t("Message")}
										multiline
										{...register("content", {
											required: language === Language.En,
											validate: language === Language.En ? validateTranslation : undefined,
										})}
									/>
								</>
							)}

							<Stack direction="row" justifyContent="space-between" width="100%">
								<Button
									variant={preview ? "contained" : "outlined"}
									color="primary"
									disabled={!fields.content}
									onClick={() => setPreview(!preview)}
								>
									{preview ? t("ClosePreview") : t("Preview")}
								</Button>
							</Stack>
						</AccordionDetails>
					</Accordion>
				))}
			</DialogContent>
			<DialogActions>
				<Stack width="100%" pl={2} pr={2} pb={1}>
					<Stack direction="row" alignItems="center">
						<FormControlLabel
							control={
								<Switch
									checked={isBanner}
									onChange={(e) => {
										const type = e.target.checked ? NotificationType.Banner : NotificationType.Notification
										set({ type: type })
									}}
								/>
							}
							label={t("SetAsBanner")}
						/>

						<Stack direction="row" spacing={2} justifyContent="space-between">
							{isBanner && (
								<SelectInput
									size="small"
									fullWidth
									label={t("Severity")}
									options={READABLE_SEVERITY_TYPES}
									{...register("severity", {
										required: true,
									})}
								/>
							)}
						</Stack>

						<Stack direction="row" spacing={2} justifyContent="space-between" ml="auto">
							<DateInput
								size="small"
								fullWidth={false}
								label={t("ExpireDate")}
								{...register("expirationDate", { required: true })}
							/>
						</Stack>
					</Stack>
					<Stack direction="row" justifyContent="space-between" pt={2}>
						<Button variant="outlined" onClick={() => handleDelete(fields.id as string)} color="primary">
							{t("Delete")}
						</Button>

						<Stack direction="row" spacing={2}>
							<Button onClick={onClose} color="primary">
								{t("Cancel")}
							</Button>
							<Button variant="contained" color="primary" onClick={submit}>
								{t("Save")}
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</DialogActions>
		</TSDialog>
	)
}
