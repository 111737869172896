import { ApolloCache, InMemoryCache } from "@apollo/client"
import { Save } from "@mui/icons-material"
import { LinearProgress, Stack, Typography } from "@mui/material"
import {
	PvtHeatPumpAdminSettingsHeatingCoolingFragment,
	PvtHeatPumpAdminSettingsHeatingCoolingFragmentDoc,
	PvtHeatPumpInput,
	UpdatePvtHeatPumpSettingsMutation,
	UpdatePvtHeatPumpSettingsMutationVariables,
	useReadHeatPumpHeatingCoolingSettingsQuery,
	useUpdatePvtHeatPumpSettingsMutation,
} from "generated/graphql"
import useForm from "hooks/useForm"
import NumberInput from "inputs/NumberInput"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"
import { useMessage } from "providers/MessageProvider"
import { Trans, useTranslation } from "react-i18next"
import appendErrorMessage from "tools/appendErrorMessage"
import onlyChangedValues from "tools/onlyChangedValues"
import RadioInput from "inputs/RadioInput"
import BooleanInput from "inputs/BooleanInput"
import MoreInfo from "components/MoreInfo"
import { isPvtHeatPumpFirmwareNewerOrEqual, PVT_HEAT_PUMP_V1_1 } from "settings/firmwareVersions"
import { PvtHeatPumpData } from "../index"
import FloatingFab from "components/FloatingFab"
import InterfaceSaveTypeAlert from "components/InterfaceSaveTypeAlert"
import { StatusMessageProps } from "components/BatchUpdate"
import handleSettingsError from "tools/handleSettingsError"
import { GraphQLError } from "graphql/error"
const CELCIUS = "°C"

function updatePvtHeatPumpCache(
	cache: ApolloCache<InMemoryCache>,
	{ data, errors }: { data?: UpdatePvtHeatPumpSettingsMutation | null; errors?: readonly GraphQLError[] | undefined },
	{ variables }: { variables?: UpdatePvtHeatPumpSettingsMutationVariables },
) {
	if (!data?.updatePvtHeatPump || !variables) return
	const { pvtHeatPumpdata: inputData, interfaceIds } = variables
	const ids = Array.isArray(interfaceIds) && interfaceIds.length > 0 ? interfaceIds : []

	const interfaceIdsWithErrors = errors
		?.filter((error) => typeof error.extensions.interfaceID === "string")
		.map((error) => error.extensions.interfaceID)

	const onlineInterfaceIds = ids.filter((id) => !interfaceIdsWithErrors?.includes(id))

	onlineInterfaceIds.forEach((defaultInterfaceId) => {
		const id = `PvtHeatPumpData:${defaultInterfaceId}`
		const oldPvtHeatPump = cache.readFragment<PvtHeatPumpAdminSettingsHeatingCoolingFragment>({
			id,
			fragment: PvtHeatPumpAdminSettingsHeatingCoolingFragmentDoc,
			fragmentName: "PvtHeatPumpAdminSettingsHeatingCooling",
		})
		if (!oldPvtHeatPump) return

		const newPvtHeatPump = (
			Object.keys(oldPvtHeatPump) as Array<keyof PvtHeatPumpAdminSettingsHeatingCoolingFragment>
		).reduce(
			(acc, key) => ({ ...acc, [key]: inputData[key as keyof PvtHeatPumpInput] ?? oldPvtHeatPump[key] }),
			{} as PvtHeatPumpAdminSettingsHeatingCoolingFragment,
		)

		cache.writeFragment<PvtHeatPumpAdminSettingsHeatingCoolingFragment>({
			id,
			fragment: PvtHeatPumpAdminSettingsHeatingCoolingFragmentDoc,
			fragmentName: "PvtHeatPumpAdminSettingsHeatingCooling",
			data: newPvtHeatPump,
		})
	})
}

type Props = {
	defaultInterfaceId: string
	batchInterfaceIds?: string[]
	isPartialSave?: boolean
	handleSavingMessages?: (updateStatus: StatusMessageProps[]) => void | undefined
	handleSaveLoading?: (isLoading: boolean) => void
}

export default function HeatingCoolingSettings({
	defaultInterfaceId,
	batchInterfaceIds,
	isPartialSave,
	handleSavingMessages,
	handleSaveLoading,
}: Props) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage", "general", "heatPumpSettingsPage", "heatPumpErrorCard"])
	const message = useMessage()
	const { register, submit, reset, fields, set } = useForm<PvtHeatPumpInput>({}, handleSave)

	const isBatchUpdate = Array.isArray(batchInterfaceIds) && batchInterfaceIds.length > 0

	const { data, loading, error } = useReadHeatPumpHeatingCoolingSettingsQuery({
		variables: { interfaceId: defaultInterfaceId },
		onCompleted: (data) => {
			if (data.interface?.pvtHeatPump) {
				const { firmwareVersion: _firmwareVersion, id: _id, __typename, ...rest } = data.interface.pvtHeatPump
				reset(rest)
			}
		},
	})

	const [updateSettings, { loading: loadingSave }] = useUpdatePvtHeatPumpSettingsMutation({
		update: updatePvtHeatPumpCache,
	})

	const changedFields = onlyChangedValues(data?.interface?.pvtHeatPump ?? {}, fields)

	async function handleSave() {
		if ((isPartialSave || isPartialSave === undefined) && Object.entries(changedFields).length === 0) {
			message.info(t("heatPumpSettingsPage:Errors.HPHasTheseSettings"))
			return
		}

		if (isBatchUpdate) {
			message.info(t("general:SavingSettingsPleaseWait"))
		}

		try {
			const response = await updateSettings({
				variables: {
					interfaceIds: isBatchUpdate ? batchInterfaceIds : [defaultInterfaceId],
					pvtHeatPumpdata: isBatchUpdate ? (isPartialSave ? changedFields : fields) : changedFields,
					controllerSettings: {},
					isExpert: true,
					isAdmin: false,
				},
				errorPolicy: isBatchUpdate ? "all" : "none", // If 1 or more interface is offline, the mutation will continue, This will ignore the error catch for GraphQL
			})

			if (handleSavingMessages) {
				handleSavingMessages([{ success: true }])
			}

			// Since we use errorPolicy: "all", the error won't return at catch error
			if (response?.errors) {
				handleSettingsError({ errorResponse: response.errors, handleSavingMessages, t })
			}
		} catch (e) {
			message.error(appendErrorMessage(t("heatPumpSettingsPage:Errors.UnkownSaving"), e))
		}

		message.success(t("heatPumpSettingsPage:SettingsSaved"))

		if (handleSaveLoading) {
			handleSaveLoading(loadingSave)
		}
	}

	const resetField = (field: string) => {
		if (!field || !data?.interface?.pvtHeatPump) return

		const pvtHeatPumpData: PvtHeatPumpData = data?.interface.pvtHeatPump
		const value = pvtHeatPumpData[field]

		set({
			[field]: value,
		})
	}

	if (loading) {
		return <LinearProgress />
	}

	if (error || data?.interface == null) {
		return <ErrorCard error={error} interfaceId={defaultInterfaceId} />
	}

	const READABLE_HEATING_OPTIONS = {
		OFF: t("general:Off"),
		HEATING: t("heatPumpSettingsPage:Heating"),
		COOLING: t("heatPumpSettingsPage:Cooling"),
		HEATING_AND_COOLING: t("heatPumpSettingsPage:HeatingAndCooling"),
	}

	const READABLE_HEATING_BY_OPTIONS = {
		THERMOSTAT: t("heatPumpSettingsPage:OnOff"),
		ROOM_SENSOR: t("heatPumpSettingsPage:RoomTempSensor"),
		DM_CONTROL: t("heatPumpSettingsPage:HeatingCurve"),
		OPENTHERM: t("general:OpenTherm"),
	}

	const READABLE_AUX_RELAY_OPTIONS = {
		OFF: t("general:Off"),
		SMATRIX: t("heatPumpSettingsPage:Smatrix"),
		OPENTHERM: t("general:OpenTherm"),
	}

	return (
		<>
			{batchInterfaceIds && batchInterfaceIds.length > 0 && <InterfaceSaveTypeAlert isPartialSave={isPartialSave} />}

			<RadioInput
				label={t("HeatingMode")}
				row
				options={READABLE_HEATING_OPTIONS}
				disabled={loading}
				{...register("roomTemperatureControl", { required: true })}
				fieldIsChanged={"roomTemperatureControl" in changedFields}
				reset={() => resetField("roomTemperatureControl")}
			/>

			<RadioInput
				label={`${t("HeatingVia")}:`}
				row
				options={READABLE_HEATING_BY_OPTIONS}
				disabled={loading}
				{...register("roomControlType", { required: true })}
				fieldIsChanged={"roomControlType" in changedFields}
				reset={() => resetField("roomControlType")}
			/>

			<BooleanInput
				label={t("SHPassiveRelease")}
				disabled={loading}
				{...register("shPasiveEnable")}
				fieldIsChanged={"shPasiveEnable" in changedFields}
				reset={() => resetField("shPasiveEnable")}
			/>
			<BooleanInput
				label={t("SHCombinationRelease")}
				disabled={loading}
				{...register("shCombineEnable")}
				fieldIsChanged={"shCombineEnable" in changedFields}
				reset={() => resetField("shCombineEnable")}
			/>
			<BooleanInput
				label={t("SHActiveRelease")}
				disabled={loading}
				{...register("shActiveEnable")}
				fieldIsChanged={"shActiveEnable" in changedFields}
				reset={() => resetField("shActiveEnable")}
			/>
			<BooleanInput
				label={t("SHBackupRelease")}
				disabled={loading}
				{...register("shBackupEnable")}
				fieldIsChanged={"shBackupEnable" in changedFields}
				reset={() => resetField("shBackupEnable")}
			/>

			<BooleanInput
				label={t("SCPassiveRelease")}
				disabled={loading}
				{...register("scPasiveEnable")}
				fieldIsChanged={"scPasiveEnable" in changedFields}
				reset={() => resetField("scPasiveEnable")}
			/>
			<BooleanInput
				label={t("SCCombinationRelease")}
				disabled={loading}
				{...register("scCombineEnable")}
				fieldIsChanged={"scCombineEnable" in changedFields}
				reset={() => resetField("scCombineEnable")}
			/>
			<BooleanInput
				label={t("SCActiveRelease")}
				disabled={loading}
				{...register("scActiveEnable")}
				fieldIsChanged={"scActiveEnable" in changedFields}
				reset={() => resetField("scActiveEnable")}
			/>

			{isPvtHeatPumpFirmwareNewerOrEqual(data?.interface?.pvtHeatPump?.firmwareVersion ?? 0, PVT_HEAT_PUMP_V1_1) && (
				<RadioInput
					label={t("heatPumpSettingsPage:CoolingContactMode")}
					row
					options={READABLE_AUX_RELAY_OPTIONS}
					disabled={loading}
					{...register("auxRelayMode", { required: true })}
				/>
			)}
			<Stack direction="row" alignItems="center">
				<NumberInput
					label={t("SHSetRoomTemperature")}
					{...register("shRoomSetpTemp", { required: true, min: 5, max: 30 })}
					unit={CELCIUS}
					disabled={loading}
					fieldIsChanged={"shRoomSetpTemp" in changedFields}
					reset={() => resetField("shRoomSetpTemp")}
				/>
				<MoreInfo>
					<Typography gutterBottom>
						<Trans i18nKey="WhenHeatingVia" ns="pvtHeatPumpAdminPage" components={{ italic: <i /> }} />
					</Typography>
				</MoreInfo>
			</Stack>

			<Stack direction="row" alignItems="center">
				<NumberInput
					label={t("SHRoomTemperatureHysteresis")}
					{...register("shRoomHysteresisTemp", { required: true, min: 0.1, max: 3 })}
					unit={CELCIUS}
					disabled={loading}
					fieldIsChanged={"shRoomHysteresisTemp" in changedFields}
					reset={() => resetField("shRoomHysteresisTemp")}
				/>
				<MoreInfo>
					<Typography gutterBottom>{t("TheMaximumTemperatureTheRoomWillCoolDown")}</Typography>
				</MoreInfo>
			</Stack>

			<Stack direction="row" alignItems="center">
				<NumberInput
					label={t("MaximumSupplyTemperatureSH")}
					{...register("sinkMaxShTemp", { required: true, min: -10, max: 100 })}
					unit={CELCIUS}
					disabled={loading}
					fieldIsChanged={"sinkMaxShTemp" in changedFields}
					reset={() => resetField("sinkMaxShTemp")}
				/>
				<MoreInfo>
					<Typography gutterBottom>{t("DefaultForUnderfloorHeating")}</Typography>
				</MoreInfo>
			</Stack>

			<Stack direction="row" alignItems="center">
				<NumberInput
					label={t("MinimumDeliveryTemperatureSH")}
					{...register("sinkMinShTemp", { required: true, min: -10, max: 30 })}
					unit={CELCIUS}
					disabled={loading}
					fieldIsChanged={"sinkMinShTemp" in changedFields}
					reset={() => resetField("sinkMinShTemp")}
				/>
				<MoreInfo>
					<Typography gutterBottom>{t("Default5C")}</Typography>
				</MoreInfo>
			</Stack>

			<Stack direction="row" alignItems="center">
				<NumberInput
					label={t("SCSetRoomTemperature")}
					{...register("scRoomSetpTemp", { required: true, min: 5, max: 30 })}
					unit={CELCIUS}
					disabled={loading}
					fieldIsChanged={"scRoomSetpTemp" in changedFields}
					reset={() => resetField("scRoomSetpTemp")}
				/>
				<MoreInfo>
					<Typography gutterBottom>
						<Trans i18nKey="WhenCoolingVia" ns="pvtHeatPumpAdminPage" components={{ italic: <i /> }} />
					</Typography>
				</MoreInfo>
			</Stack>

			<Stack direction="row" alignItems="center">
				<NumberInput
					label={t("SCRoomTemperatureHysteresis")}
					{...register("scRoomHysteresisTemp", { required: true, min: 0.1, max: 3 })}
					unit={CELCIUS}
					disabled={loading}
					fieldIsChanged={"scRoomHysteresisTemp" in changedFields}
					reset={() => resetField("scRoomHysteresisTemp")}
				/>
				<MoreInfo>
					<Typography gutterBottom>{t("TheMaximumTemperatureTheRoomWillWarmUp")}</Typography>
				</MoreInfo>
			</Stack>

			{!isPvtHeatPumpFirmwareNewerOrEqual(data?.interface?.pvtHeatPump?.firmwareVersion ?? 0, PVT_HEAT_PUMP_V1_1) && (
				<>
					<Stack direction="row" alignItems="center">
						<NumberInput
							label={t("SupplyTemperatureAtHighOutdoorTemperature")}
							{...register("supplyTempAtHiOutdoorTemp", { required: true, min: 0, max: 70 })}
							unit={CELCIUS}
							disabled={loading}
							fieldIsChanged={"supplyTempAtHiOutdoorTemp" in changedFields}
							reset={() => resetField("supplyTempAtHiOutdoorTemp")}
						/>
						<MoreInfo>
							<Typography gutterBottom>{t("ForDeterminingTheHeatingCurve")}</Typography>
						</MoreInfo>
					</Stack>

					<Stack direction="row" alignItems="center">
						<NumberInput
							label={t("SupplyTemperatureAtLowOutdoorTemperature")}
							{...register("supplyTempAtLowOutdoorTemp", { required: true, min: 0, max: 70 })}
							unit={CELCIUS}
							disabled={loading}
							fieldIsChanged={"supplyTempAtLowOutdoorTemp" in changedFields}
							reset={() => resetField("supplyTempAtLowOutdoorTemp")}
						/>
						<MoreInfo>
							<Typography gutterBottom>{t("ForDeterminingTheHeatingCurve")}</Typography>
						</MoreInfo>
					</Stack>

					<Stack direction="row" alignItems="center">
						<NumberInput
							label={t("HighOutdoorTemperature")}
							{...register("outdoorHiTemp", { required: true, min: -30, max: 40 })}
							unit={CELCIUS}
							disabled={loading}
							fieldIsChanged={"outdoorHiTemp" in changedFields}
							reset={() => resetField("outdoorHiTemp")}
						/>
						<MoreInfo>
							<Typography gutterBottom>{t("ForDeterminingTheHeatingCurve")}</Typography>
						</MoreInfo>
					</Stack>
				</>
			)}

			<Stack direction="row" alignItems="center">
				<NumberInput
					label={t("MinimumDeliveryTemperatureForCooling")}
					{...register("sinkCoolingPauseThresholdTemp", { required: true, min: 10, max: 25 })}
					unit={CELCIUS}
					disabled={loading}
					fieldIsChanged={"sinkCoolingPauseThresholdTemp" in changedFields}
					reset={() => resetField("sinkCoolingPauseThresholdTemp")}
				/>
				<MoreInfo>
					<Typography gutterBottom>{t("RiskOfCondensation")}</Typography>
				</MoreInfo>
			</Stack>

			<FloatingFab submit={submit} loading={loading} fitBottom={isBatchUpdate} batchLoading={loadingSave}>
				<Save />
				<Typography variant="button">{t("SaveHeatingCoolingSettings")}</Typography>
			</FloatingFab>
		</>
	)
}
