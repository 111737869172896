import { ApolloError, DocumentNode } from "@apollo/client"
import { CardContent, Grid, LinearProgress, Typography } from "@mui/material"
import useSize from "hooks/useSize"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"
import CustomCard from "./CustomCard"
import { ErrIfaceUnclaimed } from "resources/backendErrors"
import { Role, useClaimInterfaceMutation, useMeQuery } from "generated/graphql"
import { useMessage } from "providers/MessageProvider"
import appendErrorMessage from "tools/appendErrorMessage"
import { useEffect, useState } from "react"
import InterfaceClaimDialog from "./forms/InterfaceClaimDialog"
import useHasRole from "hooks/useHasRole"
import { useTranslation } from "react-i18next"
import { styled } from "@mui/material/styles"

type Props = {
	titleCard: React.ReactNode
	children: React.ReactNode
	error: ApolloError | undefined
	isLoading: boolean
	interfaceId: string
	fetchOnClaim: DocumentNode
	noTitle?: boolean
}

//CardContent has extra padding bottom, which cant be edit in sx
const StyledCardContent = styled(CardContent)(({ theme }) => ({
	"&:last-child": {
		paddingBottom: theme.spacing(2),
	},
}))

export default function HeatPumpInfoDisplay({
	noTitle = false,
	titleCard,
	children,
	error,
	isLoading,
	interfaceId,
	fetchOnClaim,
}: Props) {
	const { t } = useTranslation()
	const isInternal = useHasRole([Role.Admin, Role.Mechanic])
	const smallScreen = useSize("down", "md")
	const message = useMessage()
	const [ifaceClaimFailed, setIfaceClaimFailed] = useState(false)
	const [ifaceClaimed, setIfaceClaimed] = useState(false)
	const [claimInterface, { loading: claimLoading }] = useClaimInterfaceMutation({
		refetchQueries: [{ query: fetchOnClaim, variables: { interfaceId, isInternal } }],
		awaitRefetchQueries: true,
	})
	const { data: meData, loading: groupIdLoading, refetch: refetchMeQuery } = useMeQuery()
	const claimInProgress = groupIdLoading || claimLoading
	const handleClaimInterface = async () => {
		try {
			if (!meData) {
				await refetchMeQuery()
			}

			if (!meData?.me.group?.id) {
				throw Error(t("OrgNotFound"))
			}
			await claimInterface({ variables: { groupId: meData.me.group.id, interfaceId } })
			message.success(t("HPClaimed"))
			setIfaceClaimed(true)
		} catch (e) {
			message.error(appendErrorMessage(t("Errors.ClaimingHP"), e))
			setIfaceClaimFailed(true)
		}
	}

	useEffect(() => {
		if (error && error.message.includes(ErrIfaceUnclaimed)) {
			handleClaimInterface()
		}
	}, [error])

	return (
		<Grid container spacing={smallScreen ? 0 : 1} alignItems="center" direction="column" marginBottom={1}>
			{!noTitle && (
				<Grid item sx={{ width: !smallScreen ? 750 : "100%" }}>
					<CustomCard>
						<StyledCardContent>{titleCard}</StyledCardContent>
					</CustomCard>
				</Grid>
			)}

			{(isLoading || claimInProgress) && (
				<Grid item sx={{ width: !smallScreen ? 750 : "100%" }}>
					<CustomCard sx={{ p: 4, my: 1 }}>
						<CardContent>
							<Typography variant="h5">{t("Loading")}</Typography>
							<LinearProgress sx={{ mt: 2 }} />
						</CardContent>
					</CustomCard>
				</Grid>
			)}
			{error && (!error.message.includes(ErrIfaceUnclaimed) || ifaceClaimFailed) && !claimInProgress && (
				<Grid item sx={{ width: !smallScreen ? 750 : "100%" }}>
					<ErrorCard error={error} interfaceId={interfaceId} />
				</Grid>
			)}
			{!error && !isLoading && children}
			{ifaceClaimed && (
				<InterfaceClaimDialog interfaceId={interfaceId} onClose={() => setIfaceClaimed(false)} navigateToPage={false} />
			)}
		</Grid>
	)
}
