import { Accordion, AccordionDetails, AccordionSummary, CardContent, Grid, Typography } from "@mui/material"
import useSize from "hooks/useSize"
import CustomCard from "./CustomCard"
import { ExpandMore } from "@mui/icons-material"

type Props = {
	title: string | React.ReactNode
	children: React.ReactNode
	collapsible?: boolean
	defaultCollapsed?: boolean
	fullWidth?: boolean
}

export default function HeatPumpInfoCard({ title, children, defaultCollapsed, collapsible, fullWidth = false }: Props) {
	const smallScreen = useSize("down", "md")

	return (
		<Grid item style={{ width: fullWidth || smallScreen ? "100%" : 750 }}>
			<>
				{collapsible ? (
					<Accordion defaultExpanded={!defaultCollapsed}>
						<AccordionSummary expandIcon={<ExpandMore />}>
							<Typography variant="h5" sx={{ width: "100%" }}>
								{title}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>{children}</AccordionDetails>
					</Accordion>
				) : (
					<>
						<CustomCard>
							<CardContent>
								<Typography variant="h5">{title}</Typography>
								{children}
							</CardContent>
						</CustomCard>
					</>
				)}
			</>
		</Grid>
	)
}
