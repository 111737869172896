import {
	FormControl,
	Radio,
	RadioGroup,
	FormLabel,
	FormControlLabel,
	FormHelperText,
	Typography,
	Stack,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import MoreInfo from "../components/MoreInfo"
import InputReset from "components/InputReset"

const StyledFormControl = styled(FormControl)(({ theme }) => ({
	marginTop: theme.spacing(2),
}))

const StyledDiv = styled("div")(() => ({
	marginLeft: "auto",
	whiteSpace: "pre-line",
}))

export interface Props<T extends string> {
	reset?: () => void
	fieldIsChanged?: boolean
	value?: T | null
	onChange: (value: T | null) => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
	options: { [key in T]?: string }
	hiddenOptions?: { [key in T]?: string } | null
	hiddenOptionHelperText?: string
	row?: boolean
	message?: string
	disabled?: boolean
	moreInfo?: string
}

export default function RadioInput<T extends string>({
	reset,
	fieldIsChanged,
	value,
	error,
	required,
	onChange,
	label,
	options,
	hiddenOptions,
	hiddenOptionHelperText,
	helperText,
	row,
	message,
	disabled,
	moreInfo,
}: Props<T>) {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange((e.target.value as T) ?? null)
	}

	const hiddenOptionSelected = hiddenOptions && Object.keys(hiddenOptions).includes(String(value))
	const currentOptions = hiddenOptionSelected ? { ...options, ...hiddenOptions } : options

	return (
		<StyledFormControl fullWidth disabled={hiddenOptionSelected || disabled}>
			<FormLabel component="legend" required={required} error={error} color={fieldIsChanged ? "secondary" : undefined}>
				{label}
			</FormLabel>

			<Stack direction="row" alignItems="center">
				<RadioGroup value={value ?? ""} onChange={handleChange} row={row}>
					{Object.entries(currentOptions).map(([value, label]) => (
						<FormControlLabel
							value={value}
							key={value}
							control={<Radio color={fieldIsChanged ? "secondary" : undefined} />}
							label={String(label)}
						/>
					))}
					{message && <MoreInfo>{message}</MoreInfo>}
				</RadioGroup>

				{reset && fieldIsChanged && <InputReset reset={reset} isFieldChanged={fieldIsChanged} />}

				{moreInfo && (
					<StyledDiv>
						<MoreInfo>
							<Typography gutterBottom>{moreInfo}</Typography>
						</MoreInfo>
					</StyledDiv>
				)}
			</Stack>
			<FormHelperText error>{helperText}</FormHelperText>
			{hiddenOptionSelected && hiddenOptionHelperText && <FormHelperText>{hiddenOptionHelperText}</FormHelperText>}
		</StyledFormControl>
	)
}
