import { Card, CardProps, Divider } from "@mui/material"
import { styled } from "@mui/material/styles"
import useSize from "hooks/useSize"

const StyledCard = styled(Card)(({ theme }) => ({
	paddingTop: theme.spacing(1),
	paddingBottom: theme.spacing(1),
}))

interface Props extends CardProps {
	hideDivider?: boolean
}

export default function CustomCard({ children, hideDivider, ...props }: Props) {
	const smallScreen = useSize("down", "md")
	if (smallScreen) {
		return (
			<>
				<StyledCard elevation={0} square {...props}>
					{children}
				</StyledCard>
				<Divider sx={hideDivider ? { display: "none" } : {}} />
			</>
		)
	}
	return <Card {...props}>{children}</Card>
}
