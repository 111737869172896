import { ChangeEvent, ReactElement, RefObject, useEffect, useRef, useState } from "react"
import React from "react"
import { styled, useTheme } from "@mui/material/styles"
import {
	Table,
	TableRow,
	TableCell,
	TableHead,
	TableBody,
	Tooltip,
	Stack,
	TablePagination,
	Checkbox,
	Button,
} from "@mui/material"
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import useSize from "hooks/useSize"
import Resizable from "./Resizable"
import DeleteIcon from "@mui/icons-material/Delete"
import { useTranslation } from "react-i18next"

const PREFIX = "Table"

const classes = {
	resizableTable: `${PREFIX}-resizableTable`,
	fullWidth: `${PREFIX}-fullWidth`,
	clickable: `${PREFIX}-clickable`,
	table: `${PREFIX}-table`,
	tableSmall: `${PREFIX}-tableSmall`,
	breakText: `${PREFIX}-breakText`,
	headerCell: `${PREFIX}-headerCell`,
	cell: `${PREFIX}-cell`,
	tableResponsive: `${PREFIX}-tableResponsive`,
}

interface StyledProps {
	fullHeight: boolean
}

const StyledTableFooter = styled("div")<StyledProps>(({ theme, fullHeight }) => {
	if (!fullHeight) {
		return
	}

	return {
		position: "fixed",
		width: "100%",
		right: 0,
		bottom: 0,
		background: theme.palette.grey[100],
		borderTop: `1px solid ${theme.palette.divider}`,
		overflow: "hidden",
		[theme.breakpoints.up("lg")]: {
			width: `calc(100% - ${theme.spacing(26)})`,
		},
		"& .MuiTablePagination-spacer": {
			display: "none",
		},
	}
})

const Root = styled("div")<RootProps>((props) => {
	const theme = useTheme()

	return {
		[`& .${classes.fullWidth}`]: {
			margin: -theme.spacing(2),
			overflow: "hidden",
		},

		[`& .${classes.clickable}`]: {
			cursor: "pointer",
			userSelect: "none",
		},

		[`& .${classes.resizableTable}`]: {
			width: `${props.tableDimensions.width}`,
			tableLayout: "fixed",
		},

		[`& .${classes.table}`]: {
			width: "100%",
			tableLayout: "fixed",
		},

		[`& .${classes.tableResponsive}`]: {
			overflowX: "auto",
			height: `${props.tableDimensions.height}`,
		},

		[`& .${classes.tableSmall}`]: {
			"& th": {
				display: "none",
			},
			"& td": {
				display: "flex",
				alignItems: "center",
				minWidth: theme.spacing(16.5),
				"&:not(:last-child)": {
					borderBottom: "none",
				},
				"&:last-child": {
					paddingBottom: theme.spacing(2),
					marginBottom: theme.spacing(2),
				},
			},
			"& td::before": {
				content: "attr(label)",
				fontWeight: 500,
				marginRight: theme.spacing(1),
				width: theme.spacing(16.5),
				minWidth: theme.spacing(16.5),
			},
		},

		[`& .${classes.breakText}`]: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
		},

		[`& .${classes.headerCell}`]: {
			height: theme.spacing(4),
		},

		[`& .${classes.cell}`]: {
			paddingRight: theme.spacing(1),
		},
	}
})

interface StyledTableCellProps {
	resizable?: string //styled component doesn't accept boolean
}

interface TableDimensionsProps {
	height: string | number
	width: string | number
}

interface RootProps {
	children: React.ReactNode
	className: string
	tableDimensions: TableDimensionsProps
}

const StyledTableCell = styled(TableCell)<StyledTableCellProps>((props) => {
	const theme = useTheme()

	return {
		...(props.resizable
			? {
					borderRight: `1px solid #e0e0e0`,
					width: theme.spacing(16.5),
					minWidth: theme.spacing(16.5),
				}
			: {}),
	}
})

type InterfaceDeviceProps = {
	id?: string | null
	name?: string | null
	isOnline?: boolean | null
	firmwareVersion?: number | null | undefined
	[key: string]: unknown
}

export interface SelectedInterfacesProps {
	interfaceId: string
	name: string
	isOnline: boolean
	firmwareVersion: number | null | undefined
	interfaceFirmwareVersion?: string
}

export type Label<T extends InterfaceDeviceProps> = {
	key: string
	name: string
	width?: number
	sortable?: boolean
	sort?: (row: T) => number | string
	resolve?: (row: T) => React.ReactNode
	hide?: "md" | "lg" | string
	tooltip?: boolean | ((row: T) => NonNullable<React.ReactNode>)
	wrapText?: boolean
}

export type ColWidths = {
	key: string
	width: number
}

type Props<T extends InterfaceDeviceProps> = {
	numberSelected?: SelectedInterfacesProps[]
	handleNumberSelected?: (data: SelectedInterfacesProps[]) => void
	toggleSelect?: boolean
	onClickAction?: (id: string) => void
	search?: string
	defaultItemsPerPage: number
	offsetHeight?: number
	toolBarRef?: RefObject<HTMLElement>
	resizable?: boolean
	columnWidths?: ColWidths[]
	onChangeColumnWidths?: (colWidths: ColWidths[]) => void
	rows: T[]
	labels: Label<T>[]
	loading?: boolean
	onClickRow?: (id: string) => void
	fullHeight?: boolean
	fullWidth?: boolean
	size?: "medium" | "small"
	smallTable?: "xs" | "sm" | "md" | "lg" | "xl"
	endButton?: ReactElement
	defaultSortCol?: string
	startSortReverse?: boolean
	onSort?: (key: string, reverse: boolean) => void
	hiddenRows?: string[]
	wrapText?: boolean
}

export default function QuickTable<T extends InterfaceDeviceProps>({
	numberSelected = [],
	handleNumberSelected,
	toggleSelect,
	onClickAction,
	search,
	defaultItemsPerPage,
	offsetHeight,
	toolBarRef,
	resizable,
	columnWidths = [],
	onChangeColumnWidths,
	rows = [],
	labels,
	loading = false,
	onClickRow,
	fullHeight = false,
	fullWidth = false,
	size = "medium",
	smallTable,
	endButton,
	defaultSortCol,
	startSortReverse,
	onSort,
	hiddenRows = [],
	wrapText = false,
}: Props<T>) {
	const theme = useTheme()
	const paginationRef = useRef()
	const { t } = useTranslation("general")
	function getValue(row: T, { resolve, key }: { resolve?: (row: T) => React.ReactNode; key: string }): React.ReactNode {
		if (resolve) return resolve(row)
		if (!(key in row) || typeof row[key] === "object") return "" // if not found, or is an object (which included null/undefined!) return empty string
		if (typeof row[key] === "number") return row[key] as number // if it was a number, keep it as such for sorting
		return String(row[key]) // if anything else (string, boolean, ...) convert to string
	}
	const [currentPage, setPage] = useState(0)
	const [rowsPerTable, setRowsPerTable] = useState(defaultItemsPerPage)

	const [tableDimensions, setTableDimensions] = useState<TableDimensionsProps>({
		height: "auto",
		width: "100%",
	})

	const [reverse, setReverse] = useState(startSortReverse ?? false)
	const [sortCol, setSortCol] = useState(
		labels.find(({ key, sortable }) => key === defaultSortCol && sortable) ?? labels.find(({ sortable }) => sortable),
	)

	const showSmall = useSize("down", smallTable)
	const smallerThanLg = useSize("down", "lg")
	const smallerThanMd = useSize("down", "md")
	const sizes: { [size: string]: boolean } = {
		lg: smallerThanLg,
		md: smallerThanMd,
	}

	const isSelected = (id: string) => numberSelected?.some((item) => item.interfaceId === id)

	if (loading) return null

	const rowsSorted = sortCol
		? // rows may be read only, so copy it!
			[...rows].sort((r1, r2) => {
				const v1 = sortCol.sort ? sortCol.sort(r1) : getValue(r1, sortCol)
				const v2 = sortCol.sort ? sortCol.sort(r2) : getValue(r2, sortCol)
				if (typeof v1 === "number" && typeof v2 === "number") {
					return reverse ? v1 - v2 : v2 - v1
				} else if (typeof v1 === "string" && typeof v2 === "string") {
					return reverse ? v2.localeCompare(v1) : v1.localeCompare(v2)
				}
				return 0
			})
		: rows

	const handleSort = (key: string) => {
		if (sortCol?.key === key) {
			setReverse(!reverse)
			onSort && onSort(key, !reverse)
		} else {
			setSortCol(labels.find((l) => l.key === key))
			setReverse(false)
			onSort && onSort(key, false)
		}
	}

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerTable(parseInt(event.target.value))
		setPage(0)
	}

	const getRowsPerPageOptions = (rowsSorted: T[], rowsPerPage: number): { label: string; value: number }[] => {
		const options: { label: string; value: number }[] = [{ label: `${rowsPerPage}`, value: rowsPerPage }]

		if (rowsSorted.length >= 50) {
			options.push({ label: "50", value: 50 })
		}

		if (rowsSorted.length >= 100) {
			options.push({ label: "100", value: 100 })
		}

		options.push({ label: "All", value: -1 })

		return options
	}

	const handleOnResize = (newWidth: number, key: string) => {
		let newColumnsWidths: ColWidths[] = []

		const foundKey = columnWidths.findIndex((el) => el.key === key)

		if (foundKey === -1) {
			newColumnsWidths = [...columnWidths, { key: key, width: newWidth }]
		} else {
			newColumnsWidths = columnWidths.map((el) => (el.key === key ? { ...el, width: newWidth } : el))
		}

		onChangeColumnWidths?.(newColumnsWidths)
	}

	const handleResize = (mainContainer: HTMLElement) => {
		const setOffset = offsetHeight ?? 0
		const heightOffsetPagination = fullHeight && rows.length > rowsPerTable ? parseInt(theme.spacing(6.5)) : 0

		const height = fullHeight
			? `${
					window.innerHeight -
					mainContainer.offsetTop -
					setOffset -
					(toolBarRef?.current?.offsetHeight || 0) -
					heightOffsetPagination
				}px`
			: "auto"

		setTableDimensions({
			height,
			width: `${mainContainer.offsetWidth}px`,
		})
	}

	useEffect(() => {
		const mainContainer = document.querySelector("main")

		if (!mainContainer) {
			return
		}

		const resizeHandler = () => {
			handleResize(mainContainer)
		}

		if (search) {
			setPage(0)
			resizeHandler()
			setRowsPerTable(defaultItemsPerPage)
		}
		handleResize(mainContainer)
		window.addEventListener("resize", resizeHandler)
	}, [search])

	const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!handleNumberSelected) {
			return
		}

		if (event.target.checked) {
			const newSelected = rows.map((n) => ({
				interfaceId: n.id ?? "",
				name: n.name ?? "",
				isOnline: n.isOnline ?? false,
				firmwareVersion: n.firmwareVersion ?? 0,
			}))
			handleNumberSelected(newSelected)
			return
		}
		handleNumberSelected([])
	}

	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		interfaceId: string,
		name: string,
		isOnline: boolean,
		firmwareVersion: number,
	) => {
		event.stopPropagation()
		if (!handleNumberSelected) {
			return
		}
		const selectedIndex = numberSelected?.findIndex((item) => item.interfaceId === interfaceId) ?? 0
		let newSelected: SelectedInterfacesProps[] = []

		if (selectedIndex === -1) {
			newSelected = [...(numberSelected ?? []), { interfaceId, name, isOnline, firmwareVersion }]
		} else {
			newSelected = [
				...(numberSelected?.slice(0, selectedIndex) ?? []),
				...(numberSelected?.slice(selectedIndex + 1) ?? []),
			]
		}
		handleNumberSelected(newSelected)
	}

	return (
		<Root className={`${fullWidth ? classes.fullWidth : ""}`} tableDimensions={tableDimensions}>
			<div className={`${classes.tableResponsive}`}>
				<Table
					className={` ${showSmall ? classes.tableSmall : ""} ${resizable ? classes.resizableTable : classes.table}`}
					size={showSmall ? "small" : size}
				>
					{!showSmall && (
						<colgroup>
							{labels.map(
								({ key, width, hide }) =>
									(!hide || !sizes[hide]) &&
									!hiddenRows.includes(key) && (
										<col key={`col-${key}`} width={columnWidths?.find((col) => col.key === key)?.width ?? width} />
									),
							)}
						</colgroup>
					)}
					<TableHead>
						<TableRow>
							{toggleSelect && (
								<StyledTableCell padding="checkbox">
									<Checkbox
										color="primary"
										checked={labels.length > 0 && numberSelected?.length === rows.length}
										onChange={onSelectAllClick}
									/>
								</StyledTableCell>
							)}

							{labels.map(({ key, name, sortable, hide }, ix) => {
								if ((hide && sizes[hide]) || hiddenRows.includes(key)) return null
								return (
									<StyledTableCell
										key={key}
										onMouseDown={() => sortable && handleSort(key)}
										className={sortable ? classes.clickable : ""}
										resizable={resizable ? "resizable" : ""} //styled component doesn't accept boolean
										width={toggleSelect && key === "status" ? "40px" : "auto"}
									>
										<Resizable resizable={resizable} onResize={(newWidth) => handleOnResize(newWidth, key)}>
											<Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
												<Stack direction="row">
													<div className={classes.breakText}>{name}</div>
													{sortCol?.key === key && <>{reverse ? <ArrowDropUp /> : <ArrowDropDown />}</>}
												</Stack>
												{endButton && ix + 1 === labels.length && endButton}
											</Stack>
										</Resizable>
									</StyledTableCell>
								)
							})}
							{onClickAction && <TableCell align="right">{t("Action")}</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{(currentPage !== undefined && rowsPerTable > 0
							? rowsSorted.slice(currentPage * rowsPerTable, currentPage * rowsPerTable + rowsPerTable)
							: rowsSorted
						).map((row, ix) => {
							const isItemSelected = isSelected(row.id ?? "")

							return (
								<TableRow
									hover={Boolean(onClickRow)}
									className={onClickRow ? classes.clickable : ""}
									key={`row-${ix}`}
									onClick={() => onClickRow && row.id && onClickRow(row.id)}
								>
									{toggleSelect && (
										<TableCell padding="checkbox">
											<Checkbox
												onClick={(e) => {
													handleClick(e, row.id ?? "", row.name ?? "", row.isOnline ?? false, row.firmwareVersion ?? 0)
												}}
												color="primary"
												checked={isItemSelected}
											/>
										</TableCell>
									)}
									{labels.map((label) => {
										if ((label.hide && sizes[label.hide]) || hiddenRows.includes(label.key)) return null
										const value = getValue(row, label)

										return (
											<TableCell
												// eslint-disable-next-line @typescript-eslint/ban-ts-comment
												// @ts-ignore -> needed because TableCell does not recognize the label prop (which is correct, but it is forwarded to the <td>)
												label={label.name}
												key={`cell-${ix}-${label.key}`}
												className={classes.cell}
												width={toggleSelect && label.key === "status" ? "40px" : "auto"}
											>
												<Tooltip
													title={typeof label.tooltip === "function" ? label.tooltip(row) : value ?? ""}
													disableHoverListener={!label.tooltip || !value}
												>
													<div className={wrapText ? "" : classes.breakText}>{value}</div>
												</Tooltip>
											</TableCell>
										)
									})}
									{onClickAction && (
										<TableCell align="right" padding="none">
											<Button
												onClick={(event) => {
													event.stopPropagation()
													onClickAction(row?.id ?? "")
												}}
											>
												<DeleteIcon />
											</Button>
										</TableCell>
									)}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>

				{rows.length > defaultItemsPerPage && (
					<StyledTableFooter fullHeight={fullHeight}>
						<TablePagination
							ref={paginationRef}
							id="pagination"
							rowsPerPageOptions={getRowsPerPageOptions(rowsSorted, defaultItemsPerPage)}
							colSpan={labels.length}
							component="div"
							count={rows.length}
							rowsPerPage={rowsPerTable}
							page={currentPage}
							onPageChange={(_, page) => setPage(page)}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</StyledTableFooter>
				)}
			</div>
		</Root>
	)
}
