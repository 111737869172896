import {
	Box,
	Chip,
	CircularProgress,
	Divider,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import CheckIcon from "@mui/icons-material/Check"
import { RefObject, useEffect, useRef, useState } from "react"
import { BatchUpdateInterfaceProps } from "./BatchUpdate"
import {
	BatchUpdateInterfaceFieldsFragment,
	GetCurrentUpdateStatusListDocument,
	PvtHeatPumpFirmwareVersion,
	UpdateDevice,
	UpdateProgress,
	useGetCurrentUpdateStatusHeatPumpListQuery,
	useUpdatePvtHeatPumpFirmwareMutation,
} from "generated/graphql"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import ProgressStatus, { Status } from "components/ProgressStatus"
import HeatPumpUpdateSelector from "components/HeatPumpUpdateSelector"
import { useMessage } from "providers/MessageProvider"
import { useConfirm } from "providers/ConfirmProvider"
import { isUpdateStuck } from "tools/isUpdateStuck"
import UpdatingDeviceAlert from "components/UpdatingDeviceAlert"
import { ApolloError } from "@apollo/client"

interface ErrorsObjectsProps {
	interfaceId: string
	errorMessage: string
}

interface Props {
	dialogOpenedAt: Date | null | undefined
	interfaces: BatchUpdateInterfaceFieldsFragment[]
	loadingUpdate: boolean
	updateErrors: ErrorsObjectsProps[]
	version: PvtHeatPumpFirmwareVersion | undefined
}

function TableUpdateResults({ dialogOpenedAt, interfaces, loadingUpdate, updateErrors, version }: Props) {
	const { t } = useTranslation(["general", "pvtHeatPumpAdminPage", "heatPumpErrorCard", "heatPumpSettingsPage"])

	const getFirmwareVersionLabel = (iface: BatchUpdateInterfaceFieldsFragment) => {
		if (iface.pvtHeatPump?.firmwareVersion) {
			return `V${iface.pvtHeatPump.firmwareVersion.toFixed(1)}`
		}
		if (iface.updateStatus?.progress === UpdateProgress.InProgress) {
			return t("Updating")
		}
		return "-"
	}

	const getStatusChip = (iface: BatchUpdateInterfaceFieldsFragment) => {
		if (loadingUpdate) {
			return <CircularProgress size={30} />
		}

		if (updateErrors.some((err) => err.interfaceId === iface.id)) {
			const errorMessage = updateErrors.find((err) => err.interfaceId === iface.id)?.errorMessage
			return (
				<Chip
					label={errorMessage}
					color="error"
					variant="outlined"
					sx={{ pl: 1 }}
					icon={<WarningAmberIcon sx={{ fontSize: 16 }} />}
				/>
			)
		}

		if (iface.updateStatus?.progress && iface.updateStatus?.device === UpdateDevice.Mantova) {
			switch (iface.updateStatus.progress) {
				case UpdateProgress.Completed:
					if (dialogOpenedAt && new Date(iface.updateStatus.time).getTime() > dialogOpenedAt.getTime()) {
						return (
							<Chip
								label={t("UpdatedToFirmwareVersion", {
									firmwareVersion: `V${iface.pvtHeatPump?.firmwareVersion?.toFixed(1)}`,
								})}
								color="info"
								variant="outlined"
								sx={{ pl: 1 }}
								icon={<CheckIcon sx={{ fontSize: 16 }} />}
							/>
						)
					}
					break
				case UpdateProgress.InProgress:
				case UpdateProgress.Requested:
					return (
						<ProgressStatus
							noLogo
							percentage={iface.updateStatus.percentage ?? undefined}
							text={iface.updateStatus.message}
							status={Status.InProgress}
						/>
					)
				case UpdateProgress.Failed:
					return (
						<ProgressStatus
							noLogo
							percentage={iface.updateStatus.percentage ?? undefined}
							text={iface.updateStatus.message}
							status={Status.Error}
						/>
					)
				default:
					return (
						<ProgressStatus
							noLogo
							percentage={iface.updateStatus.percentage ?? undefined}
							text={iface.updateStatus.message}
							status={Status.Error}
						/>
					)
			}
		}

		if (iface.pvtHeatPump?.firmwareVersion && version) {
			if (iface.pvtHeatPump.firmwareVersion === version.version) {
				return (
					<Chip
						label={t("CurrentVersion")}
						color="info"
						variant="outlined"
						sx={{ pl: 1 }}
						icon={<CheckIcon sx={{ fontSize: 16 }} />}
					/>
				)
			}

			if (iface.pvtHeatPump.firmwareVersion > version.version) {
				return (
					<Chip
						label={t("SelectedVersionIsOlder")}
						color="warning"
						variant="outlined"
						sx={{ pl: 1 }}
						icon={<WarningAmberIcon sx={{ fontSize: 16 }} />}
					/>
				)
			}
		} else {
			return (
				<Chip
					label={t("HeatPumpNotConnected")}
					color="error"
					variant="outlined"
					sx={{ pl: 1 }}
					icon={<WarningAmberIcon sx={{ fontSize: 16 }} />}
				/>
			)
		}

		return (
			<Chip
				label={t("UpdateAvailable")}
				color="success"
				variant="outlined"
				sx={{ pl: 1 }}
				icon={<CheckIcon sx={{ fontSize: 16 }} />}
			/>
		)
	}

	return interfaces.map((iface: BatchUpdateInterfaceFieldsFragment) => {
		const inProgress =
			(iface.updateStatus?.progress === UpdateProgress.Requested ||
				iface.updateStatus?.progress === UpdateProgress.InProgress) &&
			iface.updateStatus.progress &&
			iface.updateStatus.device === UpdateDevice.Mantova

		return (
			<TableRow key={iface.name}>
				<TableCell>{iface.id}</TableCell>
				<TableCell>{iface.name}</TableCell>
				<TableCell>{getFirmwareVersionLabel(iface)}</TableCell>
				<TableCell
					align="right"
					sx={{
						maxWidth: "250px",
						...(inProgress && { p: 0 }), // Remove padding from table when in progress to save spacing
					}}
				>
					{getStatusChip(iface)}
				</TableCell>
			</TableRow>
		)
	})
}

export default function BatchUpdateHeatPumps({
	dialogOpenedAt,
	interfaces,
	loading,
	handleRefetch,
}: BatchUpdateInterfaceProps) {
	const { t } = useTranslation(["general", "pvtHeatPumpAdminPage", "heatPumpErrorCard", "heatPumpSettingsPage"])
	const message = useMessage()
	const confirm = useConfirm()

	const [version, setVersion] = useState<PvtHeatPumpFirmwareVersion>()
	const [polling, setPolling] = useState(false)
	const resultsRef: RefObject<HTMLElement | null | undefined> = useRef()
	const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false)
	const [updateErrors, setUpdateErrors] = useState<ErrorsObjectsProps[]>([])

	const onlineInterfaceIds = interfaces.filter((item) => item.isOnline).map((item) => item.id)

	const handleGetFirmwareVersion = (data: PvtHeatPumpFirmwareVersion) => {
		if (!data) return
		setVersion(data)
	}

	const { data: currentStatus, loading: currentStatusLoading } = useGetCurrentUpdateStatusHeatPumpListQuery({
		variables: {
			interfaceIds: onlineInterfaceIds,
		},
		errorPolicy: "all", // Return data even if some interfaces has errors
		pollInterval: polling ? 1000 : 0,
	})

	useEffect(() => {
		if (!currentStatus?.interfaces) return

		if (!currentStatusLoading) {
			setLoadingUpdate(false)
		}

		currentStatus.interfaces.forEach((interfaceObj) => {
			if (interfaceObj.updateStatus?.progress === UpdateProgress.Completed && handleRefetch !== undefined) {
				handleRefetch()
			}
		})

		setPolling(
			currentStatus.interfaces.some(
				(interfaceObj) =>
					interfaceObj.updateStatus?.progress === UpdateProgress.InProgress ||
					interfaceObj.updateStatus?.progress === UpdateProgress.Requested,
			),
		)
	}, [currentStatus])

	const [updateHeatPump] = useUpdatePvtHeatPumpFirmwareMutation({
		refetchQueries: [
			{
				query: GetCurrentUpdateStatusListDocument,
				variables: { interfaceIds: onlineInterfaceIds },
				errorPolicy: "all",
			},
		],
	})

	const handleUpdateHeatPump = async (update: PvtHeatPumpFirmwareVersion) => {
		await confirm(
			t("pvtHeatPumpAdminPage:MsgConfirmAllHeatPumpUpdate", {
				firmwareVersionName: `V${update.version.toFixed(1)}`,
			}),
		)

		// Filter out interfaces/heatpump when in progress or same version
		const availableInterfaces = interfaces
			.filter((iface) => {
				const firmwareVersion = iface.pvtHeatPump?.firmwareVersion
				const targetVersion = version?.version

				return (
					(iface.isOnline &&
						firmwareVersion !== undefined &&
						firmwareVersion !== targetVersion &&
						iface.updateStatus?.progress !== UpdateProgress.InProgress) ||
					isUpdateStuck(iface)
				)
			})
			.map((item) => item.id)

		if (!availableInterfaces?.length) {
			message.info(t("AllSelectedInterfacesBeingUpdated"))
			return
		}

		setPolling(true)
		setLoadingUpdate(true)
		resultsRef?.current?.scrollIntoView({ behavior: "smooth" })

		try {
			await updateHeatPump({
				variables: { interfaceIds: availableInterfaces, firmwareId: update.id },
			})

			message.success(t("pvtHeatPumpAdminPage:HeatPumpUpdateStarted"))
		} catch (e) {
			if (e instanceof ApolloError) {
				const message = e.message
				const errorsObject: ErrorsObjectsProps[] = []

				// If the backend errors are same amount of the availableInterfaces from front-end then set the polling back to false
				if (e.graphQLErrors.length === availableInterfaces?.length) {
					setPolling(false)
					setLoadingUpdate(false)
				}

				e.graphQLErrors.map((error) => {
					const interfaceId = error?.extensions?.interfaceID

					if (typeof interfaceId === "string" && message && interfaceId) {
						errorsObject.push({
							interfaceId: interfaceId,
							errorMessage: (() => {
								switch (true) {
									case message.includes("The operation failed because the requested device isn't online"):
										return t("heatPumpErrorCard:InterfaceNotOnline.Title")

									case message.includes("error updating PVT Heat Pump firmware"):
										return t("heatPumpErrorCard:ErrorUpdatingHeatPumpFirmware")

									default:
										return message
								}
							})(),
						})

						setUpdateErrors(errorsObject)
					}
				})
			}
		}
	}

	return (
		<>
			<UpdatingDeviceAlert currentStatus={currentStatus} />

			<HeatPumpUpdateSelector
				handleUpdateHeatPump={handleUpdateHeatPump}
				handleGetFirmwareVersion={handleGetFirmwareVersion}
			/>

			<Divider sx={{ mt: 4 }} />
			{loading ? (
				<Stack direction="row" pt={4} justifyContent="center">
					<CircularProgress size={30} />
				</Stack>
			) : (
				<Box ref={resultsRef}>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>{t("InterfaceID")}</TableCell>
									<TableCell>{t("Name")}</TableCell>
									<TableCell>{t("Version")}</TableCell>
									<TableCell align="right" sx={{ mw: "100px" }}>
										{t("Status")}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableUpdateResults
									dialogOpenedAt={dialogOpenedAt}
									interfaces={interfaces}
									version={version}
									loadingUpdate={loadingUpdate}
									updateErrors={updateErrors}
								/>
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			)}
		</>
	)
}
