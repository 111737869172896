import { TextField, TextFieldProps } from "@mui/material"

export type TextInputProps = Omit<TextFieldProps, "onChange"> & {
	value?: string | null
	onChange: (value: string | null) => void
	defaultValue?: string
}

export default function TextInput({ value, onChange, onFocus, defaultValue, ...textFieldProps }: TextInputProps) {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value === "" ? null : e.target.value)
	}

	const handleFocus = () => {
		// on focus, set value to default
		if (defaultValue != null && value == null) onChange(defaultValue)
	}

	return (
		<TextField
			fullWidth
			margin="normal"
			value={value ?? ""}
			onChange={handleChange}
			onFocus={onFocus ?? handleFocus}
			placeholder={defaultValue}
			InputLabelProps={{
				shrink: defaultValue != null ? true : undefined,
			}}
			{...textFieldProps}
		/>
	)
}
