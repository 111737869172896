import { Logout, Settings } from "@mui/icons-material"
import { Typography, IconButton, List, ListItem, Stack } from "@mui/material"
import { useState } from "react"
import GroupMemberSettings from "./ChangeMemberDialog"
import { useAuth } from "providers/AuthProvider"
import { FormatUserOrGroup as User, useFormatUserOrGroupString } from "components/FormatUserOrGroup"
import { GroupFieldsFragment, InterfacesDocument, MyGroupDocument, useLeaveGroupMutation } from "generated/graphql"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ErrCanNotRemoveLastAdmin } from "resources/backendErrors"
import appendErrorMessage from "tools/appendErrorMessage"
import { useConfirm } from "providers/ConfirmProvider"
import { useMessage } from "providers/MessageProvider"

type Props = {
	group: GroupFieldsFragment
	isGroupAdmin?: boolean | null
}

export default function MemberList({ group, isGroupAdmin }: Props) {
	const { t } = useTranslation(["groupManagement", "general"])
	const groupNameFormatter = useFormatUserOrGroupString()
	const message = useMessage()
	const confirm = useConfirm()
	const auth = useAuth()

	const [userIdToChange, setUserIdToChange] = useState<string | null>(null)

	const navigate = useNavigate()
	// we need to refetch the me query, because when we leave a group, we are assigned to a new (personal) group
	const [leaveGroup, { loading: leaveGroupLoading }] = useLeaveGroupMutation({
		refetchQueries: [{ query: MyGroupDocument }, { query: InterfacesDocument }],
		awaitRefetchQueries: true,
	})

	const confirmMessage = (
		<Trans
			i18nKey="MsgConfirmLeave"
			ns="groupManagement"
			values={{ groupName: groupNameFormatter(group) }}
			components={{ italic: <i /> }}
		/>
	)
	const handleLeaveGroup = async () => {
		await confirm(confirmMessage)
		try {
			if (auth.user?.id == null) throw new Error(t("Errors.curUserNotFound"))
			await leaveGroup({ variables: { groupId: group.id, userId: auth.user?.id } })
			message.success(t("LeaveOrg"))
			navigate("/")
		} catch (e) {
			if (e instanceof Error && e.message.includes(ErrCanNotRemoveLastAdmin)) {
				message.error(t("Errors.TransferAdminRightsBeforeLeaving"))
				return
			}
			message.error(appendErrorMessage(t("Errors.UnkownErrorLeaving"), e))
		}
	}

	return (
		<>
			<List disablePadding>
				{group.members.map((member, index) => (
					<ListItem
						secondaryAction={
							member.id === auth.user?.id ? (
								<IconButton onClick={handleLeaveGroup} disabled={leaveGroupLoading}>
									<Logout />
								</IconButton>
							) : (
								isGroupAdmin && (
									<IconButton onClick={() => setUserIdToChange(member.id)}>
										<Settings />
									</IconButton>
								)
							)
						}
						divider={index + 1 < group.members.length}
						key={`member-in-group-${group.id}-${member.id}`}
					>
						<Stack direction="column">
							<Typography variant="h6">
								<User>{member}</User>
							</Typography>
							{member.isDeleted && (
								<Typography variant="body2">
									<i>{t("general:dateDeleted", { date: member.deletedAt ? new Date(member.deletedAt) : "" })}</i>
								</Typography>
							)}
							{member.id === auth.user?.id && (
								<Typography variant="body2">
									<i>{t("general:CurrentUser")}</i>
								</Typography>
							)}
							{member.isGroupAdmin && (
								<Typography variant="body2">
									<i>{t("Admin")}</i>
								</Typography>
							)}
							{member.email != null && (
								<Typography variant="body2">
									<i>
										{t("general:Email")}: {member.email}
									</i>
								</Typography>
							)}
						</Stack>
					</ListItem>
				))}
			</List>

			{userIdToChange != null && (
				<GroupMemberSettings onClose={() => setUserIdToChange(null)} userId={userIdToChange} group={group} />
			)}
		</>
	)
}
