import { CircularProgress, Stack, Typography } from "@mui/material"
import { useReadHeatPumpSettingsWizardQuery } from "generated/graphql"
import { useParams } from "react-router-dom"
import { StepperProps } from "../index"
import HeatPumpErrors from "pages/HeatPumpStatusPage/components/StatusDescriptions/HeatPumpErrors"
import InstallationStatus from "./InstallationStatus"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"

export default function ErrorStatus({
	error,
	fields,
	loading,
	steps,
	BottomNavigation,
	nextStep,
	backStep,
	step,
}: StepperProps) {
	const { interfaceId } = useParams<"interfaceId">()

	if (!fields || !steps || !interfaceId) return

	const { data, loading: readLoading } = useReadHeatPumpSettingsWizardQuery({
		variables: { interfaceId },
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-first",
	})

	if (error && interfaceId) {
		return <ErrorCard error={error} interfaceId={interfaceId} />
	}

	return (
		<>
			<Typography variant="h6">{steps[6]}</Typography>
			{!loading && data ? (
				<>
					<HeatPumpErrors data={data.interface?.pvtHeatPump} />
					<InstallationStatus fields={fields} data={data} loading={readLoading} />
				</>
			) : (
				<Stack p={5} alignItems="center">
					<CircularProgress />
				</Stack>
			)}

			{BottomNavigation && (
				<BottomNavigation
					steps={steps}
					step={step}
					backStep={backStep}
					nextStep={nextStep}
					interfaceId={interfaceId}
					disabled={readLoading || loading}
				/>
			)}
		</>
	)
}
