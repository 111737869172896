import { Box, Button, LinearProgress, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material"
import { INTERFACE_V1_2_2_TIMESTAMP } from "settings/firmwareVersions"
import LazyMarkdown from "components/LazyMarkdown"
import {
	GetCurrentFirmwareVersionQuery,
	GetCurrentUpdateStatusQuery,
	GetPvtHeatPumpFirmwareUpdatesInternalDocument,
	PvtHeatPumpFirmwareVersion,
	Role,
	UpdateProgress,
	useGetPvtHeatPumpFirmwareUpdatesInternalQuery,
	useSetDefaultPvtHeatPumpFirmwareMutation,
} from "generated/graphql"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import useHasRole from "hooks/useHasRole"
import { useMessage } from "providers/MessageProvider"
import { useConfirm } from "providers/ConfirmProvider"
import appendErrorMessage from "tools/appendErrorMessage"
import { isUpdateStuck } from "tools/isUpdateStuck"

interface Props {
	currentUpdateStatus?: GetCurrentUpdateStatusQuery
	handleUpdateHeatPump: (update: PvtHeatPumpFirmwareVersion) => Promise<void>
	currentFirmware?: GetCurrentFirmwareVersionQuery
	loading?: boolean
	handleGetFirmwareVersion?: (data: PvtHeatPumpFirmwareVersion) => void
}

export default function HeatPumpUpdateSelector({
	loading = false,
	currentUpdateStatus,
	handleUpdateHeatPump,
	currentFirmware,
	handleGetFirmwareVersion,
}: Props) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage", "heatPumpErrorCard"])

	const [selectedVersion, setSelectedVersion] = useState<PvtHeatPumpFirmwareVersion>()
	const isAdmin = useHasRole([Role.Admin])
	const message = useMessage()
	const confirm = useConfirm()

	const { data: updatesData, loading: getPvtHeatPumpFirmwaresLoading } = useGetPvtHeatPumpFirmwareUpdatesInternalQuery({
		onCompleted: (data) => {
			const currentVersion =
				data?.getPvtHeatPumpFirmwareUpdates.find((fw) => fw.default) ?? data?.getPvtHeatPumpFirmwareUpdates.at(0)
			setSelectedVersion(currentVersion)
			handleGetFirmwareVersion && currentVersion && handleGetFirmwareVersion(currentVersion)
		},
	})

	const foundVersion = updatesData?.getPvtHeatPumpFirmwareUpdates.find(
		(fw) => fw.version === currentFirmware?.interface?.pvtHeatPump?.firmwareVersion,
	)

	const [setDefaultPvtHeatPumpFirmware] = useSetDefaultPvtHeatPumpFirmwareMutation({
		refetchQueries: [{ query: GetPvtHeatPumpFirmwareUpdatesInternalDocument }],
	})
	const handleSetDefault = async (update: PvtHeatPumpFirmwareVersion) => {
		await confirm(t("MsgConfirmSetDefaultHPFW", { firmwareVersionName: `V${update.version.toFixed(1)}` }))
		try {
			await setDefaultPvtHeatPumpFirmware({ variables: { firmwareId: update.id } })
			message.success(t("msgFirmwareSetToDefault", { firmwareVersionName: update.version }))
		} catch (e) {
			message.error(appendErrorMessage("Errors.SettingDefaultFW", e))
		}
	}

	if (getPvtHeatPumpFirmwaresLoading || loading) {
		return (
			<Box width="100%">
				<LinearProgress />
			</Box>
		)
	}

	const disabled =
		selectedVersion?.version === currentFirmware?.interface?.pvtHeatPump?.firmwareVersion ||
		((currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.InProgress ||
			currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.Requested) &&
			!isUpdateStuck(currentUpdateStatus?.interface))

	return (
		<Stack alignItems="flex-start" width="100%" spacing={2}>
			{currentFirmware?.interface?.pvtHeatPump?.firmwareVersion && (
				<Typography variant="h6">
					{t("CurrentVersion")}:{" "}
					{foundVersion?.name ?? `V${currentFirmware.interface.pvtHeatPump.firmwareVersion.toFixed(1)}`}
					{foundVersion?.default && ` (${t("Default")})`}
				</Typography>
			)}
			{currentFirmware &&
				new Date(currentFirmware.interface?.firmwareVersion.timestamp ?? 0) < INTERFACE_V1_2_2_TIMESTAMP && (
					<Typography color="error" gutterBottom>
						{t("MsgNoHPUpdateSupport")}
					</Typography>
				)}

			<>
				<Select
					value={selectedVersion?.version.toFixed(1) ?? ""}
					onChange={(event: SelectChangeEvent) => {
						const version = updatesData?.getPvtHeatPumpFirmwareUpdates.find((fw) => {
							return fw.version.toFixed(1) === event.target.value
						})
						setSelectedVersion(version)
						handleGetFirmwareVersion && version && handleGetFirmwareVersion(version)
					}}
				>
					{updatesData?.getPvtHeatPumpFirmwareUpdates.map((update) => {
						return (
							<MenuItem value={update.version.toFixed(1)} key={`menuItem-${update.version}`}>
								{update.name ?? `V${update.version.toFixed(1)}}`}
								{update.default && ` (${t("Default")})`}
							</MenuItem>
						)
					})}
				</Select>
				{selectedVersion && (
					<>
						<LazyMarkdown>{selectedVersion.description}</LazyMarkdown>
						<Stack direction="row" spacing={1}>
							<Button variant="contained" disabled={disabled} onClick={() => handleUpdateHeatPump(selectedVersion)}>
								{t("Update")}
							</Button>
							{isAdmin && !selectedVersion.default && (
								<Button variant="contained" onClick={() => handleSetDefault(selectedVersion)}>
									{t("MakeDefault")}
								</Button>
							)}
						</Stack>
					</>
				)}
			</>
		</Stack>
	)
}
