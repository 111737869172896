import { ApolloCache, InMemoryCache } from "@apollo/client"
import { Save } from "@mui/icons-material"
import { LinearProgress, Stack, Typography } from "@mui/material"
import {
	PvtHeatPumpAdminDateTimeFragment,
	PvtHeatPumpAdminDateTimeFragmentDoc,
	PvtHeatPumpInput,
	UpdatePvtHeatPumpSettingsMutation,
	UpdatePvtHeatPumpSettingsMutationVariables,
	useReadHeatPumpDateTimeSettingsQuery,
	useUpdatePvtHeatPumpSettingsMutation,
} from "generated/graphql"
import useForm from "hooks/useForm"
import NumberInput from "inputs/NumberInput"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"
import { useMessage } from "providers/MessageProvider"
import { useTranslation } from "react-i18next"
import onlyChangedValues from "tools/onlyChangedValues"
import BooleanInput from "inputs/BooleanInput"
import MoreInfo from "components/MoreInfo"
import { PvtHeatPumpData } from "../index"
import appendErrorMessage from "tools/appendErrorMessage"
import FloatingFab from "components/FloatingFab"
import InterfaceSaveTypeAlert from "components/InterfaceSaveTypeAlert"
import { StatusMessageProps } from "components/BatchUpdate"
import handleSettingsError from "tools/handleSettingsError"
import { GraphQLError } from "graphql/error"

function updatePvtHeatPumpCache(
	cache: ApolloCache<InMemoryCache>,
	{ data, errors }: { data?: UpdatePvtHeatPumpSettingsMutation | null; errors?: readonly GraphQLError[] | undefined },
	{ variables }: { variables?: UpdatePvtHeatPumpSettingsMutationVariables },
) {
	if (!data?.updatePvtHeatPump || !variables) return
	const { pvtHeatPumpdata: inputData, interfaceIds } = variables
	const ids = Array.isArray(interfaceIds) && interfaceIds.length > 0 ? interfaceIds : []

	const interfaceIdsWithErrors = errors
		?.filter((error) => typeof error.extensions.interfaceID === "string")
		.map((error) => error.extensions.interfaceID)

	const onlineInterfaceIds = ids.filter((id) => !interfaceIdsWithErrors?.includes(id))

	onlineInterfaceIds.forEach((defaultInterfaceId) => {
		const id = `PvtHeatPumpData:${defaultInterfaceId}`
		const oldPvtHeatPump = cache.readFragment<PvtHeatPumpAdminDateTimeFragment>({
			id,
			fragment: PvtHeatPumpAdminDateTimeFragmentDoc,
			fragmentName: "PvtHeatPumpAdminDateTime",
		})
		if (!oldPvtHeatPump) return

		const newPvtHeatPump = (Object.keys(oldPvtHeatPump) as Array<keyof PvtHeatPumpAdminDateTimeFragment>).reduce(
			(acc, key) => ({ ...acc, [key]: inputData[key as keyof PvtHeatPumpInput] ?? oldPvtHeatPump[key] }),
			{} as PvtHeatPumpAdminDateTimeFragment,
		)

		cache.writeFragment<PvtHeatPumpAdminDateTimeFragment>({
			id,
			fragment: PvtHeatPumpAdminDateTimeFragmentDoc,
			fragmentName: "PvtHeatPumpAdminDateTime",
			data: newPvtHeatPump,
		})
	})
}

type Props = {
	defaultInterfaceId: string
	batchInterfaceIds?: string[]
	isPartialSave?: boolean
	handleSavingMessages?: (updateStatus: StatusMessageProps[]) => void | undefined
	handleSaveLoading?: (isLoading: boolean) => void
}

export default function DateTimeSettings({
	defaultInterfaceId,
	batchInterfaceIds,
	isPartialSave,
	handleSavingMessages,
	handleSaveLoading,
}: Props) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage", "general", "heatPumpSettingsPage", "heatPumpErrorCard"])
	const message = useMessage()
	const { register, submit, reset, fields, set } = useForm<PvtHeatPumpInput>({}, handleSave)

	const isBatchUpdate = Array.isArray(batchInterfaceIds) && batchInterfaceIds.length > 0

	const { data, loading, error } = useReadHeatPumpDateTimeSettingsQuery({
		variables: { interfaceId: defaultInterfaceId },
		onCompleted: (data) => {
			if (data.interface?.pvtHeatPump) {
				const { id: _id, __typename, ...rest } = data.interface.pvtHeatPump
				reset(rest)
			}
		},
	})

	const [updateSettings, { loading: loadingSave }] = useUpdatePvtHeatPumpSettingsMutation({
		update: updatePvtHeatPumpCache,
	})
	const changedFields = onlyChangedValues(data?.interface?.pvtHeatPump ?? {}, fields)

	async function handleSave() {
		if ((isPartialSave || isPartialSave === undefined) && Object.entries(changedFields).length === 0) {
			message.info(t("heatPumpSettingsPage:Errors.HPHasTheseSettings"))
			return
		}

		if (isBatchUpdate) {
			message.info(t("general:SavingSettingsPleaseWait"))
		}

		try {
			const response = await updateSettings({
				variables: {
					interfaceIds: isBatchUpdate ? batchInterfaceIds : [defaultInterfaceId],
					pvtHeatPumpdata: isBatchUpdate ? (isPartialSave ? changedFields : fields) : changedFields,
					controllerSettings: {},
					isExpert: true,
					isAdmin: false,
				},
				errorPolicy: isBatchUpdate ? "all" : "none", // If 1 or more interface is offline, the mutation will continue, This will ignore the error catch for GraphQL
			})

			if (handleSavingMessages) {
				handleSavingMessages([{ success: true }])
			}

			// Since we use errorPolicy: "all", the error won't return at catch error
			if (response?.errors) {
				handleSettingsError({ errorResponse: response.errors, handleSavingMessages, t })
			}
		} catch (e) {
			message.error(appendErrorMessage(t("heatPumpSettingsPage:Errors.UnkownSaving"), e))
		}

		message.success(t("heatPumpSettingsPage:SettingsSaved"))

		if (handleSaveLoading) {
			handleSaveLoading(loadingSave)
		}
	}

	const resetField = (field: string) => {
		if (!field || !data?.interface?.pvtHeatPump) return

		const pvtHeatPumpData: PvtHeatPumpData = data?.interface.pvtHeatPump
		const value = pvtHeatPumpData[field]

		set({
			[field]: value,
		})
	}

	if (loading) {
		return <LinearProgress />
	}

	if (error || data?.interface == null) {
		return <ErrorCard error={error} interfaceId={defaultInterfaceId} />
	}

	return (
		<>
			{batchInterfaceIds && batchInterfaceIds.length > 0 && <InterfaceSaveTypeAlert isPartialSave={isPartialSave} />}

			<NumberInput
				label={t("general:Minute")}
				{...register("curTimeMin", { required: true, min: 0, max: 59 })}
				disabled={loading}
				fieldIsChanged={"curTimeMin" in changedFields}
				reset={() => resetField("curTimeMin")}
			/>

			<NumberInput
				label={t("general:Hour24")}
				{...register("curTimeHour", { required: true, min: 0, max: 23 })}
				disabled={loading}
				fieldIsChanged={"curTimeHour" in changedFields}
				reset={() => resetField("curTimeHour")}
			/>

			<Stack direction="row" alignItems="center">
				<NumberInput
					label={t("general:Weekday")}
					{...register("curTimeDay", { required: true, min: 1, max: 7 })}
					disabled={loading}
					fieldIsChanged={"curTimeDay" in changedFields}
					reset={() => resetField("curTimeDay")}
				/>
				<MoreInfo>
					<Typography gutterBottom>{t("general:DayInWeek")}</Typography>
				</MoreInfo>
			</Stack>

			<NumberInput
				label={t("general:DateLabel")}
				{...register("curTimeDate", { required: true, min: 1, max: 31 })}
				disabled={loading}
				fieldIsChanged={"curTimeDate" in changedFields}
				reset={() => resetField("curTimeDate")}
			/>

			<NumberInput
				label={t("general:Month")}
				{...register("curTimeMonth", { required: true, min: 1, max: 12 })}
				disabled={loading}
				fieldIsChanged={"curTimeMonth" in changedFields}
				reset={() => resetField("curTimeMonth")}
			/>

			<NumberInput
				label={t("general:Year")}
				{...register("curTimeYear", { required: true, min: 0, max: 99 })}
				disabled={loading}
				fieldIsChanged={"curTimeYear" in changedFields}
				reset={() => resetField("curTimeYear")}
			/>

			<BooleanInput
				label={t("general:DaylightSavingTime")}
				disabled={loading}
				{...register("daylightSavingEnable", { required: true })}
				fieldIsChanged={"daylightSavingEnable" in changedFields}
				reset={() => resetField("daylightSavingEnable")}
			/>

			<FloatingFab submit={submit} loading={loading} fitBottom={isBatchUpdate} batchLoading={loadingSave}>
				<Save />
				<Typography variant="button">{t("SaveDateTime")}</Typography>
			</FloatingFab>
		</>
	)
}
