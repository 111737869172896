import useDebouncedSate from "hooks/useDebouncedState"
import MenuInput, { MenuInputProps } from "./MenuInput"
import { useEffect } from "react"

type Props = Pick<MenuInputProps, "onBlur" | "onFocus"> & {
	initialValue?: string
	onChange: (value: string) => void
	clearable?: boolean
	clearOnBlur?: boolean
}

export default function DebouncedMenuInput({ initialValue, onChange, onFocus, onBlur, clearable, clearOnBlur }: Props) {
	const [value, debouncedValue, setValue, setValueNow] = useDebouncedSate(initialValue ?? "")

	useEffect(() => onChange(debouncedValue), [debouncedValue])

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)
	const handleClear = () => clearable && setValueNow("")
	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		if (clearOnBlur) setValueNow("")
		if (onBlur) onBlur(e)
	}

	return <MenuInput value={value} onBlur={handleBlur} onChange={handleChange} onClear={handleClear} onFocus={onFocus} />
}
