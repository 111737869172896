import ReactDOM from "react-dom/client"
import App from "./App"
import { ApolloProvider } from "@apollo/client"
import AuthProvider from "providers/AuthProvider"
import { CssBaseline } from "@mui/material"
import { createTheme, responsiveFontSizes, ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import { setupApollo } from "apollo"
import { GlobalStyles } from "@mui/material"
import "./i18n"

const theme = responsiveFontSizes(
	createTheme({
		palette: {
			primary: {
				main: "#DD4F05",
			},
			secondary: {
				main: "#0491DC",
			},
			background: {
				default: "#FAFAFA",
			},
		},
		typography: {
			fontSize: 13,
		},
	}),
)

// some global styles, style unstyled tags consistenly with material-ui
const globalStyles = {
	body: {
		fontFamily: theme.typography.body1.fontFamily,
		fontSize: theme.typography.body1.fontSize,
		fontWeight: theme.typography.body1.fontWeight,
		color: theme.typography.body1.color,
		lineHeight: theme.typography.body1.lineHeight,
		letterSpacing: theme.typography.body1.letterSpacing,
	},
	a: {
		color: theme.palette.primary.main,
		textDecoration: "none",
		"&:hover": {
			textDecoration: "underline",
		},
	},
	li: {
		marginBottom: theme.spacing(1.5),
	},
	figure: {
		marginInlineStart: 0,
		marginInlineEnd: 0,
	},
}

// TODO: Check if this is still needed, I don't think I still use MUIRichTextEditor
Object.assign(theme, {
	overrides: {
		MUIRichTextEditor: {
			placeHolder: {
				position: "inherit",
			},
		},
	},
})

const { client, auth } = setupApollo()

const rootEl = document.getElementById("root")
if (!rootEl) throw new Error("Root element not found!")
const root = ReactDOM.createRoot(rootEl)

root.render(
	<StyledEngineProvider injectFirst>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<GlobalStyles styles={globalStyles} />
			<AuthProvider auth={auth}>
				<ApolloProvider client={client}>
					<App />
				</ApolloProvider>
			</AuthProvider>
		</ThemeProvider>
	</StyledEngineProvider>,
)
