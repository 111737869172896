import i18next from "i18n"

import CompressorOffTimer, { CompressorStartData } from "./CompressorOffTimer"
import { PvtHeatPumpDhwState, PvtHeatPumpSpaceHeatingCoolingState } from "generated/graphql"
import NamedListItem from "../NamedListItem"
import { useTranslation } from "react-i18next"

type Props = {
	data?: CompressorStartData | null
	onStarupTimerEnds: () => void
}
type Status = {
	label: string
	wait?: boolean
}
function getReadableStatus(
	spaceState: PvtHeatPumpSpaceHeatingCoolingState,
	dhwState: PvtHeatPumpDhwState,
): Status | null {
	if (spaceState === PvtHeatPumpSpaceHeatingCoolingState.Off && dhwState === PvtHeatPumpDhwState.Off) {
		return { label: i18next.t("Off") }
	}

	switch (dhwState) {
		case PvtHeatPumpDhwState.StartUp:
			return { label: i18next.t("StartingUp", { ns: "heatPumpStates" }), wait: true }
		case PvtHeatPumpDhwState.Passive:
			return { label: i18next.t("DHW.Passive", { ns: "heatPumpStates" }) }
		case PvtHeatPumpDhwState.Combined:
			return { label: i18next.t("DHW.Combined", { ns: "heatPumpStates" }) }
		case PvtHeatPumpDhwState.Active:
			return { label: i18next.t("DHW.Active", { ns: "heatPumpStates" }) }
		case PvtHeatPumpDhwState.Backup:
			return { label: i18next.t("DHW.Backup", { ns: "heatPumpStates" }) }
		case PvtHeatPumpDhwState.Boost:
			return { label: i18next.t("DHW.Boost", { ns: "heatPumpStates" }) }
		case PvtHeatPumpDhwState.Pause:
			return { label: i18next.t("DHW.Pause", { ns: "heatPumpStates" }), wait: true }
	}

	switch (spaceState) {
		case PvtHeatPumpSpaceHeatingCoolingState.Startup:
			return { label: i18next.t("StartingUp", { ns: "heatPumpStates" }), wait: true }
		case PvtHeatPumpSpaceHeatingCoolingState.PassiveHeat:
			return { label: i18next.t("SH.Passive", { ns: "heatPumpStates" }) }
		case PvtHeatPumpSpaceHeatingCoolingState.CombinedHeat:
			return { label: i18next.t("SH.Combined", { ns: "heatPumpStates" }) }
		case PvtHeatPumpSpaceHeatingCoolingState.ActiveHeat:
			return { label: i18next.t("SH.Active", { ns: "heatPumpStates" }) }
		case PvtHeatPumpSpaceHeatingCoolingState.BackupHeat:
			return { label: i18next.t("SH.Backup", { ns: "heatPumpStates" }) }
		case PvtHeatPumpSpaceHeatingCoolingState.BoostHeat:
			return { label: i18next.t("SH.Boost", { ns: "heatPumpStates" }) }
		case PvtHeatPumpSpaceHeatingCoolingState.PassiveCool:
			return { label: i18next.t("SC.Passive", { ns: "heatPumpStates" }) }
		case PvtHeatPumpSpaceHeatingCoolingState.CombinedCool:
			return { label: i18next.t("SC.Combined", { ns: "heatPumpStates" }) }
		case PvtHeatPumpSpaceHeatingCoolingState.ActiveCool:
			return { label: i18next.t("SC.Active", { ns: "heatPumpStates" }) }
		case PvtHeatPumpSpaceHeatingCoolingState.Standby:
			return { label: i18next.t("Standby", { ns: "heatPumpStates" }) }
		case PvtHeatPumpSpaceHeatingCoolingState.HeatPause:
			return { label: i18next.t("SH.Pause", { ns: "heatPumpStates" }), wait: true }
		case PvtHeatPumpSpaceHeatingCoolingState.CoolPause:
			return { label: i18next.t("SC.Pause", { ns: "heatPumpStates" }), wait: true }
	}
	return null
}

export default function HeatPumpState({ data, onStarupTimerEnds }: Props) {
	const { t } = useTranslation()
	const spaceState = data?.spaceHeatingCoolingState
	const dhwState = data?.dhwState
	if (!spaceState || !dhwState) return null
	const currentStatus = getReadableStatus(spaceState, dhwState)
	if (currentStatus == null) return null
	return (
		<NamedListItem
			name={t("OperatingMode")}
			value={
				<>
					{currentStatus.label}
					<CompressorOffTimer data={data} onTimerEnd={onStarupTimerEnds} />
				</>
			}
		/>
	)
}
