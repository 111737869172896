import { IconButton, Tooltip } from "@mui/material"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import { useTranslation } from "react-i18next"

type InputResetProps = {
	reset: () => void
	isFieldChanged: boolean | undefined
}

export default function InputReset({ reset, isFieldChanged = false }: InputResetProps) {
	const { t } = useTranslation(["heatPumpSettingsPage"])

	return (
		<Tooltip title={t("UndoChanges")}>
			<span>
				<IconButton onClick={reset} disabled={!isFieldChanged}>
					<RestartAltIcon color={isFieldChanged ? "secondary" : undefined} />
				</IconButton>
			</span>
		</Tooltip>
	)
}
