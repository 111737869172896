import { PvtHeatPumpStatusFieldsFragment } from "generated/graphql"
import NamedListItem from "../NamedListItem"
import { useTranslation } from "react-i18next"

type Props = {
	data?: Pick<PvtHeatPumpStatusFieldsFragment, "spaceHeatDemand" | "spaceCoolDemand" | "dhwDemand"> | null
}

export default function HeatPumpDemand({ data }: Props) {
	const [t] = useTranslation(["heatPumpStatus", "general"])

	if (!data) return null

	const statusLabel: string[] = []
	if (data.spaceHeatDemand) {
		statusLabel.push(t("Heating"))
	}
	if (data.spaceCoolDemand) {
		statusLabel.push(t("Cooling"))
	}
	if (data.dhwDemand) {
		statusLabel.push(t("DHW"))
	}
	if (statusLabel.length === 0) {
		return <NamedListItem name={t("Demand")} value={t("general:None")} />
	}
	return <NamedListItem name={t("Demand")} value={statusLabel} />
}
