import { makeVar } from "@apollo/client"

export const emptyHeatPumpPageFilter = {
	searchText: "",
	offlineHeatPumps: false,
}

const filter = makeVar(emptyHeatPumpPageFilter)

export default filter
