import { ApolloCache, InMemoryCache } from "@apollo/client"
import { Save } from "@mui/icons-material"
import { LinearProgress, Stack, Typography } from "@mui/material"
import {
	PvtHeatPumpAdminSettingsGeneralFragment,
	PvtHeatPumpAdminSettingsGeneralFragmentDoc,
	PvtHeatPumpInput,
	UpdatePvtHeatPumpSettingsMutation,
	UpdatePvtHeatPumpSettingsMutationVariables,
	useReadHeatPumpGeneralSettingsQuery,
	useUpdatePvtHeatPumpSettingsMutation,
} from "generated/graphql"
import useForm from "hooks/useForm"
import NumberInput from "inputs/NumberInput"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"
import { useMessage } from "providers/MessageProvider"
import { useTranslation } from "react-i18next"
import onlyChangedValues from "tools/onlyChangedValues"
import MoreInfo from "components/MoreInfo"
import { PvtHeatPumpData } from "../index"
import BooleanInput from "inputs/BooleanInput"
import { PVT_HEAT_PUMP_V1_1, isPvtHeatPumpFirmwareNewerOrEqual } from "settings/firmwareVersions"
import HeadingLabel from "components/HeadingLabel"
import SGReadyMoreInfo from "components/SGReadyMoreInfo"
import appendErrorMessage from "tools/appendErrorMessage"
import FloatingFab from "components/FloatingFab"
import InterfaceSaveTypeAlert from "components/InterfaceSaveTypeAlert"
import { StatusMessageProps } from "components/BatchUpdate"
import handleSettingsError from "tools/handleSettingsError"
import { GraphQLError } from "graphql/error"
const CELCIUS = "°C"

function updatePvtHeatPumpCache(
	cache: ApolloCache<InMemoryCache>,
	{ data, errors }: { data?: UpdatePvtHeatPumpSettingsMutation | null; errors?: readonly GraphQLError[] | undefined },
	{ variables }: { variables?: UpdatePvtHeatPumpSettingsMutationVariables },
) {
	if (!data?.updatePvtHeatPump || !variables) return
	const { pvtHeatPumpdata: inputData, interfaceIds } = variables
	const ids = Array.isArray(interfaceIds) && interfaceIds.length > 0 ? interfaceIds : []

	const interfaceIdsWithErrors = errors
		?.filter((error) => typeof error.extensions.interfaceID === "string")
		.map((error) => error.extensions.interfaceID)

	const onlineInterfaceIds = ids.filter((id) => !interfaceIdsWithErrors?.includes(id))

	onlineInterfaceIds.forEach((defaultInterfaceId) => {
		const id = `PvtHeatPumpData:${defaultInterfaceId}`
		const oldPvtHeatPump = cache.readFragment<PvtHeatPumpAdminSettingsGeneralFragment>({
			id,
			fragment: PvtHeatPumpAdminSettingsGeneralFragmentDoc,
			fragmentName: "PvtHeatPumpAdminSettingsGeneral",
		})
		if (!oldPvtHeatPump) return

		const newPvtHeatPump = (Object.keys(oldPvtHeatPump) as Array<keyof PvtHeatPumpAdminSettingsGeneralFragment>).reduce(
			(acc, key) => ({ ...acc, [key]: inputData[key as keyof PvtHeatPumpInput] ?? oldPvtHeatPump[key] }),
			{} as PvtHeatPumpAdminSettingsGeneralFragment,
		)

		cache.writeFragment<PvtHeatPumpAdminSettingsGeneralFragment>({
			id,
			fragment: PvtHeatPumpAdminSettingsGeneralFragmentDoc,
			fragmentName: "PvtHeatPumpAdminSettingsGeneral",
			data: newPvtHeatPump,
		})
	})
}

type Props = {
	defaultInterfaceId: string
	batchInterfaceIds?: string[]
	isPartialSave?: boolean
	handleSavingMessages?: (updateStatus: StatusMessageProps[]) => void | undefined
	handleSaveLoading?: (isLoading: boolean) => void
}

export default function GeneralSettings({
	defaultInterfaceId,
	batchInterfaceIds,
	isPartialSave,
	handleSavingMessages,
	handleSaveLoading,
}: Props) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage", "general", "heatPumpSettingsPage", "heatPumpErrorCard"])
	const message = useMessage()
	const { register, submit, reset, fields, set } = useForm<
		PvtHeatPumpInput & { firmwareVersion?: string; id?: string }
	>({}, handleSave)

	const isBatchUpdate = Array.isArray(batchInterfaceIds) && batchInterfaceIds.length > 0

	const { data, loading, error } = useReadHeatPumpGeneralSettingsQuery({
		variables: { interfaceId: defaultInterfaceId },
		onCompleted: (data) => {
			if (data.interface?.pvtHeatPump) {
				const { firmwareVersion: _firmwareVersion, __typename, ...rest } = data.interface.pvtHeatPump
				reset(rest)
			}
		},
	})

	const [updateSettings, { loading: loadingSave }] = useUpdatePvtHeatPumpSettingsMutation({
		update: updatePvtHeatPumpCache,
	})
	const changedFields = onlyChangedValues(data?.interface?.pvtHeatPump ?? {}, fields)
	const hpHasSgReady = isPvtHeatPumpFirmwareNewerOrEqual(
		data?.interface?.pvtHeatPump?.firmwareVersion ?? Infinity,
		PVT_HEAT_PUMP_V1_1,
	)

	async function handleSave() {
		if ((isPartialSave || isPartialSave === undefined) && Object.entries(changedFields).length === 0) {
			message.info(t("heatPumpSettingsPage:Errors.HPHasTheseSettings"))
			return
		}

		if (isBatchUpdate) {
			message.info(t("general:SavingSettingsPleaseWait"))
		}

		const { id: _id, ...filteredFields } = fields

		try {
			const response = await updateSettings({
				variables: {
					interfaceIds: isBatchUpdate ? batchInterfaceIds : [defaultInterfaceId],
					pvtHeatPumpdata: isBatchUpdate ? (isPartialSave ? changedFields : filteredFields) : changedFields,
					controllerSettings: {},
					isExpert: true,
					isAdmin: false,
				},
				errorPolicy: isBatchUpdate ? "all" : "none", // If 1 or more interface is offline, the mutation will continue, This will ignore the error catch for GraphQL
			})

			if (handleSavingMessages) {
				handleSavingMessages([{ success: true }])
			}

			// Since we use errorPolicy: "all", the error won't return at catch error
			if (response?.errors) {
				handleSettingsError({ errorResponse: response.errors, handleSavingMessages, t })
			}
		} catch (e) {
			message.error(appendErrorMessage(t("heatPumpSettingsPage:Errors.UnkownSaving"), e))
		}

		message.success(t("heatPumpSettingsPage:SettingsSaved"))

		if (handleSaveLoading) {
			handleSaveLoading(loadingSave)
		}
	}

	const resetField = (field: string) => {
		if (!field || !data?.interface?.pvtHeatPump) return

		const pvtHeatPumpData: PvtHeatPumpData = data?.interface.pvtHeatPump
		const value = pvtHeatPumpData[field]

		set({
			[field]: value,
		})
	}

	if (loading) {
		return <LinearProgress />
	}

	if (error || data?.interface == null) {
		return <ErrorCard error={error} interfaceId={defaultInterfaceId} />
	}

	return (
		<>
			{batchInterfaceIds && batchInterfaceIds.length > 0 && <InterfaceSaveTypeAlert isPartialSave={isPartialSave} />}

			<NumberInput
				label={t("DomesticHotWaterPriority")}
				{...register("dhwPriorityTimeMin", { required: true, min: 0, max: 240 })}
				unit={t("minutes")}
				disabled={loading}
				fieldIsChanged={"dhwPriorityTimeMin" in changedFields}
				reset={() => resetField("dhwPriorityTimeMin")}
			/>

			<NumberInput
				label={t("SHPriority")}
				{...register("shPriorityTimeMin", { required: true, min: 0, max: 240 })}
				unit={t("minutes")}
				disabled={loading}
				fieldIsChanged={"shPriorityTimeMin" in changedFields}
				reset={() => resetField("shPriorityTimeMin")}
			/>

			<NumberInput
				label={t("SCPriority")}
				{...register("scPriorityTimeMin", { required: true, min: 0, max: 240 })}
				unit={t("minutes")}
				disabled={loading}
				fieldIsChanged={"scPriorityTimeMin" in changedFields}
				reset={() => resetField("scPriorityTimeMin")}
			/>

			<Stack direction="row" alignItems="center">
				<NumberInput
					label={t("MinimumSourceTemperatureForPassiveOperation")}
					{...register("pasiveOperationMinSourceTemp", { required: true, min: -30, max: 50 })}
					unit={CELCIUS}
					disabled={loading}
					fieldIsChanged={"pasiveOperationMinSourceTemp" in changedFields}
					reset={() => resetField("pasiveOperationMinSourceTemp")}
				/>
				<MoreInfo>
					<Typography gutterBottom>{t("BelowThisSourceTemperature")}</Typography>
				</MoreInfo>
			</Stack>

			<Stack direction="row" alignItems="center">
				<NumberInput
					label={t("MinimumTForPassiveMode")}
					{...register("passiveMinDeltaT", { required: true, min: 0, max: 25 })}
					unit={CELCIUS}
					disabled={loading}
					fieldIsChanged={"passiveMinDeltaT" in changedFields}
					reset={() => resetField("passiveMinDeltaT")}
				/>
				<MoreInfo>
					<Typography gutterBottom>{t("TheDifferenceBetweenSourceAndDelivery")}</Typography>
				</MoreInfo>
			</Stack>

			<Stack direction="row" alignItems="center">
				<NumberInput
					label={t("TPreheating")}
					{...register("preheatDeltaT", { required: true, min: 0, max: 100 })}
					unit={CELCIUS}
					disabled={loading}
					fieldIsChanged={"preheatDeltaT" in changedFields}
					reset={() => resetField("preheatDeltaT")}
				/>
				<MoreInfo>
					<Typography gutterBottom>{t("TheDifferenceBetweenSourceAndDeliveryPassive")}</Typography>
				</MoreInfo>
			</Stack>
			{hpHasSgReady && (
				<>
					<HeadingLabel>
						{t("heatPumpSettingsPage:SGReady")}
						<SGReadyMoreInfo />
					</HeadingLabel>
					<BooleanInput label={t("heatPumpSettingsPage:SGEnable")} disabled={loading} {...register("enableSgInput")} />
					<BooleanInput label={t("heatPumpSettingsPage:SGAffectSC")} disabled={loading} {...register("sgAffectSc")} />
					<BooleanInput label={t("heatPumpSettingsPage:SGAffectSH")} disabled={loading} {...register("sgAffectSh")} />
					<BooleanInput label={t("heatPumpSettingsPage:SGAllowBHsh")} disabled={loading} {...register("sgAllowBhSh")} />
					<BooleanInput label={t("heatPumpSettingsPage:SGAffectDHW")} disabled={loading} {...register("sgAffectDhw")} />
					<BooleanInput
						label={t("heatPumpSettingsPage:SGAllowBHdhw")}
						disabled={loading}
						{...register("sgAllowBhDhw")}
					/>
				</>
			)}

			<FloatingFab submit={submit} loading={loading} fitBottom={isBatchUpdate} batchLoading={loadingSave}>
				<Save />
				<Typography variant="button">{t("SaveGeneral")}</Typography>
			</FloatingFab>
		</>
	)
}
