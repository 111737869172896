import {
	GroupInterfaceNameDocument,
	InterfaceFieldsFragment,
	useUpdateGroupInterfaceNameMutation,
} from "generated/graphql"
import { useState } from "react"
import EditableField from "./EditableField"
import { useMessage } from "providers/MessageProvider"
import appendErrorMessage from "tools/appendErrorMessage"
import { Typography, styled } from "@mui/material"
import { useTranslation } from "react-i18next"

const StyledTypography = styled(Typography)`
	display: inline-block;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
`

type Props = {
	interface: Pick<InterfaceFieldsFragment, "id" | "name">
	groupId: string
	hideEdit?: boolean
}
export default function EditableGroupInterfaceName({ interface: iface, groupId, hideEdit }: Props) {
	const { t } = useTranslation(["heatPumpManagement"])
	const [name, setName] = useState(iface.name ?? iface.id)
	const message = useMessage()
	const [updateInterfaceName] = useUpdateGroupInterfaceNameMutation({
		refetchQueries: [{ query: GroupInterfaceNameDocument, variables: { interfaceId: iface.id } }],
	})

	const saveName = async () => {
		if (iface.name === name) {
			message.info(t("Errors.HPHasThisName"))
			return
		}
		try {
			await updateInterfaceName({ variables: { groupId, name, interfaceId: iface.id } })
			message.success(t("NameChanged"))
		} catch (e) {
			message.error(appendErrorMessage(t("Errors.UnkownErrorChangingName"), e))
		}
	}
	if (hideEdit) return <StyledTypography>{iface.name}</StyledTypography>

	return <EditableField value={name} onChange={(e) => setName(e.target.value)} onBlur={saveName} />
}
