import { createContext, useState, useContext, useRef } from "react"
import { Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material"
import TSDialog from "components/TSDialog"
import { useTranslation } from "react-i18next"

type ConfirmationDialogProps = {
	message: React.ReactNode
	onSubmit: () => void
	onClose: () => void
}

export function ConfirmationDialog({ message, onSubmit, onClose }: ConfirmationDialogProps) {
	const { t } = useTranslation("general")
	return (
		<TSDialog open onClose={() => onClose()}>
			<DialogTitle>{t("Warning")}</DialogTitle>
			<DialogContent>
				<DialogContentText>{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="primary" variant="outlined" onClick={onClose} autoFocus>
					{t("Cancel")}
				</Button>
				<Button color="primary" variant="contained" onClick={onSubmit}>
					{t("Okay")}
				</Button>
			</DialogActions>
		</TSDialog>
	)
}

const ConfirmationServiceContext = createContext<(message: React.ReactNode) => Promise<unknown>>(Promise.reject)

type ConfirmationProviderProps = {
	children: React.ReactNode
}

export function ConfirmationProvider({ children }: ConfirmationProviderProps) {
	const [message, setMessage] = useState<React.ReactNode | null>(null)
	const awaitingPromiseRef = useRef<{
		resolve: (value: unknown) => void
	} | null>(null)

	const openConfirmation = (message: React.ReactNode) => {
		setMessage(message)
		return new Promise((resolve) => {
			awaitingPromiseRef.current = { resolve }
		})
	}

	const handleClose = () => {
		setMessage(null)
	}

	const handleSubmit = () => {
		if (awaitingPromiseRef.current) {
			awaitingPromiseRef.current.resolve(null)
		}
		setMessage(null)
	}

	return (
		<>
			<ConfirmationServiceContext.Provider value={openConfirmation}>{children}</ConfirmationServiceContext.Provider>
			{message && <ConfirmationDialog message={message} onClose={handleClose} onSubmit={handleSubmit} />}
		</>
	)
}

export const useConfirm = () => useContext(ConfirmationServiceContext)
