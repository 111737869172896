import { Box, LinearProgress, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import TripleSolarLoading from "./TripleSolarLoading"

export enum Status {
	InProgress,
	Error,
	Completed,
}
interface Props {
	percentage?: number
	text?: string
	status?: Status
	noLogo?: boolean
}

const StyledBoxContainer = styled(Box)(() => ({
	width: "100%",
	textAlign: "center",
	marginTop: "2rem",
	marginBottom: "2rem",
}))

const StyledBox = styled(Box)(() => ({
	display: "flex",
	alignItems: "center",
	marginTop: "10px",
	marginBottom: "10px",
}))

const StyledLinearProgress = styled(Box)(() => ({
	width: "100%",
	mr: 1,
}))

export default function ProgressStatus(data: Props) {
	return (
		<StyledBoxContainer>
			{data.status === Status.InProgress && !data?.noLogo && <TripleSolarLoading />}
			<StyledBox>
				<StyledLinearProgress>
					<LinearProgress
						variant={data.percentage == null ? "indeterminate" : "determinate"}
						sx={{
							color: data.status === Status.Error ? "red" : data.status === Status.Completed ? "green" : "primary",
						}}
						color={data.status === Status.Error ? "inherit" : data.status === Status.Completed ? "inherit" : "primary"}
						value={data.percentage}
					/>
				</StyledLinearProgress>

				{data.percentage != null && data.status === Status.InProgress && (
					<Box sx={{ minWidth: 35 }}>
						<Typography variant="body2" color="text.secondary">
							{`${Math.round(data.percentage)}%`}
						</Typography>
					</Box>
				)}
			</StyledBox>

			{data.text && (
				<Typography
					variant="button"
					color={data.status === Status.Error ? "error" : data.status === Status.Completed ? "green" : "text.secondary"}
					gutterBottom
				>
					{data.text}
				</Typography>
			)}
		</StyledBoxContainer>
	)
}
