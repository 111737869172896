import { Save } from "@mui/icons-material"
import { LinearProgress, Stack, Typography } from "@mui/material"
import MoreInfo from "components/MoreInfo"
import {
	ControllerSettingsInput,
	Role,
	useReadExpertControllerSettingsQuery,
	useUpdatePvtHeatPumpSettingsMutation,
} from "generated/graphql"
import useForm from "hooks/useForm"
import useHasRole from "hooks/useHasRole"
import NumberInput from "inputs/NumberInput"
import { useMessage } from "providers/MessageProvider"
import { useTranslation } from "react-i18next"
import onlyChangedValues from "tools/onlyChangedValues"
import { PvtHeatPumpData } from "../index"
import Image from "components/Image"
import FloatingFab from "components/FloatingFab"
import appendErrorMessage from "tools/appendErrorMessage"
import InterfaceSaveTypeAlert from "components/InterfaceSaveTypeAlert"
import { StatusMessageProps } from "components/BatchUpdate"
import handleSettingsError from "tools/handleSettingsError"
const CELCIUS = "°C"

type Props = {
	defaultInterfaceId: string
	batchInterfaceIds?: string[]
	isPartialSave?: boolean
	handleSavingMessages?: (updateStatus: StatusMessageProps[]) => void | undefined
	handleSaveLoading?: (isLoading: boolean) => void
}

export default function ControllerSettings({
	defaultInterfaceId,
	batchInterfaceIds,
	isPartialSave,
	handleSavingMessages,
	handleSaveLoading,
}: Props) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage", "heatPumpSettingsPage", "general", "heatPumpErrorCard"])

	const isAdmin = useHasRole([Role.Admin])
	const message = useMessage()
	const { register, submit, reset, fields, set } = useForm<ControllerSettingsInput>({}, handleSave)

	const isBatchUpdate = Array.isArray(batchInterfaceIds) && batchInterfaceIds.length > 0

	const [updateSettings, { loading: updateLoading }] = useUpdatePvtHeatPumpSettingsMutation()

	const { data, loading: fetchLoading } = useReadExpertControllerSettingsQuery({
		variables: { interfaceId: defaultInterfaceId, isAdmin },
		onCompleted: (data) => {
			if (data.interface?.controller) {
				const { __typename, ...rest } = data.interface.controller
				reset(rest)
			}
		},
	})
	const changedFields = onlyChangedValues(data?.interface?.controller ?? {}, fields)
	async function handleSave() {
		if ((isPartialSave || isPartialSave === undefined) && Object.entries(changedFields).length === 0) {
			message.info(t("ControllerSettingsUnchanged"))
			return
		}

		if (isBatchUpdate) {
			message.info(t("general:SavingSettingsPleaseWait"))
		}

		try {
			const response = await updateSettings({
				variables: {
					interfaceIds: isBatchUpdate ? batchInterfaceIds : [defaultInterfaceId],
					pvtHeatPumpdata: {},
					controllerSettings: isBatchUpdate ? (isPartialSave ? changedFields : fields) : changedFields,
					isExpert: true,
					isAdmin: isAdmin,
				},
				errorPolicy: isBatchUpdate ? "all" : "none", // If 1 or more interface is offline, the mutation will continue, This will ignore the error catch for GraphQL
			})

			if (handleSavingMessages) {
				handleSavingMessages([{ success: true }])
			}

			// Since we use errorPolicy: "all", the error won't return at catch error
			if (response?.errors) {
				handleSettingsError({ errorResponse: response.errors, handleSavingMessages, t })
			}
		} catch (e) {
			message.error(appendErrorMessage(t("heatPumpSettingsPage:Errors.UnkownSaving"), e))
		}

		message.success(t("heatPumpSettingsPage:SettingsSaved"))

		if (handleSaveLoading) {
			handleSaveLoading(updateLoading)
		}
	}

	const resetField = (field: string) => {
		if (!field || !data?.interface?.controller) return

		const pvtHeatPumpData: PvtHeatPumpData = data?.interface.controller
		const value = pvtHeatPumpData[field]

		set({
			[field]: value,
		})
	}

	const loading = fetchLoading || updateLoading

	if (fetchLoading) {
		return <LinearProgress />
	}
	return (
		<>
			{batchInterfaceIds && batchInterfaceIds.length > 0 && <InterfaceSaveTypeAlert isPartialSave={isPartialSave} />}

			<Image src="/images/controller_schema.png" />

			<Stack width="100%" alignItems="center">
				<Stack width="100%" direction="row" alignItems="center">
					<NumberInput
						label={t("SourceBivTemp")}
						{...register("sourceBivTemp", { required: true, min: -30, max: 50 })}
						unit={CELCIUS}
						disabled={loading}
						fieldIsChanged={"sourceBivTemp" in changedFields}
						reset={() => resetField("sourceBivTemp")}
					/>
					<MoreInfo>{t("Tooltips.SourceBivTemp")}</MoreInfo>
				</Stack>
				<Stack width="100%" direction="row" alignItems="center">
					<NumberInput
						label={t("HysBHOn")}
						{...register("hysBHOn", { required: true, min: 1, max: 30 })}
						unit={CELCIUS}
						disabled={loading}
						fieldIsChanged={"hysBHOn" in changedFields}
						reset={() => resetField("hysBHOn")}
					/>
					<MoreInfo>{t("Tooltips.HysBHOn")}</MoreInfo>
				</Stack>
				{isAdmin && (
					<>
						<NumberInput
							label={t("HysHPOn")}
							{...register("hysHPOn", { required: true, min: 1, max: 30 })}
							unit={CELCIUS}
							disabled={loading}
							fieldIsChanged={"hysHPOn" in changedFields}
							reset={() => resetField("hysHPOn")}
						/>
						<NumberInput
							label={t("HysHPOff")}
							{...register("hysHPOff", { required: true, min: 1, max: 30 })}
							unit={CELCIUS}
							disabled={loading}
							fieldIsChanged={"hysHPOff" in changedFields}
							reset={() => resetField("hysHPOff")}
						/>
						<NumberInput
							label={t("HysBHOff")}
							{...register("hysBHOff", { required: true, min: 1, max: 30 })}
							unit={CELCIUS}
							disabled={loading}
							fieldIsChanged={"hysBHOff" in changedFields}
							reset={() => resetField("hysBHOff")}
						/>
					</>
				)}
				<Stack width="100%" direction="row" alignItems="center">
					<NumberInput
						label={t("DelayHPOnMin")}
						{...register("delayHPOnMin", { required: true, min: 0, max: 600 })}
						unit={t("minutes")}
						disabled={loading}
						fieldIsChanged={"delayHPOnMin" in changedFields}
						reset={() => resetField("delayHPOnMin")}
					/>
					<MoreInfo>{t("Tooltips.DelayHPOnMin")}</MoreInfo>
				</Stack>
				<Stack width="100%" direction="row" alignItems="center">
					<NumberInput
						label={t("heatPumpSettingsPage:ThresholdCoolingPerc")}
						{...register("minCoolingPerc", { required: true, min: 0, max: 100 })}
						unit="%"
						disabled={loading}
					/>
					<MoreInfo>{t("heatPumpSettingsPage:Tooltips.ThresholdCoolingPerc")}</MoreInfo>
				</Stack>
				{isAdmin && (
					<>
						<NumberInput
							label={t("DelayHPOffMin")}
							{...register("delayHPOffMin", { required: true, min: 0, max: 600 })}
							unit={t("minutes")}
							disabled={loading}
							fieldIsChanged={"delayHPOffMin" in changedFields}
							reset={() => resetField("delayHPOffMin")}
						/>
						<NumberInput
							label={t("DelayHPCheckMin")}
							{...register("delayHPCheckMin", { required: true, min: 0, max: 600 })}
							unit={t("minutes")}
							disabled={loading}
							fieldIsChanged={"delayHPCheckMin" in changedFields}
							reset={() => resetField("delayHPCheckMin")}
						/>
						<NumberInput
							label={t("DelayHPPostRunMin")}
							{...register("delayHPPostRunMin", { required: true, min: 0, max: 600 })}
							unit={t("minutes")}
							disabled={loading}
							fieldIsChanged={"delayHPPostRunMin" in changedFields}
							reset={() => resetField("delayHPPostRunMin")}
						/>
					</>
				)}

				<FloatingFab submit={submit} loading={loading} fitBottom={isBatchUpdate} batchLoading={updateLoading}>
					<Save />
					<Typography variant="button">{t("SaveControllerSettings")}</Typography>
				</FloatingFab>
			</Stack>
		</>
	)
}
