import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Stack,
	Typography,
	FormControlLabel,
	Switch,
} from "@mui/material"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { useTranslation } from "react-i18next"
import { InterfacesFieldsFragment } from "generated/graphql"

interface Props {
	item?: string
	title: string
	isBatchUpdate?: boolean
	interfaces?: InterfacesFieldsFragment[] | undefined
	isPartialSave?: Record<string, boolean>
	handleToggleSavingType?: (id: string) => void
	children: React.ReactNode
}

export default function AccordionItem({
	item,
	title,
	isBatchUpdate,
	interfaces,
	isPartialSave,
	handleToggleSavingType,
	children,
}: Props) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage", "general"])

	if (isBatchUpdate && !interfaces) return

	return (
		<Accordion
			slotProps={{ transition: { unmountOnExit: true } }}
			disabled={isBatchUpdate && interfaces && interfaces.length === 0}
		>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<Stack direction="row" justifyContent="space-between" width="100%">
					<Typography>{title}</Typography>

					{isBatchUpdate && handleToggleSavingType && item && isPartialSave !== undefined && (
						<FormControlLabel
							onClick={(e) => e.stopPropagation()}
							control={
								<Switch checked={isPartialSave[item]} onChange={() => handleToggleSavingType(item)} size="small" />
							}
							labelPlacement="end"
							label={t("general:SaveOnlyChangedFields")}
						/>
					)}
				</Stack>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	)
}
