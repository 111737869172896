import { Box, Checkbox, Divider, FormControlLabel, Typography } from "@mui/material"

type Props = {
	possibleFlags: { [value: string]: string }
	value: string[] | null | undefined
	onChange: (value: string[]) => void
	groupLabel?: string
	disabled?: boolean
}

export default function FlagsInput({ possibleFlags, value: value, onChange, groupLabel, disabled }: Props) {
	return (
		<Box sx={{ my: 2 }}>
			<Typography color="primary" variant="body1">
				{groupLabel}
			</Typography>
			<Divider />
			<Box display="flex" flexWrap="wrap" justifyContent="space-around">
				{Object.entries(possibleFlags).map(([_, label], ix) => {
					return (
						<FormControlLabel
							key={`group-checkbox-box-${groupLabel}-${ix}`}
							control={
								<Checkbox
									key={`group-checkbox-${groupLabel}-${ix}`}
									checked={Boolean(value?.includes(label))}
									onChange={(e) => {
										const val = value ?? []
										if (e.target.checked) {
											onChange([...val, label])
										} else {
											onChange(val.filter((value) => value !== label))
										}
									}}
									disabled={disabled}
								/>
							}
							label={label}
						/>
					)
				})}
			</Box>
			<Divider />
		</Box>
	)
}
