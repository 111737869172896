import { Button, LinearProgress, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material"
import LazyMarkdown from "components/LazyMarkdown"
import {
	GetCurrentFirmwareVersionQuery,
	GetCurrentUpdateStatusQuery,
	GetInterfaceFirmwareUpdatesInternalDocument,
	InterfaceFirmwareVersion,
	Role,
	UpdateProgress,
	useGetInterfaceFirmwareUpdatesInternalQuery,
	useSetDefaultInterfaceFirmwareMutation,
} from "generated/graphql"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import useHasRole from "hooks/useHasRole"
import { useMessage } from "providers/MessageProvider"
import { useConfirm } from "providers/ConfirmProvider"
import appendErrorMessage from "tools/appendErrorMessage"
import { isUpdateStuck } from "tools/isUpdateStuck"

interface Props {
	currentUpdateStatus?: GetCurrentUpdateStatusQuery
	handleUpdateInterface: (update: InterfaceFirmwareVersion) => Promise<void>
	currentFirmware?: GetCurrentFirmwareVersionQuery
	loading?: boolean
	handleGetFirmwareVersion?: (interfaceVersion: InterfaceFirmwareVersion) => void
}

export default function InterfaceUpdateSelector({
	currentUpdateStatus,
	handleUpdateInterface,
	currentFirmware,
	loading = false,
	handleGetFirmwareVersion,
}: Props) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage", "heatPumpErrorCard"])

	const [selectedVersion, setSelectedVersion] = useState<InterfaceFirmwareVersion>()
	const isAdmin = useHasRole([Role.Admin])
	const message = useMessage()
	const confirm = useConfirm()

	const { data, loading: getInterfaceFirmwaresLoading } = useGetInterfaceFirmwareUpdatesInternalQuery({
		onCompleted: (data) => {
			const currentVersion =
				data?.getInterfaceFirmwareUpdates.find((fw) => fw.default) ?? data?.getInterfaceFirmwareUpdates.at(0)

			setSelectedVersion(currentVersion)

			handleGetFirmwareVersion && currentVersion && handleGetFirmwareVersion(currentVersion)
		},
	})

	const [setDefaultInterfaceFirmware] = useSetDefaultInterfaceFirmwareMutation({
		refetchQueries: [{ query: GetInterfaceFirmwareUpdatesInternalDocument }],
	})

	const handleChangeVersion = (event: SelectChangeEvent) => {
		const selectedVersion = data?.getInterfaceFirmwareUpdates.find((fw) => fw.version === event.target.value)
		setSelectedVersion(selectedVersion)
		handleGetFirmwareVersion && selectedVersion && handleGetFirmwareVersion(selectedVersion)
	}

	const handleSetDefault = async (update: InterfaceFirmwareVersion) => {
		await confirm(t("MsgConfirmSetDefaultInterfaceFW", { firmwareVersionName: update.version }))
		try {
			await setDefaultInterfaceFirmware({ variables: { firmwareId: update.id } })
			message.success(t("msgFirmwareSetToDefault", { firmwareVersionName: update.version }))
		} catch (e) {
			message.error(appendErrorMessage("Errors.SettingDefaultFW", e))
		}
	}

	if (getInterfaceFirmwaresLoading || loading) return <LinearProgress />

	const disabled =
		(selectedVersion && selectedVersion.timestamp === currentFirmware?.interface?.firmwareVersion.timestamp) ||
		((currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.InProgress ||
			currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.Requested) &&
			!isUpdateStuck(currentUpdateStatus?.interface))

	return (
		<Stack width="100%" alignItems="flex-start" spacing={2}>
			{currentFirmware?.interface?.firmwareVersion && (
				<Typography variant="h6">
					{t("CurrentVersion")}: {currentFirmware.interface.firmwareVersion.name}
				</Typography>
			)}
			<Select value={selectedVersion?.version ?? ""} onChange={handleChangeVersion}>
				{data?.getInterfaceFirmwareUpdates.map((update) => {
					return (
						<MenuItem value={update.version} key={`menuItem-${update.version}`}>
							{update.name}
							{update.default && ` (${t("Default")})`}
						</MenuItem>
					)
				})}
			</Select>
			{selectedVersion && (
				<>
					<LazyMarkdown>{selectedVersion.description}</LazyMarkdown>
					<Stack direction="row" spacing={1}>
						<Button variant="contained" disabled={disabled} onClick={() => handleUpdateInterface(selectedVersion)}>
							{t("Update")}
						</Button>
						{isAdmin && !selectedVersion.default && (
							<Button variant="contained" onClick={() => handleSetDefault(selectedVersion)}>
								{t("MakeDefault")}
							</Button>
						)}
					</Stack>
				</>
			)}
		</Stack>
	)
}
