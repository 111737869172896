import { useConfirm } from "providers/ConfirmProvider"
import { useContext, useEffect } from "react"
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom"

function useCloseAppWarning(active: boolean = true) {
	// show a message when the user tries to leave the page
	useEffect(() => {
		const handlePreventClose = (e: BeforeUnloadEvent) => {
			// by preventing the default, we show a message when the user tries to close/reload the page
			if (active) e.preventDefault()
		}
		window.addEventListener("beforeunload", handlePreventClose)
		return () => window.removeEventListener("beforeunload", handlePreventClose)
	}, [active])
}

export function useMoveAwayWithinApp(message: string, active: boolean = true) {
	const { navigator } = useContext(NavigationContext)
	const confirm = useConfirm()

	useEffect(() => {
		// store original push to restore when we are done
		const push = navigator.push

		// "monkey patch" push method to show confirm first
		navigator.push = async (...args: Parameters<typeof push>) => {
			if (active) await confirm(message) // this never resolves if not confirmed
			push(...args)
		}

		// make sure to use original push when unmounting
		return () => {
			navigator.push = push
		}
	}, [navigator, message, confirm, active])
}

export function useMoveAwayWarning(message: string, active: boolean = true) {
	// show a warning when we try to close the page
	useCloseAppWarning(active)

	// show a warning when we try to go to another page within the app
	useMoveAwayWithinApp(message, active)
}
