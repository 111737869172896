import { makeVar } from "@apollo/client"
import { LogPeriod } from "generated/graphql"

let period: LogPeriod
try {
	period = localStorage.getItem("logPeriod") as LogPeriod
	if (!period) throw new Error("first visit")
	if (!Object.values(LogPeriod).includes(period)) {
		throw new Error("Not a valid log period")
	}
} catch (e) {
	period = LogPeriod.LastWeek
}

const logPeriod = makeVar(period)

const handleChange = (period: LogPeriod) => {
	logPeriod.onNextChange(handleChange)
	// do not save realtime
	if (period !== LogPeriod.Realtime) {
		localStorage.setItem("logPeriod", period)
	}
}
logPeriod.onNextChange(handleChange)

export default logPeriod
