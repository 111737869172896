import { TextField, TextFieldProps } from "@mui/material"
import { Language } from "generated/graphql"
import { Translation } from "tools/translation"

export type TranslatedTextInputProps = Omit<TextFieldProps, "onChange"> & {
	language: Language
	value?: Translation | null
	onChange: (value: Translation | null) => void
	defaultValue?: Translation
}

export default function TranslatedTextInput({
	value,
	onChange,
	onFocus,
	defaultValue,
	language,
	...textFieldProps
}: TranslatedTextInputProps) {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange({ ...value, [language]: e.target.value === "" ? null : e.target.value })
	}

	const handleFocus = () => {
		// on focus, set value to default
		if (defaultValue != null && value == null) onChange(defaultValue)
	}

	return (
		<TextField
			fullWidth
			margin="normal"
			value={value?.[language] ?? ""}
			onChange={handleChange}
			onFocus={onFocus ?? handleFocus}
			placeholder={defaultValue?.[language]}
			InputLabelProps={{
				shrink: defaultValue != null ? true : undefined,
			}}
			{...textFieldProps}
		/>
	)
}
