import { CardHeader, CardContent, Button, Skeleton, Typography, CardActions, styled, Stack } from "@mui/material"
import CustomCard from "components/CustomCard"
import Page from "components/Page"
import { NewUser, useMyAccountQuery, useUpsertUserMutation } from "generated/graphql"
import useForm from "hooks/useForm"
import useSize from "hooks/useSize"
import MultipleTextField from "inputs/MultipleTextField"
import TextInput from "inputs/TextInput"
import { useAuth } from "providers/AuthProvider"
import { useConfirm } from "providers/ConfirmProvider"
import { useMessage } from "providers/MessageProvider"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import appendErrorMessage from "tools/appendErrorMessage"

const RedTitle = styled("span")(({ theme }) => ({
	color: theme.palette.error.main,
}))

const StyledCardActions = styled(CardActions)(({ theme }) => ({
	padding: theme.spacing(2),
}))

type ChangePasswordInput = {
	oldPassword: string
	newPassword: string
}

export default function AccountSettingsPage() {
	const { t } = useTranslation(["accountSettings", "general"])
	const smallScreen = useSize("down", "md")
	const message = useMessage()
	const confirm = useConfirm()
	const auth = useAuth()
	const { data, error, loading: meLoading } = useMyAccountQuery({ onCompleted: (data) => resetMe(data.me) })
	const [upsertUser, { loading: updateLoading }] = useUpsertUserMutation()
	const { register: registerMe, submit: submitMe, reset: resetMe } = useForm<NewUser>(data?.me, updateUser)
	const {
		register: registerPassword,
		submit: submitPassword,
		reset: resetPassword,
	} = useForm<ChangePasswordInput>({ oldPassword: "", newPassword: "" }, changePassword)
	const [passwordIsChanging, setPasswordIsChanging] = useState(false)
	const [accountIsDeleting, setAccountIsDeleting] = useState(false)

	async function updateUser(user: NewUser) {
		try {
			await upsertUser({ variables: { user } })
			message.success(t("UserSettingsChanged"))
		} catch (e) {
			message.error(appendErrorMessage(t("Errors.ChangeUserSettings"), e))
		}
	}

	async function changePassword(passwords: ChangePasswordInput) {
		setPasswordIsChanging(true)
		try {
			await auth.changePassword(passwords.oldPassword, passwords.newPassword)
			message.success(t("PasswordChanged"))
			resetPassword({ oldPassword: "", newPassword: "" })
		} catch (e) {
			message.error(appendErrorMessage(t("Errors.ChangePassword"), e))
		} finally {
			setPasswordIsChanging(false)
		}
	}

	async function deleteAccount() {
		await confirm(t("MsgConfirmAccountDelete"))
		setAccountIsDeleting(true)
		try {
			await auth.deleteAccount()
			message.success(t("AccountDeleted"))
		} catch (e) {
			message.error(appendErrorMessage(t("Errors.DeletingAccount"), e))
		} finally {
			setAccountIsDeleting(false)
		}
	}

	const itemStyle = { width: smallScreen ? "100%" : 750 }

	return (
		<Page title={t("general:Account")} backButton noPadding={smallScreen}>
			<Stack alignItems="center" direction="column" spacing={smallScreen ? 0 : 1}>
				<CustomCard sx={itemStyle}>
					<CardHeader title={t("UserDetails")} />
					<form onSubmit={submitMe} noValidate>
						<CardContent>
							{meLoading && <Skeleton variant="rectangular" height={291} width="100%" />}
							{error && (
								<Typography color="error">
									{t("Errors.LoadingAccountDetails")} : {error.message}
								</Typography>
							)}
							{data && (
								<>
									<TextInput
										{...registerMe("firstName", { required: true, minLength: 2, maxLength: 50 })}
										label={t("general:FirstName")}
									/>
									<TextInput
										{...registerMe("lastName", { required: true, minLength: 2, maxLength: 50 })}
										label={t("general:LastName")}
									/>
									<MultipleTextField {...registerMe("phoneNumbers")} label={t("PhoneNumbers")} />
								</>
							)}
						</CardContent>
						<StyledCardActions>
							<Button type="submit" variant="contained" disabled={updateLoading}>
								{t("general:Save")}
							</Button>
						</StyledCardActions>
					</form>
				</CustomCard>
				<CustomCard sx={itemStyle}>
					<CardHeader title={t("ChangePassword")} />
					<form onSubmit={submitPassword} noValidate>
						<CardContent>
							<TextInput
								{...registerPassword("oldPassword", { required: true })}
								label={t("CurrentPassword")}
								type="password"
							/>
							<TextInput
								{...registerPassword("newPassword", {
									required: true,
									validate: (newPassword, { oldPassword }) =>
										newPassword === oldPassword ? t("NewPasswordIsOldPassword") : null,
								})}
								label={t("NewPassword")}
								type="password"
							/>
						</CardContent>
						<StyledCardActions>
							<Button type="submit" variant="contained" disabled={passwordIsChanging}>
								{t("Change")}
							</Button>
						</StyledCardActions>
					</form>
				</CustomCard>
				<CustomCard sx={itemStyle}>
					<CardHeader title={<RedTitle>{t("DeleteAccount")}</RedTitle>} color="error" />
					<CardContent>
						<Typography color="error" gutterBottom>
							{t("MsgDeleteWarning")}
						</Typography>
					</CardContent>
					<StyledCardActions>
						<Button variant="contained" color="error" onClick={deleteAccount} disabled={accountIsDeleting}>
							{t("DeleteAccountButton")}
						</Button>
					</StyledCardActions>
				</CustomCard>
			</Stack>
		</Page>
	)
}
