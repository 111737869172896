import { DialogContent, DialogContentText, Tab, Tabs } from "@mui/material"
import { useState } from "react"
import Markdown from "markdown-to-jsx"

type LongDescriptionItem = {
	title: string
	items: string[]
}

interface ErrorObject {
	shortDescription: string
	shortDescriptionLabel: string
	longDescription: LongDescriptionItem[]
	tabs: string[]
	dialogTitle: string
}

type Props = {
	errorObject: ErrorObject
}
export default function AdvancedErrorView({ errorObject }: Props) {
	const [moreInfoTab, setMoreInfoTab] = useState(0)

	const DynamicTabs = () => {
		return (
			<>
				{errorObject.tabs.length > 1 && (
					<Tabs
						sx={{ mx: -2 }}
						variant="scrollable"
						scrollButtons
						allowScrollButtonsMobile
						value={moreInfoTab}
						onChange={(_, newValue) => {
							setMoreInfoTab(newValue)
						}}
					>
						{errorObject.tabs?.map((el, index) => (
							<Tab key={index} label={el} />
						))}
					</Tabs>
				)}
			</>
		)
	}

	return (
		<>
			<DialogContentText variant="h5">{errorObject.dialogTitle}</DialogContentText>

			<DynamicTabs />

			<DialogContent>
				{Array.from({ length: errorObject.longDescription.length }).map(
					(_, index) =>
						moreInfoTab === index && (
							<Markdown key={index}>{errorObject.longDescription[index].items.join("\n")}</Markdown>
						)
				)}
			</DialogContent>
		</>
	)
}
