import { Dialog, DialogProps } from "@mui/material"
import { Breakpoint } from "@mui/material/styles"
import useSize from "hooks/useSize"

interface Props extends DialogProps {
	fullScreenDown?: Breakpoint
	autoFullScreen?: boolean
}

export default function TSDialog({ fullScreenDown = "sm", autoFullScreen, ...props }: Props) {
	const fullScreen = autoFullScreen && useSize("down", fullScreenDown)
	const handleKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key !== "Escape") return
		e.preventDefault()
		if (props.onClose) props.onClose(e, "escapeKeyDown")
	}

	return (
		<Dialog
			fullScreen={fullScreen}
			maxWidth={fullScreenDown}
			fullWidth
			{...props}
			disableEscapeKeyDown
			onKeyDown={!props.disableEscapeKeyDown ? handleKey : undefined}
		/>
	)
}
