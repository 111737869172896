import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"
import TSDialog from "components/TSDialog"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { HEAT_PUMP } from "settings/url"

type Props = {
	onClose: () => void
	interfaceId: string
	navigateToPage: boolean
}

export default function InterfaceClaimDialog({ onClose, interfaceId, navigateToPage }: Props) {
	const { t } = useTranslation(["newInterfaceClaimedDialog", "general"])
	return (
		<TSDialog open onClose={onClose}>
			<form>
				<DialogTitle>{t("ClaimedNewHP")}</DialogTitle>
				<DialogContent>
					<DialogContentText gutterBottom>
						<Trans i18nKey="MsgSuccessfullyAdded" ns="newInterfaceClaimedDialog" components={{ italic: <i /> }} />
					</DialogContentText>
					<DialogContentText>{t("AddResidentsIfApplicable")}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={onClose}>
						{t("general:Close")}
					</Button>
					{navigateToPage ? (
						<Button component={Link} to={`/${HEAT_PUMP}/${interfaceId}`} variant="contained" sx={{ ml: 1 }}>
							{t("ToHeatPump")}
						</Button>
					) : (
						<Button variant="contained" onClick={onClose}>
							{t("ToHeatPump")}
						</Button>
					)}
				</DialogActions>
			</form>
		</TSDialog>
	)
}
