import {
	BackupHeaterType,
	ControllerSettingsInput,
	InterfacesFieldsFragment,
	ManualCoolingMode,
	PvtHeatPumpDhwMode,
	PvtHeatPumpInput,
	PvtHeatPumpRoomControlType,
	PvtHeatPumpSettingsFieldsFragment,
	PvtHeatPumpSettingsFieldsFragmentDoc,
	ReadHeatPumpSettingsQuery,
	UpdatePvtHeatPumpSettingsMutation,
	UpdatePvtHeatPumpSettingsMutationVariables,
	useRestartPvtHeatPumpMutation,
	useUpdatePvtHeatPumpSettingsMutation,
} from "generated/graphql"
import { ApolloCache, ApolloError, InMemoryCache } from "@apollo/client"
import { useEffect, useMemo, useState } from "react"
import { useMessage } from "providers/MessageProvider"
import useForm from "hooks/useForm"
import {
	INTERFACE_V1_2_0_TIMESTAMP,
	INTERFACE_V1_2_1_TIMESTAMP,
	INTERFACE_V1_2_3_TIMESTAMP,
	isPvtHeatPumpFirmwareNewerOrEqual,
	PVT_HEAT_PUMP_V1_1,
	PVT_HEAT_PUMP_V490_1,
} from "settings/firmwareVersions"
import onlyChangedValues from "tools/onlyChangedValues"
import appendErrorMessage from "tools/appendErrorMessage"
import HeatPumpInfoCard from "components/HeatPumpInfoCard"
import { Box, Button, CircularProgress, FormControlLabel, Stack, styled, Switch, Typography } from "@mui/material"
import RadioInput from "inputs/RadioInput"
import SelectInput from "inputs/SelectInput"
import MoreInfo from "components/MoreInfo"
import Image from "components/Image"
import NumberInput from "inputs/NumberInput"
import BooleanInput from "inputs/BooleanInput"
import HeadingLabel from "components/HeadingLabel"
import SGReadyMoreInfo from "components/SGReadyMoreInfo"
import { PowerSettingsNew, Save } from "@mui/icons-material"
import FloatingFab from "components/FloatingFab"
import { Trans, useTranslation } from "react-i18next"
import InterfaceSaveTypeAlert from "components/InterfaceSaveTypeAlert"
import { StatusMessageProps } from "components/BatchUpdate"
import handleSettingsError from "tools/handleSettingsError"
import { GraphQLError } from "graphql/error"
import { ExtendedRoomControlType } from "types"
import { getStaticLables } from "tools/getStaticLabels"
import DraggableHeatingCurve from "components/DraggableHeatingCurve"
import { handleSupplyTempCooling, handleSupplyTempHeating } from "tools/handleHeatingCruve"
const CELCIUS = "°C"

type FormFields = Omit<PvtHeatPumpInput, "roomControlType"> & {
	extendedControlType?: ExtendedRoomControlType | null
} & ControllerSettingsInput

const StyledTypography = styled(Typography)(() => ({
	display: "flex",
	alignItems: "center",
}))

function updatePvtHeatPumpCache(
	cache: ApolloCache<InMemoryCache>,
	{ data, errors }: { data?: UpdatePvtHeatPumpSettingsMutation | null; errors?: readonly GraphQLError[] | undefined },
	{ variables }: { variables?: UpdatePvtHeatPumpSettingsMutationVariables },
) {
	if (!data?.updatePvtHeatPump || !variables) return
	const { pvtHeatPumpdata: inputData, interfaceIds } = variables
	const ids = Array.isArray(interfaceIds) && interfaceIds.length > 0 ? interfaceIds : []

	const interfaceIdsWithErrors = errors
		?.filter((error) => typeof error.extensions.interfaceID === "string")
		.map((error) => error.extensions.interfaceID)

	const onlineInterfaceIds = ids.filter((id) => !interfaceIdsWithErrors?.includes(id))

	onlineInterfaceIds.forEach((defaultInterfaceId) => {
		const id = `PvtHeatPumpData:${defaultInterfaceId}`
		const oldPvtHeatPump = cache.readFragment<PvtHeatPumpSettingsFieldsFragment>({
			id,
			fragment: PvtHeatPumpSettingsFieldsFragmentDoc,
			fragmentName: "PvtHeatPumpSettingsFields",
		})
		if (!oldPvtHeatPump) return

		const newPvtHeatPump = (Object.keys(oldPvtHeatPump) as Array<keyof PvtHeatPumpSettingsFieldsFragment>).reduce(
			(acc, key) => ({ ...acc, [key]: inputData[key as keyof PvtHeatPumpInput] ?? oldPvtHeatPump[key] }),
			{} as PvtHeatPumpSettingsFieldsFragment,
		)

		cache.writeFragment<PvtHeatPumpSettingsFieldsFragment>({
			id,
			fragment: PvtHeatPumpSettingsFieldsFragmentDoc,
			fragmentName: "PvtHeatPumpSettingsFields",
			data: newPvtHeatPump,
		})
	})
}

export default function GeneralSettingsFields({
	data,
	error,
	readLoading,
	isPartialSave,
	refetch,
	interfaces,
	defaultInterfaceId = "",
	handleToggleSavingType,
	handleSavingMessages,
	handleSaveLoading,
}: {
	data: ReadHeatPumpSettingsQuery | undefined
	error: ApolloError | undefined
	readLoading: boolean
	isPartialSave?: boolean
	refetch: () => void
	interfaces?: InterfacesFieldsFragment[] | undefined
	defaultInterfaceId: string | undefined
	handleToggleSavingType?: (id: string) => void
	handleSavingMessages?: (updateStatus: StatusMessageProps[]) => void
	handleSaveLoading?: (isLoading: boolean) => void
}) {
	const isBatchUpdate = Array.isArray(interfaces) && interfaces.length > 0

	const { t, i18n } = useTranslation([
		"heatPumpSettingsPage",
		"pvtHeatPumpAdminPage",
		"general",
		"heatPumpErrorCard",
		"heatPumpStatus",
	])

	const {
		READABLE_ROOM_TEMP_CONTROL_WITH_COOLING,
		READABLE_ROOM_TEMP_CONTROL_NO_COOLING,
		READABLE_ROOM_TEMP_CONTROL_NO_COOLING_HIDDEN,
		READABLE_BACKUP_HEATER,
		READABLE_BACKUP_HEATER_HIDDEN,
		READABLE_DHW_MODE,
		READABLE_DHW_MODE_HIDDEN,
		READABLE_FLUSHING_MODE,
		READABLE_FLUSHING_MODE_HIDDEN,
		READABLE_MANUAL_COOLING_MODE,
		READABLE_MANUAL_COOLING_MODE_HIDDEN,
		HIDDEN_SETTING_TEXT,
		includesHeating,
		includesCooling,
		usedHpContacts,
		validateCHTempMax,
		validateRoomControlType,
		getReadableRoomControlType,
	} = useMemo(() => getStaticLables(t), [i18n.language])

	const onlineInterfaceIds = isBatchUpdate ? interfaces.filter((item) => item.isOnline).map((item) => item.id) : []

	const message = useMessage()

	const [updatePVTHeatPumpSettings, { loading: updateLoading }] = useUpdatePvtHeatPumpSettingsMutation({
		update: updatePvtHeatPumpCache,
	})

	const { register, submit, reset, fields, set } = useForm<FormFields>({}, handleSave, [
		"manualCoolingMode",
		"chSetpMaxTemp",
		"supplyTempMin30CustomHeating",
		"supplyTempMin20CustomHeating",
		"supplyTempMin10CustomHeating",
		"supplyTemp0CustomHeating",
		"supplyTemp10CustomHeating",
		"supplyTemp20CustomHeating",
		"supplyTemp30CustomHeating",
		"supplyTemp20CustomCooling",
		"supplyTemp30CustomCooling",
		"supplyTemp40CustomCooling",
	])

	useEffect(() => {
		// Also gets triggered when loading from cache, not only when done with network fetch. Relevant on switching tabs
		if (!data?.interface?.pvtHeatPump) return

		const { roomControlType, ...fields } = data.interface.pvtHeatPump

		let extendedControlType: ExtendedRoomControlType | null = roomControlType ?? null
		if (
			new Date(data?.interface?.firmwareVersion.timestamp ?? 0) >= INTERFACE_V1_2_3_TIMESTAMP &&
			roomControlType === PvtHeatPumpRoomControlType.Opentherm && // should be in OT mode, else manual cooling will not work
			data?.interface?.controller.manualCoolingMode !== ManualCoolingMode.Inactive // either on or off
		) {
			extendedControlType = "MANUAL_COOLING"
		}
		reset({ extendedControlType, ...fields, ...data.interface.controller })
	}, [data])

	useEffect(() => {
		if (fields.extendedControlType == null) return
		if (fields.extendedControlType === "MANUAL_COOLING") {
			// if extendedControlType is set to manual cooling mode, make sure to change manualCoolingMode too
			set({
				manualCoolingMode:
					fields.manualCoolingMode !== ManualCoolingMode.Inactive ? fields.manualCoolingMode : ManualCoolingMode.Off,
			})
		} else {
			set({ manualCoolingMode: ManualCoolingMode.Inactive })
		}
	}, [fields.extendedControlType])

	async function handleSave(formData: FormFields) {
		if (!data?.interface?.pvtHeatPump) {
			message.error(t("Errors.ConnectingToHP"))
			console.error("readData is undefined when checking for changed values on save")
			return
		}

		//SG-Ready setpoints
		formData.enableSgInput = Boolean(formData.sgAffectDhw || formData.sgAffectSc || formData.sgAffectSh)
		if (formData.dhwMode === PvtHeatPumpDhwMode.Off) {
			//This is a metrotherm bug where SG can start dhw production, even if dhwmode is off
			//Also happens in backend to make sure it happens but this was the easiest way to keep the cache in sync
			formData.sgAffectDhw = false
			formData.sgAllowBhDhw = false
		}

		// split into controller and heatpump settings
		const { backupHeater, chSetpMaxTemp, manualCoolingMode, extendedControlType, ...heatPumpFields } = formData
		const controllerData: ControllerSettingsInput = { backupHeater, chSetpMaxTemp, manualCoolingMode }

		const heatPumpData: PvtHeatPumpInput & { id?: string } = {
			...heatPumpFields,
			roomControlType:
				extendedControlType === "MANUAL_COOLING" ? PvtHeatPumpRoomControlType.Opentherm : extendedControlType,
		}

		// get changed fields
		const changedControllerData = onlyChangedValues(data.interface.controller, controllerData)
		const changedHeatPumpData = onlyChangedValues(data.interface.pvtHeatPump, heatPumpData)

		// check if nothing changed
		if (
			isPartialSave &&
			Object.keys(changedHeatPumpData).length === 0 &&
			Object.keys(changedControllerData).length === 0
		) {
			message.info(t("Errors.HPHasTheseSettings"))
			return
		}

		//remove id if all fields are saving
		const { id: _id, ...heatPumpFormData } = heatPumpData

		try {
			if (!isBatchUpdate && !defaultInterfaceId) throw new Error(t("general:InterfaceIDNotFound"))
			if (isBatchUpdate && !defaultInterfaceId) throw new Error(t("general:InterfaceIDNotFound"))

			const response = await updatePVTHeatPumpSettings({
				variables: {
					interfaceIds: isBatchUpdate ? onlineInterfaceIds : [defaultInterfaceId],
					pvtHeatPumpdata: isBatchUpdate
						? isPartialSave
							? changedHeatPumpData
							: heatPumpFormData
						: changedHeatPumpData,
					controllerSettings: isBatchUpdate
						? isPartialSave
							? changedControllerData
							: controllerData
						: changedControllerData,
					isExpert: false,
					isAdmin: false,
				},
				errorPolicy: isBatchUpdate ? "all" : "none", // If 1 or more interface is offline, the mutation will continue, This will ignore the error catch for GraphQL
			})

			message.success(t("SettingsSaved"))

			if (handleSavingMessages) {
				handleSavingMessages([{ success: true }])
			}

			// Since we use errorPolicy: "all", the error won't return at catch error
			if (response?.errors) {
				handleSettingsError({ errorResponse: response?.errors, handleSavingMessages, t })
			}
		} catch (e) {
			message.error(appendErrorMessage(t("heatPumpSettingsPage:Errors.UnkownSaving"), e))
		}

		if (handleSaveLoading) {
			handleSaveLoading(updateLoading)
		}
	}

	const loading = updateLoading || readLoading

	const interfaceHasChMaxTemp = new Date(data?.interface?.firmwareVersion.timestamp ?? 0) >= INTERFACE_V1_2_0_TIMESTAMP // 0 is epoch, always a long time ago
	const interfaceHasOTCooling = new Date(data?.interface?.firmwareVersion.timestamp ?? 0) >= INTERFACE_V1_2_1_TIMESTAMP

	const currentHPVersion = data?.interface?.pvtHeatPump?.firmwareVersion ?? Infinity // if unknown, pick high firmware version number
	const hpHasMinSinkTempSetp = isPvtHeatPumpFirmwareNewerOrEqual(currentHPVersion, PVT_HEAT_PUMP_V490_1)
	const hpHasBoost = isPvtHeatPumpFirmwareNewerOrEqual(currentHPVersion, PVT_HEAT_PUMP_V1_1)
	const hpHasSgReady = isPvtHeatPumpFirmwareNewerOrEqual(currentHPVersion, PVT_HEAT_PUMP_V1_1)
	const hpHasDM = isPvtHeatPumpFirmwareNewerOrEqual(currentHPVersion, PVT_HEAT_PUMP_V1_1)

	const hasHeating = includesHeating(fields.roomTemperatureControl)
	const hasCooling = includesCooling(fields.roomTemperatureControl)
	const hasDhw = fields.dhwMode !== PvtHeatPumpDhwMode.Off
	const hasDmControl = fields?.extendedControlType === PvtHeatPumpRoomControlType.DmControl

	const readableRoomControlType = getReadableRoomControlType(
		hasHeating,
		hasCooling,
		data?.interface?.firmwareVersion.timestamp,
		hpHasDM,
	)

	const [restartingHeatPump, setRestartingHeatPump] = useState(false)
	const [restartPVTHeatPump, { loading: restartLoading }] = useRestartPvtHeatPumpMutation()

	async function restartHeatPump() {
		setRestartingHeatPump(true)
		try {
			if (!isBatchUpdate && !defaultInterfaceId) throw new Error(t("general:InterfaceIDNotFound"))
			if (isBatchUpdate && !defaultInterfaceId) throw new Error(t("general:InterfaceIDNotFound"))
			await restartPVTHeatPump({
				variables: { interfaceIds: isBatchUpdate ? onlineInterfaceIds : [defaultInterfaceId] },
			})
			message.success(t("heatPumpStatus:HeatPumpIsRestarting"))
			setTimeout(async () => {
				await refetch() // Errors gets reset, refetch the data to check for errors
				setRestartingHeatPump(false)
			}, 5000)
		} catch (error) {
			message.error(appendErrorMessage(t("heatPumpStatus:Errors.RestartingHeatPump"), error))
			setRestartingHeatPump(false)
		}
	}

	const changedFields = onlyChangedValues(data?.interface?.pvtHeatPump ?? {}, fields)
	const changedFieldsController = onlyChangedValues(data?.interface?.controller ?? {}, fields)

	const resetField = (field: string) => {
		if (!field || !data?.interface?.pvtHeatPump) return

		const pvtHeatPumpData: PvtHeatPumpInput = data.interface.pvtHeatPump

		const value = pvtHeatPumpData[field as keyof PvtHeatPumpInput]

		set({
			[field]: value,
		})
	}

	const resetFieldController = (field: string) => {
		if (!field || !data?.interface?.controller) return

		const pvtHeatPumpController: ControllerSettingsInput = data.interface.controller

		const value = pvtHeatPumpController[field as keyof ControllerSettingsInput]

		set({
			[field]: value,
		})
	}

	const dataSinkSupplyHeatingFields = [
		fields.supplyTempMin30CustomHeating,
		fields.supplyTempMin20CustomHeating,
		fields.supplyTempMin10CustomHeating,
		fields.supplyTemp0CustomHeating,
		fields.supplyTemp10CustomHeating,
		fields.supplyTemp20CustomHeating,
		fields.supplyTemp30CustomHeating,
	]

	const dataSinkSupplyCoolingFields = [
		fields.supplyTemp20CustomCooling,
		fields.supplyTemp30CustomCooling,
		fields.supplyTemp40CustomCooling,
	]

	return (
		<>
			<HeatPumpInfoCard
				fullWidth={isBatchUpdate}
				title={
					<Stack direction="row" justifyContent="space-between" width="100%">
						{t("HeatingCoolingTitle")}
						{isBatchUpdate && handleToggleSavingType && isPartialSave !== undefined && (
							<FormControlLabel
								onClick={(e) => e.stopPropagation()}
								control={
									<Switch
										checked={isPartialSave}
										onChange={() => handleToggleSavingType("generalSettings")}
										size="small"
									/>
								}
								labelPlacement="end"
								label={t("general:SaveOnlyChangedFields")}
							/>
						)}
					</Stack>
				}
			>
				{isBatchUpdate && <InterfaceSaveTypeAlert isPartialSave={isPartialSave} />}

				<RadioInput
					label={t("Hybrid")}
					options={READABLE_BACKUP_HEATER}
					hiddenOptions={READABLE_BACKUP_HEATER_HIDDEN}
					disabled={loading}
					hiddenOptionHelperText={HIDDEN_SETTING_TEXT}
					row
					{...register("backupHeater", { required: true })}
					fieldIsChanged={"backupHeater" in changedFieldsController}
					reset={() => resetFieldController("backupHeater")}
				/>
				{fields.backupHeater === BackupHeaterType.Opentherm && !data?.interface?.openThermBoilerConnected && (
					<Typography color="primary">{t("heatPumpStatus:NoOtherOTFound")}</Typography>
				)}
				<RadioInput
					label={t("HeatingMode")}
					options={
						interfaceHasOTCooling ? READABLE_ROOM_TEMP_CONTROL_WITH_COOLING : READABLE_ROOM_TEMP_CONTROL_NO_COOLING
					}
					hiddenOptions={!interfaceHasOTCooling ? READABLE_ROOM_TEMP_CONTROL_NO_COOLING_HIDDEN : null}
					hiddenOptionHelperText={HIDDEN_SETTING_TEXT}
					disabled={loading}
					row
					{...register("roomTemperatureControl", { required: true })}
					fieldIsChanged={"roomTemperatureControl" in changedFields}
					reset={() => resetField("roomTemperatureControl")}
				/>
				{!hpHasMinSinkTempSetp && hasCooling && (
					<>
						<Typography color="error" gutterBottom>
							{t("Errors.FirmwareNeedsUpdate", {
								firmwareVersion: data?.interface?.pvtHeatPump?.firmwareVersion
									? `V${data?.interface?.pvtHeatPump?.firmwareVersion}`
									: "van deze installatie",
							})}
						</Typography>
						{fields.sinkMinShTemp != null && fields.sinkMinShTemp < 15 && (
							<Typography color="error" gutterBottom>
								<Trans i18nKey="MsgCondensationWarning" ns="heatPumpSettingsPage" components={{ italic: <i /> }} />
							</Typography>
						)}
					</>
				)}
				{hasCooling && (
					<Typography color="primary" gutterBottom>
						{t("MsgCoolingSinkTypeWarning")}
					</Typography>
				)}
				<SelectInput
					label={t("ControlType")}
					options={readableRoomControlType}
					disabled={loading}
					{...register("extendedControlType", {
						required: true,
						validate: validateRoomControlType(currentHPVersion),
					})}
				/>
				{(fields.extendedControlType === PvtHeatPumpRoomControlType.RoomSensor ||
					fields.extendedControlType === PvtHeatPumpRoomControlType.Thermostat) &&
					fields.extendedControlType &&
					fields.roomTemperatureControl && (
						<Typography color="primary" gutterBottom>
							{t("WhereToFindContacts", {
								contacts: usedHpContacts(fields.extendedControlType, fields.roomTemperatureControl),
							})}
							<MoreInfo>
								<>
									<Typography gutterBottom>{t("SeePVTStartGuide")}</Typography>
									<Image src="/images/L8L24Diagram.png" />
								</>
							</MoreInfo>
						</Typography>
					)}
				{hasCooling && fields.extendedControlType === PvtHeatPumpRoomControlType.Opentherm && (
					<StyledTypography color="primary">
						{t("CheckThermostatForCoolingSupport")}
						<MoreInfo>
							<Typography>{t("MsgSupportedThermostats")}</Typography>
						</MoreInfo>
					</StyledTypography>
				)}
				{fields.extendedControlType === PvtHeatPumpRoomControlType.RoomSensor && hasHeating && (
					<>
						<NumberInput
							label={t("DesiredRoomTempHeating")}
							disabled={loading}
							unit={CELCIUS}
							{...register("shRoomSetpTemp", { required: true, min: 5, max: 30 })}
							fieldIsChanged={"shRoomSetpTemp" in changedFields}
							reset={() => resetField("shRoomSetpTemp")}
						/>
						<NumberInput
							label={t("RoomTempHystHeating")}
							disabled={loading}
							unit={CELCIUS}
							{...register("shRoomHysteresisTemp", { required: true, min: 0.1, max: 3 })}
							fieldIsChanged={"shRoomHysteresisTemp" in changedFields}
							reset={() => resetField("shRoomHysteresisTemp")}
						/>
					</>
				)}
				{fields.extendedControlType === PvtHeatPumpRoomControlType.RoomSensor && hasCooling && (
					<>
						<NumberInput
							label={t("DesiredRoomTempCooling")}
							disabled={loading}
							unit={CELCIUS}
							{...register("scRoomSetpTemp", { required: true, min: 5, max: 30 })}
							fieldIsChanged={"scRoomSetpTemp" in changedFields}
							reset={() => resetField("scRoomSetpTemp")}
						/>
						<NumberInput
							label={t("RoomTempHystCooling")}
							disabled={loading}
							unit={CELCIUS}
							{...register("scRoomHysteresisTemp", { required: true, min: 0.1, max: 3 })}
							fieldIsChanged={"scRoomHysteresisTemp" in changedFields}
							reset={() => resetField("scRoomHysteresisTemp")}
						/>
					</>
				)}
				{fields.extendedControlType === "MANUAL_COOLING" && (
					<RadioInput
						label={t("ManualCooling")}
						row
						disabled={loading}
						options={READABLE_MANUAL_COOLING_MODE}
						hiddenOptions={READABLE_MANUAL_COOLING_MODE_HIDDEN}
						{...register("manualCoolingMode")}
						fieldIsChanged={"manualCoolingMode" in changedFieldsController}
						reset={() => resetFieldController("manualCoolingMode")}
					/>
				)}
				{fields.extendedControlType === PvtHeatPumpRoomControlType.Opentherm && hasHeating && interfaceHasChMaxTemp && (
					<NumberInput
						label={t("MaxSinkTempHeating")}
						disabled={loading}
						unit={CELCIUS}
						{...register("chSetpMaxTemp", { required: true, validate: validateCHTempMax })}
						fieldIsChanged={"chSetpMaxTemp" in changedFieldsController}
						reset={() => resetFieldController("chSetpMaxTemp")}
					/>
				)}
				{hpHasMinSinkTempSetp && hasCooling && (
					<>
						<NumberInput
							label={t("MinSinkCooling")}
							disabled={loading}
							unit={CELCIUS}
							{...register("sinkCoolingPauseThresholdTemp", { required: true, min: 15, max: 25 })}
							fieldIsChanged={"sinkCoolingPauseThresholdTemp" in changedFields}
							reset={() => resetField("sinkCoolingPauseThresholdTemp")}
						/>
						{fields.sinkCoolingPauseThresholdTemp != null && fields.sinkCoolingPauseThresholdTemp < 15 && (
							<Typography color="primary">{t("MsgMin15C")}</Typography>
						)}
					</>
				)}
				<BooleanInput
					label={t("BackupEnable")}
					disabled={loading}
					{...register("shBackupEnable", { required: true })}
					fieldIsChanged={"shBackupEnable" in changedFields}
					reset={() => resetField("shBackupEnable")}
				/>
			</HeatPumpInfoCard>
			<HeatPumpInfoCard fullWidth={isBatchUpdate} title={t("DHW")}>
				{isBatchUpdate && <InterfaceSaveTypeAlert isPartialSave={isPartialSave} />}

				<RadioInput
					label={t("DHWMode")}
					options={READABLE_DHW_MODE}
					hiddenOptions={READABLE_DHW_MODE_HIDDEN}
					hiddenOptionHelperText={HIDDEN_SETTING_TEXT}
					disabled={loading}
					row
					{...register("dhwMode")}
					fieldIsChanged={"dhwMode" in changedFields}
					reset={() => resetField("dhwMode")}
				/>
				{hasDhw && (
					<Stack width="100%" direction="row" alignItems="center">
						<NumberInput
							label={t("DesiredDHWTemp")}
							disabled={loading}
							unit={CELCIUS}
							{...register("dhwAutoTemp", { required: true, min: 30, max: 70 })}
							fieldIsChanged={"dhwAutoTemp" in changedFields}
							reset={() => resetField("dhwAutoTemp")}
						/>

						<MoreInfo>
							<Typography>{t("Tooltips.IdealBoilerTempMessage")}</Typography>
						</MoreInfo>
					</Stack>
				)}
			</HeatPumpInfoCard>
			<HeatPumpInfoCard fullWidth={isBatchUpdate} title={t("Advanced")} collapsible defaultCollapsed>
				{isBatchUpdate && <InterfaceSaveTypeAlert isPartialSave={isPartialSave} />}

				{fields.extendedControlType === PvtHeatPumpRoomControlType.DmControl &&
					(hasHeating || hasCooling) &&
					hpHasDM && (
						<>
							<HeadingLabel gutterTop>{t("HeatingCurve")}</HeadingLabel>
							{hasHeating && (
								<Box mb={4}>
									<DraggableHeatingCurve
										labels={["-30°C", "-20°C", "-10°C", "0°C", "10°C", "20°C", "30°C"]}
										minYValue={5}
										maxYValue={80}
										dataSinkSupplyTempFields={dataSinkSupplyHeatingFields}
										handleSupplyTemp={(curveData) => {
											const { propertyName, value } = handleSupplyTempHeating(curveData)
											set({ [propertyName]: value })
										}}
									/>

									<NumberInput
										label={t("pvtHeatPumpAdminPage:OutdoorTemperatureThresholdForHeating")}
										{...register("dmTOutdoorShEnable", { required: true, min: 5, max: 20 })}
										unit={CELCIUS}
										disabled={loading}
										fieldIsChanged={"dmTOutdoorShEnable" in changedFields}
										reset={() => resetField("dmTOutdoorShEnable")}
									/>

									<NumberInput
										label={t("pvtHeatPumpAdminPage:DegreeMinutesThresholdForHeating")}
										{...register("dmHeatingThreshold", { required: true, min: -10000, max: -10 })}
										unit={t("general:DM")}
										disabled={loading}
										fieldIsChanged={"dmHeatingThreshold" in changedFields}
										reset={() => resetField("dmHeatingThreshold")}
									/>
								</Box>
							)}

							{hasCooling && (
								<Box>
									<DraggableHeatingCurve
										labels={["20°C", "30°C", "40°C"]}
										minYValue={5}
										maxYValue={25}
										dataSinkSupplyTempFields={dataSinkSupplyCoolingFields}
										handleSupplyTemp={(curveData) => {
											const { propertyName, value } = handleSupplyTempCooling(curveData)
											set({ [propertyName]: value })
										}}
									/>

									<NumberInput
										label={t("pvtHeatPumpAdminPage:OutdoorTemperatureThresholdForCooling")}
										{...register("dmTOutdoorScEnable", { required: true, min: 10, max: 40 })}
										unit={CELCIUS}
										disabled={loading}
										fieldIsChanged={"dmTOutdoorScEnable" in changedFields}
										reset={() => resetField("dmTOutdoorScEnable")}
									/>

									<NumberInput
										label={t("pvtHeatPumpAdminPage:DegreeMinutesThresholdForCooling")}
										{...register("dmCoolingThreshold", { required: true, min: 10, max: 7500 })}
										unit={t("general:DM")}
										disabled={loading}
										fieldIsChanged={"dmCoolingThreshold" in changedFields}
										reset={() => resetField("dmCoolingThreshold")}
									/>
								</Box>
							)}
						</>
					)}

				<HeadingLabel>{t("FaultLimits")}</HeadingLabel>
				<NumberInput
					label={t("MaxSinkTempErrorSetp")}
					disabled={loading}
					unit={CELCIUS}
					{...register("sinkMaxShTemp", { required: true, min: -10, max: 100 })}
					fieldIsChanged={"sinkMaxShTemp" in changedFields}
					reset={() => resetField("sinkMaxShTemp")}
				/>
				<NumberInput
					label={t("MinSinkTempErrorSetp")}
					disabled={loading}
					unit={CELCIUS}
					{...register("sinkMinShTemp", { required: true, min: -10, max: 30 })}
					fieldIsChanged={"sinkMinShTemp" in changedFields}
					reset={() => resetField("sinkMinShTemp")}
				/>

				<HeadingLabel gutterBottom>{t("Venting")}</HeadingLabel>
				<RadioInput
					options={READABLE_FLUSHING_MODE}
					hiddenOptions={READABLE_FLUSHING_MODE_HIDDEN}
					hiddenOptionHelperText={HIDDEN_SETTING_TEXT}
					disabled={loading}
					row
					label={t("FlushingMode")}
					{...register("flushingMode", { required: true })}
					fieldIsChanged={"flushingMode" in changedFields}
					reset={() => resetField("flushingMode")}
				/>
				{hpHasBoost && (
					<>
						<HeadingLabel>{t("BoostMode")}</HeadingLabel>
						<Stack direction="row" alignItems="center">
							<BooleanInput
								label={t("HeatingBoost")}
								disabled={loading}
								{...register("shBoostEnabled", { required: true })}
								fieldIsChanged={"shBoostEnabled" in changedFields}
								reset={() => resetField("shBoostEnabled")}
							/>
							<BooleanInput
								label={t("DHWBoost")}
								disabled={loading}
								{...register("dhwBoostEnabled", { required: true })}
								fieldIsChanged={"dhwBoostEnabled" in changedFields}
								reset={() => resetField("dhwBoostEnabled")}
							/>
							<MoreInfo>
								<Trans i18nKey="MsgBoostDescription" ns="heatPumpSettingsPage" components={{ italic: <i /> }} />
							</MoreInfo>
						</Stack>
						{(fields.shBoostEnabled || fields.dhwBoostEnabled) && (
							<>
								<Typography color="primary">{t("MsgBoostConsumption")}</Typography>
								<NumberInput
									label={t("BoostSourceTemp")}
									disabled={loading}
									unit={CELCIUS}
									{...register("boostSourceTemp", { required: true, min: -20, max: 50 })}
									fieldIsChanged={"boostSourceTemp" in changedFields}
									reset={() => resetField("boostSourceTemp")}
								/>
							</>
						)}
					</>
				)}

				{hpHasSgReady && (hasDhw || (hasDmControl && (hasHeating || hasCooling))) && (
					<>
						<HeadingLabel>
							{t("SGReady")}
							<SGReadyMoreInfo />
						</HeadingLabel>
						{hasDmControl && (
							<>
								{hasCooling && (
									<BooleanInput
										label={t("SGAffectSC")}
										disabled={loading}
										{...register("sgAffectSc")}
										fieldIsChanged={"sgAffectSc" in changedFields}
										reset={() => resetField("sgAffectSc")}
									/>
								)}

								{hasHeating && (
									<Stack direction="row">
										<BooleanInput
											label={t("SGAffectSH")}
											disabled={loading}
											{...register("sgAffectSh")}
											fieldIsChanged={"sgAffectSh" in changedFields}
											reset={() => resetField("sgAffectSh")}
										/>
										{fields.sgAffectSh && (
											<BooleanInput
												label={t("SGAllowBHsh")}
												disabled={loading}
												{...register("sgAllowBhSh")}
												fieldIsChanged={"sgAllowBhSh" in changedFields}
												reset={() => resetField("sgAllowBhSh")}
											/>
										)}
									</Stack>
								)}
							</>
						)}
						{hasDhw && (
							<Stack direction="row">
								<BooleanInput
									label={t("SGAffectDHW")}
									disabled={loading}
									{...register("sgAffectDhw")}
									fieldIsChanged={"sgAffectDhw" in changedFields}
									reset={() => resetField("sgAffectDhw")}
								/>
								{fields.sgAffectDhw && (
									<BooleanInput
										label={t("SGAllowBHdhw")}
										disabled={loading}
										{...register("sgAllowBhDhw")}
										fieldIsChanged={"sgAllowBhDhw" in changedFields}
										reset={() => resetField("sgAllowBhDhw")}
									/>
								)}
							</Stack>
						)}
					</>
				)}

				{(!data || !error) && !readLoading ? (
					<Button
						variant="outlined"
						fullWidth
						onClick={restartHeatPump}
						disabled={restartLoading || restartingHeatPump}
						startIcon={restartingHeatPump ? <CircularProgress size={20} /> : <PowerSettingsNew fontSize="large" />}
					>
						{t("heatPumpStatus:RestartHeatPump")}
					</Button>
				) : (
					<CircularProgress size={30} />
				)}
			</HeatPumpInfoCard>

			{data && !error && (
				<FloatingFab submit={submit} loading={loading} fitBottom={isBatchUpdate}>
					<Save />
					<Typography variant="button">{t("SaveSettings")}</Typography>
				</FloatingFab>
			)}
		</>
	)
}
