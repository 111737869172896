import { PvtHeatPumpInput } from "generated/graphql"

export function handleSupplyTempCooling(curveData: { supplyTempIndex: number; value: number }) {
	const coolingCurveFields: (keyof PvtHeatPumpInput)[] = [
		"supplyTemp20CustomCooling",
		"supplyTemp30CustomCooling",
		"supplyTemp40CustomCooling",
	]

	return { propertyName: coolingCurveFields[curveData.supplyTempIndex], value: curveData.value }
}

export function handleSupplyTempHeating(curveData: { supplyTempIndex: number; value: number }) {
	const heatingCurveFields: (keyof PvtHeatPumpInput)[] = [
		"supplyTempMin30CustomHeating",
		"supplyTempMin20CustomHeating",
		"supplyTempMin10CustomHeating",
		"supplyTemp0CustomHeating",
		"supplyTemp10CustomHeating",
		"supplyTemp20CustomHeating",
		"supplyTemp30CustomHeating",
	]

	return { propertyName: heatingCurveFields[curveData.supplyTempIndex], value: curveData.value }
}
