import { format, isToday, isYesterday } from "date-fns"
import { nl } from "date-fns/locale/nl"

export default function formatShortTime(timestamp?: Date | string | null) {
	if (!timestamp) return "Onbekende tijd"
	const date = new Date(timestamp)
	if (isToday(date)) {
		return `vandaag ${format(date, "HH:mm")}`
	}
	if (isYesterday(date)) {
		return `gisteren ${format(date, "HH:mm")}`
	}
	return format(date, "dd MMM HH:mm", {
		locale: nl,
	})
}
