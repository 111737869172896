import { ApolloCache, InMemoryCache } from "@apollo/client"
import { Save } from "@mui/icons-material"
import { LinearProgress, Typography } from "@mui/material"
import {
	PvtHeatPumpAdminSettingsPumpsFragment,
	PvtHeatPumpAdminSettingsPumpsFragmentDoc,
	PvtHeatPumpInput,
	UpdatePvtHeatPumpSettingsMutation,
	UpdatePvtHeatPumpSettingsMutationVariables,
	useReadHeatPumpPumpsSettingsQuery,
	useUpdatePvtHeatPumpSettingsMutation,
} from "generated/graphql"
import useForm from "hooks/useForm"
import NumberInput from "inputs/NumberInput"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"
import { useMessage } from "providers/MessageProvider"
import { useTranslation } from "react-i18next"
import onlyChangedValues from "tools/onlyChangedValues"
import RadioInput from "inputs/RadioInput"
import { PvtHeatPumpData } from "../index"
import appendErrorMessage from "tools/appendErrorMessage"
import FloatingFab from "components/FloatingFab"
import InterfaceSaveTypeAlert from "components/InterfaceSaveTypeAlert"
import { StatusMessageProps } from "components/BatchUpdate"
import handleSettingsError from "tools/handleSettingsError"
import { GraphQLError } from "graphql/error"
const CELCIUS = "°C"

function updatePvtHeatPumpCache(
	cache: ApolloCache<InMemoryCache>,
	{ data, errors }: { data?: UpdatePvtHeatPumpSettingsMutation | null; errors?: readonly GraphQLError[] | undefined },
	{ variables }: { variables?: UpdatePvtHeatPumpSettingsMutationVariables },
) {
	if (!data?.updatePvtHeatPump || !variables) return
	const { pvtHeatPumpdata: inputData, interfaceIds } = variables
	const ids = Array.isArray(interfaceIds) && interfaceIds.length > 0 ? interfaceIds : []

	const interfaceIdsWithErrors = errors
		?.filter((error) => typeof error.extensions.interfaceID === "string")
		.map((error) => error.extensions.interfaceID)

	const onlineInterfaceIds = ids.filter((id) => !interfaceIdsWithErrors?.includes(id))

	onlineInterfaceIds.forEach((defaultInterfaceId) => {
		const id = `PvtHeatPumpData:${defaultInterfaceId}`
		const oldPvtHeatPump = cache.readFragment<PvtHeatPumpAdminSettingsPumpsFragment>({
			id,
			fragment: PvtHeatPumpAdminSettingsPumpsFragmentDoc,
			fragmentName: "PvtHeatPumpAdminSettingsPumps",
		})
		if (!oldPvtHeatPump) return

		const newPvtHeatPump = (Object.keys(oldPvtHeatPump) as Array<keyof PvtHeatPumpAdminSettingsPumpsFragment>).reduce(
			(acc, key) => ({ ...acc, [key]: inputData[key as keyof PvtHeatPumpInput] ?? oldPvtHeatPump[key] }),
			{} as PvtHeatPumpAdminSettingsPumpsFragment,
		)

		cache.writeFragment<PvtHeatPumpAdminSettingsPumpsFragment>({
			id,
			fragment: PvtHeatPumpAdminSettingsPumpsFragmentDoc,
			fragmentName: "PvtHeatPumpAdminSettingsPumps",
			data: newPvtHeatPump,
		})
	})
}

type Props = {
	defaultInterfaceId: string
	batchInterfaceIds?: string[]
	isPartialSave?: boolean
	handleSavingMessages?: (updateStatus: StatusMessageProps[]) => void | undefined
	handleSaveLoading?: (isLoading: boolean) => void
}

export default function PumpSettings({
	defaultInterfaceId,
	batchInterfaceIds,
	isPartialSave,
	handleSavingMessages,
	handleSaveLoading,
}: Props) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage", "general", "heatPumpSettingsPage", "heatPumpErrorCard"])
	const message = useMessage()
	const { register, submit, reset, fields, set } = useForm<PvtHeatPumpInput>({}, handleSave)

	const isBatchUpdate = Array.isArray(batchInterfaceIds) && batchInterfaceIds.length > 0

	const { data, loading, error } = useReadHeatPumpPumpsSettingsQuery({
		variables: { interfaceId: defaultInterfaceId },
		onCompleted: (data) => {
			if (data.interface?.pvtHeatPump) {
				const { id: _id, __typename, ...rest } = data.interface.pvtHeatPump
				reset(rest)
			}
		},
	})

	const [updateSettings, { loading: loadingSave }] = useUpdatePvtHeatPumpSettingsMutation({
		update: updatePvtHeatPumpCache,
	})
	const changedFields = onlyChangedValues(data?.interface?.pvtHeatPump ?? {}, fields)

	async function handleSave() {
		if ((isPartialSave || isPartialSave === undefined) && Object.entries(changedFields).length === 0) {
			message.info(t("heatPumpSettingsPage:Errors.HPHasTheseSettings"))
			return
		}

		if (isBatchUpdate) {
			message.info(t("general:SavingSettingsPleaseWait"))
		}

		try {
			const response = await updateSettings({
				variables: {
					interfaceIds: isBatchUpdate ? batchInterfaceIds : [defaultInterfaceId],
					pvtHeatPumpdata: isBatchUpdate ? (isPartialSave ? changedFields : fields) : changedFields,
					controllerSettings: {},
					isExpert: true,
					isAdmin: false,
				},
				errorPolicy: isBatchUpdate ? "all" : "none", // If 1 or more interface is offline, the mutation will continue, This will ignore the error catch for GraphQL
			})

			if (handleSavingMessages) {
				handleSavingMessages([{ success: true }])
			}

			// Since we use errorPolicy: "all", the error won't return at catch error
			if (response?.errors) {
				handleSettingsError({ errorResponse: response.errors, handleSavingMessages, t })
			}
		} catch (e) {
			message.error(appendErrorMessage(t("heatPumpSettingsPage:Errors.UnkownSaving"), e))
		}

		message.success(t("heatPumpSettingsPage:SettingsSaved"))

		if (handleSaveLoading) {
			handleSaveLoading(loadingSave)
		}
	}

	const resetField = (field: string) => {
		if (!field || !data?.interface?.pvtHeatPump) return

		const pvtHeatPumpData: PvtHeatPumpData = data?.interface.pvtHeatPump
		const value = pvtHeatPumpData[field]

		set({
			[field]: value,
		})
	}

	if (loading) {
		return <LinearProgress />
	}

	if (error || data?.interface == null) {
		return <ErrorCard error={error} interfaceId={defaultInterfaceId} />
	}

	const FLUSINGMODEOPTIONS = {
		OFF: t("general:Off"),
		FLUSHING: t("heatPumpSettingsPage:Flushing"),
		BALANCING: t("heatPumpSettingsPage:Balancing"),
	}

	return (
		<>
			{batchInterfaceIds && batchInterfaceIds.length > 0 && <InterfaceSaveTypeAlert isPartialSave={isPartialSave} />}

			<RadioInput
				label={t("FlushMode")}
				row
				options={FLUSINGMODEOPTIONS}
				disabled={loading}
				moreInfo={t("InFlushMode")}
				{...register("flushingMode", { required: true })}
				fieldIsChanged={"flushingMode" in changedFields}
				reset={() => resetField("flushingMode")}
			/>

			<NumberInput
				label={t("SourcePumpMaximumSpeed")}
				{...register("sourcePumpMaxSpeedPerc", { required: true, min: 20, max: 100 })}
				unit="%"
				disabled={loading}
				fieldIsChanged={"sourcePumpMaxSpeedPerc" in changedFields}
				reset={() => resetField("sourcePumpMaxSpeedPerc")}
			/>

			<NumberInput
				label={t("DeliveryPumpMaximumSpeed")}
				{...register("sinkPumpMaxSpeedPerc", { required: true, min: 20, max: 100 })}
				unit="%"
				disabled={loading}
				fieldIsChanged={"sinkPumpMaxSpeedPerc" in changedFields}
				reset={() => resetField("sinkPumpMaxSpeedPerc")}
			/>

			<NumberInput
				label={t("TDomesticHotWaterActiveSource")}
				{...register("dhwActiveSourceDeltaT", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"dhwActiveSourceDeltaT" in changedFields}
				reset={() => resetField("dhwActiveSourceDeltaT")}
			/>

			<NumberInput
				label={t("TDomesticHotWaterCombinationDelivery")}
				{...register("dhwCombinedSinkDeltaT", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"dhwCombinedSinkDeltaT" in changedFields}
				reset={() => resetField("dhwCombinedSinkDeltaT")}
			/>

			<NumberInput
				label={t("TDomesticHotWaterActiveDelivery")}
				{...register("dhwActiveSinkDeltaT", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"dhwActiveSinkDeltaT" in changedFields}
				reset={() => resetField("dhwActiveSinkDeltaT")}
			/>

			<NumberInput
				label={t("TSHActiveSource")}
				{...register("shActiveSourceDeltaT", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"shActiveSourceDeltaT" in changedFields}
				reset={() => resetField("shActiveSourceDeltaT")}
			/>

			<NumberInput
				label={t("TSHUnderfloorHeatingActiveDelivery")}
				{...register("shFloorActiveSinkDeltaT", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"shFloorActiveSinkDeltaT" in changedFields}
				reset={() => resetField("shFloorActiveSinkDeltaT")}
			/>

			<NumberInput
				label={t("TSHUnderfloorHeatingCombinationDelivery")}
				{...register("shFloorCombinedSinkDeltaT", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"shFloorCombinedSinkDeltaT" in changedFields}
				reset={() => resetField("shFloorCombinedSinkDeltaT")}
			/>

			<NumberInput
				label={t("TSCActiveSource")}
				{...register("scActiveSourceDeltaT", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"scActiveSourceDeltaT" in changedFields}
				reset={() => resetField("scActiveSourceDeltaT")}
			/>

			<NumberInput
				label={t("TSCActiveDelivery")}
				{...register("scActiveSinkDeltaT", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"scActiveSinkDeltaT" in changedFields}
				reset={() => resetField("scActiveSinkDeltaT")}
			/>

			<NumberInput
				label={t("TSCCombinationDelivery")}
				{...register("scCombinedSinkDeltaT", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"scCombinedSinkDeltaT" in changedFields}
				reset={() => resetField("scCombinedSinkDeltaT")}
			/>

			<NumberInput
				label={t("TSCPassiveDelivery")}
				{...register("scPassiveSinkDeltaT", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"scPassiveSinkDeltaT" in changedFields}
				reset={() => resetField("scPassiveSinkDeltaT")}
			/>

			<NumberInput
				label={t("SourcePumpMinimumSpeed")}
				{...register("sourcePumpMinSpeedPerc", { required: true, min: 0, max: 50 })}
				unit="%"
				disabled={loading}
				fieldIsChanged={"sourcePumpMinSpeedPerc" in changedFields}
				reset={() => resetField("sourcePumpMinSpeedPerc")}
			/>

			<NumberInput
				label={t("DeliveryPumpMinimumSpeed")}
				{...register("sinkPumpMinSpeedPerc", { required: true, min: 0, max: 80 })}
				unit="%"
				disabled={loading}
				fieldIsChanged={"sinkPumpMinSpeedPerc" in changedFields}
				reset={() => resetField("sinkPumpMinSpeedPerc")}
			/>

			<FloatingFab submit={submit} loading={loading} fitBottom={isBatchUpdate} batchLoading={loadingSave}>
				<Save />
				<Typography variant="button">{t("SavePumpSettings")}</Typography>
			</FloatingFab>
		</>
	)
}
