import { Alert, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const InterfaceSaveTypeAlert = ({ isPartialSave }: { isPartialSave: undefined | boolean }) => {
	const { t } = useTranslation([])

	return (
		<Alert severity="warning" sx={{ mb: 2 }}>
			<Typography>{isPartialSave ? t("general:SaveOnlyEditedFields") : t("general:AllFieldsUpdated")}</Typography>
		</Alert>
	)
}

export default InterfaceSaveTypeAlert
