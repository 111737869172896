import { InputAdornment, Stack, TextField } from "@mui/material"
import InputReset from "components/InputReset"

type Props = {
	reset?: () => void
	fieldIsChanged?: boolean
	value?: number | null
	onChange: (value: number | null) => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
	autoFocus?: boolean
	defaultValue?: number | null
	unit?: string | null
	disabled?: boolean
}

export default function NumberInput({
	reset,
	fieldIsChanged,
	value,
	onChange,
	label,
	required,
	error,
	helperText,
	autoFocus,
	defaultValue,
	unit,
	disabled,
}: Props) {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value ? parseFloat(e.target.value) : null)
	}

	const handleFocus = () => {
		// on focus, set value to default
		if (defaultValue != null && value == null) onChange(defaultValue)
	}

	return (
		<Stack direction="row" width="100%" alignItems="center">
			<TextField
				fullWidth
				focused={fieldIsChanged}
				color={fieldIsChanged ? "secondary" : undefined}
				required={required}
				margin="normal"
				label={label}
				autoFocus={autoFocus}
				type="number"
				value={value ?? ""}
				onChange={handleChange}
				onFocus={handleFocus}
				error={error}
				helperText={helperText}
				InputProps={{
					endAdornment: unit && <InputAdornment position="end">{unit}</InputAdornment>,
				}}
				placeholder={defaultValue?.toLocaleString()}
				InputLabelProps={{
					shrink: defaultValue != null ? true : undefined,
				}}
				disabled={disabled}
			/>
			{reset && <InputReset reset={reset} isFieldChanged={fieldIsChanged} />}
		</Stack>
	)
}
