import HeatPumpInfoCard from "components/HeatPumpInfoCard"
import {
	InterfaceStatusFieldsFragment,
	PvtHeatPumpRoomControlType,
	Maybe,
	PvtHeatPumpStatusFieldsFragment,
} from "generated/graphql"
import showDecimalPoints from "tools/showDecimalPoints"
import NamedListItem from "../NamedListItem"
import { useTranslation } from "react-i18next"

type InterfaceFields = Pick<InterfaceStatusFieldsFragment, "id" | "openThermThermostatConnected" | "openTherm">
type PvtHeatPumpFields = Pick<PvtHeatPumpStatusFieldsFragment, "auxInputTemp" | "shRoomSetpTemp" | "roomControlType">

type Data = InterfaceFields & {
	pvtHeatPump?: PvtHeatPumpFields | null
}

type Props = {
	data?: Data | null
	cardStyle?: boolean
	message?: string
}

export default function HeatPumpRoomTemp({ data, cardStyle = true, message }: Props) {
	const [t] = useTranslation(["heatPumpStatus", "general"])

	if (!data) return null
	let cardName: string = t("general:RoomTemperature")
	let curTemp: Maybe<number> | undefined
	let setpoint: Maybe<number> | undefined
	if (data.pvtHeatPump?.roomControlType === PvtHeatPumpRoomControlType.Opentherm && data.openThermThermostatConnected) {
		curTemp = data.openTherm?.roomTemp
		setpoint = data.openTherm?.roomSetpTemp
		cardName = t("general:Thermostat")
	}
	if (data.pvtHeatPump?.roomControlType === PvtHeatPumpRoomControlType.RoomSensor) {
		curTemp = data.pvtHeatPump?.auxInputTemp
		setpoint = data.pvtHeatPump?.shRoomSetpTemp
	}
	if (curTemp == null && setpoint == null) return null

	return cardStyle ? (
		<HeatPumpInfoCard title={cardName}>
			{curTemp != null && (
				<NamedListItem name={t("CurrentRoomTemperature")} value={String(showDecimalPoints(curTemp, 1)) + "°C"} />
			)}
			{setpoint != null && (
				<NamedListItem name={t("DesiredRoomTemperature")} value={String(showDecimalPoints(setpoint, 1)) + "°C"} />
			)}
		</HeatPumpInfoCard>
	) : (
		<>
			{curTemp != null && (
				<NamedListItem
					name={t("CurrentRoomTemperature")}
					value={String(showDecimalPoints(curTemp, 1)) + "°C"}
					message={message}
				/>
			)}
			{setpoint != null && (
				<NamedListItem name={t("DesiredRoomTemperature")} value={String(showDecimalPoints(setpoint, 1)) + "°C"} />
			)}
		</>
	)
}
