import { red, orange, green, grey } from "@mui/material/colors"

const colors = {
	red: red[500],
	orange: orange[500],
	green: green[500],
	grey: grey[500],
}

type Props = {
	color?: "green" | "red" | "orange" | "grey"
}

export default function StatusDot({ color }: Props) {
	return (
		<svg width="1.2em" height="1em" viewBox="0 0 12 10">
			<circle r="3" cy="6.5" cx="6" fill={colors[color ?? "grey"]}></circle>
		</svg>
	)
}
