import { useMessage } from "providers/MessageProvider"
import {
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Tabs,
	Tab,
	Typography,
} from "@mui/material"
import useForm from "hooks/useForm"
import TSDialog from "components/TSDialog"
import {
	GroupFieldsFragment,
	useUpdateGroupNameMutation,
	UserFieldsFragment,
	useGroupQuery,
	Group,
	useRemoveUserFromGroupMutation,
} from "generated/graphql"
import TextInput from "inputs/TextInput"
import QuickTable, { Label } from "../Table"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import CreateUser from "components/forms/UpsertUser"
import AddButton from "components/AddButton"
import appendErrorMessage from "tools/appendErrorMessage"
import AddMemberDialog from "components/AddMemberDialog"
import { useConfirm } from "providers/ConfirmProvider"
import { ErrCanNotRemoveOwner } from "resources/backendErrors"
import GroupAddInterface from "components/GroupAddInterface"
import ClaimedInterfaces from "components/ClaimedInterfaces"

type Props = {
	onClose: () => void
	group: Pick<GroupFieldsFragment, "id" | "name">
}

function getLabelsMembers(t: TFunction<["general"]>): Label<UserFieldsFragment>[] {
	return [
		{
			key: "name",
			name: t("Name"),
			sortable: true,
		},
		{
			key: "isGroupAdmin",
			name: t("GroupAdmin"),
			sortable: true,
			resolve: (isGroupAdmin) => (isGroupAdmin ? t("Yes") : t("No")),
		},
	]
}

enum TabsTypes {
	Interfaces = "interfaces",
	Members = "members",
}

export default function UpdateOrganization({ onClose, group }: Props) {
	const confirm = useConfirm()
	const { t } = useTranslation(["general", "groupManagement", "serviceRequests"])

	const [removeUser] = useRemoveUserFromGroupMutation()

	const [selectedTab, setSelectedTab] = useState(TabsTypes.Members)
	const [dialogOpenUsers, setDialogOpenUsers] = useState(false)
	const [dialogOpenInterfaces, setDialogOpenUsersInterfaces] = useState(false)
	const [dialogInviteUser, setDialogInviteUser] = useState(false)
	const [user, setUser] = useState<UserFieldsFragment | null>()

	const [updateGroupName, { loading }] = useUpdateGroupNameMutation()

	//Get selected group
	const {
		data,
		loading: currentGroupLoading,
		error,
	} = useGroupQuery({
		variables: {
			groupId: group.id,
		},
	})

	const message = useMessage()
	const { register, submit } = useForm(group, handleSave)

	async function handleSave(group: Group) {
		try {
			const { data } = await updateGroupName({
				variables: {
					groupId: group.id,
					name: group.name,
				},
			})
			if (!data?.updateGroupName) return
			message.success(t("groupManagement:GroupSaved"))
			onClose()
		} catch (e) {
			message.error(appendErrorMessage(t("serviceRequests:Errors.Saving"), e))
		}
	}

	const handleEdit = (id: string) => {
		const foundUser = data?.group.members?.find((u) => u?.id === id)
		const userWithGroupInfo = foundUser ? { ...foundUser, group: data?.group || null } : null

		setUser(userWithGroupInfo)
		setDialogOpenUsers(true)
	}

	const handleRemoveMemberFromGroup = async (id: string) => {
		if (!data?.group.id) {
			return
		}

		await confirm(t("groupManagement:ConfirmRemoveUserFromGroup", { groupName: data?.group.name }))

		try {
			await removeUser({ variables: { groupId: data?.group.id ?? [], userId: id } })
			message.success(t("groupManagement:MemberRemovedFromOrg"))
		} catch (e) {
			if (e instanceof Error && e.message.includes(ErrCanNotRemoveOwner)) {
				message.error(t("groupManagement:Errors.TransferAdminRightsBeforeRemovingUser"))
				return
			}
			message.error(appendErrorMessage(t("groupManagement:Errors.UnkownErrorRemovingUser"), e))
		}
	}

	return (
		<TSDialog autoFullScreen open onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{t("Organization")}</DialogTitle>

			{currentGroupLoading && <Typography>{t("groupManagement:FetchingGroup")}</Typography>}
			{error && <Typography>{t("groupManagement:Errors.FetchingGroup")}</Typography>}

			<DialogContent>
				<DialogContentText>{t("groupManagement:EnterGroupDetails")}</DialogContentText>
				{!currentGroupLoading && (
					<TextInput autoFocus label={t("Name")} {...register("name", { required: true, minLength: 2 })} />
				)}

				<Tabs
					value={selectedTab}
					onChange={(_, newValue) => {
						setSelectedTab(newValue)
					}}
					centered
					sx={{ mb: 2 }}
				>
					<Tab label={t("Members")} value={TabsTypes.Members} />
					<Tab label={t("Interfaces")} value={TabsTypes.Interfaces} />
				</Tabs>

				{selectedTab === TabsTypes.Members && (
					<>
						<AddButton onClick={() => setDialogInviteUser(true)}>{t("Add")}</AddButton>
						{data && data?.group.members?.length > 0 ? (
							<QuickTable
								onClickAction={handleRemoveMemberFromGroup}
								defaultItemsPerPage={20}
								rows={data.group.members}
								labels={getLabelsMembers(t)}
								onClickRow={handleEdit}
							/>
						) : (
							<Typography>{t("None")}</Typography>
						)}
					</>
				)}

				{selectedTab === TabsTypes.Interfaces && data !== undefined && <ClaimedInterfaces group={data.group} />}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary" disabled={loading || currentGroupLoading}>
					{t("Cancel")}
				</Button>
				{selectedTab === TabsTypes.Members && (
					<Button onClick={submit} color="primary" disabled={loading || currentGroupLoading}>
						{t("Save")}
					</Button>
				)}
			</DialogActions>

			{dialogInviteUser && data?.group && (
				<AddMemberDialog group={data.group} onClose={() => setDialogInviteUser(false)} />
			)}

			{dialogOpenUsers && (
				<CreateUser
					user={user}
					onClose={() => {
						setUser(null)
						setDialogOpenUsers(false)
					}}
				/>
			)}

			{dialogOpenInterfaces && data?.group && (
				<GroupAddInterface group={data.group} onClose={() => setDialogOpenUsersInterfaces(false)} />
			)}
		</TSDialog>
	)
}
