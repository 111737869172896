import { isArray } from "@apollo/client/cache/inmemory/helpers"
import { ListItem, ListItemText, Stack, Typography } from "@mui/material"
import MoreInfo from "components/MoreInfo"

type Props = {
	name: string
	value: string | string[] | JSX.Element | number
	isInset?: boolean
	longData?: boolean
	message?: string | null | JSX.Element
}

export default function NamedListItem({ name, value, isInset, longData, message }: Props) {
	return (
		<ListItem>
			<ListItemText inset={isInset} sx={!longData ? { width: "60%" } : {}}>
				<Typography sx={{ fontWeight: "Bold" }}>{name}</Typography>
			</ListItemText>
			<ListItemText sx={!longData ? { width: "40%", pl: 1 } : { textAlign: "end", pl: 1 }}>
				{isArray(value) ? (
					<Stack direction="column">
						{value.map((item, index) => (
							<Typography sx={{ fontStyle: "italic" }} key={`${name}-${index}`}>
								{item}
							</Typography>
						))}
					</Stack>
				) : (
					<>
						<Typography component="span" sx={{ fontStyle: "italic" }}>
							{value}
						</Typography>

						{message && (
							<MoreInfo>
								<Typography>{message}</Typography>
							</MoreInfo>
						)}
					</>
				)}
			</ListItemText>
		</ListItem>
	)
}
