import { CircularProgress, Stack } from "@mui/material"
import NamedListItem from "pages/HeatPumpStatusPage/components/NamedListItem"
import HeatPumpRoomTemp from "pages/HeatPumpStatusPage/components/StatusDescriptions/HeatPumpRoomTemp"
import { BackupHeaterType } from "generated/graphql"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"
import { StepperProps } from "../index"

export default function InstallationStatus({ error, fields, data, loading }: StepperProps) {
	const { t } = useTranslation(["heatPumpStatus", "heatPumpMonitoring", "heatPumpSettingsPage", "general"])
	const { interfaceId } = useParams<"interfaceId">()

	if (!fields || !interfaceId) return

	if (error && interfaceId) {
		return <ErrorCard error={error} interfaceId={interfaceId} />
	}

	return loading ? (
		<CircularProgress size="1rem" color="inherit" sx={{ mr: 1 }} />
	) : (
		<>
			<Stack direction="column" mt={2}>
				<NamedListItem
					name={t("OTThermostatConnected")}
					value={data?.interface?.openThermThermostatConnected ? t("general:Yes") : t("general:No")}
					message={!data?.interface?.openThermThermostatConnected ? t("ThermostatInstruction") : null}
				/>

				{fields.extendedBackupHeater === BackupHeaterType.Opentherm && !data?.interface?.openThermBoilerConnected && (
					<NamedListItem
						name={t("OTBoilerConnected")}
						value={data?.interface?.openThermBoilerConnected ? t("general:Yes") : t("general:No")}
						message={
							(!data?.interface?.openThermBoilerConnected && t("heatPumpSettingsPage:WizardNoOpenTermConnected")) ||
							null
						}
					/>
				)}
			</Stack>

			<HeatPumpRoomTemp
				data={data?.interface}
				cardStyle={false}
				message={t("heatPumpSettingsPage:RoomTemperatureCheck")}
			/>
		</>
	)
}
