import { ApolloCache, InMemoryCache } from "@apollo/client"
import { Save } from "@mui/icons-material"
import { Box, CardContent, LinearProgress, Stack, Typography } from "@mui/material"
import MoreInfo from "components/MoreInfo"
import {
	PvtHeatPumpDmSetpointsFragment,
	PvtHeatPumpDmSetpointsFragmentDoc,
	PvtHeatPumpInput,
	PvtHeatPumpRoomControlType,
	UpdatePvtHeatPumpSettingsMutation,
	UpdatePvtHeatPumpSettingsMutationVariables,
	useReadHeatPumpDmFieldsQuery,
	useUpdatePvtHeatPumpSettingsMutation,
} from "generated/graphql"
import useForm from "hooks/useForm"
import NumberInput from "inputs/NumberInput"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"
import NamedListItem from "pages/HeatPumpStatusPage/components/NamedListItem"
import { useMessage } from "providers/MessageProvider"
import { useTranslation } from "react-i18next"
import onlyChangedValues from "tools/onlyChangedValues"
import { isPvtHeatPumpFirmwareNewerOrEqual, PVT_HEAT_PUMP_V1_1 } from "settings/firmwareVersions"
import CustomCard from "components/CustomCard"
import { PvtHeatPumpData } from "../index"
import appendErrorMessage from "tools/appendErrorMessage"
import Image from "components/Image"
import FloatingFab from "components/FloatingFab"
import InterfaceSaveTypeAlert from "components/InterfaceSaveTypeAlert"
import { StatusMessageProps } from "components/BatchUpdate"
import handleSettingsError from "tools/handleSettingsError"
import { GraphQLError } from "graphql/error"
import HeadingLabel from "components/HeadingLabel"
import DraggableHeatingCurve from "components/DraggableHeatingCurve"
import { useMemo } from "react"
import { getStaticLables } from "tools/getStaticLabels"
import { handleSupplyTempCooling, handleSupplyTempHeating } from "tools/handleHeatingCruve"
const CELCIUS = "°C"

function updatePvtHeatPumpCache(
	cache: ApolloCache<InMemoryCache>,
	{ data, errors }: { data?: UpdatePvtHeatPumpSettingsMutation | null; errors?: readonly GraphQLError[] | undefined },
	{ variables }: { variables?: UpdatePvtHeatPumpSettingsMutationVariables },
) {
	if (!data?.updatePvtHeatPump || !variables) return

	const { pvtHeatPumpdata: inputData, interfaceIds } = variables
	const ids = Array.isArray(interfaceIds) && interfaceIds.length > 0 ? interfaceIds : []

	const interfaceIdsWithErrors = errors
		?.filter((error) => typeof error.extensions.interfaceID === "string")
		.map((error) => error.extensions.interfaceID)

	const onlineInterfaceIds = ids.filter((id) => !interfaceIdsWithErrors?.includes(id))

	onlineInterfaceIds.forEach((defaultInterfaceId) => {
		const id = `PvtHeatPumpData:${defaultInterfaceId}`
		const oldPvtHeatPump = cache.readFragment<PvtHeatPumpDmSetpointsFragment>({
			id,
			fragment: PvtHeatPumpDmSetpointsFragmentDoc,
			fragmentName: "PvtHeatPumpDMSetpoints",
		})
		if (!oldPvtHeatPump) return

		const newPvtHeatPump = (Object.keys(oldPvtHeatPump) as Array<keyof PvtHeatPumpDmSetpointsFragment>).reduce(
			(acc, key) => ({ ...acc, [key]: inputData[key as keyof PvtHeatPumpInput] ?? oldPvtHeatPump[key] }),
			{} as PvtHeatPumpDmSetpointsFragment,
		)

		cache.writeFragment<PvtHeatPumpDmSetpointsFragment>({
			id,
			fragment: PvtHeatPumpDmSetpointsFragmentDoc,
			fragmentName: "PvtHeatPumpDMSetpoints",
			data: newPvtHeatPump,
		})
	})
}

type Props = {
	defaultInterfaceId: string
	batchInterfaceIds?: string[]
	isPartialSave?: boolean
	handleSavingMessages?: (updateStatus: StatusMessageProps[]) => void | undefined
	handleSaveLoading?: (isLoading: boolean) => void
}

export default function DmSettings({
	defaultInterfaceId,
	batchInterfaceIds,
	isPartialSave,
	handleSavingMessages,
	handleSaveLoading,
}: Props) {
	const { t, i18n } = useTranslation(["pvtHeatPumpAdminPage", "general", "heatPumpSettingsPage", "heatPumpErrorCard"])
	const message = useMessage()

	const { register, submit, reset, fields, set } = useForm<
		PvtHeatPumpInput & { dmSc: number | null; dmSh: number | null } // DM values are a status readout only and should not be pushed to the mutation
	>({}, handleSave, [
		"supplyTempMin30CustomHeating",
		"supplyTempMin20CustomHeating",
		"supplyTempMin10CustomHeating",
		"supplyTemp0CustomHeating",
		"supplyTemp10CustomHeating",
		"supplyTemp20CustomHeating",
		"supplyTemp30CustomHeating",
		"supplyTemp20CustomCooling",
		"supplyTemp30CustomCooling",
		"supplyTemp40CustomCooling",
	])

	const isBatchUpdate = Array.isArray(batchInterfaceIds) && batchInterfaceIds.length > 0
	const { includesHeating, includesCooling } = useMemo(() => getStaticLables(t), [i18n.language])

	const { data, loading, error } = useReadHeatPumpDmFieldsQuery({
		variables: { interfaceId: defaultInterfaceId },
		onCompleted: (data) => {
			if (data.interface?.pvtHeatPump) {
				const { firmwareVersion: _firmwareVersion, __typename, ...rest } = data.interface.pvtHeatPump
				reset(rest)
			}
		},
	})

	const currentHPVersion = data?.interface?.pvtHeatPump?.firmwareVersion ?? Infinity // if unknown, pick high firmware version number
	const hasHeating = includesHeating(fields.roomTemperatureControl)
	const hasCooling = includesCooling(fields.roomTemperatureControl)
	const hpHasDM = isPvtHeatPumpFirmwareNewerOrEqual(currentHPVersion, PVT_HEAT_PUMP_V1_1)

	const [updateSettings, { loading: loadingSave }] = useUpdatePvtHeatPumpSettingsMutation({
		update: updatePvtHeatPumpCache,
	})
	const changedFields = onlyChangedValues(data?.interface?.pvtHeatPump ?? {}, fields)

	async function handleSave() {
		if ((isPartialSave || isPartialSave === undefined) && Object.entries(changedFields).length === 0) {
			message.info(t("HeatingCurveSettingsUnchanged"))
			return
		}

		if (isBatchUpdate) {
			message.info(t("general:SavingSettingsPleaseWait"))
		}

		const { dmSc: _dmSc, dmSh: _dmSh, ...filteredFields } = fields

		try {
			const response = await updateSettings({
				variables: {
					interfaceIds: isBatchUpdate ? batchInterfaceIds : [defaultInterfaceId],
					pvtHeatPumpdata: isBatchUpdate ? (isPartialSave ? changedFields : filteredFields) : changedFields,
					controllerSettings: {},
					isExpert: true,
					isAdmin: false,
				},
				errorPolicy: isBatchUpdate ? "all" : "none", // If 1 or more interface is offline, the mutation will continue, This will ignore the error catch for GraphQL
			})

			if (handleSavingMessages) {
				handleSavingMessages([{ success: true }])
			}

			// Since we use errorPolicy: "all", the error won't return at catch error
			if (response?.errors) {
				handleSettingsError({ errorResponse: response.errors, handleSavingMessages, t })
			}
		} catch (e) {
			message.error(appendErrorMessage(t("heatPumpSettingsPage:Errors.UnkownSaving"), e))
		}

		message.success(t("heatPumpSettingsPage:SettingsSaved"))

		if (handleSaveLoading) {
			handleSaveLoading(loadingSave)
		}
	}

	const resetField = (field: string) => {
		if (!field || !data?.interface?.pvtHeatPump) return

		const pvtHeatPumpData: PvtHeatPumpData = data?.interface.pvtHeatPump
		const value = pvtHeatPumpData[field]

		set({
			[field]: value,
		})
	}

	if (loading) {
		return <LinearProgress />
	}

	if (!isPvtHeatPumpFirmwareNewerOrEqual(data?.interface?.pvtHeatPump?.firmwareVersion ?? 0, PVT_HEAT_PUMP_V1_1)) {
		return (
			<CustomCard>
				<CardContent>
					<Typography variant="h5" color="error">
						{t("FirmwareTooOldForHeatCurveSettings", {
							firmwareVersion: data?.interface?.pvtHeatPump?.firmwareVersion
								? ` V${data.interface.pvtHeatPump.firmwareVersion.toFixed(1)}`
								: "",
						})}
					</Typography>
				</CardContent>
			</CustomCard>
		)
	}

	if (error || data?.interface == null) {
		return <ErrorCard error={error} interfaceId={defaultInterfaceId} />
	}

	const dataSinkSupplyHeatingFields = [
		fields.supplyTempMin30CustomHeating,
		fields.supplyTempMin20CustomHeating,
		fields.supplyTempMin10CustomHeating,
		fields.supplyTemp0CustomHeating,
		fields.supplyTemp10CustomHeating,
		fields.supplyTemp20CustomHeating,
		fields.supplyTemp30CustomHeating,
	]

	const dataSinkSupplyCoolingFields = [
		fields.supplyTemp20CustomCooling,
		fields.supplyTemp30CustomCooling,
		fields.supplyTemp40CustomCooling,
	]

	return (
		<>
			{batchInterfaceIds && batchInterfaceIds.length > 0 && <InterfaceSaveTypeAlert isPartialSave={isPartialSave} />}

			<NamedListItem
				name={t("DegreeMinutesHeating")}
				value={
					data.interface.pvtHeatPump?.dmSh != null
						? `${String(data.interface.pvtHeatPump.dmSh)} ${t("general:DM")}`
						: t("general:unknown")
				}
			/>
			<NamedListItem
				name={t("DegreeMinutesCooling")}
				value={
					data.interface.pvtHeatPump?.dmSc != null
						? `${String(data.interface.pvtHeatPump.dmSc)} ${t("general:DM")}`
						: t("general:unknown")
				}
			/>

			{fields.roomControlType === PvtHeatPumpRoomControlType.DmControl && (hasHeating || hasCooling) && hpHasDM && (
				<>
					<HeadingLabel gutterTop>{t("heatPumpSettingsPage:HeatingCurve")}</HeadingLabel>
					{hasHeating && (
						<Box mb={4}>
							<DraggableHeatingCurve
								labels={["-30°C", "-20°C", "-10°C", "0°C", "10°C", "20°C", "30°C"]}
								minYValue={5}
								maxYValue={80}
								dataSinkSupplyTempFields={dataSinkSupplyHeatingFields}
								handleSupplyTemp={(curveData) => {
									const { propertyName, value } = handleSupplyTempHeating(curveData)
									set({ [propertyName]: value })
								}}
							/>
						</Box>
					)}

					{hasCooling && (
						<Box>
							<DraggableHeatingCurve
								labels={["20°C", "30°C", "40°C"]}
								minYValue={5}
								maxYValue={25}
								dataSinkSupplyTempFields={dataSinkSupplyCoolingFields}
								handleSupplyTemp={(curveData) => {
									const { propertyName, value } = handleSupplyTempCooling(curveData)
									set({ [propertyName]: value })
								}}
							/>
						</Box>
					)}
				</>
			)}

			<NumberInput
				label={t("DegreeMinutesThresholdForHeating")}
				{...register("dmHeatingThreshold", { required: true, min: -10000, max: -10 })}
				unit={t("general:DM")}
				disabled={loading}
				fieldIsChanged={"dmHeatingThreshold" in changedFields}
				reset={() => resetField("dmHeatingThreshold")}
			/>
			<NumberInput
				label={t("DegreeMinutesThresholdForBoostHeating")}
				{...register("dmBoostHeating", { required: true, min: 10, max: 750 })}
				unit={t("general:DM")}
				disabled={loading}
				fieldIsChanged={"dmBoostHeating" in changedFields}
				reset={() => resetField("dmBoostHeating")}
			/>
			<NumberInput
				label={t("OutdoorTemperatureForBoostHeating")}
				{...register("dmTOutdoorBoostHeating", { required: true, min: -10, max: 40 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"dmTOutdoorBoostHeating" in changedFields}
				reset={() => resetField("dmTOutdoorBoostHeating")}
			/>
			<Stack width="100%" direction="row" alignItems="center">
				<NumberInput
					label={t("HeatingCurveSlope")}
					{...register("heatingCurve", { required: true, min: 0, max: 15 })}
					disabled={loading}
					fieldIsChanged={"heatingCurve" in changedFields}
					reset={() => resetField("heatingCurve")}
				/>
				<MoreInfo>
					<>
						<Typography gutterBottom>{t("HeatingCurveHeating")}</Typography>
						<Image src="/images/dm_sh_curves.png" />
					</>
				</MoreInfo>
			</Stack>

			<NumberInput
				label={t("DegreeMinutesThresholdForCooling")}
				{...register("dmCoolingThreshold", { required: true, min: 10, max: 7500 })}
				unit={t("general:DM")}
				disabled={loading}
				fieldIsChanged={"dmCoolingThreshold" in changedFields}
				reset={() => resetField("dmCoolingThreshold")}
			/>

			<Stack width="100%" direction="row" alignItems="center">
				<NumberInput
					label={t("CoolingCurveSlope")}
					{...register("coolingCurve", { required: true, min: 1, max: 3 })}
					disabled={loading}
					fieldIsChanged={"coolingCurve" in changedFields}
					reset={() => resetField("coolingCurve")}
				/>
				<MoreInfo>
					<>
						<Typography gutterBottom>{t("HeatingCurveCooling")}</Typography>
						<Image src="/images/dm_sc_curves.png" />
					</>
				</MoreInfo>
			</Stack>

			<NumberInput
				label={t("VerticalShiftOfHeatingCurve")}
				{...register("heatingCurveOffset", { required: true, min: -10, max: 10 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"heatingCurveOffset" in changedFields}
				reset={() => resetField("heatingCurveOffset")}
			/>
			<NumberInput
				label={t("VerticalShiftOfCoolingCurve")}
				{...register("coolingCurveOffset", { required: true, min: -10, max: 10 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"coolingCurveOffset" in changedFields}
				reset={() => resetField("coolingCurveOffset")}
			/>
			<NumberInput
				label={t("OutdoorTemperatureThresholdForHeating")}
				{...register("dmTOutdoorShEnable", { required: true, min: 5, max: 20 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"dmTOutdoorShEnable" in changedFields}
				reset={() => resetField("dmTOutdoorShEnable")}
			/>
			<NumberInput
				label={t("OutdoorTemperatureThresholdForCooling")}
				{...register("dmTOutdoorScEnable", { required: true, min: 10, max: 40 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"dmTOutdoorScEnable" in changedFields}
				reset={() => resetField("dmTOutdoorScEnable")}
			/>

			<NumberInput
				label={t("OutdoorTemperatureFilterTime")}
				{...register("outdoorTempFilterTime", { required: true, min: 0, max: 24 })}
				unit={t("general:Hour").toLowerCase()}
				disabled={loading}
				fieldIsChanged={"outdoorTempFilterTime" in changedFields}
				reset={() => resetField("outdoorTempFilterTime")}
			/>

			<FloatingFab submit={submit} loading={loading} fitBottom={isBatchUpdate} batchLoading={loadingSave}>
				<Save />
				<Typography variant="button"> {t("SaveHeatingCurveSettings")}</Typography>
			</FloatingFab>
		</>
	)
}
