import { createContext, useContext, useEffect, useRef, useState } from "react"
import Auth from "tools/auth"

const UserContext = createContext<Auth | null>(null)

type Props = {
	children: React.ReactNode
	auth: Auth
}

export default function AuthProvider({ children, auth }: Props) {
	return <UserContext.Provider value={auth}>{children}</UserContext.Provider>
}

export function useAuth() {
	const auth = useContext(UserContext)
	if (!auth) throw new Error("AuthProvider not called..")

	const [rerender, setRerender] = useState(false) // used to explicitly rerender children (and notify them of the auth chamge)
	const resolveRef = useRef<(() => void) | null>(null)

	useEffect(() => {
		const cancel = auth.onAuthStatusChanged(() => {
			return new Promise<void>((resolve) => {
				setRerender((rerender) => !rerender)
				resolveRef.current = resolve
			})
		})
		return cancel // clean up callback when no longer mounted
	}, [auth])

	useEffect(() => {
		resolveRef.current?.()
		resolveRef.current = null
	}, [rerender])

	return auth
}
