export default function TripleSolarLoading() {
	return (
		<svg height="75" width="150" viewBox="0 0 236.27 141.73" xmlns="http://www.w3.org/2000/svg">
			<polygon points="42.93 0 85.25 0 42.33 141.73 0 141.73 42.93 0 42.93 0" fill="#d94f00">
				<animate attributeName="opacity" values="1;0;1" dur="2s" keyTimes="0;0.5;1" repeatCount="indefinite" />
			</polygon>

			<polygon points="104.41 0 146.74 0 103.81 141.73 61.48 141.73 104.41 0 104.41 0" fill="#d94f00">
				<animate
					attributeName="opacity"
					values="1;0;1"
					dur="2s"
					keyTimes="0;0.5;1"
					begin="0.2s"
					repeatCount="indefinite"
				/>
			</polygon>

			<polygon points="165.89 0 208.22 0 165.3 141.73 122.97 141.73 165.89 0 165.89 0" fill="#d94f00">
				<animate
					attributeName="opacity"
					values="1;0;1"
					dur="2s"
					keyTimes="0;0.5;1"
					begin="0.4s"
					repeatCount="indefinite"
				/>
			</polygon>

			<polygon points="194.25 102.82 236.27 102.82 214.16 32.9 194.25 102.82 194.25 102.82" fill="#d94f00">
				<animate
					attributeName="opacity"
					values="1;0;1"
					dur="2s"
					keyTimes="0;0.5;1"
					begin="0.6s"
					repeatCount="indefinite"
				/>
			</polygon>
		</svg>
	)
}
