import { Role } from "generated/graphql"
import useHasRole from "hooks/useHasRole"
import NotFoundPage from "pages/NotFoundPage"
import { useAuth } from "providers/AuthProvider"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { SIGN_IN } from "settings/url"

type Props = {
	roles?: Role[]
}

export default function ProtectedRoute({ roles }: Props) {
	const auth = useAuth()
	const { pathname } = useLocation()
	const allowed = useHasRole(roles ?? [])

	if (!auth.user) {
		const path = pathname === "/" ? "" : `?redirect=${encodeURIComponent(pathname)}`
		return <Navigate replace to={`/${SIGN_IN}${path}`} />
	}

	if (roles && !allowed) return <NotFoundPage />
	return <Outlet />
}
