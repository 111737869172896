import {
	Alert,
	Button,
	DialogActions,
	DialogContent,
	LinearProgress,
	Stack,
	Tab,
	Tabs,
	Typography,
} from "@mui/material"
import TSDialog from "./TSDialog"
import { SelectedInterfacesProps } from "./Table"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import BatchUpdateHeatPump from "./BatchUpdateHeatPump"
import BatchUpdateInterface from "./BatchUpdateInterface"
import BatchUpdateSettings from "./BatchUpdateSettings"
import { BatchUpdateInterfaceFieldsFragment, Role, useBatchUpdateInterfacesQuery } from "generated/graphql"
import i18next from "i18next"
import useHasRole from "hooks/useHasRole"

interface BatchUpdateProps {
	onClose: () => void
	selectedInterfaces: SelectedInterfacesProps[]
	dialogOpenedAt: Date | null
}

export enum TabsTypes {
	Settings = "settings",
	Interface = "interface",
	PvtHeatPump = "pvtHeatPump",
}

export interface StatusMessageProps {
	interfaceId?: string
	errorMessage?: string
	success?: boolean
}

export interface BatchUpdateInterfaceProps {
	handleRefetch?: () => void
	interfaces: BatchUpdateInterfaceFieldsFragment[]
	loading: boolean
	dialogOpenedAt?: Date | null
	selectedTab?: TabsTypes | null
}

interface AlertSelectedHPProps {
	onlineInterfaces?: number
	offlineInterfaces?: number
	totalSelected: number | null
}

function OfflineInterfaceAlert({ onlineInterfaces, offlineInterfaces, totalSelected }: AlertSelectedHPProps) {
	if (!totalSelected) return

	switch (true) {
		case onlineInterfaces === 0:
			return (
				<Alert elevation={6} variant="filled" severity="error" sx={{ mb: 2 }}>
					<Typography>{i18next.t("SelectedHeatPumpsNotAvailable", { count: totalSelected })}</Typography>
				</Alert>
			)

		case offlineInterfaces && offlineInterfaces > 0:
			return (
				<Alert variant="filled" severity="warning" sx={{ mb: 2 }}>
					<Typography>
						{i18next.t("SelectedInterfaceOffline", { count: offlineInterfaces ?? 0, total: totalSelected ?? 0 })}
					</Typography>
				</Alert>
			)
		default:
			return null
	}
}

export default function BatchUpdate({ onClose, selectedInterfaces, dialogOpenedAt }: BatchUpdateProps) {
	const { t } = useTranslation("general")
	const [selectedTab, setSelectedTab] = useState(TabsTypes.Settings)
	const offlineInterfaces = selectedInterfaces.filter((iface) => !iface.isOnline).length
	const onlineInterfaces = selectedInterfaces.filter((iface) => iface.isOnline).length
	const onlineInterfaceIds = selectedInterfaces
		.filter((iface) => iface.isOnline)
		.map((itemIface) => itemIface.interfaceId)

	const isAdmin = useHasRole([Role.Admin])
	if (!isAdmin) return

	const {
		data: interfacesData,
		refetch,
		error,
		loading,
	} = useBatchUpdateInterfacesQuery({
		variables: { interfaceIds: onlineInterfaceIds },
		errorPolicy: "all", // return data even if some interfaces has errors
	})

	return (
		<TSDialog fullScreenDown="md" autoFullScreen onClose={onClose} open fullWidth>
			<DialogContent>
				<Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)} centered sx={{ mb: 2 }}>
					<Tab label={t("Settings")} value={TabsTypes.Settings} />
					<Tab label={t("Interface")} value={TabsTypes.Interface} />
					<Tab label={t("HeatPump")} value={TabsTypes.PvtHeatPump} />
				</Tabs>

				<Stack direction="column">
					<OfflineInterfaceAlert
						onlineInterfaces={onlineInterfaces}
						offlineInterfaces={offlineInterfaces}
						totalSelected={selectedInterfaces.length ?? null}
					/>

					{loading ? (
						<LinearProgress variant="indeterminate"></LinearProgress>
					) : (
						<>
							{!interfacesData && error && (
								<OfflineInterfaceAlert
									offlineInterfaces={offlineInterfaces}
									totalSelected={selectedInterfaces.length}
								/>
							)}

							{interfacesData && selectedTab === TabsTypes.Interface && (
								<BatchUpdateInterface
									dialogOpenedAt={dialogOpenedAt}
									interfaces={interfacesData.interfaces}
									loading={loading}
									handleRefetch={refetch}
									selectedTab={selectedTab}
								/>
							)}
							{interfacesData && selectedTab === TabsTypes.PvtHeatPump && (
								<BatchUpdateHeatPump
									dialogOpenedAt={dialogOpenedAt}
									interfaces={interfacesData.interfaces}
									loading={loading}
									handleRefetch={refetch}
									selectedTab={selectedTab}
								/>
							)}
							{interfacesData && selectedTab === TabsTypes.Settings && (
								<BatchUpdateSettings interfaces={interfacesData.interfaces} loading={loading} />
							)}
						</>
					)}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary" variant="outlined">
					{t("Close")}
				</Button>
			</DialogActions>
		</TSDialog>
	)
}
