import { CardMedia, Stack, Typography } from "@mui/material"
import { PvtHeatPumpRoomControlType } from "generated/graphql"
import { useEffect, useMemo } from "react"
import { styled } from "@mui/material/styles"
import { useTranslation, Trans } from "react-i18next"
import { useParams } from "react-router-dom"
import { StepperProps } from "../index"
import BooleanInput from "inputs/BooleanInput"
import MoreInfo from "components/MoreInfo"
import NumberInput from "inputs/NumberInput"
import RadioInput from "inputs/RadioInput"
import {
	INTERFACE_V1_2_0_TIMESTAMP,
	isPvtHeatPumpFirmwareNewerOrEqual,
	PVT_HEAT_PUMP_V1_1,
	PVT_HEAT_PUMP_V490_1,
} from "settings/firmwareVersions"
import i18n from "i18next"
import { getStaticLables } from "tools/getStaticLabels"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"

const CELCIUS = "°C"

const StyledTypography = styled(Typography)(() => ({
	display: "flex",
	alignItems: "center",
}))

export default function HeatingSettings({
	error,
	handleSkip,
	loading,
	data,
	register,
	fields,
	steps,
	BottomNavigation,
	nextStep,
	backStep,
	step,
}: StepperProps) {
	const { t } = useTranslation(["heatPumpSettingsPage", "heatPumpStatus", "general"])
	const { interfaceId } = useParams<"interfaceId">()

	if (!fields || !register || !steps || !interfaceId) return

	const {
		READABLE_MANUAL_COOLING_MODE,
		READABLE_MANUAL_COOLING_MODE_HIDDEN,
		validateCHTempMax,
		includesHeating,
		includesCooling,
		usedHpContacts,
	} = useMemo(() => getStaticLables(t), [i18n.language])

	const interfaceHasChMaxTemp = new Date(data?.interface?.firmwareVersion.timestamp ?? 0) >= INTERFACE_V1_2_0_TIMESTAMP // 0 is epoch, always a long time ago

	const hpHasMinSinkTempSetp = isPvtHeatPumpFirmwareNewerOrEqual(
		data?.interface?.pvtHeatPump?.firmwareVersion ?? Infinity,
		PVT_HEAT_PUMP_V490_1,
	)

	const hpHasBoost = isPvtHeatPumpFirmwareNewerOrEqual(
		data?.interface?.pvtHeatPump?.firmwareVersion ?? Infinity,
		PVT_HEAT_PUMP_V1_1,
	)
	const hasHeating = includesHeating(fields.roomTemperatureControl)
	const hasCooling = includesCooling(fields.roomTemperatureControl)

	const allConditionsFalse =
		!hpHasBoost &&
		!hpHasBoost &&
		!(hasCooling && fields.extendedControlType === PvtHeatPumpRoomControlType.Opentherm) &&
		!(
			(fields.extendedControlType === PvtHeatPumpRoomControlType.RoomSensor ||
				fields.extendedControlType === PvtHeatPumpRoomControlType.Thermostat) &&
			fields.extendedControlType &&
			fields.roomTemperatureControl
		) &&
		!(fields.extendedControlType === PvtHeatPumpRoomControlType.RoomSensor && hasHeating) &&
		!(fields.extendedControlType === PvtHeatPumpRoomControlType.RoomSensor && hasCooling) &&
		!(fields.extendedControlType === "MANUAL_COOLING") &&
		!(fields.extendedControlType === PvtHeatPumpRoomControlType.Opentherm && hasHeating && interfaceHasChMaxTemp) &&
		!(hpHasMinSinkTempSetp && hasCooling)

	useEffect(() => {
		if (allConditionsFalse) {
			handleSkip && handleSkip()
		}
	}, [])

	if (error && interfaceId) {
		return <ErrorCard error={error} interfaceId={interfaceId} />
	}

	return (
		<>
			<Typography variant="h6">{steps[4]}</Typography>

			{fields.extendedControlType === PvtHeatPumpRoomControlType.Opentherm && hasHeating && interfaceHasChMaxTemp && (
				<Stack direction="row">
					<NumberInput
						label={t("MaxSinkTempHeating")}
						disabled={loading}
						unit={CELCIUS}
						{...register("chSetpMaxTemp", { required: true, validate: validateCHTempMax })}
					/>
					<MoreInfo>
						<Typography>{t("heatPumpStatus:MaxSinkTempHeatingInfo")}</Typography>
					</MoreInfo>
				</Stack>
			)}
			{hpHasMinSinkTempSetp && hasCooling && (
				<Stack direction="row">
					<NumberInput
						label={t("MinSinkCooling")}
						disabled={loading}
						unit={CELCIUS}
						{...register("sinkCoolingPauseThresholdTemp", { required: true, min: 15, max: 25 })}
					/>
					<MoreInfo>
						<Typography>{t("heatPumpStatus:MinSinkCoolingInfo")}</Typography>
					</MoreInfo>
					{fields.sinkCoolingPauseThresholdTemp != null && fields.sinkCoolingPauseThresholdTemp < 15 && (
						<Typography color="primary">{t("MsgMin15C")}</Typography>
					)}
				</Stack>
			)}

			{hpHasBoost && (
				<>
					<Stack direction="row" alignItems="center">
						<BooleanInput
							label={t("heatPumpSettingsPage:HeatingBoost")}
							disabled={loading}
							{...register("shBoostEnabled")}
						/>
						<MoreInfo>
							<Trans i18nKey="MsgBoostDescription" ns="heatPumpSettingsPage" components={{ italic: <i /> }} />
						</MoreInfo>
					</Stack>
					{fields.shBoostEnabled && (
						<>
							<Typography color="primary">{t("heatPumpSettingsPage:MsgBoostConsumption")}</Typography>
							<NumberInput
								label={t("heatPumpSettingsPage:BoostSourceTemp")}
								disabled={loading}
								unit={CELCIUS}
								{...register("boostSourceTemp", { required: true })}
							/>
						</>
					)}
				</>
			)}

			{(fields.extendedControlType === PvtHeatPumpRoomControlType.RoomSensor ||
				fields.extendedControlType === PvtHeatPumpRoomControlType.Thermostat) &&
				fields.extendedControlType &&
				fields.roomTemperatureControl && (
					<Typography color="primary" gutterBottom>
						{t("WhereToFindContacts", {
							contacts: usedHpContacts(fields.extendedControlType, fields.roomTemperatureControl),
						})}
						<MoreInfo>
							<>
								<Typography gutterBottom>{t("SeePVTStartGuide")}</Typography>
								<a href="/images/L8L24Diagram.png" target="_blank">
									<CardMedia component="img" alt={t("HeatPumpconnectionDiagram")} image="/images/L8L24Diagram.png" />
								</a>
							</>
						</MoreInfo>
					</Typography>
				)}
			{hasCooling && fields.extendedControlType === PvtHeatPumpRoomControlType.Opentherm && (
				<StyledTypography color="primary">
					{t("CheckThermostatForCoolingSupport")}
					<MoreInfo>
						<Typography>{t("MsgSupportedThermostats")}</Typography>
					</MoreInfo>
				</StyledTypography>
			)}
			{fields.extendedControlType === PvtHeatPumpRoomControlType.RoomSensor && hasHeating && (
				<>
					<NumberInput
						label={t("DesiredRoomTempHeating")}
						disabled={loading}
						unit={CELCIUS}
						{...register("shRoomSetpTemp", { required: true })}
					/>
					<NumberInput
						label={t("RoomTempHystHeating")}
						disabled={loading}
						unit={CELCIUS}
						{...register("shRoomHysteresisTemp", { required: true })}
					/>
				</>
			)}
			{fields.extendedControlType === PvtHeatPumpRoomControlType.RoomSensor && hasCooling && (
				<>
					<NumberInput
						label={t("DesiredRoomTempCooling")}
						disabled={loading}
						unit={CELCIUS}
						{...register("scRoomSetpTemp", { required: true })}
					/>
					<NumberInput
						label={t("RoomTempHystCooling")}
						disabled={loading}
						unit={CELCIUS}
						{...register("scRoomHysteresisTemp", { required: true })}
					/>
				</>
			)}

			{fields.extendedControlType === "MANUAL_COOLING" && (
				<RadioInput
					label={t("ManualCooling")}
					row
					disabled={loading}
					options={READABLE_MANUAL_COOLING_MODE}
					hiddenOptions={READABLE_MANUAL_COOLING_MODE_HIDDEN}
					{...register("manualCoolingMode")}
				/>
			)}
			<BooleanInput label={t("BackupEnable")} disabled={loading} {...register("shBackupEnable", { required: true })} />

			{BottomNavigation && (
				<BottomNavigation loading={loading} steps={steps} step={step} backStep={backStep} nextStep={nextStep} />
			)}
		</>
	)
}
