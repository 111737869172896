import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Chip,
	Stack,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	Typography,
} from "@mui/material"
import { Trans, useTranslation } from "react-i18next"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import { RefObject, useRef, useState } from "react"
import { useMessage } from "providers/MessageProvider"
import { BatchUpdateInterfaceProps, StatusMessageProps } from "./BatchUpdate"
import CheckIcon from "@mui/icons-material/Check"
import { AdminSettingsAccordion } from "pages/HeatPumpAdminPage"
import HeatPumpSettingsPage from "pages/HeatPumpSettingsPage"
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom"

enum TabsTypes {
	GeneralSettings = "General",
	AdminSettings = "Admin settings",
}

export default function BatchUpdateSettings({ interfaces, loading }: BatchUpdateInterfaceProps) {
	const { t } = useTranslation(["general", "pvtHeatPumpAdminPage", "heatPumpSettingsPage", "heatPumpErrorCard"])
	const message = useMessage()
	const [saveLoading, setSaveLoading] = useState<boolean>(false)
	const [isPartialSave, setIsPartialSave] = useState<Record<string, boolean>>({
		dhw: true,
		heatingCooling: true,
		pumps: true,
		general: true,
		security: true,
		dateTime: true,
		controller: true,
		dmSettings: true,
		generalSettings: true,
	})
	const [selectedTab, setSelectedTab] = useState(TabsTypes.AdminSettings)
	const [statusMessage, setStatusMessage] = useState<StatusMessageProps[]>([])

	const onlineInterfaceIds = interfaces.filter((item) => item.isOnline).map((item) => item.id)

	const resultsRef: RefObject<HTMLElement | null | undefined> = useRef()

	const [defaultInterfaceId, setDefaultInterfaceId] = useState({
		id: interfaces?.at(0)?.id ?? "",
		name: interfaces?.at(0)?.name ?? "",
	})

	const handleSavingMessages = (updateStatus: StatusMessageProps[]) => {
		setStatusMessage(updateStatus)
		resultsRef?.current?.scrollIntoView({ behavior: "smooth" })
	}

	const handleSaveLoading = (isLoading: boolean) => {
		setSaveLoading(isLoading)
	}

	const handleDefaultInterface = (interfaceId: string, interfaceName: string) => {
		setDefaultInterfaceId({ id: interfaceId, name: interfaceName })
		message.success(
			<Trans
				i18nKey="LoadedSettingsOf"
				ns="heatPumpSettingsPage"
				components={{ strong: <strong /> }}
				values={{ heatpump: interfaceName.toString() }}
			/>,
		)
	}

	const handleToggleSavingType = (id: string) => {
		setIsPartialSave((prev: { [key: string]: boolean }) => ({
			...prev,
			[id]: !prev[id],
		}))
	}

	return (
		<>
			<Alert severity="info" sx={{ mb: 2 }}>
				<Typography>
					<Trans
						i18nKey="LoadedSettingsOf"
						ns="heatPumpSettingsPage"
						components={{ strong: <strong /> }}
						values={{ heatpump: defaultInterfaceId.name }}
					/>
				</Typography>
			</Alert>

			<Tabs
				value={selectedTab}
				onChange={(_, newValue) => {
					setSelectedTab(newValue)
				}}
				centered
				sx={{ mb: 2 }}
			>
				<Tab label="General" value={TabsTypes.GeneralSettings} />
				<Tab label="Admin settings" value={TabsTypes.AdminSettings} />
			</Tabs>

			{selectedTab === TabsTypes.AdminSettings && (
				<Box mb={4}>
					<AdminSettingsAccordion
						handleSaveLoading={handleSaveLoading}
						handleSavingMessages={handleSavingMessages}
						interfaces={interfaces}
						defaultInterfaceId={defaultInterfaceId.id}
						handleToggleSavingType={handleToggleSavingType}
						isPartialSave={isPartialSave}
					/>
				</Box>
			)}
			{selectedTab === TabsTypes.GeneralSettings && (
				<Box mb={4}>
					<HeatPumpSettingsPage
						handleSaveLoading={handleSaveLoading}
						handleSavingMessages={handleSavingMessages}
						interfaces={interfaces}
						defaultInterfaceId={defaultInterfaceId.id}
						handleToggleSavingType={handleToggleSavingType}
						isPartialSave={isPartialSave}
					/>
				</Box>
			)}

			{onlineInterfaceIds.length > 0 &&
				(loading ? (
					<Stack direction="row" pt={4} justifyContent="center">
						<CircularProgress size={30} />
					</Stack>
				) : (
					<Box ref={resultsRef}>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell sx={{ width: "170px" }}>{t("heatPumpSettingsPage:RetrieveSettings")}</TableCell>
										<TableCell>{t("InterfaceID")}</TableCell>
										<TableCell>{t("Name")}</TableCell>
										<TableCell align="right">{t("Status")}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{interfaces.map((iface) => (
										<TableRow key={iface.name}>
											<TableCell component="th" scope="row">
												{defaultInterfaceId.id === iface.id ? (
													<Button disabled>{t("Selected")}</Button>
												) : (
													<Button onClick={() => handleDefaultInterface(iface.id, iface.name ?? "")}>
														{t("SetAsDefault")}
													</Button>
												)}
											</TableCell>
											<TableCell>{iface.id}</TableCell>
											<TableCell>{iface.name}</TableCell>
											<TableCell align="right">
												{saveLoading ? (
													<CircularProgress size={30} />
												) : (
													<>
														{statusMessage?.length ? (
															statusMessage.some((err) => err.interfaceId === iface.id) ? (
																<Chip
																	label={statusMessage.find((err) => err.interfaceId === iface.id)?.errorMessage}
																	color="error"
																	variant="outlined"
																	sx={{ pl: 1 }}
																	icon={<WarningAmberIcon sx={{ fontSize: 16 }} />}
																/>
															) : (
																<Chip
																	label={t("heatPumpSettingsPage:SettingsSaved")}
																	color="success"
																	variant="outlined"
																	sx={{ pl: 1 }}
																	icon={<CheckIcon sx={{ fontSize: 16 }} />}
																/>
															)
														) : (
															<Chip
																label={t("heatPumpSettingsPage:Pending")}
																color="warning"
																variant="outlined"
																sx={{ pl: 1 }}
																icon={<HourglassBottomIcon sx={{ fontSize: 16 }} />}
															/>
														)}
													</>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				))}
		</>
	)
}
