import { useRef, useState } from "react"

export default function useDebouncedSate<T>(
	initialValue: T | (() => T),
	wait = 750,
): [T, T, React.Dispatch<React.SetStateAction<T>>, React.Dispatch<React.SetStateAction<T>>] {
	const [state, setCurrentState] = useState<T>(initialValue)
	const [debouncedState, setDebouncedState] = useState<T>(initialValue)
	const timeout = useRef<number | null>(null)

	const setState: React.Dispatch<React.SetStateAction<T>> = (value) => {
		// odd workaround found here: https://github.com/microsoft/TypeScript/issues/37663
		const newValue: T = value instanceof Function ? value(state) : value
		setCurrentState(value)

		if (timeout.current !== null) clearTimeout(timeout.current)

		timeout.current = setTimeout(() => {
			setDebouncedState(newValue)
			timeout.current = null
		}, wait)
	}

	const setStateNow: React.Dispatch<React.SetStateAction<T>> = (value) => {
		if (timeout.current != null) clearTimeout(timeout.current)
		setCurrentState(value)
		setDebouncedState(value)
	}

	return [state, debouncedState, setState, setStateNow]
}
