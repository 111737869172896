import Page from "components/Page"
import { styled } from "@mui/material/styles"
import { Typography, Grid } from "@mui/material"
import { Search } from "@mui/icons-material"
import { useTranslation } from "react-i18next"

const StyledContainer = styled(Grid)`
	margin-top: 10vh;
`

const SearchIcon = styled(Search)(({ theme }) => ({
	fontSize: 150,
	color: theme.palette.grey[800],
}))

export default function NotFoundPage() {
	const { t } = useTranslation("notFoundPage")
	return (
		<Page title={t("NotFound")}>
			<StyledContainer container direction="column" spacing={4} alignItems="center">
				<Grid item>
					<SearchIcon />
				</Grid>
				<Grid item>
					<Typography variant="h2">{t("Oops")}</Typography>
				</Grid>
				<Grid item>
					<Typography>{t("PageDoesNotSeemToExist")}.</Typography>
				</Grid>
			</StyledContainer>
		</Page>
	)
}
