import { Stack, Tab, Tabs } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import FirmwareProgress from "./FirmwareProgress"
import useHasRole from "hooks/useHasRole"
import { Role } from "generated/graphql"
import InterfaceFirmwareUpdatesExpert from "./InterfaceFirmwareUpdatesExpert"
import PvtHeatPumpFirmwareUpdatesExpert from "./PvtHeatPumpFirmwareUpdatesExpert"
import HeatPumpSingleUpdate from "components/HeatPumpSingleUpdate"
import InterfaceSingleUpdate from "components/InterfaceSingleUpdate"

type Props = {
	interfaceId: string
}
enum UpdateTypes {
	Interface = "interface",
	PvtHeatPump = "pvtHeatPump",
}

export default function FirmwareUpdates({ interfaceId }: Props) {
	const { t } = useTranslation("general")
	const isInternal = useHasRole([Role.Admin, Role.Mechanic])
	const [selectedTab, setSelectedTab] = useState(UpdateTypes.Interface)

	return (
		<>
			<Tabs
				sx={{ mb: 2, mt: -2 }}
				allowScrollButtonsMobile
				value={selectedTab}
				onChange={(_, newValue) => {
					setSelectedTab(newValue)
				}}
				centered
			>
				<Tab label={t("Interface")} value={UpdateTypes.Interface} />
				<Tab label={t("HeatPump")} value={UpdateTypes.PvtHeatPump} />
			</Tabs>
			<FirmwareProgress interfaceId={interfaceId} />

			<Stack direction="row">
				{isInternal ? (
					<>
						{selectedTab === UpdateTypes.Interface && <InterfaceSingleUpdate interfaceId={interfaceId} />}
						{selectedTab === UpdateTypes.PvtHeatPump && <HeatPumpSingleUpdate interfaceId={interfaceId} />}
					</>
				) : (
					<>
						{selectedTab === UpdateTypes.Interface && <InterfaceFirmwareUpdatesExpert interfaceId={interfaceId} />}
						{selectedTab === UpdateTypes.PvtHeatPump && <PvtHeatPumpFirmwareUpdatesExpert interfaceId={interfaceId} />}
					</>
				)}
			</Stack>
		</>
	)
}
