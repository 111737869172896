import { LinearProgress, Tooltip, Typography } from "@mui/material"
import QuickTable, { Label } from "components/Table"
import {
	EventType,
	ModbusChangeFieldsFragment,
	EventFieldsFragment,
	useReadInterfaceEventLogQuery,
} from "generated/graphql"
import pvtHeatPumpData from "resources/pvt_heatpump.yml"
import { PVTHeatPump } from "resources/modbusTypes"
import HeatPumpInfoMessage from "pages/HeatPumpStatusPage/components/HeatPumpInfoMessage"
import formatShortTime from "tools/formatShortTime"
import showDecimalPoints from "tools/showDecimalPoints"
import { FormatUserOrGroup as User } from "components/FormatUserOrGroup"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"

//based on the go math pkg
const MaxInt16 = (1 << 15) - 1 // 32767
const MaxUint16 = (1 << 16) - 1 // 65535

const holdingRegisters = (pvtHeatPumpData as PVTHeatPump).pvt_heat_pump.holding_registers
const getEventLabels = (t: TFunction<["pvtHeatPumpAdminPage", "general"]>): Label<EventFieldsFragment>[] => {
	return [
		{
			key: "start",
			name: t("general:From"),
			sortable: true,
			width: 115,
			sort: ({ start }) => new Date(start).getTime(),
			resolve: ({ start }) => formatShortTime(start),
		},
		{
			key: "stop",
			name: t("general:To"),
			sortable: true,
			hide: "md",
			width: 115,
			sort: ({ stop }) => (stop ? new Date(stop).getTime() : 0),
			resolve: ({ stop, type: myType }) => (myType === EventType.HpError ? formatShortTime(stop) : ""),
		},
		{
			key: "message",
			name: t("general:Notification"),
			sortable: true,
			sort: ({ data }) => {
				switch (data.__typename) {
					case "ModbusChangeData":
						return data.address
					case "HPErrorData":
						return [...data.errors]
							.sort((e1, e2) => e1 - e2)
							.reduce((score, errNr, ix) => (score += errNr * Math.pow(10, ix)), 0) // group matching errors toghether
					default:
						return -Infinity
				}
			},
			resolve: ({ data }) => {
				switch (data.__typename) {
					case "ModbusChangeData": {
						// create new lexical block so declared variable below does not polute other switch cases
						const change = convertMbTypes(data, t)
						if (!change) return null
						return (
							<Typography>
								<Tooltip enterDelay={500} title={data.user.email}>
									<span>{data?.user ? <User>{data.user}</User> : t("general:Someone")}</span>
								</Tooltip>{" "}
								{t("UserSetFieldTo", { field: change.name.toString(), value: change.value.toString() })}
								{change.unit.length > 2 ? " " + change.unit : change.unit}
							</Typography>
						)
					}
					case "HPErrorData":
						return data.errors.map((errNr, ix) => (
							<HeatPumpInfoMessage errorCode={errNr} key={`log-error-${ix}`} align="right" />
						))
				}
			},
		},
	]
}

type Props = {
	interfaceId: string
}

//In the backend this is handled by casting a uint32 to a int16 which is the maximum value of a modbus value
//Overflow handles the negative values. This workaround exists because TSX doens't know anything but numbers
function modbusFloatToSignedNumber(value: number): number {
	return value > MaxInt16 ? value - (MaxUint16 + 1) : value
}

export default function InterfaceEventLog({ interfaceId }: Props) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage", "general"])
	const { data, loading } = useReadInterfaceEventLogQuery({
		variables: { interfaceId },
		fetchPolicy: "cache-and-network",
	})
	const eventLabels = getEventLabels(t)
	return (
		<>
			{loading && <LinearProgress />}
			{!loading && data?.interface?.eventLog && (
				<QuickTable
					defaultItemsPerPage={20}
					labels={eventLabels}
					rows={data?.interface?.eventLog}
					defaultSortCol="start"
					fullWidth
					size="small"
					wrapText
				/>
			)}
		</>
	)
}

function convertMbTypes(data: ModbusChangeFieldsFragment, t: TFunction<["pvtHeatPumpAdminPage", "general"]>) {
	const settingData = holdingRegisters.find((item) => {
		if (item.address !== undefined) {
			return item.address === data.address
		} else if (item.addresses !== undefined) {
			return item.addresses.some((address) => address === data.address)
		}
	})

	if (!settingData) return { name: `${t("withUnknownAddress")} ${data.address}`, value: data.value, unit: "" }
	const settingName = settingData.readable_admin_name ?? settingData.readable_name ?? settingData.name
	let textResult = ""
	let unit = ""

	switch (settingData.var_type) {
		case "bool":
			switch (data.value) {
				case 0:
					textResult = t("general:Off")
					break
				case 1:
					textResult = t("general:On")
					break
				default:
					textResult = data.value.toString()
					break
			}
			break
		case "float":
			textResult = showDecimalPoints(
				modbusFloatToSignedNumber(data.value) / Math.pow(10, settingData.decimals ?? 0),
				settingData.decimals ?? 0,
			)
			if (settingData.unit) unit = settingData.unit
			break
		case "int":
			textResult = data.value.toLocaleString()
			if (settingData.unit) unit = settingData.unit
			break
		case "enum": {
			if (!settingData.options || settingData.options.length < data.value) {
				textResult = data.value.toString()
				break
			}
			textResult =
				settingData.options.find((option) => option.value === data.value)?.readable_name ??
				`${t("unknownValue")} ${data.value}`
			break
		}
		case "flags": {
			textResult = data.value.toString()
			break
		}
	}
	return { name: settingName, value: textResult, unit: unit }
}
