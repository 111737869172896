import { CardContent, Typography, LinearProgress, Stack, CardHeader, IconButton } from "@mui/material"
import CustomCard from "components/CustomCard"
import { useMyGroupQuery } from "generated/graphql"
import useSize from "hooks/useSize"
import NotFoundPage from "pages/NotFoundPage"
import Page from "components/Page"
import EditableGroupName from "./components/EditableGroupName"
import MembersList from "./components/MemberList"
import { PersonAddAlt1Outlined } from "@mui/icons-material"
import AddMemberDialog from "components/AddMemberDialog"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export default function GroupManagementPage() {
	const { t } = useTranslation(["groupManagement", "general"])
	const smallScreen = useSize("down", "md")
	const { data, loading, error } = useMyGroupQuery()
	const [addMemberDialog, setAddMemberDialog] = useState(false)

	if (!loading && !data?.me.group?.isOrganization) return <NotFoundPage />

	const cardStyles = { width: smallScreen !== true ? 750 : "100%" }

	return (
		<Page
			title={
				loading ? (
					t("general:Loading")
				) : (
					<Stack direction="row" alignItems="center">
						<span>
							{t("general:ManagementOf")}{" "}
							{data?.me.group ? (
								<EditableGroupName group={data?.me.group} hideEdit={!data.me.isGroupAdmin} />
							) : (
								<>{t("Errors.UnkownOrg")}</>
							)}
						</span>
					</Stack>
				)
			}
			noPadding={smallScreen}
		>
			<Stack alignItems="center" direction="column">
				{loading && (
					<CustomCard sx={cardStyles}>
						<CardHeader title={t("general:Loading")} />
						<CardContent>
							<LinearProgress sx={{ mt: 2 }} />
						</CardContent>
					</CustomCard>
				)}
				{!loading && data?.me.group && (
					<>
						<CustomCard sx={cardStyles}>
							<CardHeader
								title={
									<Stack alignItems="center" direction="row" justifyContent="space-between">
										{t("Members")}
										{data.me.isGroupAdmin && (
											<IconButton
												size="large"
												onClick={(e) => {
													e.stopPropagation()
													setAddMemberDialog(true)
												}}
											>
												<PersonAddAlt1Outlined />
											</IconButton>
										)}
									</Stack>
								}
							/>
							<CardContent>
								<MembersList isGroupAdmin={data.me.isGroupAdmin} group={data.me.group} />
							</CardContent>
						</CustomCard>
						{addMemberDialog && <AddMemberDialog group={data.me.group} onClose={() => setAddMemberDialog(false)} />}
					</>
				)}
				{error != null && (
					<CustomCard sx={cardStyles}>
						<CardHeader title={t("Errors.UnkownError")} titleTypographyProps={{ color: "error" }} />
						<CardContent>
							{error instanceof Error && (
								<Typography>
									<i>{error.message}</i>
								</Typography>
							)}
						</CardContent>
					</CustomCard>
				)}
			</Stack>
		</Page>
	)
}
