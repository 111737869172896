import { Divider, styled, Typography } from "@mui/material"
import React from "react"
import { alpha } from "@mui/material/styles"

interface PropsHeadingLabel {
	children: React.ReactNode
	gutterTop?: boolean
	gutterBottom?: boolean
}

interface StyledProps {
	gutterTop: boolean
	gutterBottom: boolean
}

const StyledTypography = styled("div")<StyledProps>(({ theme, gutterTop, gutterBottom }) => ({
	["& h6"]: {
		color: theme && alpha(theme?.palette?.text?.primary, 0.8),
		marginTop: !gutterTop ? theme?.spacing(1) : theme?.spacing(-1), //-8 padding for the 8px card padding
		paddingBottom: theme?.spacing(0.5),
		fontWeight: theme?.typography.fontWeightRegular,
	},
	["& hr"]: {
		marginBottom: gutterBottom ? theme?.spacing(-1) : 0.5,
	},
}))

export default function HeadingLabel({ children, gutterTop = false, gutterBottom = false }: PropsHeadingLabel) {
	return (
		<StyledTypography gutterTop={gutterTop} gutterBottom={gutterBottom}>
			<Typography variant="h6">{children}</Typography>
			<Divider />
		</StyledTypography>
	)
}
