import { useEffect, useMemo, useState } from "react"
import { styled } from "@mui/material/styles"
import { Avatar, Box, Button, Container, Link, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { PersonOutlined } from "@mui/icons-material"
import { EMAIL, HEAT_PUMP_LIST, INTERFACE_ID, NOT_FOUND, ORGANIZATION_ID, ORGANIZATION_NAME } from "settings/url"
import { useAuth } from "providers/AuthProvider"
import { useMessage } from "providers/MessageProvider"
import useForm from "hooks/useForm"
import TextInput from "inputs/TextInput"
import useQueryParam from "hooks/useQueryParam"
import { Copyright } from "components/Copyright"
import BooleanInput from "inputs/BooleanInput"
import RadioInput from "inputs/RadioInput"
import appendErrorMessage from "tools/appendErrorMessage"
import { Trans, useTranslation } from "react-i18next"
import i18n from "i18n"
import { TFunction } from "i18next"
import FormLogo from "components/FormLogo"
import { CocType } from "../../generated/graphql"

const StyledAvatar = styled(Avatar)(({ theme }) => ({
	margin: theme.spacing(1),
	backgroundColor: theme.palette.secondary.main,
}))

const StyledContent = styled("div")(({ theme }) => ({
	marginTop: theme.spacing(8),
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
}))

const StyledForm = styled("form")(({ theme }) => ({
	width: "100%", // Fix IE 11 issue.
	marginTop: theme.spacing(1),
}))

const StyledSubmit = styled(Button)(({ theme }) => ({
	margin: theme.spacing(3, 0, 2),
}))

function getAccountTypes(t: TFunction<["accountCreation", "general"]>) {
	return {
		resident: t("general:Resident"),
		organization: t("general:Organization"),
	}
}

type UserInput = {
	email: string
	firstName: string
	lastName: string
	password: string
	repeatPassword: string
	isOrganization: boolean
	organizationName?: string
	acceptPrivacyPolicy: boolean
	cocNumber?: string
	cocType?: CocType
}

function checkPrivacyPolicyChecked(checked?: boolean) {
	if (!checked) return i18n.t("AcceptPrivacyPolicy", { ns: "accountCreation" })
	return null
}
export default function ActivationPage() {
	const { t, i18n } = useTranslation(["accountCreation", "general"])
	const accountTypes = useMemo(() => getAccountTypes(t), [i18n.language])
	const { code } = useParams<"code">()
	if (!code) return <Navigate to={`/${NOT_FOUND}`} /> // should never happen, but just in case, navigate to not found page
	const navigate = useNavigate()
	const auth = useAuth()
	const message = useMessage()
	const email = useQueryParam(EMAIL)
	const interfaceId = useQueryParam(INTERFACE_ID)
	const groupId = useQueryParam(ORGANIZATION_ID)
	const groupName = useQueryParam(ORGANIZATION_NAME)
	const [loading, setLoading] = useState(false)
	const [companyCoC, setCompanyCoC] = useState<string | null>(null)

	const { submit, register, fields, set } = useForm<UserInput>(
		{
			email: email ?? undefined,
			cocType: CocType.DutchKvk,
		},
		handleSubmit
	)
	register("isOrganization", { required: !groupName })

	const [activeStep, setActiveStep] = useState(0)

	const translatedBusinessType = {
		[CocType.DutchKvk]: t("DutchCoC"),
		[CocType.Other]: t("OtherCoc"),
	}

	async function handleSubmit(user: UserInput) {
		if (user.repeatPassword !== user.password) {
			message.error(t("PasswordErrors.DoNotMatch"))
			return
		}

		if (!user.password || user.password.length < 10) {
			message.error(t("PasswordErrors.MustBeMin10Chars"))
			return
		}

		setLoading(true)
		try {
			if (!code) throw new Error(t("Errors.CodeNotFound"))
			await auth.activateUser(code, {
				email: user.email,
				password: user.password,
				firstName: user.firstName,
				lastName: user.lastName,
				isOrganization: user.isOrganization,
				organizationName: user.organizationName,
				cocNumber: user.cocNumber,
				cocType: user.cocType,
			})
			message.success(t("UserCreated"))
			try {
				await auth.signIn(user.email, user.password)
			} catch (e) {
				message.error(t("PasswordErrors.LoginFailed"))
			} finally {
				// doesn't matter if you are signed in or not, redirect in any case, if not signed in correctly you will be redirected to the /login page
				if (interfaceId != null) {
					navigate(`/${HEAT_PUMP_LIST}?${INTERFACE_ID}=${interfaceId}`)
				} else {
					navigate("/")
				}
			}
		} catch (e) {
			if (e instanceof Error) {
				if (e.message.includes("unknown email")) {
					message.error(t("PasswordErrors.EmailUnknownOrLinkExpired"))
					return
				} else if (e.message.includes("Password insecure")) {
					message.error(t("PasswordErrors.MsgInsecure"))
					return
				} else if (e.message.includes("chamber of commerce number already in use")) {
					setActiveStep(0)
					setCompanyCoC(user.cocNumber ?? null)
					message.error(t("Errors.CoCAlreadyInUse"))
					return
				}
			}
			message.error(appendErrorMessage(t("Errors.ActivationFailed"), e))
		} finally {
			setLoading(false)
		}
	}

	// if we are still signed in when entering this page, sign out!
	useEffect(() => {
		if (auth.user) auth.signOut()
	}, [])

	// step one is complete when either you are not creating an organization or you are creating an organization and filled out the name field
	const isStepOneComplete =
		Boolean(groupId) ||
		fields.isOrganization === false ||
		(fields.isOrganization && Boolean(fields.organizationName) && fields.isOrganization && Boolean(fields.cocNumber))

	return (
		<Container component="main" maxWidth="sm">
			<StyledContent>
				<FormLogo />
				<StyledAvatar>
					<PersonOutlined />
				</StyledAvatar>
				<Typography component="h1" variant="h5" align="center">
					{t("CreateAccount")}
					{groupName && ` ${t("With")} ${groupName}`}
				</Typography>
				<StyledForm>
					<Stepper orientation="vertical" activeStep={activeStep}>
						<Step key="welcome">
							<StepLabel onClick={() => setActiveStep(0)}>{t("Welcome")}</StepLabel>
							<StepContent>
								{groupId ? (
									<Typography gutterBottom>
										<Trans i18nKey="MsgInvitedToOrg" ns="accountCreation" values={{ groupName: groupName }} />
									</Typography>
								) : (
									<>
										<Typography gutterBottom>{t("CreateAsResidentOrOrg")}</Typography>
										<RadioInput
											label={t("AccountType")}
											row
											value={
												fields.isOrganization !== undefined
													? fields.isOrganization
														? "organization"
														: "resident"
													: null
											}
											onChange={(accountType) => set({ isOrganization: accountType === "organization" })}
											options={accountTypes}
											required
										/>
										{fields.isOrganization && (
											<>
												<Typography gutterBottom>{t("FillInNameToCreateOrg")}</Typography>
												<RadioInput
													label={t("CoC")}
													options={translatedBusinessType}
													row
													{...register("cocType", { required: true })}
												/>
												<TextInput
													variant="outlined"
													label={t("CompanyName")}
													autoComplete="organization"
													{...register("organizationName", { required: fields.isOrganization })}
												/>

												<TextInput
													variant="outlined"
													label={t("CoC")}
													disabled={loading}
													{...register("cocNumber", {
														required: true,
														validate: (value) => {
															if (value !== companyCoC) {
																return null
															}
															return t("MsgCheckOrgExists")
														},
													})}
												/>
											</>
										)}
									</>
								)}
								<Button
									disabled={!isStepOneComplete || fields.cocNumber === companyCoC}
									variant="contained"
									onClick={() => setActiveStep(1)}
								>
									{t("Continue")}
								</Button>
							</StepContent>
						</Step>
						<Step key="user-details-step">
							<StepLabel onClick={() => isStepOneComplete && setActiveStep(1)}>{t("PersonalDetails")}</StepLabel>
							<StepContent>
								<TextInput
									disabled={Boolean(email) || loading}
									variant="outlined"
									label={t("general:Email")}
									autoComplete="email"
									type="email"
									autoFocus={!email}
									{...register("email", { required: true, email: true })}
								/>
								<TextInput
									autoFocus={Boolean(email)}
									variant="outlined"
									label={t("general:FirstName")}
									autoComplete="given-name"
									disabled={loading}
									{...register("firstName", { required: true, minLength: 2 })}
								/>
								<TextInput
									variant="outlined"
									label={t("general:LastName")}
									autoComplete="family-name"
									disabled={loading}
									{...register("lastName", { required: true, minLength: 2 })}
								/>
								<TextInput
									variant="outlined"
									label={t("general:Password")}
									type="password"
									autoComplete="new-password"
									disabled={loading}
									{...register("password", { required: true })}
								/>
								<TextInput
									variant="outlined"
									label={t("general:RepeatPassword")}
									type="password"
									autoComplete="new-password"
									disabled={loading}
									{...register("repeatPassword", { required: true })}
								/>
								<BooleanInput
									label={
										<Trans i18nKey="IAcceptPrivacyPolicy" ns="accountCreation">
											I agree to the{" "}
											<Link href={t("privacyPolicyURL")} target="__blank">
												privacy policy
											</Link>
										</Trans>
									}
									disabled={loading}
									{...register("acceptPrivacyPolicy", { validate: checkPrivacyPolicyChecked })}
								/>
								<StyledSubmit onClick={submit} fullWidth variant="contained" color="primary" disabled={loading}>
									{t("CreateAccount")}
								</StyledSubmit>
							</StepContent>
						</Step>
					</Stepper>
				</StyledForm>
			</StyledContent>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>
	)
}
