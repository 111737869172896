import { MouseEvent, useState } from "react"
import { styled } from "@mui/material/styles"
import { Avatar, Badge, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack } from "@mui/material"
import { Toolbar, AppBar, Typography, Menu, MenuItem } from "@mui/material"
import { ArrowBack, Menu as MenuIcon, Logout, PersonOutline, ManageAccountsOutlined, Public } from "@mui/icons-material"
import { useAuth } from "providers/AuthProvider"
import { useMessage } from "providers/MessageProvider"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { ACCOUNT, SIGN_IN } from "settings/url"
import { NotificationType, useMeQuery, useUserNotificationsQuery } from "generated/graphql"
import TSLogoNoTextIcon from "./icons/TSLogo"
import { useApolloClient } from "@apollo/client"
import useSize from "hooks/useSize"
import { useTranslation } from "react-i18next"
import NotificationsIcon from "@mui/icons-material/Notifications"
import NotificationMenu from "./NotificationMenu"

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	zIndex: theme.zIndex.drawer + 1,
}))

const StyledToolBar = styled(Toolbar)`
	display: flex;
	justify-content: space-between;
`

const StyledLogo = styled(TSLogoNoTextIcon)(({ theme }) => ({
	marginRight: theme.spacing(2),
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
	[theme.breakpoints.down("sm")]: {
		maxWidth: 200,
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		display: "inline-block",
	},
}))

const StyledAvatar = styled(Avatar)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	color: theme.palette.primary.main,
	marginLeft: theme.spacing(1),
}))

const StyledIconButton = styled(IconButton)({
	padding: 0,
})
const StyledList = styled(List)({
	padding: 0,
	maxHeight: 400,
})

const StyledNotificationButton = styled(IconButton)(({ theme }) => ({
	padding: 0,
	marginRight: theme.spacing(1),
	["& .MuiBadge-badge"]: {
		top: 3,
		right: 3,
	},
}))

type Props = {
	title: React.ReactNode
	middle?: React.ReactNode
	hideTitle?: boolean
	backButton?: boolean
	color?: string | null
	onClickMenu?: () => void
}

export default function TopBar({ title, middle, backButton = false, hideTitle, color, onClickMenu }: Props) {
	const { t, i18n } = useTranslation()

	const auth = useAuth()
	const message = useMessage()
	const { data: meData, loading: meLoading } = useMeQuery()
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [anchorElNotificatoins, setAnchorElNotificatoins] = useState<HTMLButtonElement | null>(null)
	const [anchorElLanguage, setAnchorElLanguage] = useState<HTMLLIElement | null>(null)
	const navigate = useNavigate()
	const client = useApolloClient()
	const location = useLocation()
	const smallScreen = useSize("down", "lg")

	const { data: userNotification } = useUserNotificationsQuery({
		variables: { types: [NotificationType.Notification] },
	})

	const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
		handleCloseLanguage()
	}

	const handleOpenLanguage = (event: MouseEvent<HTMLLIElement>) => {
		event.stopPropagation()
		event.preventDefault()
		setAnchorElLanguage(event.currentTarget)
	}

	const handleCloseLanguage = () => {
		setAnchorElLanguage(null)
	}

	const handleSetLanguage = (lng: string) => {
		i18n.changeLanguage(lng)
		handleCloseLanguage()
	}

	const handleSignOut = async () => {
		handleClose()
		await auth.signOut()
		client.clearStore()
		message.success(t("AppBar.LogoutSuccess"))
		navigate(`/${SIGN_IN}`)
	}

	const handleBack = () => {
		const pathSegments = location.pathname.split("/").filter((segment) => segment !== "")
		const queryParams = new URLSearchParams(location.search)
		const refParam = queryParams.get("ref")

		if (refParam) {
			navigate(`/${refParam}`)
		} else {
			navigate(`/${pathSegments[0]}`)
		}
	}

	return (
		<StyledAppBar position="fixed" style={color ? { backgroundColor: color } : {}}>
			<StyledToolBar>
				<Stack direction="row">
					{smallScreen && onClickMenu && (
						<IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={onClickMenu}>
							<MenuIcon />
						</IconButton>
					)}
					{backButton && (
						<IconButton size="large" edge="start" color="inherit" onClick={handleBack}>
							<ArrowBack />
						</IconButton>
					)}
					<Stack direction="row" alignItems="center">
						{(!smallScreen || !onClickMenu) && (
							<IconButton component={Link} to="/" color="inherit" disableRipple>
								<StyledLogo fontSize="large" />
							</IconButton>
						)}
						{!hideTitle && (
							<StyledTypography variant="h6" noWrap>
								{title}
							</StyledTypography>
						)}
					</Stack>
				</Stack>
				<div>{middle}</div>

				<div>
					{auth.user && (
						<>
							<Stack direction="row" alignItems="center">
								<StyledNotificationButton
									color="inherit"
									onClick={(e) => setAnchorElNotificatoins(e.currentTarget)}
									size="large"
								>
									<Badge
										badgeContent={userNotification?.notifications.filter((notification) => !notification.read).length}
										color="secondary"
									>
										<NotificationsIcon sx={{ fontSize: 27 }} />
									</Badge>
								</StyledNotificationButton>

								<StyledIconButton color="inherit" onClick={handleOpen} size="large">
									<StyledAvatar>
										{!meLoading && meData !== undefined
											? meData.me.firstName.charAt(0) + meData.me.lastName.charAt(0)
											: "?"}
									</StyledAvatar>
								</StyledIconButton>
							</Stack>

							<Menu
								anchorEl={anchorElNotificatoins}
								keepMounted
								open={Boolean(anchorElNotificatoins)}
								onClose={() => setAnchorElNotificatoins(null)}
							>
								<StyledList>
									<NotificationMenu data={userNotification} />
								</StyledList>
							</Menu>

							<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
								{!meLoading && meData !== undefined && (
									<ListItem divider>
										<ListItemIcon sx={{ minWidth: 36 }}>
											<PersonOutline />
										</ListItemIcon>
										<ListItemText>{meData.me.email}</ListItemText>
									</ListItem>
								)}
								<MenuItem component={Link} to={`/${ACCOUNT}`} onClick={handleClose}>
									<ListItemIcon>
										<ManageAccountsOutlined />
									</ListItemIcon>
									<ListItemText>{t("Account")}</ListItemText>
								</MenuItem>
								<MenuItem onClick={handleSignOut}>
									<ListItemIcon>
										<Logout />
									</ListItemIcon>
									<ListItemText>{t("AppBar.Logout")}</ListItemText>
								</MenuItem>
								<MenuItem onClick={handleOpenLanguage}>
									<ListItemIcon>
										<Public />
									</ListItemIcon>
									<ListItemText>{t("ChangeLanguage")}</ListItemText>
								</MenuItem>
							</Menu>
							<Menu
								anchorEl={anchorElLanguage}
								keepMounted
								open={Boolean(anchorElLanguage)}
								onClose={() => {
									handleClose()
								}}
							>
								<MenuItem onClick={() => handleSetLanguage("nl")}>{t("Dutch")}</MenuItem>
								<MenuItem onClick={() => handleSetLanguage("en")}>{t("English")}</MenuItem>
								<MenuItem onClick={() => handleSetLanguage("de")}>{t("German")}</MenuItem>
								{/* eslint-disable i18next/no-literal-string */}
								{/*internal only option */}
								{import.meta.env.DEV && <MenuItem onClick={() => handleSetLanguage("cimode")}>Debug mode</MenuItem>}
							</Menu>
						</>
					)}
				</div>
			</StyledToolBar>
		</StyledAppBar>
	)
}
