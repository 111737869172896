import { Button, DialogContent, DialogTitle, Stack } from "@mui/material"
import useForm from "hooks/useForm"
import TSDialog from "components/TSDialog"
import InterfaceIDfromDBInput from "inputs/InterfaceIdFromDBInput"
import { GroupFieldsSettingsFragment, useClaimInterfaceMutation } from "generated/graphql"
import appendErrorMessage from "tools/appendErrorMessage"
import { useMessage } from "providers/MessageProvider"
import { useTranslation } from "react-i18next"

type Props = {
	onClose?: () => void
	group: GroupFieldsSettingsFragment
}

export interface SingleInterfaceProps {
	interfaceId: string
}
export default function GroupAddInterface({ onClose, group }: Props) {
	const { t } = useTranslation(["general", "groupManagement", "serviceRequests"])
	const message = useMessage()
	const [claimInterface] = useClaimInterfaceMutation()

	const handleSave = async (formData: SingleInterfaceProps) => {
		if (!formData?.interfaceId) return

		try {
			await claimInterface({
				variables: { groupId: group?.id, interfaceId: formData.interfaceId },
			})
			message.success(t("groupManagement:InterfaceAddedToGroup"))
			if (onClose) {
				onClose()
			}
		} catch (e) {
			message.error(appendErrorMessage(t("serviceRequests:Errors.Saving"), e))
		}
	}

	const { register, submit } = useForm<SingleInterfaceProps>({}, handleSave)

	return (
		<TSDialog open onClose={onClose} sx={{ margin: 5 }}>
			<DialogTitle sx={{ typography: "h4" }}>{t("AddInterface")}</DialogTitle>
			<DialogContent>
				<form onSubmit={submit}>
					<Stack direction="row" justifyContent="space-between" spacing={1} pt={1}>
						<InterfaceIDfromDBInput
							label={t("InterfaceIDs")}
							{...register("interfaceId")}
							selectedInterfaces={group?.interfaces}
						/>

						<Button variant="contained" onClick={submit}>
							{t("Save")}
						</Button>
					</Stack>
				</form>
			</DialogContent>
		</TSDialog>
	)
}
