import { ApolloCache, InMemoryCache } from "@apollo/client"
import { Save } from "@mui/icons-material"
import { LinearProgress, Typography } from "@mui/material"
import {
	Maybe,
	PvtHeatPumpAdminOverrideFragment,
	PvtHeatPumpAdminOverrideFragmentDoc,
	PvtHeatPumpInput,
	PvtHeatPumpInputOverrideMask,
	PvtHeatPumpOutputOverrideMask,
	UpdatePvtHeatPumpSettingsMutation,
	UpdatePvtHeatPumpSettingsMutationVariables,
	useReadHeatPumpOverrideSettingsQuery,
	useUpdatePvtHeatPumpSettingsMutation,
} from "generated/graphql"
import useForm from "hooks/useForm"
import NumberInput from "inputs/NumberInput"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"
import { useMessage } from "providers/MessageProvider"
import { useTranslation } from "react-i18next"
import appendErrorMessage from "tools/appendErrorMessage"
import onlyChangedValues from "tools/onlyChangedValues"
import BooleanInput from "inputs/BooleanInput"
import FlagsInput from "inputs/FlagsInput"
import FloatingFab from "components/FloatingFab"

function updatePvtHeatPumpCache(
	cache: ApolloCache<InMemoryCache>,
	{ data }: { data?: UpdatePvtHeatPumpSettingsMutation | null },
	{ variables }: { variables?: UpdatePvtHeatPumpSettingsMutationVariables },
) {
	if (!data?.updatePvtHeatPump || !variables) return
	const { pvtHeatPumpdata: inputData, interfaceIds: defaultInterfaceId } = variables
	const id = `PvtHeatPumpData:${defaultInterfaceId}`
	const oldPvtHeatPump = cache.readFragment<PvtHeatPumpAdminOverrideFragment>({
		id,
		fragment: PvtHeatPumpAdminOverrideFragmentDoc,
		fragmentName: "PvtHeatPumpAdminOverride",
	})
	if (!oldPvtHeatPump) return

	const newPvtHeatPump = (Object.keys(oldPvtHeatPump) as Array<keyof PvtHeatPumpAdminOverrideFragment>).reduce(
		(acc, key) => ({ ...acc, [key]: inputData[key as keyof PvtHeatPumpInput] ?? oldPvtHeatPump[key] }),
		{} as PvtHeatPumpAdminOverrideFragment,
	)

	cache.writeFragment<PvtHeatPumpAdminOverrideFragment>({
		id,
		fragment: PvtHeatPumpAdminOverrideFragmentDoc,
		fragmentName: "PvtHeatPumpAdminOverride",
		data: newPvtHeatPump,
	})
}

export default function ManualSettings({ defaultInterfaceId }: { defaultInterfaceId: string }) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage", "general", "heatPumpSettingsPage"])
	const message = useMessage()
	const { register, submit, reset, fields, set } = useForm<PvtHeatPumpInput>({}, handleSave)

	const { data, loading, error } = useReadHeatPumpOverrideSettingsQuery({
		variables: { interfaceId: defaultInterfaceId },
		onCompleted: (data) => {
			if (data.interface?.pvtHeatPump) reset(data.interface.pvtHeatPump)
		},
	})

	const [updateSettings] = useUpdatePvtHeatPumpSettingsMutation({
		update: updatePvtHeatPumpCache,
	})
	const changedFields = onlyChangedValues(data?.interface?.pvtHeatPump ?? {}, fields)

	async function handleSave() {
		if (Object.entries(changedFields).length === 0) {
			message.info(t("heatPumpSettingsPage:Errors.HPHasTheseSettings"))
			return
		}
		try {
			await updateSettings({
				variables: {
					interfaceIds: [defaultInterfaceId],
					pvtHeatPumpdata: changedFields,
					controllerSettings: {},
					isExpert: true,
					isAdmin: false,
				},
			})
			message.success(t("heatPumpSettingsPage:SettingsSaved"))
		} catch (e) {
			message.error(appendErrorMessage(t("heatPumpSettingsPage:Errors.UnkownSaving"), e))
		}
	}

	const resetField = (field: string) => {
		if (!field || !data?.interface?.pvtHeatPump) return

		const pvtHeatPumpData: PvtHeatPumpInput = data.interface.pvtHeatPump

		const value = pvtHeatPumpData[field as keyof PvtHeatPumpInput]

		set({
			[field]: value,
		})
	}

	if (loading) {
		return <LinearProgress />
	}

	if (error || data?.interface == null) {
		return <ErrorCard error={error} interfaceId={defaultInterfaceId} />
	}

	const {
		value: inputOverrideMaskValue,
		onChange: inputOverrideMaskOnchange,
		...inputOverrideMaskRest
	} = register("inputOverrideMask" as keyof PvtHeatPumpInput, {
		required: true,
	})

	const {
		value: outputOverrideMaskValue,
		onChange: outputOverrideMaskOnchange,
		...outputOverrideMaskRest
	} = register("outputOverrideMask" as keyof PvtHeatPumpInput, {
		required: true,
	})

	return (
		<>
			<FlagsInput
				possibleFlags={PvtHeatPumpInputOverrideMask}
				groupLabel={t("SensorOverrideMask")}
				disabled={loading}
				value={inputOverrideMaskValue as Maybe<string[]>}
				onChange={(value) => {
					if (
						value.some(
							(flag) => !Object.values(PvtHeatPumpInputOverrideMask).includes(flag as PvtHeatPumpInputOverrideMask),
						)
					)
						return
					inputOverrideMaskOnchange(value as PvtHeatPumpInputOverrideMask[])
				}}
				{...inputOverrideMaskRest}
			/>

			<NumberInput
				label={t("BT1Override")}
				disabled={loading}
				{...register("bt1OverrideValue", { required: true, min: -30, max: 130 })}
				fieldIsChanged={"bt1OverrideValue" in changedFields}
				reset={() => resetField("bt1OverrideValue")}
			/>

			<NumberInput
				label={t("BT2Override")}
				disabled={loading}
				{...register("bt2OverrideValue", { required: true, min: -30, max: 130 })}
				fieldIsChanged={"bt2OverrideValue" in changedFields}
				reset={() => resetField("bt2OverrideValue")}
			/>

			<NumberInput
				label={t("BT3Override")}
				disabled={loading}
				{...register("bt3OverrideValue", { required: true, min: -30, max: 130 })}
				fieldIsChanged={"bt3OverrideValue" in changedFields}
				reset={() => resetField("bt3OverrideValue")}
			/>

			<NumberInput
				label={t("BT4Override")}
				disabled={loading}
				{...register("bt4OverrideValue", { required: true, min: -30, max: 130 })}
				fieldIsChanged={"bt4OverrideValue" in changedFields}
				reset={() => resetField("bt4OverrideValue")}
			/>

			<NumberInput
				label={t("BT5Override")}
				disabled={loading}
				{...register("bt5OverrideValue", { required: true, min: -30, max: 130 })}
				fieldIsChanged={"bt5OverrideValue" in changedFields}
				reset={() => resetField("bt5OverrideValue")}
			/>

			<NumberInput
				label={t("BT6Override")}
				disabled={loading}
				{...register("bt6OverrideValue", { required: true, min: -30, max: 130 })}
				fieldIsChanged={"bt6OverrideValue" in changedFields}
				reset={() => resetField("bt6OverrideValue")}
			/>

			<NumberInput
				label={t("BT7Override")}
				disabled={loading}
				{...register("bt7OverrideValue", { required: true, min: -30, max: 130 })}
				fieldIsChanged={"bt7OverrideValue" in changedFields}
				reset={() => resetField("bt7OverrideValue")}
			/>

			<NumberInput
				label={t("BTAUX1Override")}
				disabled={loading}
				{...register("btAux1OverrideValue", { required: true, min: -30, max: 130 })}
				fieldIsChanged={"btAux1OverrideValue" in changedFields}
				reset={() => resetField("btAux1OverrideValue")}
			/>

			<NumberInput
				label={t("BTAUX2Override")}
				disabled={loading}
				{...register("btAux2OverrideValue", { required: true, min: -30, max: 130 })}
				fieldIsChanged={"btAux2OverrideValue" in changedFields}
				reset={() => resetField("btAux2OverrideValue")}
			/>

			<NumberInput
				label={t("BTAUX3Override")}
				disabled={loading}
				{...register("btAux3OverrideValue", { required: true, min: -30, max: 130 })}
				fieldIsChanged={"btAux3OverrideValue" in changedFields}
				reset={() => resetField("btAux3OverrideValue")}
			/>

			<FlagsInput
				possibleFlags={PvtHeatPumpOutputOverrideMask}
				groupLabel={t("RelayOverrideMask")}
				disabled={loading}
				value={outputOverrideMaskValue as Maybe<string[]>}
				onChange={(value) => {
					if (
						value.some(
							(flag) => !Object.values(PvtHeatPumpOutputOverrideMask).includes(flag as PvtHeatPumpOutputOverrideMask),
						)
					)
						return
					outputOverrideMaskOnchange(value as PvtHeatPumpOutputOverrideMask[])
				}}
				{...outputOverrideMaskRest}
			/>

			<BooleanInput
				label={t("GQ1CompressorRelay1Override")}
				disabled={loading}
				{...register("gq1Compressor1Override", { required: true })}
				fieldIsChanged={"gq1Compressor1Override" in changedFields}
				reset={() => resetField("gq1Compressor1Override")}
			/>

			<BooleanInput
				label={t("GQ1CompressorRelay2Override")}
				disabled={loading}
				{...register("gq1Compressor2Override", { required: true })}
				fieldIsChanged={"gq1Compressor2Override" in changedFields}
				reset={() => resetField("gq1Compressor2Override")}
			/>

			<BooleanInput
				label={t("EB1ElectricElementOverride")}
				disabled={loading}
				{...register("eb1ElectricHeaterRelayOverride", { required: true })}
				fieldIsChanged={"eb1ElectricHeaterRelayOverride" in changedFields}
				reset={() => resetField("eb1ElectricHeaterRelayOverride")}
			/>

			<BooleanInput
				label={t("QN4FourWayValveOverride")}
				disabled={loading}
				{...register("qn44WayValveRelayOverride", { required: true })}
				fieldIsChanged={"qn44WayValveRelayOverride" in changedFields}
				reset={() => resetField("qn44WayValveRelayOverride")}
			/>

			<BooleanInput
				label={t("AuxiliaryRelayOverride")}
				disabled={loading}
				{...register("auxRelayOverride", { required: true })}
				fieldIsChanged={"auxRelayOverride" in changedFields}
				reset={() => resetField("auxRelayOverride")}
			/>

			<BooleanInput
				label={t("GP1/GP2PumpPowerOverride")}
				disabled={loading}
				{...register("gp1Gp2PumpRelayOverride", { required: true })}
				fieldIsChanged={"gp1Gp2PumpRelayOverride" in changedFields}
				reset={() => resetField("gp1Gp2PumpRelayOverride")}
			/>

			<NumberInput
				label={t("GP1SourcePumpPWMOverride")}
				disabled={loading}
				{...register("gp1PumpSourcePwmOverride", { required: true, min: 0, max: 100 })}
				fieldIsChanged={"gp1PumpSourcePwmOverride" in changedFields}
				reset={() => resetField("gp1PumpSourcePwmOverride")}
			/>

			<NumberInput
				label={t("GP2DeliveryPumpPWMOverride")}
				disabled={loading}
				{...register("gp2PumpSinkPwmOverride", { required: true, min: 0, max: 100 })}
				fieldIsChanged={"gp2PumpSinkPwmOverride" in changedFields}
				reset={() => resetField("gp2PumpSinkPwmOverride")}
			/>

			<NumberInput
				label={t("QN2PassiveValveOverride")}
				disabled={loading}
				{...register("qn2HeatSourceValveOverride", { required: true, min: 0, max: 10 })}
				fieldIsChanged={"qn2HeatSourceValveOverride" in changedFields}
				reset={() => resetField("qn2HeatSourceValveOverride")}
			/>

			<NumberInput
				label={t("QN3DHWValveOverride")}
				disabled={loading}
				{...register("qn3HeatSinkValveOverride", { required: true, min: 0, max: 10 })}
				fieldIsChanged={"qn3HeatSinkValveOverride" in changedFields}
				reset={() => resetField("qn3HeatSinkValveOverride")}
			/>

			<FloatingFab submit={submit} loading={loading}>
				<Save />
				<Typography variant="button">{t("SaveManualOverride")}</Typography>
			</FloatingFab>
		</>
	)
}
