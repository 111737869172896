import { differenceInSeconds } from "date-fns"
import { useState, useEffect } from "react"

export default function useTimer(
	endTime: Date | (() => Date),
	onTimerEnd: () => void,
): [number, (newEndTime: Date) => void] {
	const [timerEnd, setTimerEnd] = useState<Date>(endTime)
	const [remainingTime, setRemainingTime] = useState<number>(-1)
	let timerInterval: number
	const processTimer = () => {
		const timeTillEnd = differenceInSeconds(timerEnd, new Date())
		setRemainingTime(timeTillEnd)
		if (timeTillEnd <= 0) {
			onTimerEnd()
			setRemainingTime(-1)
			clearInterval(timerInterval)
		}
	}
	useEffect(() => {
		if (timerEnd > new Date()) {
			processTimer()
			timerInterval = setInterval(processTimer, 1000)
			return () => clearInterval(timerInterval)
		}
	}, [timerEnd.getTime()])

	return [remainingTime, setTimerEnd]
}
