import { useState } from "react"
import { Line as LineData } from "./line"
import {
	ResponsiveContainer,
	Line,
	CartesianGrid,
	Label,
	Tooltip,
	XAxis,
	YAxis,
	Legend,
	ReferenceArea,
	ComposedChart,
	Area,
} from "recharts"
import { Props as LegendProps } from "recharts/types/component/DefaultLegendContent"
import formatTime from "tools/formatTime"
import CustomizedLegend from "./CustomLegend"
import { AxisDomain } from "recharts/types/util/types"
import { toDate } from "date-fns"
import useSize from "hooks/useSize"
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent"

type ChartData = {
	[key: string]: number | Date | null
}[]

function linesToChartData(lines: LineData[]) {
	const x = (lines[0]?.x ?? []).map((x) => ({ x: x.getTime() }))
	return lines.reduce((acc, line: LineData) => acc.map((p, ix) => ({ ...p, [line.id]: line.y[ix] })), x as ChartData)
}

type TSLineChartProps = {
	lines: LineData[]
	yrange?: [number, number]
	xlabel: string
	ylabel: string
	ylabelSecondary?: string
	animation?: boolean
	onDateRangeChanged?: (from: Date, until: Date) => void
	onZoomOut?: () => void
}

function formatXAxis(val: Date | number) {
	// return formatTime(new Date(val))
	if (typeof val === "number") {
		if (!isFinite(val)) {
			return String(val)
		}
		return formatTime(new Date(val))
	}
	return val instanceof Date ? formatTime(val) : String(val)
}
type ZoomData = {
	domain: AxisDomain
	refAreaLeft?: string
	refAreaRight?: string
}

export default function TSLineChart({
	lines,
	yrange,
	xlabel,
	ylabel,
	ylabelSecondary,
	animation,
	onDateRangeChanged,
	onZoomOut,
}: TSLineChartProps) {
	const data = linesToChartData(lines)
	const [zoomData, setZoomData] = useState<ZoomData>({ domain: ["dataMin", "dataMax"] })
	const [hoveredLine, setHoveredLine] = useState("")
	const largeScreen = useSize("up", "lg")
	const lowScreen = window.innerHeight < 500
	const zoom = () => {
		let domain: AxisDomain
		if (
			!zoomData.refAreaLeft ||
			!zoomData.refAreaRight ||
			zoomData.refAreaLeft === zoomData.refAreaRight ||
			zoomData.refAreaRight === ""
		) {
			setZoomData({ domain: zoomData.domain })
			return
		}
		if (zoomData.refAreaLeft > zoomData.refAreaRight) domain = [zoomData.refAreaRight, zoomData.refAreaLeft]
		else domain = [zoomData.refAreaLeft, zoomData.refAreaRight]

		setZoomData({ domain: domain })
		if (onDateRangeChanged) onDateRangeChanged(toDate(Number(domain[0])), toDate(Number(domain[1])))
	}

	const handleRightClick = (e: React.MouseEvent) => {
		if (onZoomOut && e.button === 2) {
			onZoomOut()
			e.preventDefault()
		}
	}
	return (
		<div onClick={handleRightClick} onContextMenu={handleRightClick}>
			<ResponsiveContainer width="100%" height={lowScreen ? 300 : 400}>
				<ComposedChart
					width={730}
					height={400}
					margin={
						largeScreen
							? { left: 20, bottom: 20, top: 20 }
							: { left: -20, right: lines.some((line) => line.yAxis === "secondary") ? -20 : 20, bottom: 20, top: 20 }
					}
					data={data}
					onMouseDown={(e) => {
						e && e.activeLabel && setZoomData({ ...zoomData, refAreaLeft: e.activeLabel })
					}}
					onMouseMove={(e) => {
						e && zoomData.refAreaLeft && e.activeLabel && setZoomData({ ...zoomData, refAreaRight: e.activeLabel })
					}}
					onMouseUp={() => {
						zoom()
					}}
				>
					<XAxis
						allowDataOverflow={true}
						dataKey="x"
						type="number"
						domain={zoomData.domain}
						tickFormatter={formatXAxis}
					>
						<Label value={xlabel} position="insideBottom" offset={-10} />
					</XAxis>
					<YAxis yAxisId="primary" domain={yrange} allowDataOverflow={Boolean(yrange)}>
						{largeScreen && <Label value={ylabel} position="insideLeft" angle={-90} offset={15} />}
					</YAxis>
					{lines.some((line) => line.yAxis === "secondary") && (
						<YAxis yAxisId="secondary" orientation="right">
							{largeScreen && <Label value={ylabelSecondary} position="insideRight" angle={-90} offset={15} />}
						</YAxis>
					)}
					{lines.some((line) => line.booleanField) && <YAxis yAxisId="boolean" hide />}
					{lines.some((line) => line.topThickLine) && <YAxis yAxisId="topThickLine" domain={[0, 25]} reversed hide />}
					{lines.some((line) => line.bottomThickLine) && <YAxis yAxisId="bottomThickLine" domain={[0, 25]} hide />}

					<CartesianGrid strokeDasharray="3 3" />
					<Tooltip
						formatter={(value: ValueType, name: NameType, item) => {
							if (typeof value !== "number") return [] //Empty array means no label or value while an empty string is default label and no value
							const line = lines.find((line) => line.id === item?.dataKey)
							if (line?.tooltipFormater != null) return line.tooltipFormater(value, name, item)
							if (line?.booleanField || line?.bottomThickLine || line?.topThickLine) return value ? "Ja" : []
							return value.toFixed(line?.decimals ?? 1)
						}}
						cursor={{ stroke: "grey", strokeWidth: 1 }}
						labelFormatter={(val) => formatXAxis(val)}
						allowEscapeViewBox={{ y: true }}
						itemStyle={{ marginBottom: "-7px" }}
					/>
					{largeScreen && (
						<Legend
							verticalAlign="middle"
							layout="vertical"
							align="right"
							iconType="plainline"
							content={(props: LegendProps) => <CustomizedLegend lines={lines} {...props} onHover={setHoveredLine} />}
						/>
					)}
					{lines.flatMap((line) => {
						if (line.active != null && !line.active) return []
						if (line.booleanField)
							return (
								<Area
									key={line.id}
									dot={false}
									dataKey={line.id}
									opacity={0.15}
									name={line.label}
									isAnimationActive={animation}
									type="step"
									stroke="none"
									fill={line.color}
									yAxisId="boolean"
								/>
							)
						if (line.bottomThickLine || line.topThickLine)
							return (
								<Area
									key={line.id}
									dot={false}
									dataKey={line.id}
									opacity={0.5}
									name={line.label}
									type="step"
									stroke="none"
									fill={line.color}
									isAnimationActive={animation}
									yAxisId={line.bottomThickLine ? "bottomThickLine" : "topThickLine"}
								/>
							)
						return (
							<Line
								key={line.id}
								dot={false}
								dataKey={line.id}
								strokeWidth={line.id === hoveredLine ? 2 * (line.thickness ?? 1.2) : line.thickness ?? 1.2}
								name={line.label}
								isAnimationActive={animation}
								stroke={line.color}
								yAxisId={line.yAxis ?? "primary"}
								unit={!line.yAxis || line.yAxis === "primary" ? ylabel : ylabelSecondary}
							/>
						)
					})}
					{zoomData.refAreaLeft && zoomData.refAreaRight ? (
						<ReferenceArea yAxisId="primary" x1={zoomData.refAreaLeft} x2={zoomData.refAreaRight} strokeOpacity={0.7} />
					) : null}
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	)
}
