import { PvtHeatPumpRoomControlType, PvtHeatPumpStatusFieldsFragment } from "generated/graphql"
import { useTranslation } from "react-i18next"
import NamedListItem from "pages/HeatPumpStatusPage/components/NamedListItem"

type Props = {
	data?: Pick<PvtHeatPumpStatusFieldsFragment, "dmSh" | "dmSc" | "roomControlType"> | null
}

export default function HeatPumpDmTemp({ data }: Props) {
	const { t } = useTranslation(["general", "pvtHeatPumpAdminPage"])

	if (data?.roomControlType !== PvtHeatPumpRoomControlType.DmControl) return

	return (
		<>
			<NamedListItem
				name={t("pvtHeatPumpAdminPage:DegreeMinutesHeating")}
				value={data?.dmSh != null ? `${String(data.dmSh)} ${t("DM")}` : t("unknown")}
			/>
			<NamedListItem
				name={t("pvtHeatPumpAdminPage:DegreeMinutesCooling")}
				value={data?.dmSc != null ? `${String(data.dmSc)} ${t("DM")}` : t("unknown")}
			/>
		</>
	)
}
