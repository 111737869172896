import { GraphQLError } from "graphql/error"
import { StatusMessageProps } from "components/BatchUpdate"
import { TFunction } from "i18next/index"

type FlexibleTFunction = TFunction<
	["pvtHeatPumpAdminPage" | "general" | "heatPumpSettingsPage" | "heatPumpErrorCard" | "heatPumpStatus"],
	undefined
>
interface Props {
	errorResponse: readonly GraphQLError[]
	handleSavingMessages: ((updateStatus: StatusMessageProps[]) => void) | undefined
	t: FlexibleTFunction
}

export default function handleSettingsError({ errorResponse, handleSavingMessages, t }: Props) {
	const errorsObject: StatusMessageProps[] = []

	errorResponse.forEach((error) => {
		const message = error.message
		const interfaceId = error?.extensions?.interfaceID

		if (typeof interfaceId === "string" && message && interfaceId) {
			errorsObject.push({
				interfaceId: interfaceId,
				errorMessage: (() => {
					switch (true) {
						case message.includes("interface not online"):
							return t("heatPumpErrorCard:InterfaceNotOnline.Title")

						case message.includes("error updating controller settings"):
							return t("pvtHeatPumpAdminPage:Errors.SavingControllerSettings")

						case message.includes("error updating interface"):
							return t("pvtHeatPumpAdminPage:Errors.SavingHeatPumpSettings")

						default:
							return message
					}
				})(),
			})

			if (handleSavingMessages) {
				handleSavingMessages(errorsObject)
			}
		}
	})
}
