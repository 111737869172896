import { Language } from "generated/graphql"

export const languageOrder = [Language.En, Language.De, Language.Nl]

export type Translation = {
	[lang in Language]?: string
}

export function pickBestLanguage(preferred: Language, trans: Translation) {
	if (trans[preferred]) return trans[preferred] ?? null
	const language = languageOrder.find((lang) => trans[lang])
	if (!language) return null
	return trans[language] ?? null
}
