import { Avatar, Button, Link, Box, Container, Typography, Grid } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Navigate } from "react-router-dom"
import { LockOutlined } from "@mui/icons-material"
import { CREATE_ACCOUNT } from "settings/url"
import { useAuth } from "providers/AuthProvider"
import { useMessage } from "providers/MessageProvider"
import useForm from "hooks/useForm"
import TextInput from "inputs/TextInput"
import useQueryParam from "hooks/useQueryParam"
import { Link as RouterLink } from "react-router-dom"
import { FORGOT_PASSWORD } from "settings/url"
import appendErrorMessage from "tools/appendErrorMessage"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Copyright } from "components/Copyright"
import FormLogo from "components/FormLogo"

const StyledAvatar = styled(Avatar)(({ theme }) => ({
	margin: theme.spacing(1),
	backgroundColor: theme.palette.secondary.main,
}))

const StyledContent = styled("div")(({ theme }) => ({
	marginTop: theme.spacing(8),
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
}))

const StyledForm = styled("form")(({ theme }) => ({
	width: "100%", // Fix IE 11 issue.
	marginTop: theme.spacing(1),
}))

const StyledSubmit = styled(Button)(({ theme }) => ({
	margin: theme.spacing(3, 0, 2),
}))

type Credentials = {
	email?: string
	password?: string
}

export default function SignIn() {
	const { t } = useTranslation(["signInPage", "general"])
	const auth = useAuth()
	const message = useMessage()
	const redirect = useQueryParam("redirect")
	const { register, submit } = useForm({ email: "", password: "" } as Credentials, handleSubmit)
	const [loading, setLoading] = useState(false)

	async function handleSubmit({ email, password }: Credentials) {
		try {
			setLoading(true)
			await auth.signIn(email ?? "", password ?? "")
			message.success(t("LoginSuccess"))
		} catch (e) {
			if (e instanceof Error) {
				if (e.message.includes("Wrong credentials")) {
					message.error(t("Errors.WrongCredentials"))
					return
				}
				if (e.message.includes("Too many requests")) {
					message.error(t("Errors.TooManyRequests"))
					return
				}
			}
			message.error(appendErrorMessage(t("Errors.LoginFailed"), e))
		} finally {
			setLoading(false)
		}
	}

	if (auth.user) {
		if (redirect) return <Navigate replace to={redirect} />
		return <Navigate replace to="/" />
	}

	return (
		<Container component="main" maxWidth="xs">
			<StyledContent>
				<FormLogo />
				<StyledAvatar>
					<LockOutlined />
				</StyledAvatar>
				<Typography component="h1" variant="h5">
					{t("Login")}
				</Typography>
				<StyledForm onSubmit={submit}>
					<TextInput
						variant="outlined"
						label={t("general:EmailAdress")}
						autoComplete="email"
						type="email"
						autoFocus
						{...register("email", { required: true, email: true })}
					/>
					<TextInput
						variant="outlined"
						label={t("general:Password")}
						type="password"
						autoComplete="current-password"
						{...register("password", { required: true })}
					/>
					<StyledSubmit type="submit" fullWidth variant="contained" color="primary" formNoValidate disabled={loading}>
						{t("Login")}
					</StyledSubmit>
					<Grid container justifyContent="space-between">
						<Grid item>
							<Link component={RouterLink} to={`/${FORGOT_PASSWORD}`}>
								{t("ForgotPassword")}
							</Link>
						</Grid>
						<Grid item>
							<Link component={RouterLink} to={`/${CREATE_ACCOUNT}${redirect ? `?redirect=${redirect}` : ""}`}>
								{t("CreateAccount")}
							</Link>
						</Grid>
					</Grid>
				</StyledForm>
			</StyledContent>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>
	)
}
