import { addSeconds } from "date-fns"
import {
	PvtHeatPumpDhwState,
	PvtHeatPumpSpaceHeatingCoolingState,
	PvtHeatPumpStatusFieldsFragment,
} from "generated/graphql"
import useTimer from "hooks/useTimer"
import { useEffect } from "react"

export type CompressorStartData = Pick<
	PvtHeatPumpStatusFieldsFragment,
	| "spaceHeatingCoolingState"
	| "dhwState"
	| "startupCountdown"
	| "startupTimeSec"
	| "compressorOffToOnDelaySec"
	| "compressorOffTime"
	| "pauseTimeMin"
	| "spaceHeatingCoolingPauseTime"
	| "dhwPauseTime"
	| "timestamp"
>

function timeCompressorWillStart(data: CompressorStartData) {
	if (!data) return

	const parseData = () => {
		if (
			//Startup timers
			(data.spaceHeatingCoolingState === PvtHeatPumpSpaceHeatingCoolingState.Startup ||
				data.dhwState === PvtHeatPumpDhwState.StartUp) &&
			data.startupCountdown != null &&
			data.startupTimeSec != null
		) {
			if (data.startupCountdown < data.startupTimeSec) {
				return data.startupCountdown
			}
			if (data.compressorOffToOnDelaySec != null && data.compressorOffTime != null) {
				return data.compressorOffToOnDelaySec - data.compressorOffTime
			}
		}

		//Pause timers
		if (data.pauseTimeMin == null) return
		if (
			//Space pause timers
			data.spaceHeatingCoolingState ===
				(PvtHeatPumpSpaceHeatingCoolingState.CoolPause || PvtHeatPumpSpaceHeatingCoolingState.HeatPause) &&
			data.spaceHeatingCoolingPauseTime != null
		) {
			return data.pauseTimeMin * 60 - data.spaceHeatingCoolingPauseTime
		}
		if (
			//DHW pause timers
			data.dhwState === PvtHeatPumpDhwState.Pause &&
			data.dhwPauseTime != null
		) {
			return data.pauseTimeMin * 60 - data.dhwPauseTime
		}
	}
	const timer = parseData()
	if (timer == null) return null
	return addSeconds(new Date(data.timestamp), timer)
}

function formatTimer(timeToGo: number) {
	const timerMinutes = String(Math.floor(timeToGo / 60) % 60).padStart(2, "0")
	const timerSeconds = String(Math.floor(timeToGo) % 60).padStart(2, "0")
	return `${timerMinutes}:${timerSeconds}`
}
type Props = {
	data: CompressorStartData
	onTimerEnd: () => void
}

export default function CompressorOffTimer({ data, onTimerEnd }: Props) {
	const [timer, setTimer] = useTimer(new Date(), onTimerEnd)
	useEffect(() => {
		const compressorStartTime = timeCompressorWillStart(data)
		if (compressorStartTime != null) setTimer(compressorStartTime)
	}, [data])

	if (timer >= 0) {
		return <>{formatTimer(timer)}</>
	}
	return null
}
