import { DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from "@mui/material"
import TSDialog from "components/TSDialog"
import { GroupFieldsFragment, useRemoveUserFromGroupMutation, useUpdateGroupAdminMutation } from "generated/graphql"
import { useMessage } from "providers/MessageProvider"
import LoadingBackdrop from "components/LoadingBackdrop"
import appendErrorMessage from "tools/appendErrorMessage"
import { ErrCanNotRemoveOwner } from "resources/backendErrors"
import { useFormatUserOrGroupString } from "components/FormatUserOrGroup"
import { Trans, useTranslation } from "react-i18next"

type Props = {
	onClose: () => void
	userId: string
	group: GroupFieldsFragment
}
export default function GroupMemberSettings({ group, userId, onClose }: Props) {
	const { t } = useTranslation(["groupManagement", "general"])
	const nameFormatter = useFormatUserOrGroupString()
	const [removeUser, { loading: removeUserLoading }] = useRemoveUserFromGroupMutation()
	const [setAdmin, { loading: setAdminLoading }] = useUpdateGroupAdminMutation()
	const message = useMessage()

	const handleRemoveUser = async () => {
		try {
			await removeUser({ variables: { groupId: group.id, userId } })
			message.success(t("MemberRemovedFromOrg"))
			onClose()
		} catch (e) {
			if (e instanceof Error && e.message.includes(ErrCanNotRemoveOwner)) {
				message.error(t("Errors.TransferAdminRightsBeforeRemovingUser"))
				return
			}
			message.error(appendErrorMessage(t("Errors.UnkownErrorRemovingUser"), e))
		}
	}

	const handleChangeUserRights = async (admin: boolean) => {
		try {
			await setAdmin({ variables: { admin, groupId: group.id, userId } })
			message.success(t("AdminRightsChanged"))
			onClose()
		} catch (e) {
			message.error(appendErrorMessage(t("Errors.UnkownErrorChangingRights"), e))
		}
	}

	// user should always be in the member list, if not, close window
	const member = group.members.find(({ id }) => id === userId)
	if (!member) {
		onClose()
		return null
	}

	return (
		<>
			<TSDialog open onClose={onClose}>
				<DialogTitle>{t("UserSettings")}</DialogTitle>
				<DialogContent>
					<DialogContentText variant="h6">{t("AdminRights")}</DialogContentText>
					<DialogContentText>
						<Trans
							i18nKey="MsgDisplayUserRights"
							ns="groupManagement"
							context={member.isGroupAdmin ? "admin" : ""}
							values={{
								groupName: nameFormatter(group),
								memberName: nameFormatter(member),
							}}
							components={{ italic: <i /> }}
						/>
					</DialogContentText>
					<br />
					<Button
						variant="contained"
						fullWidth
						onClick={() => handleChangeUserRights(!member.isGroupAdmin)}
						disabled={setAdminLoading}
					>
						{t(member.isGroupAdmin ? "ChangeRightsButton_revoke" : "ChangeRightsButton_grant")}
					</Button>
				</DialogContent>
				<DialogContent>
					<DialogContentText variant="h6">{t("RemoveUser")}</DialogContentText>
					<DialogContentText>
						<Trans
							i18nKey="MsgWantToRemoveUser"
							ns="groupManagement"
							values={{
								groupName: nameFormatter(group),
								memberName: nameFormatter(member),
							}}
							components={{ italic: <i /> }}
						/>
					</DialogContentText>
					<br />
					<Button variant="contained" color="error" fullWidth onClick={handleRemoveUser} disabled={removeUserLoading}>
						{t("general:Delete")}
					</Button>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => onClose()} variant="outlined">
						{t("general:Cancel")}
					</Button>
				</DialogActions>
			</TSDialog>
			{(removeUserLoading || setAdminLoading) && <LoadingBackdrop onClick={onClose} />}
		</>
	)
}
