import { DatePicker } from "@mui/x-date-pickers"

export type Props = {
	size?: "medium" | "small"
	fullWidth?: boolean
	required?: boolean
	value?: string | null
	error?: boolean
	helperText: string | null
	label?: string
	onChange: (date: string | null) => void
	clearable?: boolean
}

export default function DateInput({
	size = "medium",
	fullWidth = true,
	required = false,
	label,
	onChange,
	value,
	error,
	helperText,
	clearable,
}: Props) {
	const handleChange = (date: Date | null) => {
		if (isNaN(date?.getTime() ?? 0)) {
			onChange("Invalid Date")
			return
		}
		onChange(date?.toISOString() ?? null)
	}

	return (
		<DatePicker
			format="dd-MM-yyyy"
			label={label}
			value={value ? new Date(value) : null}
			slotProps={{
				textField: {
					error,
					helperText,
					required,
					margin: "normal",
					fullWidth: fullWidth,
					size: size,
				},
				actionBar: {
					actions: clearable ? ["clear"] : [],
				},
			}}
			onChange={handleChange}
		/>
	)
}
