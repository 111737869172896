import { useEffect, useRef, useState } from "react"

export default function useInView<T extends HTMLElement>(
	callback?: (isIntersecting: boolean) => void
): { ref: React.Ref<T>; inView: boolean; wasInView: boolean } {
	const ref = useRef<T | null>(null)
	const last = useRef<boolean>(false)
	const [inView, setInView] = useState(false)
	const [wasInView, setWasInView] = useState(false)

	useEffect(() => {
		if (!ref.current) return
		if ("IntersectionObserver" in window) {
			const observer = new IntersectionObserver(([e]) => {
				if (last.current !== e.isIntersecting) {
					if (e.isIntersecting) {
						setWasInView(true)
					}
					last.current = e.isIntersecting
					setInView(e.isIntersecting)
					if (callback) callback(e.isIntersecting)
				}
			})
			observer.observe(ref.current)
		} else {
			// fallback to allways in viewport
			last.current = true
			setInView(true)
			if (callback) callback(true)
		}
	}, [callback])

	return { ref, inView, wasInView }
}
