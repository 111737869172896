import ProgressStatus, { Status } from "components/ProgressStatus"
import { format } from "date-fns"
import { enGB } from "date-fns/locale"
import { UpdateProgress, useGetCurrentUpdateStatusQuery } from "generated/graphql"
import { useEffect, useState } from "react"

type Props = {
	interfaceId: string
}
export default function FirmwareProgress({ interfaceId }: Props) {
	const [polling, setPolling] = useState(false)
	const { data: currentStatus } = useGetCurrentUpdateStatusQuery({
		variables: { interfaceId },
		pollInterval: polling ? 1000 : 0,
	})

	useEffect(() => {
		if (currentStatus?.interface?.updateStatus != null) {
			setPolling(
				currentStatus.interface.updateStatus.progress === UpdateProgress.InProgress ||
					currentStatus.interface.updateStatus.progress === UpdateProgress.Requested
			)
		}
	}, [currentStatus])

	if (currentStatus?.interface?.updateStatus == null) return null

	return (
		<ProgressStatus
			percentage={currentStatus.interface.updateStatus.percentage ?? undefined}
			text={`${format(new Date(currentStatus.interface.updateStatus.time), "pp", { locale: enGB })}:
				${currentStatus.interface.updateStatus.message}`}
			status={
				(() => {
					switch(currentStatus.interface.updateStatus.progress) {
						case UpdateProgress.InProgress:
						case UpdateProgress.Requested:
							return Status.InProgress
						case UpdateProgress.Completed:
							return Status.Completed
						case UpdateProgress.Failed:
							return Status.Error
					}
				})()
			}
		/>
	)
}
