import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

export default function FormLogo() {
	const theme = useTheme()
	const { t } = useTranslation()
	return (
		<img
			src="/logo.png"
			alt={t("TripleSolar")}
			loading="eager"
			width="80%"
			style={{ marginBottom: theme.spacing(2) }}
		/>
	)
}
