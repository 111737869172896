import { Skeleton } from "@mui/material"
import { MarkdownToJSX } from "markdown-to-jsx"
import { Suspense, lazy } from "react"

const Markdown = lazy(() => import("markdown-to-jsx")) //Question for review: This is different from QR-reader which is exported in a new file. But this seems to work. Am I missing something?
type Props = {
	children: string
	options?: MarkdownToJSX.Options
}
export default function LazyMarkdown({ children, options }: Props) {
	return (
		<Suspense
			fallback={
				<>
					<Skeleton width="75%" />
					<Skeleton width="50%" />
				</>
			}
		>
			<Markdown options={options}>{children}</Markdown>
		</Suspense>
	)
}
