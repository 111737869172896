import { styled } from "@mui/material/styles"
import { CircularProgress, Fab, PropTypes, Stack } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

const StyledFab = styled(Fab)(({ theme }) => ({
	display: "flex",
	position: "sticky",
	marginLeft: "auto",
	marginRight: "auto",
	marginTop: theme.spacing(1),
}))

interface Props {
	children: React.ReactNode
	submit: () => void
	color?: PropTypes.Color
	loading?: boolean
	batchLoading?: boolean
	fitBottom?: boolean
}

export default function FloatingFab({
	children,
	submit,
	color = "primary",
	loading,
	fitBottom = false,
	batchLoading = false,
}: Props) {
	const { t } = useTranslation([])

	return (
		<StyledFab variant="extended" onClick={submit} color={color} disabled={loading} sx={{ bottom: fitBottom ? 0 : 63 }}>
			{batchLoading ? (
				<Stack direction="row" spacing={2}>
					<CircularProgress size={20} sx={{ color: "#fff" }} />
					<span>{t("general:Loading")}</span>
				</Stack>
			) : (
				<Stack direction="row" spacing={1}>
					{children}
				</Stack>
			)}
		</StyledFab>
	)
}
