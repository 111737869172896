import { BatchUpdateInterfaceFieldsFragment, UpdateProgress } from "generated/graphql"
import { differenceInSeconds } from "date-fns"

const MAX_SECONDS_NO_UPDATE = 120

export function isUpdateStuck(iface: Partial<BatchUpdateInterfaceFieldsFragment>) {
	const lastUpdateTime = iface?.updateStatus?.time

	if (iface.updateStatus?.progress !== UpdateProgress.InProgress || !lastUpdateTime) return false

	const lastUpdateDate = new Date(lastUpdateTime).getTime()
	const now = Date.now()

	return differenceInSeconds(now, lastUpdateDate) > MAX_SECONDS_NO_UPDATE
}
