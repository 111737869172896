import { Button, DialogContent, DialogTitle, Stack, Typography } from "@mui/material"
import TSDialog from "components/TSDialog"
import RadioInput from "inputs/RadioInput"
import { BackupHeaterType } from "generated/graphql"
import { useTranslation } from "react-i18next"
import { useMemo, useState } from "react"
import { StepperProps } from "../index"
import SearchHeatPump from "components/SearchHeatPump"
import { getStaticLables } from "tools/getStaticLabels"

export default function UpsertCascade({
	onClose,
	register,
	fields,
	data,
	loading,
	handleSetSlave,
	handleAddInterface,
}: StepperProps) {
	const { t, i18n } = useTranslation(["heatPumpSettingsPage", "heatPumpStatus", "general"])
	const [addHeatPumpOpen, setAddHeatPumpOpen] = useState(false)

	if (!register || !handleAddInterface || !data || !fields) return

	const { READABLE_BACKUP_HEATER_HIDDEN, HIDDEN_SETTING_TEXT } = useMemo(() => getStaticLables(t), [i18n.language])

	// Different naming in the wizard
	const READABLE_BACKUP_HEATER = {
		CASCADE: t("PVTHeatPump"),
		OPENTHERM: t("OTBoiler"),
	}

	return (
		<TSDialog open onClose={onClose} sx={{ margin: 5 }}>
			<DialogTitle>{t("HeatPumpSetupExpansion")}</DialogTitle>

			<DialogContent>
				<RadioInput
					label={t("ChooseHeatingDevice")}
					options={READABLE_BACKUP_HEATER}
					hiddenOptions={READABLE_BACKUP_HEATER_HIDDEN}
					disabled={loading}
					hiddenOptionHelperText={HIDDEN_SETTING_TEXT}
					row
					{...register("extendedBackupHeater", { required: true })}
				/>

				{fields.extendedBackupHeater === BackupHeaterType.Opentherm && !data?.interface?.openThermBoilerConnected && (
					<>
						<Typography color="primary">{t("heatPumpStatus:NoOtherOTFound")}</Typography>
					</>
				)}

				<Stack direction="row" justifyContent="space-between" mt={2}>
					<Button variant="outlined" onClick={onClose} disabled={loading}>
						{t("general:Close")}
					</Button>

					{fields.extendedBackupHeater === BackupHeaterType.Opentherm && (
						<Button
							variant="contained"
							onClick={() => handleAddInterface(false)}
							disabled={loading || !data?.interface?.openThermBoilerConnected}
						>
							{t("general:Save")}
						</Button>
					)}
					{fields.extendedBackupHeater === "CASCADE" && (
						<Button variant="contained" onClick={() => setAddHeatPumpOpen(true)} disabled={loading}>
							{t("general:Add")}
						</Button>
					)}
				</Stack>

				{addHeatPumpOpen && handleSetSlave && (
					<SearchHeatPump
						navigateToPage={false}
						isDialog
						onClose={() => {
							handleAddInterface(false)
							setAddHeatPumpOpen(false)
						}}
						onClaimInterface={handleSetSlave}
					/>
				)}
			</DialogContent>
		</TSDialog>
	)
}
