import { useMemo, useState } from "react"
import Table, { Label } from "components/Table"
import UpsertUser from "components/forms/UpsertUser"
import { Typography } from "@mui/material"
import roles from "settings/roles"
import { UserFieldsFragment, useUsersQuery } from "generated/graphql"
import { useTranslation } from "react-i18next"
import i18n, { TFunction } from "i18next"
import Fuse from "fuse.js"
import AddButton from "components/AddButton"

function getLabels(t: TFunction<["general", "groupManagement"]>): Label<UserFieldsFragment>[] {
	return [
		{
			key: "name",
			name: t("Name"),
			sortable: true,
			resolve: (user) => user.name,
		},
		{ key: "email", sortable: true, name: t("Email") },
		{
			key: "phoneNumbers",
			sortable: true,
			name: t("PhoneNumbers"),
			resolve: ({ phoneNumbers }) => phoneNumbers.join(", "),
		},
		{
			key: "group",
			name: t("groupManagement:Group"),
			sortable: true,
			resolve: ({ group }) => (group?.isOrganization ? group.name : t("groupManagement:IndividualUser")),
		},
		{
			key: "roles",
			name: t("Roles"),
			sortable: true,
			resolve: ({ roles: inputRoles }) => inputRoles?.map((role) => roles[role]).join(", ") ?? t("UnknownRoles"),
		},
		{
			key: "hasAccount",
			name: t("Account"),
			sortable: true,
			resolve: ({ hasAccount }) => (hasAccount ? t("Yes") : t("No")),
		},
		{
			key: "isOrganization",
			name: "Organisation",
			sortable: true,
			resolve: ({ group }) => (group?.isOrganization ? t("Yes") : t("No")),
		},
	]
}

export interface SearchProps {
	search: string
}

const options = {
	includeScore: true,
	threshold: 0.4,
	keys: [
		"email",
		"lastName",
		"firstName",
		"name",
		"phoneNumbers",
		"roles",
		"group.name",
		"hasAccount",
		"group.isOrganization",
	],
	getFn: (obj: UserFieldsFragment | null, path: string | string[]) => {
		const value = Fuse.config.getFn(obj, path)

		if (path.includes("hasAccount")) {
			return obj?.hasAccount ? i18n.t("Yes") : i18n.t("No")
		}

		if (path.includes("isOrganization")) {
			return obj?.group?.isOrganization ? i18n.t("Yes") : i18n.t("No")
		}

		return value
	},
}

export default function UsersPage({ search }: SearchProps) {
	const { t, i18n } = useTranslation()
	const { data, loading, error } = useUsersQuery()
	const labels: Label<UserFieldsFragment>[] = useMemo(() => getLabels(t), [i18n.language])
	const [addDialogOpen, setAddDialogOpen] = useState(false)
	const [userIx, setUserIx] = useState<number | null>()

	const fuse = new Fuse(data?.users ?? [], options)
	const users = search ? fuse.search(search).map(({ item }) => item) : data?.users ?? []

	const handleEdit = (id: string) => {
		const foundUserIx = users.findIndex((u) => u?.id === id)
		setUserIx(foundUserIx)
		setAddDialogOpen(true)
	}

	if (loading) return <Typography>{t("FetchingUsers")}</Typography>
	if (error) return <Typography>{t("Errors.FetchingUsers")}</Typography>
	return (
		<>
			<AddButton onClick={() => setAddDialogOpen(true)}>{t("AddUser")}</AddButton>
			<Table defaultItemsPerPage={20} rows={users} labels={labels} loading={loading} onClickRow={handleEdit} />
			{addDialogOpen && (
				<UpsertUser
					user={userIx != null && userIx >= 0 ? users.at(userIx) : null}
					onClose={() => {
						setUserIx(null)
						setAddDialogOpen(false)
					}}
				/>
			)}
		</>
	)
}
