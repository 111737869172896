import { Line as LineData } from "./line"
import { Surface } from "recharts"
import { Typography } from "@mui/material"
import { Props as LegendProps } from "recharts/types/component/DefaultLegendContent"

type Margin = {
	top?: number
	bottom?: number
	left?: number
	right?: number
}

type Props = LegendProps & {
	lines: LineData[]
	onHover?: (line: string) => void
	margin?: Margin
}

const SIZE = 32

export default function CustomizedLegend({ lines, onHover, margin, payload }: Props) {
	const groups = [...new Set([...lines.map(({ group }) => group)])] // unique groups

	return (
		<div
			style={{
				marginTop: margin?.top ?? 0,
				marginBottom: margin?.bottom ?? 0,
				marginLeft: margin?.left ?? 0,
				marginRight: margin?.right ?? 0,
			}}
		>
			{groups.map((group?: string) => (
				<div key={group ?? `group-${lines[0].id}`}>
					<Typography style={{ fontWeight: 500, marginBottom: 8 }}>{group ?? ""}</Typography>
					<ul style={{ padding: 0, margin: 0 }} className="recharts-default-legend">
						{lines
							.filter((line) => line.group === group)
							.map((line) => {
								const data = payload?.find((p) => p?.dataKey === line.id)
								if (!data) return null
								const viewBox = { x: 0, y: 0, width: SIZE, height: SIZE }
								const itemStyle = {
									display: "block",
									marginRight: 10,
									marginBottom: 4,
								}
								const svgStyle = { display: "inline-block", verticalAlign: "middle", marginRight: 4 }
								return (
									<li
										key={line.id}
										style={itemStyle}
										onMouseEnter={() => {
											if (onHover) onHover(line.id)
										}}
										onMouseLeave={() => {
											if (onHover) onHover("")
										}}
									>
										<Surface width={10} height={10} style={svgStyle} viewBox={viewBox}>
											<line
												strokeWidth={4.5}
												fill="none"
												stroke={data.color}
												x1={0}
												y1={SIZE / 2}
												x2={SIZE}
												y2={SIZE / 2}
												className="recharts-legend-icon"
											/>
										</Surface>
										<span key={line.id} style={{ color: data?.color ?? "inherit" }}>
											{line.label}
										</span>
									</li>
								)
							})}
					</ul>
				</div>
			))}
		</div>
	)
}
