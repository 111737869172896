import { CircularProgress, Grid, styled } from "@mui/material"
import { lazy, Suspense } from "react"
const QrReader = lazy(() => import("./QrReader"))

const StyledCircularProgress = styled(CircularProgress)`
	margin-left: auto;
	margin-right: auto;
	width: 50%;
`

export default function LazyQrReader(props: QrReader.props) {
	return (
		<Suspense
			fallback={
				<Grid container justifyItems="center" justifyContent="center">
					<Grid item>
						<StyledCircularProgress />
					</Grid>
				</Grid>
			}
		>
			<QrReader {...props} />
		</Suspense>
	)
}
