import { Button, CircularProgress, Grid, Stack, Typography } from "@mui/material"
import {
	PvtHeatPumpFlushingMode,
	PvtHeatPumpInput,
	PvtHeatPumpSettingsFieldsWizardFragment,
	PvtHeatPumpSettingsFieldsWizardFragmentDoc,
	UpdatePvtHeatPumpSettingsMutation,
	UpdatePvtHeatPumpSettingsMutationVariables,
	useUpdatePvtHeatPumpSettingsMutation,
} from "generated/graphql"
import { useMessage } from "providers/MessageProvider"
import { styled } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { StepperProps } from "../index"
import { ApolloCache, InMemoryCache } from "@apollo/client"
import HeatPumpAnimation from "components/HeatPumpAnimation"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"

interface StyledImageProps {
	component?: string
}

const StyledCardMedia = styled(HeatPumpAnimation)<StyledImageProps>(() => ({
	objectFit: "contain",
	maxHeight: "300px",
	marginLeft: "auto",
	marginRight: "auto",
}))

function updatePvtHeatPumpCache(
	cache: ApolloCache<InMemoryCache>,
	{ data }: { data?: UpdatePvtHeatPumpSettingsMutation | null },
	{ variables }: { variables?: UpdatePvtHeatPumpSettingsMutationVariables },
) {
	if (!data?.updatePvtHeatPump || !variables) return
	const { pvtHeatPumpdata: inputData, interfaceIds } = variables
	const id = `PvtHeatPumpData:${interfaceIds?.at(0)}`
	const oldPvtHeatPump = cache.readFragment<PvtHeatPumpSettingsFieldsWizardFragment>({
		id,
		fragment: PvtHeatPumpSettingsFieldsWizardFragmentDoc,
		fragmentName: "PvtHeatPumpSettingsFieldsWizard",
	})
	if (!oldPvtHeatPump) return

	const newPvtHeatPump = (Object.keys(oldPvtHeatPump) as Array<keyof PvtHeatPumpSettingsFieldsWizardFragment>).reduce(
		(acc, key) => ({ ...acc, [key]: inputData[key as keyof PvtHeatPumpInput] ?? oldPvtHeatPump[key] }),
		{} as PvtHeatPumpSettingsFieldsWizardFragment,
	)

	cache.writeFragment<PvtHeatPumpSettingsFieldsWizardFragment>({
		id,
		fragment: PvtHeatPumpSettingsFieldsWizardFragmentDoc,
		fragmentName: "PvtHeatPumpSettingsFieldsWizard",
		data: newPvtHeatPump,
	})
}

export default function FlushPump({
	data,
	error,
	steps,
	BottomNavigation,
	nextStep,
	backStep,
	step,
	loading,
}: StepperProps) {
	const { t } = useTranslation(["heatPumpSettingsPage", "general"])
	const message = useMessage()
	const { interfaceId } = useParams<"interfaceId">()

	if (!interfaceId) return

	const [updatePVTHeatPumpSettings, { loading: updateLoading }] = useUpdatePvtHeatPumpSettingsMutation({
		update: updatePvtHeatPumpCache,
	})

	const toggleFlushingMode = async () => {
		let status
		if (data?.interface?.pvtHeatPump?.flushingMode === PvtHeatPumpFlushingMode.Flushing) {
			status = PvtHeatPumpFlushingMode.Off
		} else {
			status = PvtHeatPumpFlushingMode.Flushing
		}

		try {
			if (!interfaceId) throw new Error(t("general:InterfaceIDNotFound"))
			await updatePVTHeatPumpSettings({
				variables: {
					interfaceIds: [interfaceId],
					pvtHeatPumpdata: { flushingMode: status },
					controllerSettings: {},
					isExpert: false,
					isAdmin: false,
				},
			})
		} catch (e) {
			message.error(t("heatPumpSettingsPage:Errors.UnkownSaving"))
		}
	}

	const isFlushing = data?.interface?.pvtHeatPump?.flushingMode === PvtHeatPumpFlushingMode.Flushing
	const groupedLoading = updateLoading || loading

	if (error && interfaceId) {
		return <ErrorCard error={error} interfaceId={interfaceId} />
	}

	return (
		<Grid container spacing={2} alignItems="center" justifyContent="center">
			<Grid item xs={12} md={7}>
				<Typography variant="h6" gutterBottom>
					{t("CheckOnAir")}
				</Typography>
				<Typography variant="body1" gutterBottom>
					{t("StartCheckOnAir")}
				</Typography>

				<Stack direction="row" alignItems="center" spacing={2}>
					<Button onClick={toggleFlushingMode} disabled={groupedLoading} variant="contained">
						{groupedLoading && <CircularProgress size="1rem" color="inherit" sx={{ mr: 1 }} />}
						{data?.interface?.pvtHeatPump?.flushingMode === PvtHeatPumpFlushingMode.Flushing
							? t("general:StopRinsing")
							: t("general:StartRinsing")}
					</Button>

					{data?.interface?.pvtHeatPump?.flushingMode === PvtHeatPumpFlushingMode.Flushing && (
						<Typography color="primary">{t("RinsingInProgress")}</Typography>
					)}
				</Stack>
				{data?.interface?.pvtHeatPump?.flushingMode === PvtHeatPumpFlushingMode.Flushing && (
					<Typography sx={{ mt: 1 }} color="primary">
						{t("RestartingPumpsRinsing")}
					</Typography>
				)}
			</Grid>
			<Grid item md={5} sx={{ textAlign: "center" }} mt={4}>
				<StyledCardMedia animate={data?.interface?.pvtHeatPump?.flushingMode === PvtHeatPumpFlushingMode.Flushing} />
			</Grid>

			<Grid item xs={12}>
				{BottomNavigation && (
					<BottomNavigation
						disabled={groupedLoading || isFlushing}
						steps={steps}
						step={step}
						backStep={backStep}
						nextStep={nextStep}
					/>
				)}
			</Grid>
		</Grid>
	)
}
