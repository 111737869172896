import { useEffect, useState } from "react"

interface HeatPumpAnimationProps {
	animate: boolean
}
export default function HeatPumpAnimation({ animate = false }: HeatPumpAnimationProps) {
	const [animateKey, setAnimateKey] = useState(0)

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setAnimateKey((prevKey) => prevKey + 1)
		}, 7000)

		return () => clearTimeout(timeoutId)
	}, [animateKey])

	return (
		<svg
			key={animateKey} //to reset animation rerender svg
			width="299px"
			height="359px"
			viewBox="0 0 299 359"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Artboard" transform="translate(-102, -52)">
					<g id="containerHeatpump" transform="translate(102, 52)">
						{animate && (
							<>
								<rect
									id="6"
									fill="#dc2e2e"
									transform="translate(116.5, 236) translate(-116.5, -236)"
									x="80"
									y="215"
									width="40"
									height="34"
								>
									<animate attributeName="x" from="80" to="120" dur="2s" begin="5s" fill="freeze" />
								</rect>

								<rect id="5" fill="#dc2e2e" x="99" y="6" width="32" height="125">
									<animate attributeName="y" from="0" to="120" dur="2s" begin="4s" fill="freeze" />
								</rect>

								<rect
									id="4"
									fill="#dc2e2e"
									transform="translate(176.5, 121) translate(-176.5, -121)"
									x="165"
									y="108"
									width="34"
									height="23"
								>
									<animate attributeName="x" from="165" to="135" dur="2s" begin="3s" fill="freeze" />
								</rect>

								<rect
									id="3"
									fill="#7AA5FF"
									transform="translate(191.5, 115) translate(-191.5, -115)"
									x="170"
									y="105"
									width="34"
									height="23"
								>
									<animate attributeName="x" from="170" to="200" dur="2s" begin="3s" fill="freeze" />
								</rect>

								<rect
									id="2"
									fill="#7AA5FF"
									transform="translate(187.5, 220) translate(-187.5, -220)"
									x="156"
									y="208"
									width="34"
									height="23"
								>
									<animate attributeName="x" from="170" to="200" dur="2s" begin="2s" fill="freeze" />
								</rect>

								<rect id="1" transform="translate(215, 241)" fill="#7AA5FF" width="32" height="120">
									<animate attributeName="y" from="0" to="-120" dur="2s" begin="0s" fill="freeze" />
								</rect>
							</>
						)}

						<image
							id="Bitmap"
							x="55"
							y="1"
							width="194.914705"
							height="357"
							xlinkHref="/images/heatPumpBluePrint.png"
						></image>
					</g>
				</g>
			</g>
		</svg>
	)
}
