import InterfaceUpdateSelector from "components/InterfaceUpdateSelector"
import {
	GetCurrentUpdateStatusDocument,
	InterfaceFirmwareVersion,
	UpdateProgress,
	useGetCurrentInterfaceFirmwareVersionInternalQuery,
	useGetCurrentUpdateStatusQuery,
	useInterfaceNameQuery,
	useUpdateInterfaceFirmwareMutation,
} from "generated/graphql"
import { useEffect } from "react"
import appendErrorMessage from "tools/appendErrorMessage"
import { useTranslation } from "react-i18next"
import { useMessage } from "providers/MessageProvider"
import { useConfirm } from "providers/ConfirmProvider"

type Props = {
	interfaceId: string
}
export default function InterfaceSingleUpdate({ interfaceId }: Props) {
	const { t } = useTranslation("pvtHeatPumpAdminPage")
	const message = useMessage()
	const confirm = useConfirm()

	const { data: interfaceName } = useInterfaceNameQuery({
		variables: { interfaceId: interfaceId },
	})
	const [updateInterface] = useUpdateInterfaceFirmwareMutation({
		refetchQueries: [{ query: GetCurrentUpdateStatusDocument, variables: { interfaceId: interfaceId } }],
	})

	const {
		data: currentFirmware,
		loading: currentFirmwareLoading,
		refetch: refetchCurrentFirmware,
	} = useGetCurrentInterfaceFirmwareVersionInternalQuery({
		variables: { interfaceId: interfaceId },
	})

	const { data: currentUpdateStatus } = useGetCurrentUpdateStatusQuery({
		variables: { interfaceId: interfaceId },
	})

	useEffect(() => {
		if (currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.Completed) {
			refetchCurrentFirmware()
		}
	}, [currentUpdateStatus])

	const handleUpdateInterface = async (update: InterfaceFirmwareVersion) => {
		await confirm(
			t("MsgConfirmInterfaceUpdate", {
				interfaceName: interfaceName?.interface?.name ?? interfaceId,
				firmwareVersionName: update.version,
			}),
		)

		try {
			await updateInterface({
				variables: { interfaceIds: interfaceId, firmwareId: update.id },
			})
			message.success(t("InterfaceUpdateStarted"))
		} catch (e) {
			message.error(appendErrorMessage(t("Errors.UpdatingFirmware"), e))
		}
	}

	return (
		<InterfaceUpdateSelector
			currentUpdateStatus={currentUpdateStatus}
			handleUpdateInterface={handleUpdateInterface}
			currentFirmware={currentFirmware}
			loading={currentFirmwareLoading}
		/>
	)
}
