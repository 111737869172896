import { Avatar, Button, Box, Container, Typography, Grid } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Navigate } from "react-router-dom"
import { EmailOutlined, LockOutlined } from "@mui/icons-material"
import { useAuth } from "providers/AuthProvider"
import { useMessage } from "providers/MessageProvider"
import useForm from "hooks/useForm"
import TextInput from "inputs/TextInput"
import useQueryParam from "hooks/useQueryParam"
import { ForgotPassword } from "tools/auth"
import { useState } from "react"
import { Copyright } from "components/Copyright"
import { useTranslation } from "react-i18next"
import FormLogo from "components/FormLogo"

const StyledAvatar = styled(Avatar)(({ theme }) => ({
	margin: theme.spacing(1),
	backgroundColor: theme.palette.secondary.main,
}))

const StyledContent = styled("div")(({ theme }) => ({
	marginTop: theme.spacing(8),
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
}))

const StyledForm = styled("form")(({ theme }) => ({
	width: "100%", // Fix IE 11 issue.
	marginTop: theme.spacing(1),
}))

const StyledSubmit = styled(Button)(({ theme }) => ({
	margin: theme.spacing(3, 0, 2),
}))

export default function ForgotPasswordPage() {
	const { t } = useTranslation(["accountCreation", "general"])
	const auth = useAuth()
	const message = useMessage()
	const [emailSentState, setEmailSentState] = useState<"start" | "sending" | "sent">("start")
	const redirect = useQueryParam("redirect")
	const { register, submit } = useForm<ForgotPassword>({ email: "" }, handleSubmit)

	async function handleSubmit(input: ForgotPassword) {
		try {
			setEmailSentState("sending")
			await auth.forgotPassword(input)
			setEmailSentState("sent")
		} catch (e) {
			console.error(e)
			message.error(t("Errors.WhileSendingEmail"))
			setEmailSentState("start")
		}
	}

	if (auth.user) {
		if (redirect) return <Navigate to={redirect} />
		return <Navigate to="/" />
	}

	if (emailSentState === "sent")
		return (
			<Container component="main" maxWidth="xs">
				<StyledContent>
					<FormLogo />
					<Grid container direction="column" spacing={5} alignItems="center" justifyContent="center">
						<Grid item>
							<StyledAvatar>
								<EmailOutlined />
							</StyledAvatar>
						</Grid>
						<Grid item>
							<Typography component="h1" variant="h5">
								{t("EmailSent")}
							</Typography>
						</Grid>
						<Grid item>
							<Typography align="center">{t("EmailIsOnlySendToKnowAdress")}</Typography>
						</Grid>
					</Grid>
				</StyledContent>
				<Box mt={8}>
					<Copyright />
				</Box>
			</Container>
		)

	return (
		<Container component="main" maxWidth="xs">
			<StyledContent>
				<FormLogo />
				<StyledAvatar>
					<LockOutlined />
				</StyledAvatar>
				<Typography component="h1" variant="h5">
					{t("ForgotPassword")}
				</Typography>
				<StyledForm onSubmit={submit}>
					<TextInput
						variant="outlined"
						label={t("general:EmailAdress")}
						autoComplete="email"
						type="email"
						autoFocus
						disabled={emailSentState === "sending"}
						{...register("email", { required: true, email: true })}
					/>
					<StyledSubmit
						type="submit"
						formNoValidate
						fullWidth
						variant="contained"
						color="primary"
						disabled={emailSentState === "sending"}
					>
						{t("general:SendEmail")}
					</StyledSubmit>
				</StyledForm>
			</StyledContent>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>
	)
}
