import { Box, useTheme } from "@mui/material"
import {
	PvtHeatPumpDhwState,
	PvtHeatPumpSpaceHeatingCoolingState,
	ReadHeatPumpStatusAppBarQuery,
	useReadHeatPumpStatusAppBarQuery,
} from "generated/graphql"
import { GetHeatPumpErrors } from "pages/HeatPumpStatusPage/components/HeatPumpInfoMessage"
import { useMemo, useState } from "react"
import HeatPumpBottomNavigationBar from "./HeatPumpBottomNavigationBar"
import Page, { PageProps } from "./Page"
import { useTranslation } from "react-i18next"

type Props = Omit<PageProps, "title"> & {
	statusFromNetwork?: boolean
	navigationName: "status" | "settings" | "monitoring" | "admin" | "management"
	interfaceId: string
}

export default function PageWithStatusBar({
	middle,
	backButton,
	children,
	hideTitle,
	hideSideMenu,
	noPadding,
	navigationName,
	statusFromNetwork,
	interfaceId,
}: Props) {
	const theme = useTheme()
	const { t, i18n } = useTranslation(["heatPumpStates", "general", "heatPumpErrors"])
	const heatPumpErrors = useMemo(() => GetHeatPumpErrors(), [i18n.language])
	const [statusKey, setStatusKey] = useState<
		| "general:Loading"
		| "Standby"
		| "Heating"
		| "Cooling"
		| "DHW.Heating"
		| "StartingUp"
		| "HeatPumpError"
		| "Errors.GettingState"
	>("general:Loading")
	const [statusColor, setStatusColor] = useState<string | null>(null)

	const heatingColor = theme.palette.success.main
	const coolingColor = theme.palette.info.main
	const idleColor = theme.palette.primary.main
	const errorColor = theme.palette.error.main
	const parseStatusData = (data: ReadHeatPumpStatusAppBarQuery) => {
		if (!data) return
		let text: typeof statusKey = "general:Loading"
		let color: string | null = null
		switch (data.interface?.pvtHeatPump?.spaceHeatingCoolingState) {
			case PvtHeatPumpSpaceHeatingCoolingState.Startup:
				text = "StartingUp"
				color = idleColor
				break
			case PvtHeatPumpSpaceHeatingCoolingState.Off:
			case PvtHeatPumpSpaceHeatingCoolingState.HeatPause:
			case PvtHeatPumpSpaceHeatingCoolingState.CoolPause:
				text = "Standby"
				color = idleColor
				break
			case PvtHeatPumpSpaceHeatingCoolingState.PassiveHeat:
			case PvtHeatPumpSpaceHeatingCoolingState.CombinedHeat:
			case PvtHeatPumpSpaceHeatingCoolingState.ActiveHeat:
			case PvtHeatPumpSpaceHeatingCoolingState.BackupHeat:
				text = "Heating"
				color = heatingColor
				break
			case PvtHeatPumpSpaceHeatingCoolingState.PassiveCool:
			case PvtHeatPumpSpaceHeatingCoolingState.CombinedCool:
			case PvtHeatPumpSpaceHeatingCoolingState.ActiveCool:
				text = "Cooling"
				color = coolingColor
				break
		}

		switch (data.interface?.pvtHeatPump?.dhwState) {
			case PvtHeatPumpDhwState.Active:
			case PvtHeatPumpDhwState.Backup:
			case PvtHeatPumpDhwState.Combined:
			case PvtHeatPumpDhwState.Passive:
				text = "DHW.Heating"
				color = idleColor
				break
			case PvtHeatPumpDhwState.StartUp:
				text = "StartingUp"
				color = idleColor
		}

		if (data.interface?.pvtHeatPump?.errors?.length) {
			for (const error of data.interface.pvtHeatPump.errors) {
				if (heatPumpErrors[error]?.fatal) {
					text = "HeatPumpError"
					color = errorColor
					break
				}
			}
		}

		setStatusKey(text)
		setStatusColor(color)
	}

	useReadHeatPumpStatusAppBarQuery({
		variables: { interfaceId },
		fetchPolicy: statusFromNetwork ? "cache-first" : "cache-only", //When loadingStatus changes, this refetches with "cache-first" to update with new values
		onCompleted: (data) => parseStatusData(data),
		onError: () => {
			setStatusKey("Errors.GettingState")
			setStatusColor(errorColor)
		},
	})

	return (
		<Page
			middle={middle}
			backButton={backButton}
			hideTitle={hideTitle}
			hideSideMenu={hideSideMenu}
			noPadding
			titleBarColor={statusColor}
			title={<>{t(statusKey)}</>}
		>
			<Box display="flex" flexDirection="column" height="100%">
				<Box height="100%" sx={{ padding: noPadding ? 0 : 2 }}>
					{children}
				</Box>
				<HeatPumpBottomNavigationBar value={navigationName} interfaceId={interfaceId} />
			</Box>
		</Page>
	)
}
