export function fileToBase64(file: File | Blob): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = async () => {
			if (typeof reader.result === "string") resolve(reader.result)
			else reject(new Error("Unexpected reader result type."))
		}
		reader.readAsDataURL(file)
	})
}
