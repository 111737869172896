import { DialogTitle, DialogContent, DialogActions, Button } from "@mui/material"
import TSDialog from "components/TSDialog"
import { GroupFieldsFragment, useInviteNewUserToGroupMutation } from "generated/graphql"
import useForm from "hooks/useForm"
import TextInput from "inputs/TextInput"
import { useMessage } from "providers/MessageProvider"
import appendErrorMessage from "tools/appendErrorMessage"
import LoadingBackdrop from "components/LoadingBackdrop"
import { ErrCantInviteToUnclaimedIface, ErrInviteToSelf } from "resources/backendErrors"
import { useFormatUserOrGroupString } from "components/FormatUserOrGroup"
import { Trans, useTranslation } from "react-i18next"

type Props = {
	group: Omit<GroupFieldsFragment, "members">
	onClose: () => void
}

export default function AddMemberDialog({ group, onClose }: Props) {
	const { t } = useTranslation(["groupManagement", "general"])
	const groupNameFormatter = useFormatUserOrGroupString()
	const handleAddUser = async ({ userEmail }: { userEmail: string }) => {
		try {
			await inviteUser({ variables: { groupId: group.id, email: userEmail } })
			message.success(t("MemberInvitedToOrg"))
			onClose()
		} catch (e) {
			if (e instanceof Error && e.message.includes("an account with this email address is already registered")) {
				message.error(t("Errors.OnlyNewUserCanBeInvited"))
				return
			}
			if (e instanceof Error && e.message.includes("email not valid")) {
				message.error(t("general:ThisEmailIsInvalid"))
				return
			}
			if (e instanceof Error && e.message.includes(ErrInviteToSelf)) {
				message.error(t("Errors.CanNotInviteSelf"))
				return
			}
			if (e instanceof Error && e.message.includes(ErrCantInviteToUnclaimedIface)) {
				message.error(t("Errors.ResidentShouldClaim"))
				return
			}

			message.error(appendErrorMessage(t("Errors.UnkownErrorInvite"), e))
		}
	}
	const { register, submit } = useForm<{ userEmail: string }>({ userEmail: "" }, handleAddUser)
	const [inviteUser, { loading }] = useInviteNewUserToGroupMutation()
	const message = useMessage()
	return (
		<TSDialog open onClose={onClose}>
			<form onSubmit={submit}>
				<DialogTitle>
					<Trans
						i18nKey="EnterEmailOfUserToInv"
						ns="groupManagement"
						values={{ groupName: groupNameFormatter(group) }}
						components={{ italic: <i /> }}
					/>
				</DialogTitle>
				<DialogContent>
					<TextInput
						autoFocus
						{...register("userEmail")}
						label={t("general:Email")}
						defaultValue="info@triplesolar.eu"
						type="email"
					/>
					{loading && <LoadingBackdrop onClick={onClose} />}
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose} variant="outlined">
						{t("general:Cancel")}
					</Button>
					<Button type="submit" disabled={loading} variant="contained" color="success">
						{t("general:Add")}
					</Button>
				</DialogActions>
			</form>
		</TSDialog>
	)
}
