import { ApolloError } from "@apollo/client"
import { CardContent, Skeleton, Stack, Typography } from "@mui/material"

import CustomCard from "components/CustomCard"
import { useInterfaceOwnerQuery } from "generated/graphql"
import { useTranslation } from "react-i18next"
import { ErrIfaceClaimed } from "resources/backendErrors"
import HeatPumpErrorAnimation from "../../../components/HeatPumpErrorAnimation"

type Props = {
	error?: ApolloError
	interfaceId: string
}

export default function ErrorCard({ error, interfaceId }: Props) {
	const { t } = useTranslation(["heatPumpErrorCard", "general"])
	const { data, loading } = useInterfaceOwnerQuery({
		variables: { interfaceId: interfaceId ?? "" },
		skip: !error?.message.includes(ErrIfaceClaimed),
	})

	return (
		<CustomCard>
			<CardContent>
				{(() => {
					if (error?.message.includes("interface not found"))
						return (
							<>
								<HeatPumpErrorAnimation doesNotExist />

								<Typography variant="h5" color="error">
									{t("MsgInterfaceNotFound")}
								</Typography>
							</>
						)
					if (error?.message.includes("interface not online"))
						return (
							<>
								<HeatPumpErrorAnimation notOnline />

								<Typography variant="h5" color="error" gutterBottom>
									{t("InterfaceNotOnline.Title")}
								</Typography>
								<Typography>{t("InterfaceNotOnline.CheckFollowing")}</Typography>
								<ul>
									<li>{t("InterfaceNotOnline.Reboot")}</li>
									<li>{t("InterfaceNotOnline.CheckPower")}</li>
									<li>{t("InterfaceNotOnline.GreenLED")}</li>
								</ul>
							</>
						)
					if (error?.message.includes("modbus error") && error.message.includes("response timeout"))
						return (
							<>
								<HeatPumpErrorAnimation notConnected />

								<Typography variant="h5" color="error" gutterBottom>
									{t("ModbusError.ResponseTimeout.Title")}
								</Typography>
								<Typography>{t("ModbusError.ResponseTimeout.CheckFollowing")}</Typography>
								<ul>
									<li>{t("ModbusError.ResponseTimeout.CheckPower")}</li>
									<li>{t("ModbusError.ResponseTimeout.CheckCable")}</li>
								</ul>
							</>
						)

					if (error?.message.includes("rate limiter"))
						return (
							<>
								<Typography variant="h5" color="error" gutterBottom>
									{t("RateLimiter.Title")}
								</Typography>
								<Typography gutterBottom>{t("RateLimiter.CheckTomorrow")}</Typography>
								<Typography>{t("RateLimiter.NoAutomation")}</Typography>
							</>
						)
					if (error?.message.includes(ErrIfaceClaimed)) {
						return (
							<>
								<Typography variant="h5" color="error" gutterBottom>
									{t("ErrIfaceClaimed.Title")}
								</Typography>
								<Typography>
									<Stack direction="row" spacing={0.25}>
										<Typography gutterBottom>{t("ErrIfaceClaimed.CurrentOwnerIs")}</Typography>
										<Typography>
											<i>{loading ? <Skeleton width={150} /> : data?.interfaceOwner?.name ?? t("general:unknown")}.</i>
										</Typography>
									</Stack>
									<Typography>{t("ErrIfaceClaimed.ContactOwner")}</Typography>
								</Typography>
							</>
						)
					}

					return (
						<>
							<Typography variant="h5" color="error">
								{t("UnkownError")}
							</Typography>
							<Typography>
								<i>{error instanceof Error && error.message}</i>
							</Typography>
						</>
					)
				})()}
			</CardContent>
		</CustomCard>
	)
}
