import { Checkbox, FormControl, FormControlLabel, FormHelperText, Stack } from "@mui/material"
import InputReset from "components/InputReset"

type Props = {
	reset?: () => void
	fieldIsChanged?: boolean
	value?: boolean | null
	onChange: (value: boolean) => void
	label?: React.ReactNode
	disabled?: boolean
	helperText?: string | null
	error?: boolean
}

export default function BooleanInput({
	reset,
	fieldIsChanged,
	value,
	onChange,
	label,
	disabled,
	helperText,
	error,
}: Props) {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.checked)
	}

	return (
		<FormControl error={error} component="fieldset" variant="standard">
			<Stack direction="row" alignItems="center" mr={2}>
				<FormControlLabel
					sx={{ mr: 0 }}
					control={
						<Checkbox
							color={fieldIsChanged ? "secondary" : undefined}
							checked={value ?? false}
							onChange={handleChange}
							disabled={disabled}
						/>
					}
					label={label}
				/>

				<FormHelperText>{helperText}</FormHelperText>

				{reset && fieldIsChanged && <InputReset reset={reset} isFieldChanged={fieldIsChanged} />}
			</Stack>
		</FormControl>
	)
}
