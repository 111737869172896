/* eslint-disable i18next/no-literal-string */ //Internal only
import { styled } from "@mui/material/styles"
import { useEffect, useState } from "react"
import { DialogTitle, DialogContent, Tooltip, DialogActions, Button } from "@mui/material"
import TSDialog from "components/TSDialog"
import { useAuth } from "providers/AuthProvider"
import { fileToBase64 } from "tools/imgHelpers"
import { Skeleton } from "@mui/material"
import useInView from "hooks/useInView"
import { useTranslation } from "react-i18next"

const PREFIX = "Image"

const classes = {
	img: `${PREFIX}-img`,
}

const Root = styled("div")({
	[`& .${classes.img}`]: {
		cursor: "pointer",
		maxWidth: "100%",
	},
})

type PropsImage = {
	src?: string
}

interface PropsLargeImage extends PropsImage {
	onClose: () => void
}

const LargeImage = ({ src, onClose }: PropsLargeImage) => {
	const { t } = useTranslation("general")

	return (
		<TSDialog
			onClick={(e) => {
				e.stopPropagation()
				//close fix since we use stopPropagation because of child onclick of parent onclick event
				const currentClick = e.target as HTMLInputElement
				if (currentClick?.childNodes?.length > 0) {
					const currentElement = currentClick.childNodes[0]

					if (
						currentElement instanceof HTMLElement &&
						currentElement.getAttribute("aria-labelledby") === "image-modal"
					) {
						onClose()
					}
				}
			}}
			autoFullScreen
			open
			onClose={onClose}
			aria-labelledby="image-modal"
			maxWidth="lg"
		>
			<DialogTitle id="form-dialog-title">{t("ViewImage")}</DialogTitle>
			<DialogContent>
				<img width="100%" src={src} alt={t("ImageNotFound")} />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary" variant="contained">
					{t("Close")}
				</Button>
			</DialogActions>
		</TSDialog>
	)
}

interface PropsSmallImage extends PropsImage {
	clickable?: boolean
}

const Image = ({ src, clickable = true }: PropsSmallImage) => {
	const { t } = useTranslation(["general"])
	const [url, setUrl] = useState<string | undefined>()

	const [modalOpen, setModalOpen] = useState(false)
	const auth = useAuth()
	const { ref, wasInView } = useInView<HTMLDivElement>()

	useEffect(() => {
		if (src && wasInView)
			auth
				.fetchAsset(src)
				.then(fileToBase64)
				.then(setUrl)
				.catch(() => setUrl(""))
	}, [src, auth, wasInView])

	return (
		<Root ref={ref}>
			{url ? (
				<Tooltip title={clickable ? t("EnlargeImage") : ""}>
					<img
						className={classes.img}
						src={url}
						alt={t("ImageNotFound")}
						onClick={(e) => {
							e.stopPropagation()
							setModalOpen(true)
						}}
					/>
				</Tooltip>
			) : (
				<Skeleton className={classes.img} variant="rectangular" height={225} />
			)}

			{modalOpen && <LargeImage src={url} onClose={() => setModalOpen(false)} />}
		</Root>
	)
}

export default Image
