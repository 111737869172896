import { InputBase, InputBaseProps, IconButton } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Edit, Save } from "@mui/icons-material"
import { useRef, useState } from "react"

const StyledRoot = styled("div")`
	display: inline-block;
	position: relative;
	min-width: 1em;
	width: min-content;
`

const StyledInput = styled(InputBase)`
	position: absolute;
	left: 0;
	width: 100%;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
`

const StyledCalibration = styled("span")`
	visibility: hidden;
	white-space: pre;
`

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	marginLeft: theme.spacing(1),
}))

export default function EditableField(props: InputBaseProps) {
	const [edit, setEdit] = useState(false)
	const inputRef = useRef<HTMLInputElement>(null)
	if (!edit) {
		return (
			<span onClick={() => setEdit(true)}>
				{String(props.value)}

				<StyledIconButton color="inherit" size="large">
					<Edit />
				</StyledIconButton>
			</span>
		)
	}
	return (
		<StyledRoot>
			{/* this calibration is only used to mimic the same content (width) and is not visible*/}
			<StyledCalibration>
				{String(props.value)}
				<StyledIconButton color="inherit" size="large" type="submit">
					<Save />
				</StyledIconButton>
			</StyledCalibration>

			<StyledInput
				{...props}
				autoFocus
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.preventDefault()
						inputRef.current?.blur()
					}
				}}
				onBlur={(e) => {
					setEdit(false)
					props.onBlur && props.onBlur(e)
				}}
				disabled={!edit}
				endAdornment={
					<StyledIconButton color="inherit" onClick={() => setEdit(false)} size="large">
						<Save />
					</StyledIconButton>
				}
				inputRef={inputRef}
			/>
		</StyledRoot>
	)
}
