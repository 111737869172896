import { Link, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export function Copyright() {
	const { t } = useTranslation()
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{t("CopyRight")}{" "}
			<Link color="inherit" href={t("TripleSolarURL")}>
				{t("TripleSolar")}
			</Link>{" "}
			{new Date().getFullYear()}.
		</Typography>
	)
}
