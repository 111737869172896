import Page from "components/Page"
import { styled } from "@mui/material/styles"
import { Box, Button, FormControlLabel, Stack, Switch, Typography } from "@mui/material"
import { InterfaceFieldsFragment, Role, useHeatPumpPageFilterQuery, useInterfacesQuery } from "generated/graphql"
import QuickTable, { SelectedInterfacesProps, Label } from "components/Table"
import StatusDot from "components/StatusDot"
import { differenceInMinutes } from "date-fns"
import { useNavigate } from "react-router-dom"
import { HEAT_PUMP } from "settings/url"
import React, { useMemo, useRef, useState } from "react"
import DebouncedMenuInput from "components/DebouncedMenuInput"
import Fuse from "fuse.js"
import useSize from "hooks/useSize"
import AddFab from "components/AddFab"
import SearchHeatPump from "components/SearchHeatPump"
import InterfaceClaimDialog from "components/forms/InterfaceClaimDialog"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { GetHeatPumpErrors } from "pages/HeatPumpStatusPage/components/HeatPumpInfoMessage"
import heatPumpListPageFilter, { emptyHeatPumpPageFilter } from "localstate/heatPumpListPageFilter"
import BatchUpdate from "components/BatchUpdate"
import useHasRole from "hooks/useHasRole"

const StyledMessage = styled(Typography)(({ theme }) => ({
	margin: theme.spacing(2),
}))

const StyledUL = styled("ul")(({ theme }) => ({
	paddingLeft: theme.spacing(2),
}))

// Max offline time before status becomes orange (did not receive any message for 30 minutes)
// but did not receive offline notification from IoT hub
const MAX_OFFLINE_TIME = 30 // minutes
const POLL_INTERVAL = 30 // seconds

function noMessageForALongTime(timestamp?: string | null) {
	if (!timestamp) return true
	return differenceInMinutes(new Date(), new Date(timestamp)) > MAX_OFFLINE_TIME
}

export function resolveInterfaceStatusColor({ isOnline, lastMessage, pvtHeatPump }: InterfaceFieldsFragment) {
	if (pvtHeatPump?.errors?.length) return <StatusDot color="red" />
	if (!lastMessage) return <StatusDot color="orange" />
	if (!isOnline) return <StatusDot color="grey" />
	if (noMessageForALongTime(lastMessage)) return <StatusDot color="orange" />
	return <StatusDot color="green" />
}

export function resolveInterfaceStatusText(
	{ isOnline, lastMessage, pvtHeatPump }: InterfaceFieldsFragment,
	formatErrors: (errorCodes: number[]) => NonNullable<React.ReactNode>,
) {
	if (pvtHeatPump?.errors?.length) return formatErrors(pvtHeatPump.errors)
	if (!lastMessage) return i18next.t("heatPumpList:Tooltips.NoMessagesYet")
	if (!isOnline) return i18next.t("heatPumpList:Tooltips.Offline")
	if (noMessageForALongTime(lastMessage))
		return i18next.t("heatPumpList:Tooltips.NoMessagesYet", { minutes: MAX_OFFLINE_TIME })
	return i18next.t("heatPumpList:Tooltips.Online")
}

function formatErrors(errorCodes: number[]) {
	return (
		<>
			<p>{i18next.t("heatPumpList:Errors.HPHasFollowingErrors")}</p>
			<StyledUL>
				{errorCodes.map((err) => {
					const error = GetHeatPumpErrors()[err]
					return <li key={err}>{error ? error.name : err}</li>
				})}
			</StyledUL>
		</>
	)
}

function getLabels(): Label<InterfaceFieldsFragment>[] {
	return [
		{
			key: "status",
			name: "",
			sortable: true,
			sort: ({ isOnline, pvtHeatPump }) => {
				if (pvtHeatPump?.errors?.length) return 2
				return isOnline ? 1 : 0
			},
			resolve: (args) => {
				return resolveInterfaceStatusColor(args)
			},
			width: 40,
			tooltip: (args) => {
				return resolveInterfaceStatusText(args, formatErrors)
			},
		},
		{ key: "id", name: i18next.t("general:InterfaceID"), sortable: true },
		{ key: "name", name: i18next.t("general:Name"), sortable: true },
	]
}

const options = {
	includeScore: true,
	threshold: 0.4,
	keys: ["id", "name"],
}

export default function HeatPumpListPage() {
	const { t, i18n } = useTranslation(["heatPumpList", "general"])
	const [modalOpen, setModalOpen] = useState(false)
	const [toggleSelect, setToggleSelect] = useState(false)
	const [numberSelected, setNumberSelected] = useState<SelectedInterfacesProps[]>([])
	const [dialogOpenedAt, setDialogOpenedAt] = useState<Date | null>(new Date())

	const { data, loading, error } = useInterfacesQuery({ pollInterval: POLL_INTERVAL * 1000 })
	const [addInterfaceOpen, setAddInterfaceOpen] = useState(false)

	const navigate = useNavigate()
	const searchEl = useRef<HTMLDivElement | null>(null)
	const smallScreen = useSize("down", "md")
	const [claimedInterfaceId, setClaimedInterfaceId] = useState<string | null>()
	const labels = useMemo(() => getLabels(), [i18n.language])
	const pageRef = useRef<HTMLElement>()
	const toolBarRef = useRef<HTMLElement | null>(null)
	const isAdmin = useHasRole([Role.Admin])

	const { data: heatPumpSearchData } = useHeatPumpPageFilterQuery()

	const searchData = heatPumpSearchData?.heatPumpPageFilter ?? emptyHeatPumpPageFilter

	const filteredInterfaces = searchData.offlineHeatPumps
		? data?.interfaces ?? []
		: data?.interfaces.filter((item) => item.isOnline) ?? []

	const fuse = new Fuse(filteredInterfaces, options)
	const interfaces = searchData.searchText
		? fuse.search(searchData.searchText).map(({ item }) => item)
		: [...filteredInterfaces]

	const handleClickRow = (id: string) => navigate(`/${HEAT_PUMP}/${id}`)
	const hasInterfaces = data?.interfaces.length !== undefined && data.interfaces.length > 0
	const hasSearchBar = data?.interfaces.length !== undefined && data.interfaces.length > 1

	const updateSearch = (searchText: string) => {
		heatPumpListPageFilter({ ...searchData, searchText })
	}

	const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
		heatPumpListPageFilter({ ...searchData, offlineHeatPumps: e.target.checked })
	}

	const handleNumberSelected = (data: SelectedInterfacesProps[]) => {
		setNumberSelected(data)
	}

	const handleUseRef = (data: HTMLElement) => {
		pageRef.current = data
	}

	const bannerHeight =
		(pageRef?.current && Array.from(pageRef.current.children).filter((el) => el.id === "banner")[0]?.clientHeight) ??
		0 ??
		0

	return (
		<Page
			handleUseRef={handleUseRef}
			title={t("general:HeatPumps")}
			hideTitle={smallScreen}
			noPadding
			middle={
				hasSearchBar && (
					<div ref={searchEl}>
						<DebouncedMenuInput initialValue={searchData.searchText} onChange={updateSearch} clearable />
					</div>
				)
			}
		>
			{loading && <StyledMessage>{t("general:FetchingHeatPumps")}</StyledMessage>}
			{error && <StyledMessage>{t("general:Errors.FetchingHeatPumps")}</StyledMessage>}
			{!error && !loading && data && (
				<>
					{hasInterfaces ? (
						<>
							<Box p={2} pb={0} pt={1} ref={toolBarRef}>
								<Stack direction="row" justifyContent="space-between">
									<div>
										{toggleSelect && numberSelected.length > 0 && (
											<Button
												size="small"
												variant="contained"
												onClick={() => {
													setDialogOpenedAt(new Date())
													setModalOpen(true)
												}}
											>
												{t("general:UpdateNumberHeatPumps", { count: numberSelected.length })}
											</Button>
										)}
									</div>

									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between"
										spacing={6}
										p={1}
										sx={{ minHeight: "45px" }}
									>
										<FormControlLabel
											control={<Switch checked={searchData.offlineHeatPumps} size="small" onChange={handleFilter} />}
											labelPlacement="start"
											label={t("general:OfflineHeatPumps")}
										/>

										{isAdmin && (
											<FormControlLabel
												control={
													<Switch checked={toggleSelect} onChange={() => setToggleSelect(!toggleSelect)} size="small" />
												}
												labelPlacement="start"
												label={t("general:SelectHeatPumps")}
											/>
										)}
									</Stack>
								</Stack>
							</Box>
							<QuickTable
								numberSelected={numberSelected}
								handleNumberSelected={handleNumberSelected}
								toggleSelect={toggleSelect}
								defaultItemsPerPage={25}
								offsetHeight={bannerHeight}
								fullHeight
								toolBarRef={toolBarRef}
								labels={labels}
								rows={interfaces}
								onClickRow={handleClickRow}
								defaultSortCol="status"
							/>

							<AddFab onClick={() => setAddInterfaceOpen(true)}>{t("SearchHeatPump")}</AddFab>
							{addInterfaceOpen && (
								<SearchHeatPump
									isDialog
									onClose={() => setAddInterfaceOpen(false)}
									onClaimInterface={setClaimedInterfaceId}
									navigateToPage
								/>
							)}
						</>
					) : (
						<SearchHeatPump onClaimInterface={setClaimedInterfaceId} navigateToPage />
					)}
				</>
			)}

			{modalOpen && (
				<BatchUpdate
					onClose={() => {
						setDialogOpenedAt(null)
						setModalOpen(false)
					}}
					selectedInterfaces={numberSelected}
					dialogOpenedAt={dialogOpenedAt}
				/>
			)}

			{claimedInterfaceId && (
				<InterfaceClaimDialog
					onClose={() => setClaimedInterfaceId(null)}
					interfaceId={claimedInterfaceId}
					navigateToPage={true}
				/>
			)}
		</Page>
	)
}
