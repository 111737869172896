import { TFunction } from "i18next"
import {
	ControllerSettingsInput,
	PvtHeatPumpInput,
	PvtHeatPumpRoomControlType,
	PvtHeatPumpRoomTemperatureControl,
} from "generated/graphql"
import { ExtendedRoomControlType } from "types"
import {
	INTERFACE_V1_2_3_TIMESTAMP,
	isPvtHeatPumpFirmwareNewerOrEqual,
	PVT_HEAT_PUMP_V490_1,
} from "settings/firmwareVersions"

type FormFields = Omit<PvtHeatPumpInput, "roomControlType"> & {
	extendedControlType?: ExtendedRoomControlType | null
} & ControllerSettingsInput

type FlexibleTFunction = TFunction<"heatPumpSettingsPage" | "general" | "pvtHeatPumpAdminPage" | "heatPumpErrorCard">

export function getStaticLables(t: FlexibleTFunction) {
	// since OptionsInput does not really check the type of the options, check it here
	const READABLE_ROOM_TEMP_CONTROL_WITH_COOLING = {
		OFF: t("Off"),
		HEATING: t("Heating"),
		HEATING_AND_COOLING: t("HeatingAndCooling"),
		COOLING: t("Cooling"),
	}

	const READABLE_ROOM_TEMP_CONTROL_NO_COOLING = {
		OFF: t("Off"),
		HEATING: t("Heating"),
	}

	const READABLE_ROOM_TEMP_CONTROL_NO_COOLING_HIDDEN = {
		HEATING_AND_COOLING: t("HeatingAndCooling"),
		COOLING: t("Cooling"),
	}

	const READABLE_BACKUP_HEATER = {
		NONE: t("NoBoiler"),
		OPENTHERM: t("OTBoiler"),
	}

	const READABLE_BACKUP_HEATER_HIDDEN = {
		ON_OFF: t("OnOffBoiler"),
	}

	const THERMOSTAT_COOLING_LABEL: string = t("OnOffCoolingL24")
	const THERMOSTAT_HEATING_AND_COOLING_LABEL: string = t("OnOffHeatingL8CoolingL24")
	const THERMOSTAT_HEATING_LABEL: string = t("OnOffHeatingL8")

	const READABLE_ROOM_CONTROL_TYPE = {
		OPENTHERM: t("OTViaInterface"),
		ROOM_SENSOR: t("RoomTempSensorL8"),
		THERMOSTAT: THERMOSTAT_HEATING_LABEL,
		DM_CONTROL: t("DMOutdoor"),
	}

	const READABLE_MANUAL_COOLING = {
		MANUAL_COOLING: t("ManualCooling"),
	}

	const READABLE_DHW_MODE = {
		OFF: t("Off"),
		AUTO: t("On"),
	}

	const READABLE_DHW_MODE_HIDDEN = {
		BOOST: t("Boost"),
		ECO: t("Eco"),
		HOLIDAY: t("Holiday"),
	}

	const READABLE_FLUSHING_MODE = {
		OFF: t("Off"),
		FLUSHING: t("VentMode"),
	}

	const READABLE_FLUSHING_MODE_HIDDEN = {
		BALANCING: t("Balancing"),
	}

	const READABLE_MANUAL_COOLING_MODE = {
		OFF: t("Off"),
		ON: t("On"),
	}

	const READABLE_MANUAL_COOLING_MODE_HIDDEN = {
		INACTIVE: t("Inactive"),
	}

	const HIDDEN_SETTING_TEXT: string = t("MsgHiddenSetting")

	function includesHeating(roomTemperatureControl?: PvtHeatPumpRoomTemperatureControl | null): boolean {
		return (
			roomTemperatureControl === PvtHeatPumpRoomTemperatureControl.Heating ||
			roomTemperatureControl === PvtHeatPumpRoomTemperatureControl.HeatingAndCooling
		)
	}

	function includesCooling(roomTemperatureControl?: PvtHeatPumpRoomTemperatureControl | null): boolean {
		return (
			roomTemperatureControl === PvtHeatPumpRoomTemperatureControl.Cooling ||
			roomTemperatureControl === PvtHeatPumpRoomTemperatureControl.HeatingAndCooling
		)
	}

	function usedHpContacts(
		roomControlType: ExtendedRoomControlType,
		roomTemperatureControl: PvtHeatPumpRoomTemperatureControl,
	): string {
		if (roomControlType === PvtHeatPumpRoomControlType.Thermostat) {
			if (includesCooling(roomTemperatureControl) && includesHeating(roomTemperatureControl)) {
				return t("L8&L24")
			}
			if (includesCooling(roomTemperatureControl)) {
				return t("L24")
			}
		}
		return t("L8")
	}

	// This check only exists in the frontend. I don't really care as it is not a safety concern, more something to help the installer to use reasonable values
	function validateCHTempMax(value: number | null | undefined, fields: Partial<FormFields>): string | null {
		if (value == null || fields.sinkMaxShTemp == null) return null
		if (fields.sinkMaxShTemp <= value) return t("Errors.CHTempMaxOverMaxSH")
		return null
	}

	function validateRoomControlType(
		firmwareVersion: number,
	): (value: ExtendedRoomControlType | null | undefined, fields: Partial<FormFields>) => string | null {
		return (value, fields) => {
			if (!value || !fields.roomTemperatureControl) return null
			if (
				value === PvtHeatPumpRoomControlType.Thermostat &&
				includesCooling(fields.roomTemperatureControl) &&
				!isPvtHeatPumpFirmwareNewerOrEqual(firmwareVersion, PVT_HEAT_PUMP_V490_1)
			) {
				return t("Errors.FirmwareTooOldToCool", { firmwareVersion: firmwareVersion.toFixed(1) })
			}
			if (value === "MANUAL_COOLING" && !includesCooling(fields.roomTemperatureControl)) {
				return t("Errors.ManualCoolingOnlyInCoolingMode")
			}
			return null
		}
	}

	function getReadableRoomControlType(
		hasHeating: boolean,
		hasCooling: boolean,
		firmwareVersion?: string,
		hpHasDM?: boolean,
	) {
		let readableRoomControlType: Partial<typeof READABLE_ROOM_CONTROL_TYPE> = READABLE_ROOM_CONTROL_TYPE
		if (hasCooling && hasHeating) {
			readableRoomControlType.THERMOSTAT = THERMOSTAT_HEATING_AND_COOLING_LABEL
		} else if (hasCooling) {
			readableRoomControlType.THERMOSTAT = THERMOSTAT_COOLING_LABEL
		} else {
			// this includes both heating only and off
			readableRoomControlType.THERMOSTAT = THERMOSTAT_HEATING_LABEL
		}
		const interfaceHasManualCooling = new Date(firmwareVersion ?? 0) >= INTERFACE_V1_2_3_TIMESTAMP
		if (interfaceHasManualCooling) {
			readableRoomControlType = { ...readableRoomControlType, ...READABLE_MANUAL_COOLING }
		}
		if (!hpHasDM) {
			const { DM_CONTROL: _, ...filteredReadableRoomControlType } = readableRoomControlType
			readableRoomControlType = filteredReadableRoomControlType
		}

		return readableRoomControlType
	}

	return {
		READABLE_ROOM_TEMP_CONTROL_WITH_COOLING,
		READABLE_ROOM_TEMP_CONTROL_NO_COOLING,
		READABLE_ROOM_TEMP_CONTROL_NO_COOLING_HIDDEN,
		READABLE_BACKUP_HEATER,
		READABLE_BACKUP_HEATER_HIDDEN,
		THERMOSTAT_COOLING_LABEL,
		THERMOSTAT_HEATING_AND_COOLING_LABEL,
		THERMOSTAT_HEATING_LABEL,
		READABLE_ROOM_CONTROL_TYPE,
		READABLE_MANUAL_COOLING,
		READABLE_DHW_MODE,
		READABLE_DHW_MODE_HIDDEN,
		READABLE_FLUSHING_MODE,
		READABLE_FLUSHING_MODE_HIDDEN,
		READABLE_MANUAL_COOLING_MODE,
		READABLE_MANUAL_COOLING_MODE_HIDDEN,
		HIDDEN_SETTING_TEXT,
		includesHeating,
		includesCooling,
		usedHpContacts,
		validateCHTempMax,
		validateRoomControlType,
		getReadableRoomControlType,
	}
}
