import { Info } from "@mui/icons-material"
import { IconButton, Popover, styled } from "@mui/material"
import { ReactElement, useState } from "react"

const StyledContent = styled("div")(({ theme }) => ({
	padding: theme.spacing(2),
	maxWidth: 400,
	whiteSpace: "pre-line",
}))

type Props = {
	children: ReactElement | string | number
}

export default function MoreInfo({ children }: Props) {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
	const handleClose = () => setAnchorEl(null)

	return (
		<>
			<IconButton color="primary" onClick={handleClick} size="small">
				<Info />
			</IconButton>
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<StyledContent>{children}</StyledContent>
			</Popover>
		</>
	)
}
