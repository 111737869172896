import { Line } from "react-chartjs-2"
import Chart, { ChartOptions } from "chart.js/auto"
import dragDataPlugin from "chartjs-plugin-dragdata"
import { useTheme } from "@mui/material"
import { useRef } from "react"
import { InputMaybe } from "generated/graphql"
import { useTranslation } from "react-i18next"

Chart.register(dragDataPlugin)

interface Props {
	labels: string[]
	minYValue: number
	maxYValue: number
	dataSinkSupplyTempFields: (InputMaybe<number> | undefined)[]
	handleSupplyTemp: (curveData: { supplyTempIndex: number; value: number }) => void
}

// DragData is a custom object added by chartjs-plugin-dragdata lib to the chatjs
type ExtendedChartOptions = ChartOptions<"line"> & {
	plugins?: {
		dragData?: {
			round?: number
			showTooltip?: boolean
			magnet?: {
				to: (value: number) => number
			}
			onDragEnd?: (e: MouseEvent, datasetIndex: number, index: number, value: number) => void
		}
	}
}

export default function DraggableHeatingCurve({
	labels,
	minYValue,
	maxYValue,
	dataSinkSupplyTempFields,
	handleSupplyTemp,
}: Props) {
	const { t } = useTranslation(["heatPumpSettingsPage"])

	const theme = useTheme()
	const chartRef = useRef(null)

	const minXValue = labels.at(0)
	const maxXValue = labels.at(-1)

	const dataSet = {
		labels: labels,
		datasets: [
			{
				display: true,
				label: t("SinkSupplyTemp"),
				borderColor: theme.palette.primary.main,
				borderWidth: 2,
				pointRadius: 8,
				pointBackgroundColor: theme.palette.primary.main,
				fill: true,
				pointHitRadius: 25, // 25 for mobile touch support
				pointBorderColor: theme.palette.primary.main,
				data: dataSinkSupplyTempFields,
			},
		],
	}

	const options: ExtendedChartOptions = {
		responsive: true,
		onHover: function (event, chartElement) {
			if (event.native?.target instanceof HTMLElement) {
				event.native.target.style.cursor = chartElement[0] ? "pointer" : "default"
			}
		},
		plugins: {
			legend: {
				display: false,
			},
			dragData: {
				round: 0,
				showTooltip: true,
				onDragEnd: function (_, __, index, value) {
					handleSupplyTemp({ supplyTempIndex: index, value: value })
				},
			},
			tooltip: {
				callbacks: {
					label: (context) => `${context.dataset.label}: ${context.formattedValue}°C`,
				},
			},
		},
		scales: {
			x: {
				max: maxXValue,
				min: minXValue,
				labels: dataSet.labels,
				title: {
					display: true,
					text: t("OutdoorTemperature"),
				},
			},
			y: {
				type: "linear",
				max: maxYValue,
				min: minYValue,
				title: {
					display: true,
					text: t("SinkSupplyTemp"),
				},
				ticks: {
					callback: (value) => value + "°C",
				},
			},
		},
		elements: {
			point: {
				hoverRadius: 8,
				borderWidth: 2,
			},
		},
	}

	return <Line ref={chartRef} data={dataSet} options={options} />
}
