import { Stack } from "@mui/material"

interface HeatPumpAnimationProps {
	notOnline?: boolean
	doesNotExist?: boolean
	notConnected?: boolean
}
export default function HeatPumpErrorAnimation({ notOnline, doesNotExist, notConnected }: HeatPumpAnimationProps) {
	const fadeAnimation = `
    @keyframes fadeOut {
      0% { opacity: 1; }
      50% { opacity: 0; }
      100% { opacity: 1; }
    }
  `
	const crossTranslate = ((): string => {
		switch (true) {
			case notOnline:
				return "translate(95, 26.4688)"
			case doesNotExist:
				return "translate(168, 28)"
			case notConnected:
				return "translate(242, 26.4688)"
			default:
				return ""
		}
	})()

	const style = document.createElement("style")
	style.innerHTML = fadeAnimation
	document.head.appendChild(style)

	return (
		<Stack alignItems="center" m={4}>
			<svg
				width="300px"
				viewBox="0 0 367 93"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
			>
				<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="Main" transform="translate(-19, -36)">
						<g id="Parent" transform="translate(19, 36)">
							<g id="image">
								<g id="Internet" transform="translate(0, 6)" fill="#DD4F06" fillRule="nonzero">
									<g id="_259921856">
										<path
											d="M40.5,1.83277032e-15 C51.6835556,1.83277032e-15 61.8085433,4.53316483 69.1376626,11.8623277 C76.466815,19.1914246 81,29.3163709 81,40.499967 C81,51.6835135 76.4668315,61.8084929 69.1376626,69.1376062 C61.8085598,76.4667526 51.6836052,80.999934 40.5,80.999934 C29.3164444,80.999934 19.1914567,76.4667691 11.8623374,69.1376062 C4.53318503,61.8085094 0,51.683563 0,40.499967 C0,29.3164205 4.53316852,19.1914411 11.8623374,11.8623277 C19.1914402,4.53318134 29.3163948,1.83277032e-15 40.5,1.83277032e-15 L40.5,1.83277032e-15 Z M66.6477397,14.3523146 C59.9561648,7.66067911 50.7115398,3.5218109 40.5,3.5218109 C30.2885427,3.5218109 21.0440003,7.66067911 14.3522603,14.3523146 C7.66061932,21.0438841 3.52174774,30.2885015 3.52174774,40.500033 C3.52174774,50.711482 7.66061932,59.9560168 14.3522603,66.6477514 C21.0438352,73.3393869 30.2884602,77.4782551 40.5,77.4782551 C50.7114573,77.4782551 59.9559997,73.3393869 66.6477397,66.6477514 C73.3393807,59.9561819 77.4782523,50.7115645 77.4782523,40.500033 C77.4782523,30.2885841 73.3393807,21.0440492 66.6477397,14.3523146 Z"
											id="_259921640"
										></path>
										<path
											d="M40.5,-1.83277032e-15 C47.0270218,-1.83277032e-15 52.8478253,4.68837424 56.9822727,12.2681305 C60.9421465,19.5278107 63.3913933,29.5121903 63.3913933,40.5 C63.3913933,51.4877766 60.9421465,61.4720903 56.9822727,68.7318695 C52.8478253,76.3116423 47.0270218,81 40.5,81 C33.9729782,81 28.1521747,76.3116258 24.0177273,68.7318695 C20.0578535,61.4721893 17.6086067,51.4878097 17.6086067,40.5 C17.6086067,29.5122234 20.0578535,19.5279097 24.0177273,12.2681305 C28.1521747,4.68835773 33.9729782,-1.83277032e-15 40.5,-1.83277032e-15 L40.5,-1.83277032e-15 Z M53.9007145,13.9465118 C50.3875345,7.5056843 45.6430123,3.52182741 40.5,3.52182741 C35.3570042,3.52182741 30.6124655,7.5056843 27.0992855,13.9465118 C23.4113998,20.707498 21.1304204,30.0929628 21.1304204,40.5 C21.1304204,50.9070042 23.4113998,60.2924195 27.0992855,67.0534882 C30.6124655,73.4943157 35.3569877,77.4781726 40.5,77.4781726 C45.6429958,77.4781726 50.3875345,73.4943157 53.9007145,67.0534882 C57.5886002,60.292502 59.8695796,50.9070372 59.8695796,40.5 C59.8695796,30.0929958 57.5886002,20.7075805 53.9007145,13.9465118 Z"
											id="_259921616"
										></path>
										<polygon
											id="_259922096"
											points="3.40868349 26.0888504 77.4782357 26.0888504 77.4782357 29.6106943 3.40868349 29.6106943"
										></polygon>
										<polygon
											id="_259921784"
											points="3.40868349 51.3893717 77.4782357 51.3893717 77.4782357 54.9112156 3.40868349 54.9112156"
										></polygon>
									</g>
								</g>
								<rect id="FirstLine" fill="#DD4F06" x="95" y="43" width="48" height="3"></rect>
								<g id="Interface" transform="translate(157, 3)">
									<rect
										id="Rectangle"
										strokeWidth="3"
										x="1.5"
										y="1.5"
										width="68"
										height="83"
										rx="14"
										stroke="#DD4F06"
									></rect>
									<ellipse id="Oval" strokeWidth="3" cx="35.5" cy="43" rx="30" ry="36.5" stroke="#DD4F06"></ellipse>
									<rect id="Rectangle" x="34.5" y="7.5" width="1" height="71" stroke="#DD4F06"></rect>
									<rect id="Rectangle" x="6.5" y="42.5" width="58" height="1" stroke="#DD4F06"></rect>
								</g>
								<rect id="SecondLine" fill="#DD4F06" x="242" y="43" width="48" height="3"></rect>
								<g id="Heatpump" transform="translate(304, 0)">
									<path
										d="M51,16.0868832 L51,49 C51,50.5170048 50.44774,52.4100863 49.6801774,54.0161508 C48.9927373,55.4545654 48.1582164,56.7290754 47.3177496,57.0754307 C38.4818386,60.7166981 29.215491,63.7946324 19.513075,66.3111713 L3,70.6995394 L3,55.7772481 C5.55430974,55.050041 7.48874919,53.6969554 8.8248221,51.7478267 C10.2541486,49.6626549 11,46.8640744 11,43.3292073 L11,16.0868832 L51,16.0868832 Z"
										id="Rectangle"
										stroke="#DD4F06"
										strokeWidth="2"
									></path>
									<rect id="Rectangle" stroke="#DD4F06" strokeWidth="2" x="11" y="2" width="40" height="14"></rect>
									<path
										d="M0,11 C-7.43989126e-16,4.92486775 4.92486775,7.43989126e-16 11,0 L52,0 C58.0751322,-1.11598369e-15 63,4.92486775 63,11 L63,82 C63,88.0751322 58.0751322,93 52,93 L11,93 C4.92486775,93 3.71994563e-16,88.0751322 0,82 L0,70 L3,70 L3,79 C3,85.0751322 7.92486775,90 14,90 L49,90 C55.0751322,90 60,85.0751322 60,79 L60,14 C60,7.92486775 55.0751322,3 49,3 L14,3 C7.92486775,3 3,7.92486775 3,14 L3,58 L0,58 L0,11 Z"
										id="Combined-Shape"
										fill="#DD4F06"
									></path>
									<circle id="Oval" fill="#DD4F06" cx="24.5" cy="85.5" r="1.5"></circle>
									<circle id="Oval" fill="#DD4F06" cx="39.5" cy="85.5" r="1.5"></circle>
									<g fill="#DD4F06" transform="translate(25, 25)">
										<polyline id="Path" points="2.54346653 0 5.05174519 0 2.50817926 8 0 8 2.54346653 0"></polyline>
										<polygon id="Path" points="6.18670302 0 8.69488228 0 6.15141575 8 3.64313709 8"></polygon>
										<polygon id="Path" points="9.82993951 0 12.3381188 0 9.79455284 8 7.28637358 8"></polygon>
										<polygon id="Path" points="11.5105081 5.80354839 14 5.80354839 12.6896992 1.85718851"></polygon>
									</g>
								</g>
								<g
									id="Cross"
									transform={crossTranslate}
									fillRule="nonzero"
									style={{ animation: "fadeOut 2s infinite" }}
								>
									<rect
										id="Rectangle"
										fill="#5C5C5C"
										transform="translate(24, 18.0312) rotate(45) translate(-24, -18.0312)"
										x="-7.27595761e-12"
										y="16.5312229"
										width="48"
										height="3"
									></rect>
									<rect
										id="Rectangle"
										fill="#5C5C5C"
										transform="translate(24, 18.0312) rotate(-45) translate(-24, -18.0312)"
										x="5.45696821e-12"
										y="16.5312229"
										width="48"
										height="3"
									></rect>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</Stack>
	)
}
