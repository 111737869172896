import { createContext, useContext, useState } from "react"
import Message from "components/Message"

type Messages = {
	success: (text: string | JSX.Element) => void
	error: (text: string | JSX.Element) => void
	info: (text: string | JSX.Element) => void
	warning: (text: string | JSX.Element) => void
}

function fail() {
	console.error("Message context not created...")
}
const MessageContext = createContext<Messages>({
	success: fail,
	error: fail,
	info: fail,
	warning: fail,
})

type Props = {
	children: React.ReactNode
}

export default function MessageProvider({ children }: Props) {
	const [message, setMessage] = useState<{
		type: keyof Messages
		text: string | React.ReactNode
	} | null>(null)

	const messages: Messages = {
		success: (text) => setMessage({ type: "success", text }),
		error: (text) => setMessage({ type: "error", text }),
		info: (text) => setMessage({ type: "info", text }),
		warning: (text) => setMessage({ type: "warning", text }),
	}

	return (
		<MessageContext.Provider value={messages}>
			<Message message={message} onClose={() => setMessage(null)} />
			{children}
		</MessageContext.Provider>
	)
}

export function useMessage() {
	return useContext(MessageContext)
}
