// interface firmwares
export const INTERFACE_V1_2_0_TIMESTAMP = new Date(1676994046 * 1000)
export const INTERFACE_V1_2_1_TIMESTAMP = new Date(1686044686 * 1000)
export const INTERFACE_V1_2_2_TIMESTAMP = new Date(1687363942 * 1000) //Adds HP updates through interface
export const INTERFACE_V1_2_3_TIMESTAMP = new Date(1689923242 * 1000)

// heat pump firmwares
export const PVT_HEAT_PUMP_V490_1 = 490.1

export const PVT_HEAT_PUMP_V1_1 = 1.1

export const PVT_HEAT_PUMP_FIRMWARE_VERSIONS = [
	740.1,
	500.1,
	PVT_HEAT_PUMP_V490_1,
	480.1,
	470.1,
	460.1,
	450.1,
	440.1,
	430.1,
	1.0, //First production release
	PVT_HEAT_PUMP_V1_1, //Production Release
	1.2, //Adds brine freezing setp
	1.3, //Improved pump controls
]

export function isPvtHeatPumpFirmwareNewerOrEqual(fw: number, target: number) {
	const fwIx = PVT_HEAT_PUMP_FIRMWARE_VERSIONS.indexOf(fw)
	if (fwIx === -1 || fwIx >= PVT_HEAT_PUMP_FIRMWARE_VERSIONS.indexOf(target)) return true
	return false
}
