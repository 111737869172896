import { Snackbar } from "@mui/material"
import { Alert } from "@mui/material"

interface MessageType {
	type?: "success" | "info" | "warning" | "error"
	text: string | null | React.ReactNode
}

type Props = {
	message: MessageType | null
	onClose: () => void
}

export default function Message({ message, onClose }: Props) {
	return (
		<Snackbar open={!!message} autoHideDuration={6000} onClose={onClose}>
			<Alert elevation={6} variant="filled" onClose={onClose} severity={message?.type}>
				{message?.text}
			</Alert>
		</Snackbar>
	)
}
