import { useMessage } from "providers/MessageProvider"
import {
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Typography,
	FormLabel,
	Tabs,
	Tab,
} from "@mui/material"
import useForm from "hooks/useForm"
import TSDialog from "components/TSDialog"
import { UsersDocument, useUpsertUserMutation, NewUser, UserFieldsFragment } from "generated/graphql"
import TextInput from "inputs/TextInput"
import RoleInput from "inputs/RoleInput"
import MultipleTextField from "inputs/MultipleTextField"
import appendErrorMessage from "tools/appendErrorMessage"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import ClaimedInterfaces from "components/ClaimedInterfaces"
import UpdateOrganization from "components/forms/UpdateOrganization"

type Props = {
	onClose: (user: UserFieldsFragment | null) => void
	user?: Partial<UserFieldsFragment> | null
}

enum TabsTypes {
	Interfaces = "interfaces",
	Account = "account",
}

export default function UpsertUser({ onClose, user }: Props) {
	const { t } = useTranslation(["groupManagement", "general", "serviceRequests"])
	const [selectedTab, setSelectedTab] = useState(TabsTypes.Account)
	const [dialogOpen, setDialogOpen] = useState(false)

	const [upsertUser, { loading }] = useUpsertUserMutation({
		refetchQueries: [{ query: UsersDocument }],
		awaitRefetchQueries: true,
	})

	const message = useMessage()
	const { register, submit } = useForm(user, handleSave)

	async function handleSave(user: NewUser) {
		try {
			const { data } = await upsertUser({ variables: { user } })
			if (!data?.upsertUser) return
			message.success(t("UserSaved"))
			onClose(data.upsertUser)
		} catch (e) {
			message.error(appendErrorMessage(t("serviceRequests:Errors.Saving"), e))
		}
	}

	return (
		<TSDialog autoFullScreen open onClose={() => onClose(null)} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{t("general:Users")}</DialogTitle>

			{user?.group?.isOrganization === false && (
				<Tabs
					value={selectedTab}
					onChange={(_, newValue) => {
						setSelectedTab(newValue)
					}}
					centered
					sx={{ mb: 0 }}
				>
					<Tab label={t("general:Account")} value={TabsTypes.Account} />
					<Tab label={t("general:Interfaces")} value={TabsTypes.Interfaces} />
				</Tabs>
			)}

			<DialogContent>
				{selectedTab === TabsTypes.Account && (
					<>
						<DialogContentText>{t("EnterUserDetails")}</DialogContentText>
						<TextInput
							autoFocus
							label={t("general:FirstName")}
							{...register("firstName", { required: true, minLength: 2 })}
						/>
						<TextInput label={t("general:LastName")} {...register("lastName", { required: true, minLength: 2 })} />
						<TextInput label={t("general:Email")} {...register("email", { email: true })} />
						<MultipleTextField label={t("general:PhoneNumbers")} {...register("phoneNumbers")} />

						<div style={{ marginTop: 10 }}>
							<FormLabel component="legend">{t("Group")}:</FormLabel>
							{user?.group?.isOrganization ? (
								<Typography sx={{ cursor: "pointer" }} color="primary" onClick={() => setDialogOpen(true)}>
									{user.group.name}
								</Typography>
							) : (
								<Typography>{t("IndividualUser")}</Typography>
							)}
						</div>
						<div style={{ marginTop: 10 }}>
							<FormLabel component="legend">{t("general:Account")}:</FormLabel>
							<Typography>{user?.hasAccount ? t("general:Yes") : t("general:No")}</Typography>
						</div>
						<RoleInput label={t("general:Roles")} {...register("roles")} />
					</>
				)}

				{selectedTab === TabsTypes.Interfaces && user?.group?.isOrganization === false && (
					<ClaimedInterfaces group={user.group} />
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose(null)} color="primary" disabled={loading}>
					{t("general:Cancel")}
				</Button>
				{selectedTab === TabsTypes.Account && (
					<Button onClick={submit} color="primary" disabled={loading}>
						{t("general:Save")}
					</Button>
				)}
			</DialogActions>

			{dialogOpen && user?.group && <UpdateOrganization group={user.group} onClose={() => setDialogOpen(false)} />}
		</TSDialog>
	)
}
