import { AccountBalance, Article, AutoFixHigh, AutoGraph, Settings } from "@mui/icons-material"
import { BottomNavigation, BottomNavigationAction, Paper, styled, useTheme } from "@mui/material"
import { Link } from "react-router-dom"
import { ADMIN, HEAT_PUMP, MANAGEMENT, MONITORING, SETTINGS, STATUS } from "settings/url"
import useHasRole from "hooks/useHasRole"
import { Role } from "generated/graphql"
import { useTranslation } from "react-i18next"

type Status = "status" | "settings" | "monitoring" | "admin" | "management"

type Props = {
	value: Status
	interfaceId: string
}

const StyledButtonContainer = styled(Paper)`
	position: sticky;
	bottom: 0;
	left: 0;
	right: 0;
`

// this does not seem like the most elegant solution, but this is needed since react-router 6
function makeFullPath(interfaceId: string) {
	return (path: string) => `/${HEAT_PUMP}/${interfaceId}/${path}`
}

export default function HeatPumpBottomNavigationBar({ value, interfaceId }: Props) {
	const { t } = useTranslation()
	const isExpert = useHasRole([Role.Admin, Role.Expert, Role.Mechanic])
	const fullPath = makeFullPath(interfaceId) // wrap interface id
	const theme = useTheme()

	return (
		<StyledButtonContainer sx={{ zIndex: theme.zIndex.drawer - 1 }}>
			<BottomNavigation showLabels value={value}>
				<BottomNavigationAction
					label={t("Status")}
					icon={<Article />}
					component={Link}
					to={fullPath(STATUS)}
					replace
					value="status"
					sx={{ minWidth: "70px", p: 0 }}
				/>
				<BottomNavigationAction
					label={t("Settings")}
					icon={<Settings />}
					component={Link}
					to={fullPath(SETTINGS)}
					replace
					value="settings"
					sx={{ minWidth: "70px", p: 0 }}
				/>
				<BottomNavigationAction
					label={t("Management")}
					icon={<AccountBalance />}
					component={Link}
					to={fullPath(MANAGEMENT)}
					value="management"
					sx={{ minWidth: "70px", p: 0 }}
				/>
				<BottomNavigationAction
					label={t("Graphs")}
					icon={<AutoGraph />}
					component={Link}
					to={fullPath(MONITORING)}
					replace
					value="monitoring"
					sx={{ minWidth: "70px", p: 0 }}
				/>

				{isExpert && (
					<BottomNavigationAction
						label={t("Admin")}
						icon={<AutoFixHigh />}
						component={Link}
						to={fullPath(ADMIN)}
						replace
						value="admin"
						sx={{ minWidth: "70px", p: 0 }}
					/>
				)}
			</BottomNavigation>
		</StyledButtonContainer>
	)
}
