import { Routes, Route, Navigate } from "react-router-dom"
import {
	SIGN_IN,
	ACTIVATE,
	SETTINGS,
	MONITORING,
	RESET_PASSWORD,
	FORGOT_PASSWORD,
	CREATE_ACCOUNT,
	HEAT_PUMP,
	HEAT_PUMP_LIST,
	STATUS,
	ADMIN,
	MANAGEMENT,
	NOT_FOUND,
	ORGANIZATION_MANAGEMENT,
	ACCOUNT,
	INSTALLATION_HELP,
} from "settings/url"
import SignInPage from "pages/SignInPage"
import NotFoundPage from "pages/NotFoundPage"
import ActivationPage from "./ActivationPage"
import HeatPumpListPage from "./HeatPumpListPage"
import HeatPumpMonitoringPage from "./HeatPumpMonitoringPage"
import SettingsPage from "./SettingsPage"
import ResetPasswordPage from "./ResetPasswordPage"
import HeatPumpSettingsPage from "./HeatPumpSettingsPage"
import HeatPumpStatusPage from "./HeatPumpStatusPage"
import HeatPumpPage from "./HeatPumpPage"
import ProtectedRoute from "components/ProtectedRoute"
import CreateAccountPage from "./CreateAccountPage"
import ForgotPasswordPage from "./ForgotPasswordPage"
import HomePage from "./HomePage"
import { Role } from "generated/graphql"
import HPAdminSettings from "./HeatPumpAdminPage"
import HeatPumpManagementPage from "./HeatPumpManagementPage"
import GroupManagementPage from "./GroupManagementPage"
import AccountSettingsPage from "./AccountSettingsPage"
import ReinstallHeatPump from "./ReinstallHeatPump"

export default function MyRoutes() {
	return (
		<Routes>
			<Route path="/" element={<ProtectedRoute />}>
				<Route path="/" element={<HomePage />} />
			</Route>
			<Route path={`/${SIGN_IN}`} element={<SignInPage />} />
			<Route path={`/${FORGOT_PASSWORD}`} element={<ForgotPasswordPage />} />
			<Route path={`/${CREATE_ACCOUNT}`} element={<CreateAccountPage />} />
			<Route path={`/${ACTIVATE}/:code`} element={<ActivationPage />} />
			<Route path={`/${RESET_PASSWORD}/:code`} element={<ResetPasswordPage />} />
			<Route path={`/${NOT_FOUND}`} element={<NotFoundPage />} />
			<Route path={`/${HEAT_PUMP}`} element={<Navigate to={`/${HEAT_PUMP_LIST}`} />} />
			<Route path={`/${HEAT_PUMP}/:interfaceId`} element={<ProtectedRoute />}>
				<Route path={`/${HEAT_PUMP}/:interfaceId`} element={<HeatPumpPage />} />
			</Route>
			<Route path={`/${HEAT_PUMP}/:interfaceId/${SETTINGS}`} element={<ProtectedRoute />}>
				<Route path={`/${HEAT_PUMP}/:interfaceId/${SETTINGS}`} element={<HeatPumpSettingsPage />} />
			</Route>
			<Route path={`/${HEAT_PUMP}/:interfaceId/${STATUS}`} element={<ProtectedRoute />}>
				<Route path={`/${HEAT_PUMP}/:interfaceId/${STATUS}`} element={<HeatPumpStatusPage />} />
			</Route>
			<Route
				path={`/${HEAT_PUMP}/:interfaceId/${INSTALLATION_HELP}`}
				element={<ProtectedRoute roles={[Role.Admin, Role.Mechanic, Role.Expert]} />}
			>
				<Route path={`/${HEAT_PUMP}/:interfaceId/${INSTALLATION_HELP}`} element={<ReinstallHeatPump />} />
			</Route>
			<Route path={`/${HEAT_PUMP}/:interfaceId/${MANAGEMENT}`} element={<ProtectedRoute />}>
				<Route path={`/${HEAT_PUMP}/:interfaceId/${MANAGEMENT}`} element={<HeatPumpManagementPage />} />
			</Route>
			<Route path={`/${HEAT_PUMP}/:interfaceId/${MONITORING}`} element={<ProtectedRoute />}>
				<Route path={`/${HEAT_PUMP}/:interfaceId/${MONITORING}`} element={<HeatPumpMonitoringPage />} />
			</Route>
			<Route
				path={`/${HEAT_PUMP}/:interfaceId/${ADMIN}`}
				element={<ProtectedRoute roles={[Role.Admin, Role.Mechanic, Role.Expert]} />}
			>
				<Route path={`/${HEAT_PUMP}/:interfaceId/${ADMIN}`} element={<HPAdminSettings />} />
			</Route>
			<Route path={`/${SETTINGS}`} element={<ProtectedRoute roles={[Role.Admin]} />}>
				<Route path={`/${SETTINGS}`} element={<SettingsPage />} />
			</Route>
			<Route path={`/${ACCOUNT}`} element={<ProtectedRoute />}>
				<Route path={`/${ACCOUNT}`} element={<AccountSettingsPage />} />
			</Route>
			<Route path={`/${HEAT_PUMP_LIST}`} element={<ProtectedRoute />}>
				<Route path={`/${HEAT_PUMP_LIST}`} element={<HeatPumpListPage />} />
			</Route>
			<Route path={`/${ORGANIZATION_MANAGEMENT}`} element={<ProtectedRoute />}>
				<Route path={`/${ORGANIZATION_MANAGEMENT}`} element={<GroupManagementPage />} />
			</Route>
			<Route path="*" element={<ProtectedRoute />}>
				<Route path="*" element={<NotFoundPage />} />
			</Route>
		</Routes>
	)
}
