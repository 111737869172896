import Page from "components/Page"
import { useEffect, useState } from "react"
import { StepLabel, Stepper, Step, Box, Typography, useMediaQuery, Theme, Container, Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import {
	ControllerSettingsInput,
	ManualCoolingMode,
	PvtHeatPumpInput,
	PvtHeatPumpRoomControlType,
	PvtHeatPumpSettingsFieldsWizardFragment,
	PvtHeatPumpSettingsFieldsWizardFragmentDoc,
	UpdatePvtHeatPumpSettingsMutation,
	UpdatePvtHeatPumpSettingsMutationVariables,
	useReadHeatPumpSettingsWizardQuery,
	useUpdatePvtHeatPumpSettingsMutation,
	useRestartPvtHeatPumpMutation,
	BackupHeaterType,
	useSetSlaveMutation,
	HeatPumpSlaveDocument,
	useHeatPumpSlaveQuery,
	ReadHeatPumpSettingsWizardQuery,
	HeatPumpSlaveQuery,
} from "generated/graphql"
import { Navigate, useParams } from "react-router-dom"
import { ApolloCache, ApolloError, InMemoryCache } from "@apollo/client"
import { NOT_FOUND } from "settings/url"
import { INTERFACE_V1_2_3_TIMESTAMP } from "settings/firmwareVersions"
import useForm, { Options } from "hooks/useForm"
import onlyChangedValues from "tools/onlyChangedValues"
import { useMessage } from "providers/MessageProvider"
import appendErrorMessage from "tools/appendErrorMessage"
import FlushPump from "./components/FlushPump"
import TypeInstallation from "./components/TypeInstallation"
import HeatingSettings from "./components/HeatingSettings"
import BoilerSettings from "./components/BoilerSettings"
import ErrorStatus from "./components/ErrorStatus"
import IntroWizard from "./components/IntroWizard"
import HeatPumps from "./components/HeatPumps"
import useSize from "hooks/useSize"
import { useMoveAwayWarning } from "hooks/useMoveAwayWarning"
import BottomNavigation, { NavigationProps } from "components/BottomNavigation"
import { ExtendedBackupHeaterType, ExtendedRoomControlType } from "types"

type FormFields = Omit<PvtHeatPumpInput, "roomControlType"> &
	Omit<ControllerSettingsInput, "backupHeater"> & {
		extendedControlType?: ExtendedRoomControlType | null
		extendedBackupHeater?: ExtendedBackupHeaterType | null
		slaveId?: string | null
	}

export interface StepperProps {
	error?: ApolloError | undefined
	onClose?: () => void
	handleSkip?: () => void
	steps?: string[]
	submit?: () => void
	finishStep?: () => void
	BottomNavigation?: React.FC<NavigationProps>
	nextStep?: () => void
	backStep?: () => void
	step?: number
	name?: string
	loading: boolean
	register?: <K extends keyof FormFields>(
		key: K,
		options?: Options<FormFields, K>,
		callback?: ((value: FormFields[K] | null) => void) | undefined,
	) => {
		value: Partial<FormFields>[K]
		onChange: (value: FormFields[K] | null) => void
		required: boolean
		error: boolean
		helperText: string | null
	}
	fields?: Partial<FormFields>
	data?: ReadHeatPumpSettingsWizardQuery | undefined
	set?: (changeFields: Partial<FormFields>) => void
	dataSlave?: HeatPumpSlaveQuery | undefined
	handleSetSlave?: (slaveId: string) => Promise<void>
	handleAddInterface?: (data: boolean) => void
}

function updatePvtHeatPumpCache(
	cache: ApolloCache<InMemoryCache>,
	{ data }: { data?: UpdatePvtHeatPumpSettingsMutation | null },
	{ variables }: { variables?: UpdatePvtHeatPumpSettingsMutationVariables },
) {
	if (!data?.updatePvtHeatPump || !variables) return
	const { pvtHeatPumpdata: inputData, interfaceIds } = variables
	const id = `PvtHeatPumpData:${interfaceIds?.at(0)}`
	const oldPvtHeatPump = cache.readFragment<PvtHeatPumpSettingsFieldsWizardFragment>({
		id,
		fragment: PvtHeatPumpSettingsFieldsWizardFragmentDoc,
		fragmentName: "PvtHeatPumpSettingsFieldsWizard",
	})
	if (!oldPvtHeatPump) return

	const newPvtHeatPump = (Object.keys(oldPvtHeatPump) as Array<keyof PvtHeatPumpSettingsFieldsWizardFragment>).reduce(
		(acc, key) => ({ ...acc, [key]: inputData[key as keyof PvtHeatPumpInput] ?? oldPvtHeatPump[key] }),
		{} as PvtHeatPumpSettingsFieldsWizardFragment,
	)

	cache.writeFragment<PvtHeatPumpSettingsFieldsWizardFragment>({
		id,
		fragment: PvtHeatPumpSettingsFieldsWizardFragmentDoc,
		fragmentName: "PvtHeatPumpSettingsFieldsWizard",
		data: newPvtHeatPump,
	})
}

export default function ReinstallHeatPump() {
	const { t } = useTranslation(["heatPumpSettingsPage", "general"])
	const smallScreen = useSize("down", "md")

	const [step, setStep] = useState(0)
	const { interfaceId } = useParams<"interfaceId">()
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

	if (!interfaceId) return <Navigate to={`/${NOT_FOUND}`} /> // should never happen, but just in case, navigate to not found page

	const message = useMessage()
	const {
		data,
		loading: readLoading,
		error,
		refetch,
	} = useReadHeatPumpSettingsWizardQuery({
		variables: { interfaceId },
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-first",
	})

	const { data: dataSlave, loading: loadingSlave } = useHeatPumpSlaveQuery({
		variables: { interfaceId },
		onCompleted: (dataSlave) => {
			set({
				slaveId: dataSlave.interface?.slave?.id ?? null,
			})
		},
	})

	const [updatePVTHeatPumpSettings, { loading: updateLoading }] = useUpdatePvtHeatPumpSettingsMutation({
		update: updatePvtHeatPumpCache,
	})

	const [setSlave] = useSetSlaveMutation({
		refetchQueries: [{ query: HeatPumpSlaveDocument, variables: { interfaceId: interfaceId } }],
	})

	const { register, submit, reset, fields, set } = useForm<FormFields>({}, handleSave, [
		"extendedBackupHeater",
		"slaveId",
	])

	const steps = [
		t("Stepper.Welcome"),
		t("Stepper.HeatPumpSetup"),
		t("Stepper.CheckOnAir"),
		t("Stepper.InstallationType"),
		t("Stepper.HeatingSettings"),
		t("Stepper.DomesticHotWaterSettings"),
		t("Stepper.ErrorsAndMalfunctions"),
	]

	const lastStepIndex = steps.length - 1

	useMoveAwayWarning(t("general:leaveWizardConfirmation"), lastStepIndex !== step)

	useEffect(() => {
		// Also gets triggered when loading from cache, not only when done with network fetch. Relevant on switching tabs
		if (!data?.interface?.pvtHeatPump) return

		const { roomControlType, ...fields } = data.interface.pvtHeatPump

		let extendedControlType: ExtendedRoomControlType | null = roomControlType ?? null
		if (
			new Date(data.interface?.firmwareVersion.timestamp ?? 0) >= INTERFACE_V1_2_3_TIMESTAMP &&
			roomControlType === PvtHeatPumpRoomControlType.Opentherm && // should be in OT mode, else manual cooling will not work
			data.interface?.controller.manualCoolingMode !== ManualCoolingMode.Inactive // either on or off
		) {
			extendedControlType = "MANUAL_COOLING"
		}
		reset({ extendedControlType, ...fields, ...data.interface.controller })
	}, [data])

	useEffect(() => {
		if (fields.extendedControlType == null) return
		if (fields.extendedControlType === "MANUAL_COOLING") {
			// if extendedControlType is set to manual cooling mode, make sure to change manualCoolingMode too
			set({
				manualCoolingMode:
					fields.manualCoolingMode !== ManualCoolingMode.Inactive ? fields.manualCoolingMode : ManualCoolingMode.Off,
			})
		} else {
			set({ manualCoolingMode: ManualCoolingMode.Inactive })
		}
	}, [fields.extendedControlType])

	async function handleSave(formData: FormFields) {
		if (!interfaceId) return

		if (!data?.interface?.pvtHeatPump) {
			message.error(t("Errors.ConnectingToHP"))
			console.error("readData is undefined when checking for changed values on save")
			return
		}
		// split into controller and heatpump settings
		const { extendedBackupHeater, chSetpMaxTemp, manualCoolingMode, extendedControlType, slaveId, ...heatPumpFields } =
			formData

		const controllerData: ControllerSettingsInput = {
			backupHeater: extendedBackupHeater === "CASCADE" ? BackupHeaterType.Opentherm : extendedBackupHeater,
			chSetpMaxTemp,
			manualCoolingMode,
		}

		const heatPumpData: PvtHeatPumpInput = {
			...heatPumpFields,
			roomControlType:
				extendedControlType === "MANUAL_COOLING" ? PvtHeatPumpRoomControlType.Opentherm : extendedControlType,
		}

		try {
			await setSlave({
				variables: { interfaceId, slaveId: extendedBackupHeater === "CASCADE" ? slaveId ?? null : null },
			})
		} catch (e) {
			message.error(appendErrorMessage(t("general:Errors.SettingSlave"), e))
		}

		// get changed fields
		const changedControllerData = onlyChangedValues(data.interface.controller, controllerData)
		const changedHeatPumpData = onlyChangedValues(data.interface.pvtHeatPump, heatPumpData)

		// check if nothing changed
		if (Object.keys(changedHeatPumpData).length === 0 && Object.keys(changedControllerData).length === 0) {
			message.info(t("Errors.HPHasTheseSettings"))
			return
		}

		try {
			if (!interfaceId) throw new Error(t("general:InterfaceIDNotFound"))
			await updatePVTHeatPumpSettings({
				variables: {
					interfaceIds: [interfaceId],
					pvtHeatPumpdata: changedHeatPumpData,
					controllerSettings: changedControllerData,
					isExpert: false,
					isAdmin: false,
				},
			})
			message.success(t("SettingsSaved"))
		} catch (e) {
			message.error(appendErrorMessage(t("Errors.UnkownSaving"), e))
		}

		await restartHeatPump()
		nextStep()
	}

	const [restartingHeatPump, setRestartingHeatPump] = useState(false)
	const [restartPVTHeatPump, { loading: restartLoading }] = useRestartPvtHeatPumpMutation()

	async function restartHeatPump() {
		setRestartingHeatPump(true)
		try {
			if (!interfaceId) throw new Error(t("general:InterfaceIDNotFound"))
			await restartPVTHeatPump({ variables: { interfaceIds: [interfaceId] } })
			setTimeout(async () => {
				await refetch()
				setRestartingHeatPump(false)
			}, 5000)
		} catch (error) {
			setRestartingHeatPump(false)
		}
	}
	const [skipped, setSkipped] = useState(new Set<number>())

	const nextStep = () => {
		let newSkipped = skipped

		if (isStepSkipped(step)) {
			newSkipped = new Set(newSkipped.values())
			newSkipped.delete(step)
		}

		setStep((step) => step + 1)
		setSkipped(newSkipped)
	}

	const backStep = () => {
		// Check if the previous step was skipped
		if (isStepSkipped(step)) {
			// If skipped, go back two steps
			setStep((prevStep) => Math.max(0, prevStep - 2))
			setSkipped((prevSkipped) => {
				const newSkipped = new Set(prevSkipped.values())
				newSkipped.delete(step - 1) // Adjusted to remove the previous step
				return newSkipped
			})
		} else {
			// If not skipped, go back one step
			setStep((prevStep) => Math.max(0, prevStep - 1))
		}
	}
	const finishStep = async () => {
		await restartHeatPump()
		nextStep()
	}

	const isStepSkipped = (step: number) => {
		return skipped.has(step - 1) // Adjusted to check the previous step
	}

	const handleSkip = () => {
		setStep((prevActiveStep) => prevActiveStep + 1)
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values())
			newSkipped.add(step)
			return newSkipped
		})
	}

	useEffect(() => {
		if (data?.interface?.slave?.id) {
			set({ slaveId: data.interface.slave.id })
		}
	}, [data])

	return (
		<Page title={t("ReinstallHeatPump", { interface: data?.interface?.name ?? t("general:Loading") })}>
			{!isMobile && (
				<Stepper activeStep={step} alternativeLabel sx={{ mb: 3 }}>
					{steps.map((label, index) => {
						const stepProps: { completed?: boolean } = {}
						const labelProps: { optional?: React.ReactNode } = {}

						return (
							<Step onClick={() => steps.length !== index + 1 && setStep(index)} key={label} {...stepProps}>
								<StepLabel sx={{ cursor: "pointer" }} {...labelProps}>
									{label}
								</StepLabel>
							</Step>
						)
					})}
				</Stepper>
			)}
			<Box p={1}>
				<Container maxWidth="md" sx={{ p: 0 }}>
					{[steps].map((_, index) => (
						<Paper key={index} sx={{ p: smallScreen ? 2 : 4 }}>
							{step === 0 ? (
								<IntroWizard
									error={error}
									step={step}
									steps={steps}
									nextStep={nextStep}
									backStep={backStep}
									BottomNavigation={BottomNavigation}
									loading={readLoading}
								/>
							) : step === 1 ? (
								<HeatPumps
									error={error}
									step={step}
									set={set}
									dataSlave={dataSlave}
									steps={steps}
									name={data?.interface?.name ?? t("general:Loading")}
									nextStep={nextStep}
									backStep={backStep}
									BottomNavigation={BottomNavigation}
									register={register}
									fields={fields}
									data={data}
									loading={readLoading || loadingSlave}
								/>
							) : step === 2 ? (
								<FlushPump
									error={error}
									data={data}
									step={step}
									steps={steps}
									nextStep={nextStep}
									backStep={backStep}
									BottomNavigation={BottomNavigation}
									loading={readLoading}
								/>
							) : step === 3 ? (
								<TypeInstallation
									error={error}
									loading={readLoading}
									data={data}
									step={step}
									steps={steps}
									nextStep={nextStep}
									backStep={backStep}
									BottomNavigation={BottomNavigation}
									register={register}
									fields={fields}
								/>
							) : step === 4 ? (
								<HeatingSettings
									error={error}
									handleSkip={handleSkip}
									loading={readLoading}
									data={data}
									step={step}
									steps={steps}
									nextStep={nextStep}
									backStep={backStep}
									BottomNavigation={BottomNavigation}
									register={register}
									fields={fields}
								/>
							) : step === 5 ? (
								<BoilerSettings
									error={error}
									data={data}
									set={set}
									step={step}
									steps={steps}
									nextStep={nextStep}
									backStep={backStep}
									BottomNavigation={BottomNavigation}
									register={register}
									fields={fields}
									finishStep={finishStep}
									submit={submit}
									loading={updateLoading || restartLoading || restartingHeatPump || readLoading}
								/>
							) : step === 6 ? (
								<ErrorStatus
									error={error}
									fields={fields}
									step={step}
									steps={steps}
									nextStep={nextStep}
									backStep={backStep}
									BottomNavigation={BottomNavigation}
									loading={restartingHeatPump || restartLoading || readLoading}
								/>
							) : (
								<Typography variant="h6">{t("Errors.UnkownErrorWizard")}</Typography>
							)}
						</Paper>
					))}
				</Container>
			</Box>
		</Page>
	)
}
