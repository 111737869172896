import { ApolloCache, InMemoryCache } from "@apollo/client"
import { Save } from "@mui/icons-material"
import { LinearProgress, Typography } from "@mui/material"
import {
	PvtHeatPumpAdminSecurityFragment,
	PvtHeatPumpAdminSecurityFragmentDoc,
	PvtHeatPumpInput,
	UpdatePvtHeatPumpSettingsMutation,
	UpdatePvtHeatPumpSettingsMutationVariables,
	useReadHeatPumpSecuritySettingsQuery,
	useUpdatePvtHeatPumpSettingsMutation,
} from "generated/graphql"
import useForm from "hooks/useForm"
import NumberInput from "inputs/NumberInput"
import ErrorCard from "pages/HeatPumpStatusPage/components/ErrorCard"
import { useMessage } from "providers/MessageProvider"
import { useTranslation } from "react-i18next"
import onlyChangedValues from "tools/onlyChangedValues"
import BooleanInput from "inputs/BooleanInput"
import { PvtHeatPumpData } from "../index"
import appendErrorMessage from "tools/appendErrorMessage"
import FloatingFab from "components/FloatingFab"
import InterfaceSaveTypeAlert from "components/InterfaceSaveTypeAlert"
import { StatusMessageProps } from "components/BatchUpdate"
import handleSettingsError from "tools/handleSettingsError"
import { GraphQLError } from "graphql/error"
const CELCIUS = "°C"

function updatePvtHeatPumpCache(
	cache: ApolloCache<InMemoryCache>,
	{ data, errors }: { data?: UpdatePvtHeatPumpSettingsMutation | null; errors?: readonly GraphQLError[] | undefined },
	{ variables }: { variables?: UpdatePvtHeatPumpSettingsMutationVariables },
) {
	if (!data?.updatePvtHeatPump || !variables) return
	const { pvtHeatPumpdata: inputData, interfaceIds } = variables
	const ids = Array.isArray(interfaceIds) && interfaceIds.length > 0 ? interfaceIds : []

	const interfaceIdsWithErrors = errors
		?.filter((error) => typeof error.extensions.interfaceID === "string")
		.map((error) => error.extensions.interfaceID)

	const onlineInterfaceIds = ids.filter((id) => !interfaceIdsWithErrors?.includes(id))

	onlineInterfaceIds.forEach((defaultInterfaceId) => {
		const id = `PvtHeatPumpData:${defaultInterfaceId}`
		const oldPvtHeatPump = cache.readFragment<PvtHeatPumpAdminSecurityFragment>({
			id,
			fragment: PvtHeatPumpAdminSecurityFragmentDoc,
			fragmentName: "PvtHeatPumpAdminSecurity",
		})
		if (!oldPvtHeatPump) return

		const newPvtHeatPump = (Object.keys(oldPvtHeatPump) as Array<keyof PvtHeatPumpAdminSecurityFragment>).reduce(
			(acc, key) => ({ ...acc, [key]: inputData[key as keyof PvtHeatPumpInput] ?? oldPvtHeatPump[key] }),
			{} as PvtHeatPumpAdminSecurityFragment,
		)

		cache.writeFragment<PvtHeatPumpAdminSecurityFragment>({
			id,
			fragment: PvtHeatPumpAdminSecurityFragmentDoc,
			fragmentName: "PvtHeatPumpAdminSecurity",
			data: newPvtHeatPump,
		})
	})
}

type Props = {
	defaultInterfaceId: string
	batchInterfaceIds?: string[]
	isPartialSave?: boolean
	handleSavingMessages?: (updateStatus: StatusMessageProps[]) => void | undefined
	handleSaveLoading?: (isLoading: boolean) => void
}

export default function SecuritySettings({
	defaultInterfaceId,
	batchInterfaceIds,
	isPartialSave,
	handleSavingMessages,
	handleSaveLoading,
}: Props) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage", "general", "heatPumpSettingsPage", "heatPumpErrorCard"])
	const message = useMessage()
	const { register, submit, reset, fields, set } = useForm<PvtHeatPumpInput>({}, handleSave)

	const isBatchUpdate = Array.isArray(batchInterfaceIds) && batchInterfaceIds.length > 0

	const { data, loading, error } = useReadHeatPumpSecuritySettingsQuery({
		variables: { interfaceId: defaultInterfaceId },
		onCompleted: (data) => {
			if (data.interface?.pvtHeatPump) {
				const { id: _id, __typename, ...rest } = data.interface.pvtHeatPump
				reset(rest)
			}
		},
	})

	const [updateSettings, { loading: loadingSave }] = useUpdatePvtHeatPumpSettingsMutation({
		update: updatePvtHeatPumpCache,
	})
	const changedFields = onlyChangedValues(data?.interface?.pvtHeatPump ?? {}, fields)

	async function handleSave() {
		if ((isPartialSave || isPartialSave === undefined) && Object.entries(changedFields).length === 0) {
			message.info(t("heatPumpSettingsPage:Errors.HPHasTheseSettings"))
			return
		}

		if (isBatchUpdate) {
			message.info(t("general:SavingSettingsPleaseWait"))
		}

		try {
			const response = await updateSettings({
				variables: {
					interfaceIds: isBatchUpdate ? batchInterfaceIds : [defaultInterfaceId],
					pvtHeatPumpdata: isBatchUpdate ? (isPartialSave ? changedFields : fields) : changedFields,
					controllerSettings: {},
					isExpert: true,
					isAdmin: false,
				},
				errorPolicy: isBatchUpdate ? "all" : "none", // If 1 or more interface is offline, the mutation will continue, This will ignore the error catch for GraphQL
			})

			if (handleSavingMessages) {
				handleSavingMessages([{ success: true }])
			}

			// Since we use errorPolicy: "all", the error won't return at catch error
			if (response?.errors) {
				handleSettingsError({ errorResponse: response.errors, handleSavingMessages, t })
			}
		} catch (e) {
			message.error(appendErrorMessage(t("heatPumpSettingsPage:Errors.UnkownSaving"), e))
		}

		message.success(t("heatPumpSettingsPage:SettingsSaved"))

		if (handleSaveLoading) {
			handleSaveLoading(loadingSave)
		}
	}

	const resetField = (field: string) => {
		if (!field || !data?.interface?.pvtHeatPump) return

		const pvtHeatPumpData: PvtHeatPumpData = data?.interface.pvtHeatPump
		const value = pvtHeatPumpData[field]

		set({
			[field]: value,
		})
	}

	if (loading) {
		return <LinearProgress />
	}

	if (error || data?.interface == null) {
		return <ErrorCard error={error} interfaceId={defaultInterfaceId} />
	}

	return (
		<>
			{batchInterfaceIds && batchInterfaceIds.length > 0 && <InterfaceSaveTypeAlert isPartialSave={isPartialSave} />}

			<NumberInput
				label={t("MaximumCondenserTemperature")}
				{...register("maxCondensationTemp", { required: true, min: 40, max: 80 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"maxCondensationTemp" in changedFields}
				reset={() => resetField("maxCondensationTemp")}
			/>

			<NumberInput
				label={t("MinimumSourceTemperature")}
				{...register("sourceMinTemp", { required: true, min: -30, max: 20 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"sourceMinTemp" in changedFields}
				reset={() => resetField("sourceMinTemp")}
			/>

			<NumberInput
				label={t("MaximumSourceTemperature")}
				{...register("sourceMaxTemp", { required: true, min: 20, max: 80 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"sourceMaxTemp" in changedFields}
				reset={() => resetField("sourceMaxTemp")}
			/>

			<NumberInput
				label={t("MaximumHotGasTemperature")}
				{...register("maxDischargeTemp", { required: true, min: 50, max: 150 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"maxDischargeTemp" in changedFields}
				reset={() => resetField("maxDischargeTemp")}
			/>

			<NumberInput
				label={t("MaximumSourceTemperatureForCooling")}
				{...register("maxLiquidlineCoolTemp", { required: true, min: 10, max: 70 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"maxLiquidlineCoolTemp" in changedFields}
				reset={() => resetField("maxLiquidlineCoolTemp")}
			/>

			<NumberInput
				label={t("MinimumEvaporationTemperatureForCooling")}
				{...register("minEvapCoolTemp", { required: true, min: -40, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"minEvapCoolTemp" in changedFields}
				reset={() => resetField("minEvapCoolTemp")}
			/>

			<NumberInput
				label={t("CompressorOffToOnDelayTime")}
				{...register("compressorOffToOnDelaySec", { required: true, min: 10, max: 3600 })}
				unit={t("general:seconds")}
				disabled={loading}
				fieldIsChanged={"compressorOffToOnDelaySec" in changedFields}
				reset={() => resetField("compressorOffToOnDelaySec")}
			/>

			<NumberInput
				label={t("CompressorStartDelayTime")}
				{...register("startupTimeSec", { required: true, min: 5, max: 1200 })}
				unit={t("general:seconds")}
				disabled={loading}
				fieldIsChanged={"startupTimeSec" in changedFields}
				reset={() => resetField("startupTimeSec")}
			/>

			<NumberInput
				label={t("MaximumEvaporationTemperatureForHeating")}
				{...register("evapMaxTempHeating", { required: true, min: 10, max: 70 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"evapMaxTempHeating" in changedFields}
				reset={() => resetField("evapMaxTempHeating")}
			/>

			<NumberInput
				label={t("TNoCapEvaporator")}
				{...register("evapNoCapDt", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"evapNoCapDt" in changedFields}
				reset={() => resetField("evapNoCapDt")}
			/>

			<NumberInput
				label={t("TNoCapCondenser")}
				{...register("condenserNoCapDt", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"condenserNoCapDt" in changedFields}
				reset={() => resetField("condenserNoCapDt")}
			/>

			<NumberInput
				label={t("CondenserTemperatureLimit")}
				{...register("condenserMaxTemp", { required: true, min: 20, max: 80 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"condenserMaxTemp" in changedFields}
				reset={() => resetField("condenserMaxTemp")}
			/>

			<NumberInput
				label={t("TNoRefigEvaporator")}
				{...register("evapNoRefigDt", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"evapNoRefigDt" in changedFields}
				reset={() => resetField("evapNoRefigDt")}
			/>

			<NumberInput
				label={t("TNoRefigCondenser")}
				{...register("condenserNoRefigDt", { required: true, min: 0, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"condenserNoRefigDt" in changedFields}
				reset={() => resetField("condenserNoRefigDt")}
			/>

			<NumberInput
				label={t("MinimumDeliveryTemperatureForDHWMode")}
				{...register("dhwSinkMinTemp", { required: true, min: -10, max: 30 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"dhwSinkMinTemp" in changedFields}
				reset={() => resetField("dhwSinkMinTemp")}
			/>

			<NumberInput
				label={t("MaximumDeliveryTemperatureForDHWMode")}
				{...register("dhwSinkMaxTemp", { required: true, min: -10, max: 100 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"dhwSinkMaxTemp" in changedFields}
				reset={() => resetField("dhwSinkMaxTemp")}
			/>

			<NumberInput
				label={t("MinimumEvaporationTemperatureForHeating")}
				{...register("evapMinBt2HeatTemp", { required: true, min: -30, max: 20 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"evapMinBt2HeatTemp" in changedFields}
				reset={() => resetField("evapMinBt2HeatTemp")}
			/>

			<NumberInput
				label={t("MinimumTimeInHeatingActiveOrCombinationMode")}
				{...register("spaceActiveOrCombinedMinTime", { required: true, min: 5, max: 60 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"spaceActiveOrCombinedMinTime" in changedFields}
				reset={() => resetField("spaceActiveOrCombinedMinTime")}
			/>

			<NumberInput
				label={t("MinimumTimeInDHWActiveOrCombinationMode")}
				{...register("dhwActiveOrCombinedMinTime", { required: true, min: 5, max: 60 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"dhwActiveOrCombinedMinTime" in changedFields}
				reset={() => resetField("dhwActiveOrCombinedMinTime")}
			/>

			<BooleanInput
				label={t("ReleaseResistanceHeaterOnFault")}
				disabled={loading}
				{...register("backupByErrorEnabled")}
				fieldIsChanged={"backupByErrorEnabled" in changedFields}
				reset={() => resetField("backupByErrorEnabled")}
			/>

			<NumberInput
				label={t("HighDeliveryTemperatureEnvelope")}
				{...register("envelopeSinkHighTemp", { required: true, min: 50, max: 100 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"envelopeSinkHighTemp" in changedFields}
				reset={() => resetField("envelopeSinkHighTemp")}
			/>

			<NumberInput
				label={t("LowDeliveryTemperatureEnvelope")}
				{...register("envelopeSinkLowTemp", { required: true, min: 40, max: 80 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"envelopeSinkLowTemp" in changedFields}
				reset={() => resetField("envelopeSinkLowTemp")}
			/>

			<NumberInput
				label={t("HighBackupSourceEnvelope")}
				{...register("envelopeBackupSourceHighTemp", { required: true, min: -30, max: 10 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"envelopeBackupSourceHighTemp" in changedFields}
				reset={() => resetField("envelopeBackupSourceHighTemp")}
			/>

			<NumberInput
				label={t("LowBackupSourceEnvelope")}
				{...register("envelopeBackupSourceLowTemp", { required: true, min: -50, max: 0 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"envelopeBackupSourceLowTemp" in changedFields}
				reset={() => resetField("envelopeBackupSourceLowTemp")}
			/>

			<NumberInput
				label={t("BackupDHWOvershootHysteresisEnvelope")}
				{...register("envelopeBackupDhwGoBackHystTemp", { required: true, min: 1, max: 20 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"envelopeBackupDhwGoBackHystTemp" in changedFields}
				reset={() => resetField("envelopeBackupDhwGoBackHystTemp")}
			/>

			<NumberInput
				label={t("BackupSHOvershootHysteresisEnvelope")}
				{...register("envelopeBackupShGoBackHystTemp", { required: true, min: 1, max: 20 })}
				unit={CELCIUS}
				disabled={loading}
				fieldIsChanged={"envelopeBackupShGoBackHystTemp" in changedFields}
				reset={() => resetField("envelopeBackupShGoBackHystTemp")}
			/>

			<FloatingFab submit={submit} loading={loading} fitBottom={isBatchUpdate} batchLoading={loadingSave}>
				<Save />
				<Typography variant="button">{t("SaveSecuritySettings")}</Typography>
			</FloatingFab>
		</>
	)
}
