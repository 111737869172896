import { useState } from "react"
import { styled } from "@mui/material/styles"
import { Avatar, Button, Box, Container, Typography } from "@mui/material"
import { Navigate, useParams } from "react-router-dom"
import { LockOutlined } from "@mui/icons-material"
import { useAuth } from "providers/AuthProvider"
import { useMessage } from "providers/MessageProvider"
import useForm from "hooks/useForm"
import TextInput from "inputs/TextInput"
import useQueryParam from "hooks/useQueryParam"
import { NOT_FOUND } from "settings/url"
import appendErrorMessage from "tools/appendErrorMessage"
import { useTranslation } from "react-i18next"
import { Copyright } from "components/Copyright"
import FormLogo from "components/FormLogo"

const StyledAvatar = styled(Avatar)(({ theme }) => ({
	margin: theme.spacing(1),
	backgroundColor: theme.palette.secondary.main,
}))

const StyledContent = styled("div")(({ theme }) => ({
	marginTop: theme.spacing(8),
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
}))

const StyledForm = styled("form")(({ theme }) => ({
	width: "100%", // Fix IE 11 issue.
	marginTop: theme.spacing(1),
}))

const StyledSubmit = styled(Button)(({ theme }) => ({
	margin: theme.spacing(3, 0, 2),
}))

type ResetPasswordInput = {
	email: string
	password: string
	repeatPassword: string
}

export default function ResetPasswordPage() {
	const { t } = useTranslation(["accountCreation", "general"])
	const { code } = useParams<"code">()
	if (!code) return <Navigate to={`/${NOT_FOUND}`} /> // should never happen, but just in case, navigate to not found page

	const auth = useAuth()
	const message = useMessage()
	const [loading, setLoading] = useState(false)
	const email = useQueryParam("email")
	const { submit, register } = useForm<ResetPasswordInput>(
		{ email: email ?? "", password: "", repeatPassword: "" },
		handleSubmit
	)

	async function handleSubmit(user: ResetPasswordInput) {
		if (user.repeatPassword !== user.password) {
			message.error(t("PasswordErrors.DoNotMatch"))
			return
		}

		if (!user.password || user.password.length < 10) {
			message.error(t("PasswordErrors.MustBeMin10Chars"))
			return
		}

		setLoading(true)
		try {
			if (!code) throw new Error(t("PasswordErrors.ResetCodeNotFound"))
			await auth.resetPassword(code, {
				email: user.email ?? "",
				password: user.password ?? "",
			})
			message.success(t("PasswordHasBeenReset"))
			try {
				await auth.signIn(user.email, user.password)
			} catch (e) {
				message.error(t("PasswordErrors.LoginFailed"))
			}
		} catch (e) {
			setLoading(false)
			if (e instanceof Error) {
				if (e.message.includes("Unknown email")) {
					message.error(t("PasswordErrors.EmailUnknownOrLinkExpired"))
					return
				} else if (e.message.includes("Password insecure")) {
					message.error(t("PasswordErrors.MsgInsecure"))
					return
				}
			}
			message.error(appendErrorMessage(t("Errors.ActivationFailed"), e))
		}
	}

	if (auth.user) {
		return <Navigate to="/" />
	}

	return (
		<Container component="main" maxWidth="xs">
			<StyledContent>
				<FormLogo />
				<StyledAvatar>
					<LockOutlined />
				</StyledAvatar>
				<Typography component="h1" variant="h5">
					{t("ResetPassword")}
				</Typography>
				<StyledForm onSubmit={submit}>
					<TextInput
						variant="outlined"
						label={t("general:Email")}
						autoComplete="email"
						type="email"
						autoFocus={!email}
						disabled={Boolean(email)}
						{...register("email", { required: true, email: true })}
					/>
					<TextInput
						variant="outlined"
						label={t("general:Password")}
						type="password"
						autoComplete="new-password"
						autoFocus={Boolean(email)}
						{...register("password", { required: true })}
					/>
					<TextInput
						variant="outlined"
						label={t("general:RepeatPassword")}
						type="password"
						autoComplete="new-password"
						{...register("repeatPassword", { required: true })}
					/>
					<StyledSubmit type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
						{t("SavePassword")}
					</StyledSubmit>
				</StyledForm>
			</StyledContent>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>
	)
}
