import { IconButton, InputBase } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Cancel, Search } from "@mui/icons-material"
import { alpha } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

const PREFIX = "MenuInput"

const classes = {
	mapContainer: `${PREFIX}-mapContainer`,
	search: `${PREFIX}-search`,
	searchIcon: `${PREFIX}-searchIcon`,
	inputRoot: `${PREFIX}-inputRoot`,
	inputInput: `${PREFIX}-inputInput`,
}

const StyledClear = styled(IconButton)(() => ({
	color: "inherit",
}))

const Root = styled("div")(({ theme }) => ({
	[`& .${classes.mapContainer}`]: {
		margin: -theme.spacing(2),
	},

	[`&.${classes.search}`]: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		"&:hover": {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: "100%",
	},

	[`& .${classes.searchIcon}`]: {
		width: theme.spacing(9),
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},

	[`& .${classes.inputRoot}`]: {
		color: "inherit",
		width: "100%",
	},

	[`& .${classes.inputInput}`]: {
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(10),
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: 300,
			"&:focus": {
				width: 350,
			},
		},
		[theme.breakpoints.up("md")]: {
			width: 300,
			"&:focus": {
				width: 400,
			},
		},
	},
}))

export type MenuInputProps = {
	value?: string
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
	onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
	onClear?: (event: React.MouseEvent<HTMLButtonElement>) => void
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
}

export default function MenuInput({ value, onChange, onBlur, onClick, onClear, onFocus }: MenuInputProps) {
	const { t } = useTranslation()
	return (
		<Root className={classes.search}>
			<div className={classes.searchIcon}>
				<Search />
			</div>
			<InputBase
				id="client-search"
				placeholder={t("Search")}
				autoComplete="off"
				onBlur={onBlur}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				value={value}
				onChange={onChange}
				onClick={onClick}
				endAdornment={
					onClear && (
						<StyledClear id="clear-search" onClick={(e) => onClear(e)}>
							<Cancel />
						</StyledClear>
					)
				}
				onFocus={onFocus}
			/>
		</Root>
	)
}
