import { useMemo, useState } from "react"
import Table, { Label } from "components/Table"
import { Typography } from "@mui/material"
import { GroupFieldsFragment, GroupFieldsSettingsFragment, useGroupsQuery } from "generated/graphql"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import Fuse from "fuse.js"
import UpdateOrganization from "components/forms/UpdateOrganization"

function getLabels(t: TFunction<"general">): Label<GroupFieldsSettingsFragment>[] {
	return [
		{
			key: "name",
			name: t("Name"),
			sortable: true,
			resolve: (group) => group.name,
		},
		{
			key: "members",
			name: t("Members"),
			sortable: true,
			resolve: (group) => group?.members?.length ?? 0,
		},
		{
			key: "interfaces",
			name: t("Interfaces"),
			sortable: true,
			resolve: (group) => group?.interfaces?.length ?? 0,
		},
	]
}

export interface SearchProps {
	search: string
}

const options = {
	includeScore: true,
	threshold: 0.4,
	keys: ["members", "name", "interfaces"],
}

export default function Organization({ search }: SearchProps) {
	const { t, i18n } = useTranslation(["general", "groupManagement"])
	const { data, loading, error } = useGroupsQuery()
	const labels: Label<GroupFieldsSettingsFragment>[] = useMemo(() => getLabels(t), [i18n.language])
	const [group, setGroup] = useState<GroupFieldsFragment | null>(null)

	const fuse = new Fuse(data?.groups ?? [], options)
	const groups = search ? fuse.search(search).map(({ item }) => item) : data?.groups ?? []

	const handleEdit = (id: string) => {
		const foundGroup = groups.find((u) => u?.id === id)
		if (!foundGroup) {
			return
		}
		setGroup(foundGroup)
	}

	if (loading) return <Typography>{t("groupManagement:FetchingGroups")}</Typography>
	if (error) return <Typography>{t("groupManagement:FetchingGroups")}</Typography>

	return (
		<>
			<Table defaultItemsPerPage={20} rows={groups} labels={labels} loading={loading} onClickRow={handleEdit} />
			{group && (
				<UpdateOrganization
					group={group}
					onClose={() => {
						setGroup(null)
					}}
				/>
			)}
		</>
	)
}
