import { Card, Divider, Grid, Typography } from "@mui/material"
import EditableInterfaceName from "components/EditableInterfaceName"
import PageWithStatusBar from "components/PageWithStatusBar"
import { InterfacesFieldsFragment, Role } from "generated/graphql"
import useHasRole from "hooks/useHasRole"
import useSize from "hooks/useSize"
import { Navigate, useParams } from "react-router-dom"
import { NOT_FOUND } from "settings/url"
import AdminStatus from "./components/AdminStatus"
import InterfaceEventLog from "./components/InterfaceEventLog"
import FirmwareUpdates from "./components/firmwareUpdates/FirmwareUpdates"
import ControllerSettings from "./components/ControllerSettings"
import { useTranslation } from "react-i18next"
import DmSettings from "./components/DmSettings"
import DHWSettings from "./components/DHWSettings"
import HeatingCoolingSettings from "./components/HeatingCoolingSettings"
import PumpSettings from "./components/PumpSettings"
import GeneralSettings from "./components/GeneralSettings"
import SecuritySettings from "./components/SecuritySettings"
import ManualSettings from "./components/ManualSettings"
import DateTimeSettings from "./components/DateTimeSettings"
import { StatusMessageProps } from "components/BatchUpdate"
import AccordionItem from "components/AccordionItem"

export interface PvtHeatPumpData {
	[key: string]: string | boolean | number | null
}

type Props = {
	interfaces?: InterfacesFieldsFragment[]
	defaultInterfaceId: string
	handleToggleSavingType?: (id: string) => void
	isPartialSave?: Record<string, boolean>
	handleSavingMessages?: (updateStatus: StatusMessageProps[]) => void
	handleSaveLoading?: (isLoading: boolean) => void
}

export function AdminSettingsAccordion({
	interfaces,
	defaultInterfaceId,
	handleToggleSavingType,
	isPartialSave,
	handleSavingMessages,
	handleSaveLoading,
}: Props) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage"])

	const isBatchUpdate = Array.isArray(interfaces) && interfaces.length > 0
	const onlineInterfaceIds = isBatchUpdate ? interfaces.filter((item) => item.isOnline).map((item) => item.id) : []

	const isAdmin = useHasRole([Role.Admin])
	const isInternal = useHasRole([Role.Admin, Role.Mechanic])

	if (!isBatchUpdate && !defaultInterfaceId) return <Navigate to={`/${NOT_FOUND}`} /> // should never happen, but just in case, navigate to not found page

	return (
		<>
			<AccordionItem
				item="dhw"
				title={t("DomesticHotWater")}
				isBatchUpdate={isBatchUpdate}
				interfaces={interfaces}
				isPartialSave={isPartialSave}
				handleToggleSavingType={handleToggleSavingType}
			>
				<Divider />
				<DHWSettings
					handleSaveLoading={isBatchUpdate ? handleSaveLoading : undefined}
					handleSavingMessages={isBatchUpdate ? handleSavingMessages : undefined}
					defaultInterfaceId={defaultInterfaceId}
					batchInterfaceIds={onlineInterfaceIds}
					isPartialSave={isPartialSave?.["dhw"]}
				/>
			</AccordionItem>

			<AccordionItem
				item="heatingCooling"
				title={t("HeatingCooling")}
				isBatchUpdate={isBatchUpdate}
				interfaces={interfaces}
				isPartialSave={isPartialSave}
				handleToggleSavingType={handleToggleSavingType}
			>
				<Divider />
				<HeatingCoolingSettings
					handleSaveLoading={isBatchUpdate ? handleSaveLoading : undefined}
					handleSavingMessages={isBatchUpdate ? handleSavingMessages : undefined}
					defaultInterfaceId={defaultInterfaceId}
					batchInterfaceIds={onlineInterfaceIds}
					isPartialSave={isPartialSave?.["heatingCooling"]}
				/>
			</AccordionItem>

			<AccordionItem
				item="pumps"
				title={t("Pumps")}
				isBatchUpdate={isBatchUpdate}
				interfaces={interfaces}
				isPartialSave={isPartialSave}
				handleToggleSavingType={handleToggleSavingType}
			>
				<Divider />
				<PumpSettings
					handleSaveLoading={isBatchUpdate ? handleSaveLoading : undefined}
					handleSavingMessages={isBatchUpdate ? handleSavingMessages : undefined}
					defaultInterfaceId={defaultInterfaceId}
					batchInterfaceIds={onlineInterfaceIds}
					isPartialSave={isPartialSave?.["pumps"]}
				/>
			</AccordionItem>

			<AccordionItem
				item="general"
				title={t("General")}
				isBatchUpdate={isBatchUpdate}
				interfaces={interfaces}
				isPartialSave={isPartialSave}
				handleToggleSavingType={handleToggleSavingType}
			>
				<Divider />
				<GeneralSettings
					handleSaveLoading={isBatchUpdate ? handleSaveLoading : undefined}
					handleSavingMessages={isBatchUpdate ? handleSavingMessages : undefined}
					defaultInterfaceId={defaultInterfaceId}
					batchInterfaceIds={onlineInterfaceIds}
					isPartialSave={isPartialSave?.["general"]}
				/>
			</AccordionItem>

			{isInternal && (
				<AccordionItem
					item="security"
					title={t("Security")}
					isBatchUpdate={isBatchUpdate}
					interfaces={interfaces}
					isPartialSave={isPartialSave}
					handleToggleSavingType={handleToggleSavingType}
				>
					<Divider />
					<SecuritySettings
						handleSaveLoading={isBatchUpdate ? handleSaveLoading : undefined}
						handleSavingMessages={isBatchUpdate ? handleSavingMessages : undefined}
						defaultInterfaceId={defaultInterfaceId}
						batchInterfaceIds={onlineInterfaceIds}
						isPartialSave={isPartialSave?.["security"]}
					/>
				</AccordionItem>
			)}

			{!isBatchUpdate && isAdmin && (
				<AccordionItem title={t("ManualOverride")}>
					<Divider />
					<ManualSettings defaultInterfaceId={defaultInterfaceId} />
				</AccordionItem>
			)}

			<AccordionItem
				item="dateTime"
				title={t("DateTime")}
				isBatchUpdate={isBatchUpdate}
				interfaces={interfaces}
				isPartialSave={isPartialSave}
				handleToggleSavingType={handleToggleSavingType}
			>
				<Divider />
				<DateTimeSettings
					handleSaveLoading={isBatchUpdate ? handleSaveLoading : undefined}
					handleSavingMessages={isBatchUpdate ? handleSavingMessages : undefined}
					defaultInterfaceId={defaultInterfaceId}
					batchInterfaceIds={onlineInterfaceIds}
					isPartialSave={isPartialSave?.["dateTime"]}
				/>
			</AccordionItem>

			<AccordionItem
				item="controller"
				title={t("ControllerSettings")}
				isBatchUpdate={isBatchUpdate}
				interfaces={interfaces}
				isPartialSave={isPartialSave}
				handleToggleSavingType={handleToggleSavingType}
			>
				<ControllerSettings
					handleSaveLoading={isBatchUpdate ? handleSaveLoading : undefined}
					handleSavingMessages={isBatchUpdate ? handleSavingMessages : undefined}
					defaultInterfaceId={defaultInterfaceId}
					batchInterfaceIds={onlineInterfaceIds}
					isPartialSave={isPartialSave?.["controller"]}
				/>
			</AccordionItem>

			<AccordionItem
				item="dmSettings"
				title={t("HeatingCurveSettings")}
				isBatchUpdate={isBatchUpdate}
				interfaces={interfaces}
				isPartialSave={isPartialSave}
				handleToggleSavingType={handleToggleSavingType}
			>
				<DmSettings
					handleSaveLoading={isBatchUpdate ? handleSaveLoading : undefined}
					handleSavingMessages={isBatchUpdate ? handleSavingMessages : undefined}
					defaultInterfaceId={defaultInterfaceId}
					batchInterfaceIds={onlineInterfaceIds}
					isPartialSave={isPartialSave?.["dmSettings"]}
				/>
			</AccordionItem>

			{!isBatchUpdate && (
				<>
					<AccordionItem title={t("ErrorChangeLog")}>
						<InterfaceEventLog interfaceId={defaultInterfaceId} />
					</AccordionItem>

					{isInternal && (
						<AccordionItem title={t("AdminStatus")}>
							<AdminStatus interfaceId={defaultInterfaceId} />
						</AccordionItem>
					)}

					<AccordionItem title={t("FirmwareUpdates")}>
						<FirmwareUpdates interfaceId={defaultInterfaceId} />
					</AccordionItem>
				</>
			)}
		</>
	)
}

export default function HPAdminSettings() {
	const { interfaceId } = useParams<"interfaceId">()
	const smallScreen = useSize("down", "md")

	if (!interfaceId) return <Navigate to={`/${NOT_FOUND}`} /> // should never happen, but just in case, navigate to not found page

	return (
		<PageWithStatusBar
			navigationName="admin"
			noPadding={smallScreen}
			statusFromNetwork
			interfaceId={interfaceId}
			backButton
		>
			<Grid container direction="column" alignItems="center">
				<Grid
					item
					sx={{
						width: smallScreen ? "100%" : 750,
						mt: 1,
						mb: 8,
					}}
				>
					<Card>
						<Typography variant="h4" textAlign="left" margin={1} marginLeft={2}>
							<EditableInterfaceName interfaceId={interfaceId} prefix="Admin instellingen van " shouldRender />
						</Typography>
					</Card>

					<AdminSettingsAccordion defaultInterfaceId={interfaceId} />
				</Grid>
			</Grid>
		</PageWithStatusBar>
	)
}
