import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import EditableField from "components/EditableField"
import { GroupFieldsFragment, useUpdateGroupNameMutation } from "generated/graphql"
import { useMessage } from "providers/MessageProvider"
import { useState } from "react"
import appendErrorMessage from "tools/appendErrorMessage"
import { FormatUserOrGroup as Group } from "components/FormatUserOrGroup"
import { useTranslation } from "react-i18next"

const StyledTypography = styled(Typography)`
	display: inline-block;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
`

type Props = {
	group: Omit<GroupFieldsFragment, "members">
	hideEdit?: boolean
}

export default function EditableGroupName({ group, hideEdit }: Props) {
	const { t } = useTranslation("groupManagement")
	const message = useMessage()
	const [groupName, setGroupName] = useState(group.name ?? "")
	const [updateGroupName, { loading }] = useUpdateGroupNameMutation()
	const changeGroupName = async () => {
		if (groupName === group.name) {
			message.info(t("Errors.OrgHasThisName"))
			return
		}
		try {
			await updateGroupName({ variables: { groupId: group.id, name: groupName } })
			message.success(t("NameChanged"))
		} catch (e) {
			message.error(appendErrorMessage(t("Errors.UnkownErrorChangingName"), e))
		}
	}

	if (hideEdit || group.isDeleted)
		return (
			<StyledTypography>
				<Group>{group}</Group>
			</StyledTypography>
		)

	return (
		<EditableField
			value={groupName}
			onChange={(e) => setGroupName(e.target.value)}
			onBlur={changeGroupName}
			disabled={loading}
		/>
	)
}
