import { Stack, Typography, Button, LinearProgress, Box } from "@mui/material"
import {
	GetCurrentUpdateStatusDocument,
	PvtHeatPumpFirmwareVersionExpertFragment,
	UpdateProgress,
	useGetCurrentFirmwareVersionExpertQuery,
	useGetCurrentUpdateStatusQuery,
	useGetPvtHeatPumpFirmwareUpdatesExpertQuery,
	useInterfaceNameQuery,
	useUpdatePvtHeatPumpFirmwareMutation,
} from "generated/graphql"
import { useConfirm } from "providers/ConfirmProvider"
import { useMessage } from "providers/MessageProvider"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { INTERFACE_V1_2_2_TIMESTAMP, isPvtHeatPumpFirmwareNewerOrEqual } from "settings/firmwareVersions"
import appendErrorMessage from "tools/appendErrorMessage"

type Props = {
	interfaceId: string
}
export default function PvtHeatPumpFirmwareUpdatesExpert({ interfaceId }: Props) {
	const { t } = useTranslation("pvtHeatPumpAdminPage")
	const message = useMessage()
	const confirm = useConfirm()
	const [availableFirmware, setAvailableFirmware] = useState<PvtHeatPumpFirmwareVersionExpertFragment>()

	const [updateHeatPump] = useUpdatePvtHeatPumpFirmwareMutation({
		refetchQueries: [{ query: GetCurrentUpdateStatusDocument, variables: { interfaceId } }],
	})
	const {
		data: currentFirmware,
		loading: currentFirmwareLoading,
		error: currentFirmwareError,
		refetch: refetchCurrentFirmware,
	} = useGetCurrentFirmwareVersionExpertQuery({
		variables: { interfaceId },
	})
	const { data: interfaceName } = useInterfaceNameQuery({
		variables: { interfaceId },
	})
	const { data: currentUpdateStatus } = useGetCurrentUpdateStatusQuery({
		variables: { interfaceId },
	})

	const { loading: getPvtHeatPumpFirmwaresLoading } = useGetPvtHeatPumpFirmwareUpdatesExpertQuery({
		onCompleted: (data) => {
			setAvailableFirmware(data?.getPvtHeatPumpFirmwareUpdates.at(0))
		},
	})

	useEffect(() => {
		if (currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.Completed) {
			refetchCurrentFirmware()
		}
	}, [currentUpdateStatus])

	const handleUpdateHeatPump = async () => {
		await confirm(
			t("MsgConfirmHeatPumpUpdate", {
				interfaceName: interfaceName?.interface?.name ?? interfaceId,
				firmwareVersionName: `V${availableFirmware?.version.toFixed(1)}`,
			}),
		)
		try {
			await updateHeatPump({
				variables: { interfaceIds: [interfaceId] },
			})

			message.success(t("HeatPumpUpdateStarted"))
		} catch (e) {
			message.error(appendErrorMessage(t("Errors.UpdatingFirmware"), e))
		}
	}

	if (getPvtHeatPumpFirmwaresLoading || currentFirmwareLoading) {
		return (
			<Box width="100%">
				<LinearProgress />
			</Box>
		)
	}

	if (currentFirmwareError) {
		if (currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.Completed) {
			return (
				<Typography color="error" gutterBottom>
					{t("Errors.GettingCurrentFirmwareVersion")}
				</Typography>
			)
		}

		return (
			<Typography color="primary" gutterBottom>
				{t("FirmwareUpdating")}
			</Typography>
		)
	}

	const isUpdateAvailable =
		currentFirmware?.interface?.pvtHeatPump?.firmwareVersion &&
		availableFirmware &&
		!isPvtHeatPumpFirmwareNewerOrEqual(
			currentFirmware?.interface?.pvtHeatPump?.firmwareVersion ?? 0,
			availableFirmware.version,
		)

	return (
		<Stack alignItems="flex-start" width="100%" spacing={2}>
			{currentFirmware?.interface?.pvtHeatPump?.firmwareVersion && (
				<Typography variant="h6">
					{t("CurrentVersion")}: {"V" + currentFirmware.interface.pvtHeatPump.firmwareVersion.toFixed(1)}
				</Typography>
			)}
			{new Date(currentFirmware?.interface?.firmwareVersion.timestamp ?? 0) < INTERFACE_V1_2_2_TIMESTAMP ? (
				<Typography color="error" gutterBottom>
					{t("MsgNoHPUpdateSupport")}
				</Typography>
			) : (
				<>
					{!isUpdateAvailable ? (
						<>
							<Typography variant="h5">{t("PvtHPUpToDate")}</Typography>
						</>
					) : (
						<>
							<Stack direction="row" spacing={1} display="flex" alignItems="center">
								<Typography>
									{t("NewPvtHPFirmwareAvailable")} V{availableFirmware.version.toFixed(1)}
								</Typography>
								<Button
									variant="contained"
									disabled={
										(currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.InProgress ||
											currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.Requested) &&
										Date.now() - new Date(currentUpdateStatus?.interface?.updateStatus?.time ?? "").valueOf() < 300000
									}
									onClick={handleUpdateHeatPump}
								>
									{t("Update")}
								</Button>
							</Stack>
						</>
					)}
				</>
			)}
		</Stack>
	)
}
