import { Skeleton, Stack } from "@mui/material"
import i18n from "i18n"
import ErrorComponent from "./ErrorComponent"
import { Suspense } from "react"

export type HeatPumpError = {
	name: string
	fatal?: boolean
	shortDescription?: JSX.Element
	longDescription?: JSX.Element
}

type Props = {
	errorCode: number
	align?: "left" | "right"
}

export function GetHeatPumpErrors(): HeatPumpError[] {
	return [
		{ name: `E-0 | ${i18n.t("heatPumpErrors:Errors.Unknown")}` },
		{
			name: i18n.t("heatPumpErrors:Errors.E-01"),
			fatal: true,
		},
		{
			name: i18n.t("heatPumpErrors:Errors.E-02"),
			fatal: true,
		},
		{
			name: i18n.t("heatPumpErrors:Errors.E-03"),
			fatal: true,
		},
		{
			name: i18n.t("heatPumpErrors:Errors.E-04"),
			fatal: true,
		},
		{
			name: i18n.t("heatPumpErrors:Errors.E-05"),
			fatal: true,
		},
		{
			name: i18n.t("heatPumpErrors:Errors.E-06"),
			fatal: true,
		},
		{
			name: i18n.t("heatPumpErrors:Errors.E-07"),
			fatal: true,
		},
		{
			name: i18n.t("heatPumpErrors:Errors.E-08"),
			fatal: true,
		},
		{
			name: i18n.t("heatPumpErrors:Errors.E-09"),
			fatal: true,
		},
		{
			name: i18n.t("heatPumpErrors:Errors.E-10"),
			fatal: true,
		},
		{ name: `E-11 | ${i18n.t("heatPumpErrors:Errors.Unknown")}` },
		{ name: `E-12 | ${i18n.t("heatPumpErrors:Errors.Unknown")}` },
		{ name: `E-13 | ${i18n.t("heatPumpErrors:Errors.Unknown")}` },
		{ name: `E-14 | ${i18n.t("heatPumpErrors:Errors.Unknown")}` },
		{ name: i18n.t("heatPumpErrors:Errors.E-15") },
		{ name: `E-16 | ${i18n.t("heatPumpErrors:Errors.Unknown")}` },
		{ name: `E-17 | ${i18n.t("heatPumpErrors:Errors.Unknown")}` },
		{ name: `E-18 | ${i18n.t("heatPumpErrors:Errors.Unknown")}` },
		{ name: `E-19 | ${i18n.t("heatPumpErrors:Errors.Unknown")}` },
		{ name: `E-20 | ${i18n.t("heatPumpErrors:Errors.Unknown")}` },
		{ name: `E-21 | ${i18n.t("heatPumpErrors:Errors.Unknown")}` },
		{ name: `E-22 | ${i18n.t("heatPumpErrors:Errors.Unknown")}` },
		{ name: i18n.t("heatPumpErrors:Errors.E-23") },
		{ name: i18n.t("heatPumpErrors:Errors.E-24") },
		{ name: i18n.t("heatPumpErrors:Errors.E-25") },
		{ name: i18n.t("heatPumpErrors:Errors.E-26"), fatal: true },
		{ name: i18n.t("heatPumpErrors:Errors.E-27") },
		{
			name: i18n.t("heatPumpErrors:Errors.E-28"),
			fatal: true,
		},
		{ name: i18n.t("heatPumpErrors:Errors.E-29") },
		{ name: i18n.t("heatPumpErrors:Errors.E-30") },
		{ name: i18n.t("heatPumpErrors:Errors.E-31"), fatal: true },
		{ name: i18n.t("heatPumpErrors:Errors.E-32") },
		{ name: i18n.t("heatPumpErrors:Errors.E-33") },
		{
			name: i18n.t("heatPumpErrors:Errors.E-34"),
			fatal: true,
		},
		{ name: i18n.t("heatPumpErrors:Errors.E-35") },
		{ name: i18n.t("heatPumpErrors:Errors.E-36") },
		{
			name: i18n.t("heatPumpErrors:Errors.E-37"),
			fatal: true,
		},
		{ name: i18n.t("heatPumpErrors:Errors.E-38") },
		{ name: i18n.t("heatPumpErrors:Errors.E-39") },
		{
			name: i18n.t("heatPumpErrors:Errors.E-40"),
			fatal: true,
		},
		{ name: i18n.t("heatPumpErrors:Errors.E-41") },
		{ name: i18n.t("heatPumpErrors:Errors.E-42") },
		{
			name: i18n.t("heatPumpErrors:Errors.E-43"),
			fatal: true,
		},
		{ name: i18n.t("heatPumpErrors:Errors.E-44") },
		{
			name: i18n.t("heatPumpErrors:Errors.E-45"),
			fatal: true,
		},
		{ name: i18n.t("heatPumpErrors:Errors.E-46") },
		{ name: i18n.t("heatPumpErrors:Errors.E-47") },
		{
			name: i18n.t("heatPumpErrors:Errors.E-48"),
			fatal: true,
		},
		{ name: i18n.t("heatPumpErrors:Errors.E-49") },
		{ name: i18n.t("heatPumpErrors:Errors.E-50") },
		{ name: i18n.t("heatPumpErrors:Errors.E-51"), fatal: true },
		{ name: i18n.t("heatPumpErrors:Errors.E-52") },
		{
			name: i18n.t("heatPumpErrors:Errors.E-53"),
			fatal: true,
		},
		{ name: i18n.t("heatPumpErrors:Errors.E-54") },
		{ name: i18n.t("heatPumpErrors:Errors.E-55") },
		{
			name: i18n.t("heatPumpErrors:Errors.E-56"),
			fatal: true,
		},
		{
			name: i18n.t("heatPumpErrors:Errors.E-57"),
			fatal: true,
		},
		{ name: i18n.t("heatPumpErrors:Errors.E-58") },
		{
			name: i18n.t("heatPumpErrors:Errors.E-59"),
			fatal: true,
		},
		{ name: i18n.t("heatPumpErrors:Errors.E-60") },
		{ name: i18n.t("heatPumpErrors:Errors.E-61") },
		{
			name: i18n.t("heatPumpErrors:Errors.E-62"),
			fatal: true,
		},
		{
			name: i18n.t("heatPumpErrors:Errors.E-63"),
			fatal: true,
		},
	]
}

export default function HeatPumpInfoMessage({ errorCode, align }: Props) {
	return (
		<Stack direction="row" justifyContent={(align ?? "right") === "right" ? "flex-end" : "flex-start"}>
			<Suspense fallback={<Skeleton width={100} />}>
				<ErrorComponent errorCode={errorCode} />
			</Suspense>
		</Stack>
	)
}
