import { Delete, Settings } from "@mui/icons-material"
import { CardContent, Stack, Typography, IconButton, ListItem, Button } from "@mui/material"
import HeatPumpInfoCard from "components/HeatPumpInfoCard"
import {
	InterfaceAccessLevel,
	InterfacesDocument,
	ReadHeatPumpGroupSettingsDocument,
	ReadHeatPumpGroupSettingsQuery,
	Role,
	useClaimInterfaceMutation,
	useRemoveInterfaceFromGroupMutation,
} from "generated/graphql"
import { useState } from "react"
import InterfaceGroupSettingsDialog from "./InterfaceGroupSettingsDialog"
import InviteUserToInterfaceDialog from "./InviteUserToInterfaceDialog"
import useHasRole from "hooks/useHasRole"
import { FormatUserOrGroup as Group, useFormatUserOrGroupString } from "components/FormatUserOrGroup"
import { Trans, useTranslation } from "react-i18next"
import appendErrorMessage from "tools/appendErrorMessage"
import { useMessage } from "providers/MessageProvider"
import { useNavigate, useParams } from "react-router-dom"
import { ErrCanNotRemoveOwner } from "resources/backendErrors"
import { useConfirm } from "providers/ConfirmProvider"
import { NOT_FOUND } from "settings/url"

type Props = {
	queryData: ReadHeatPumpGroupSettingsQuery
}

export default function GroupManagementCard({ queryData }: Props) {
	const { t, i18n } = useTranslation(["heatPumpManagement", "general", "searchHeatPump", "groupManagement"])
	const groupNameFormatter = useFormatUserOrGroupString()
	const confirm = useConfirm()
	const navigate = useNavigate()
	const message = useMessage()
	const isAppAdmin = useHasRole([Role.Admin, Role.Mechanic])
	const [groupIdToEdit, setGroupIdToEdit] = useState<string | null>(null)
	const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false)

	const [removeInterface, { loading: removeInterfaceLoading }] = useRemoveInterfaceFromGroupMutation({
		refetchQueries: [{ query: InterfacesDocument }],
	})

	const isSameGroup = queryData.interface?.groups.some((item) => item.id === queryData.me.group?.id)

	const myGroup = queryData.interface?.groups.find((gr) => gr.id === queryData.me.group?.id)
	const groupName = groupNameFormatter(myGroup)
	const groupToEdit = queryData.interface?.groups.find(({ id }) => id === groupIdToEdit) // do not put groupToEdit in state, to make sure it is up to date with Apollo cache
	const { interfaceId } = useParams<{ interfaceId: string }>()

	// user level checks
	const iAmHpOwner = queryData.interface?.accessLevel === InterfaceAccessLevel.Owner
	const iAmGroupAdmin = queryData.me.isGroupAdmin

	if (!interfaceId) {
		navigate(`/${NOT_FOUND}`)
		return
	}
	if (!queryData.interface) return

	const handleRemoveInterface = async () => {
		if (!myGroup) return

		await confirm(
			<Trans
				i18nKey="MsgConfirmRemoveIface"
				ns="groupManagement"
				values={{
					groupName: groupName,
					ifaceName: queryData.interface?.name ?? queryData.interface?.id ?? "",
				}}
				components={{ italic: <i /> }}
			/>,
		)
		/*App admins are the only ones that can still see this page.
		If an admin goes back to the HP after being redirected, the list of groups is not updated.
		This is because we only refetch the interfaces of the user as this usually is enough*/
		try {
			await removeInterface({ variables: { groupId: myGroup.id, interfaceId: queryData.interface?.id ?? "" } })
			message.success(t("groupManagement:HeatPumpRemoved"))
			navigate("/")
		} catch (e) {
			if (e instanceof Error && e.message.includes(ErrCanNotRemoveOwner)) {
				message.error(t("groupManagement:Errors.TransferAdminRightsBeforeRemovingHP"))
				return
			}
			message.error(appendErrorMessage(t("groupManagement:Errors.UnkownErrorRemovingHP"), e))
		}
	}

	const [claimInterface] = useClaimInterfaceMutation({
		refetchQueries: [
			{ query: InterfacesDocument },
			{
				query: ReadHeatPumpGroupSettingsDocument,
				variables: { interfaceId: interfaceId, isInternal: isAppAdmin },
			},
		],
	})

	const claimHeatPump = async () => {
		try {
			if (!queryData.me.group?.id) {
				throw t("searchHeatPump:Errors.yourOrgNotFound")
			}
			await claimInterface({ variables: { groupId: queryData.me.group.id, interfaceId } })
			message.success(t("searchHeatPump:HeatPumpClaimed"))
		} catch (e) {
			message.error(appendErrorMessage("Error claiming", e))
		}
	}

	return (
		<HeatPumpInfoCard title={t("AccessManagement")}>
			{queryData.interface && (
				<>
					{queryData.interface?.groups?.length > 0 ? (
						queryData.interface.groups.map((group) => {
							// current group checks
							const thisIsMyGroup = queryData.me.group?.id === group.id
							const thisGroupIsOwner = queryData.interface?.interfaceOwner?.id === group.id
							const groupIsDeleted = group?.isDeleted

							return (
								<ListItem key={`group-${group.id}`}>
									<Stack direction="row" justifyContent="space-between" width="100%">
										<Stack direction="column">
											<Typography variant="h5">
												<Group>{group}</Group>
											</Typography>
											{groupIsDeleted && (
												<Typography variant="body2">
													<i>{t("general:dateDeleted", { date: group.deletedAt ? new Date(group.deletedAt) : "" })}</i>
												</Typography>
											)}
											{queryData.me.group?.id !== group.id && group.interfaceName && (
												<Typography variant="body2">
													<i>
														{i18n.format(t("alias"))}: {group.interfaceName}
													</i>
												</Typography>
											)}
											{thisIsMyGroup && (
												<Typography variant="body2">
													<i>{t("general:CurrentUser")}</i>
												</Typography>
											)}
											{thisGroupIsOwner && (
												<Typography variant="body2">
													<i>{t("Owner")}</i>
												</Typography>
											)}
										</Stack>
										{!groupIsDeleted && ((iAmHpOwner && iAmGroupAdmin) || isAppAdmin) && !thisIsMyGroup && (
											<Stack direction="column" justifyContent="center">
												<IconButton onClick={() => setGroupIdToEdit(group.id)}>
													<Settings />
												</IconButton>
											</Stack>
										)}
										{(groupIsDeleted || thisIsMyGroup) && (iAmGroupAdmin || isAppAdmin) && (
											<Stack direction="column" justifyContent="center">
												<IconButton onClick={handleRemoveInterface} disabled={removeInterfaceLoading}>
													<Delete />
												</IconButton>
											</Stack>
										)}
									</Stack>
								</ListItem>
							)
						})
					) : (
						// Temporary internal only text
						// eslint-disable-next-line i18next/no-literal-string
						<Typography>{queryData.interface.id} has not been claimed</Typography>
					)}

					<Stack direction="row">
						{((queryData.interface?.accessLevel === InterfaceAccessLevel.Owner && queryData.me.isGroupAdmin) ||
							isAppAdmin) && (
							<CardContent>
								<Button variant="contained" onClick={() => setInviteUserDialogOpen(true)}>
									{t("InviteNewUser")}
								</Button>
							</CardContent>
						)}

						{isAppAdmin && (
							<>
								{queryData.interface.groups.length === 0 && (
									<CardContent>
										<Button onClick={claimHeatPump} variant="contained">
											{t("ClaimHeatPump")}
										</Button>
									</CardContent>
								)}

								{queryData.interface.groups.length > 0 && !isSameGroup && (
									<CardContent>
										<Button onClick={claimHeatPump} variant="contained">
											{t("AddToGroup")}
										</Button>
									</CardContent>
								)}
							</>
						)}
					</Stack>
				</>
			)}
			{groupToEdit && queryData.interface && (
				<InterfaceGroupSettingsDialog
					group={groupToEdit}
					interface={queryData.interface}
					onClose={() => setGroupIdToEdit(null)}
				/>
			)}
			{inviteUserDialogOpen && queryData.interface && (
				<InviteUserToInterfaceDialog iface={queryData.interface} onClose={() => setInviteUserDialogOpen(false)} />
			)}
		</HeatPumpInfoCard>
	)
}
