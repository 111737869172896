import { Divider, ListItemButton, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import Markdown from "markdown-to-jsx"
import { styled } from "@mui/material/styles"
import { formatMarkDown } from "components/forms/UpsertNotifications"
import {
	useMarkNotificationReadMutation,
	Language,
	UserNotificationsQuery,
	UserNotificationFieldsFragment,
} from "generated/graphql"
import { pickBestLanguage } from "tools/translation"
import { formatDate } from "date-fns"

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
	paddingTop: theme.spacing(2),
	["& ul"]: {
		paddingLeft: theme.spacing(2),
		marginBottom: theme.spacing(0.5),
	},
	["& li"]: {
		marginBottom: theme.spacing(0.5),
		listStyle: "disc",
		["& p"]: {
			margin: 0,
		},
	},
	["& img"]: {
		maxWidth: 200,
	},
	["& strong"]: {
		fontWeight: 500,
	},
}))

const StyledDateTypography = styled(Typography)({
	marginLeft: "auto",
	fontStyle: "italic",
})

interface Props {
	data: UserNotificationsQuery | undefined
}

export default function NotificationMenu({ data }: Props) {
	const { t, i18n } = useTranslation("general")

	const [readNotification] = useMarkNotificationReadMutation()

	const handleRead = async (id: string) => {
		await readNotification({ variables: { notificationId: id } })
	}

	const ListReleaseChanges = ({ data }: { data: UserNotificationFieldsFragment }) => {
		const currentLang = i18n.language
		const content = pickBestLanguage(currentLang as Language, data.content)
		const title = pickBestLanguage(currentLang as Language, data.title)

		return (
			<>
				<StyledListItemButton selected={!data?.read} onMouseLeave={() => !data?.read && handleRead(data.id)}>
					<Stack width="100%">
						<Markdown>{formatMarkDown(content ?? "")}</Markdown>

						<Stack direction="row" mt={1}>
							<Typography variant="body2" color="text.secondary" sx={{ fontStyle: "italic" }}>
								{title}
							</Typography>

							<StyledDateTypography variant="body2" color="text.secondary">
								{t("UpdatedAt", { date: formatDate(data.createdAt, "PP") })}
							</StyledDateTypography>
						</Stack>
					</Stack>
				</StyledListItemButton>
				<Divider />
			</>
		)
	}

	return (
		<Stack sx={{ whiteSpace: "pre-wrap" }} p={0} mt={-1} mb={-1} minWidth={350} width="100%">
			<Typography variant="h6" sx={{ p: 1, pl: 2 }}>
				{t("Notifications")}
			</Typography>
			<Divider />
			{data?.notifications && data.notifications.length > 0 ? (
				data.notifications.map((item, index) => <ListReleaseChanges key={index} data={item} />)
			) : (
				<p style={{ padding: "0 16px" }}>{t("NoNotifications")}</p>
			)}
		</Stack>
	)
}
