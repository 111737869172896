import { styled } from "@mui/material/styles"
import { FormControl, FormGroup, Checkbox, FormLabel, FormControlLabel } from "@mui/material"
import { Role } from "generated/graphql"
import roles from "settings/roles"

const StyledFormControl = styled(FormControl)(({ theme }) => ({
	marginTop: theme.spacing(2),
}))

type Props = {
	value?: Role[] | null
	onChange: (value: Role[]) => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
}

export default function RoleInput({ value: valueInput, error, required, onChange, label }: Props) {
	const value = valueInput ?? []
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			onChange([...value, e.target.name as Role])
		} else {
			onChange(value.filter((v) => v !== e.target.name))
		}
	}

	return (
		// <StyledFormControl component="fieldset" required>
		<StyledFormControl required>
			<FormLabel component="legend" error={error} required={required}>
				{label}
			</FormLabel>
			<FormGroup>
				{Object.entries(Role).map(([key, role]) => (
					<FormControlLabel
						key={key}
						control={<Checkbox checked={value.includes(role) ?? false} onChange={handleChange} name={role} />}
						label={roles[role]}
					/>
				))}
			</FormGroup>
		</StyledFormControl>
	)
}
