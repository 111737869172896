import { DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from "@mui/material"
import TSDialog from "components/TSDialog"
import {
	HeatPumpGroupFieldsFragment,
	InterfaceFieldsFragment,
	ReadHeatPumpGroupSettingsDocument,
	useRemoveGroupFromInterfaceMutation,
	Role,
	useTransferInterfaceOwnershipMutation,
} from "generated/graphql"
import { useFormatUserOrGroupString, FormatUserOrGroup as Group } from "components/FormatUserOrGroup"
import { Trans, useTranslation } from "react-i18next"
import appendErrorMessage from "tools/appendErrorMessage"
import { useConfirm } from "providers/ConfirmProvider"
import { ErrCanNotRemoveOwner } from "resources/backendErrors"
import useHasRole from "hooks/useHasRole"
import { useMessage } from "providers/MessageProvider"

type Props = {
	onClose: () => void
	group: HeatPumpGroupFieldsFragment
	interface: Pick<InterfaceFieldsFragment, "id" | "name">
}

export default function InterfaceGroupSettingsDialog({ group, interface: iface, onClose }: Props) {
	const { t } = useTranslation(["heatPumpManagement", "general"])
	const groupNameFormatter = useFormatUserOrGroupString()
	const confirm = useConfirm()
	const message = useMessage()
	const isInternal = useHasRole([Role.Admin, Role.Mechanic])

	const [removeGroup] = useRemoveGroupFromInterfaceMutation({
		refetchQueries: [{ query: ReadHeatPumpGroupSettingsDocument, variables: { interfaceId: iface.id, isInternal } }],
		awaitRefetchQueries: true,
	})

	const confirmMessageRevokeAccess = (
		<Trans
			i18nKey="RevokeAccessDialog.Title"
			ns="heatPumpManagement"
			components={{ italic: <i /> }}
			values={{
				groupName: groupNameFormatter(group),
				ifaceName: iface.name ?? iface.id,
			}}
		/>
	)

	const confirmMessageTransferOwnership = (
		<Trans
			i18nKey="TransferOwnershipDialog.Title"
			ns="heatPumpManagement"
			components={{ italic: <i /> }}
			values={{
				groupName: groupNameFormatter(group),
				ifaceName: iface.name ?? iface.id,
			}}
		/>
	)

	const [transferOwnership, { loading: transferOwnershipLoading }] = useTransferInterfaceOwnershipMutation({
		refetchQueries: [{ query: ReadHeatPumpGroupSettingsDocument, variables: { interfaceId: iface.id, isInternal } }],
		awaitRefetchQueries: true,
	})

	const handleTransferOwnership = async () => {
		await confirm(confirmMessageTransferOwnership)

		try {
			await transferOwnership({ variables: { groupId: group.id, interfaceId: iface.id } })
			message.success(t("TransferOwnershipDialog.TransferedOwnership"))
			onClose()
		} catch (e) {
			message.error(appendErrorMessage(t("TransferOwnershipDialog.Errors.Unkown"), e))
		}
	}

	const handleRevokeAccess = async () => {
		await confirm(confirmMessageRevokeAccess)

		try {
			await removeGroup({ variables: { groupId: group.id, interfaceId: iface.id } })
			message.success(t("RevokeAccessDialog.OrganizationNoAccess"))
		} catch (e) {
			if (e instanceof Error && e.message.includes(ErrCanNotRemoveOwner)) {
				message.error(t("RevokeAccessDialog.Errors.RemoveOwner"))
				return
			}
			message.error(appendErrorMessage(t("RevokeAccessDialog.Errors.Unkown"), e))
		}
	}

	return (
		<TSDialog autoFullScreen open onClose={onClose}>
			<DialogTitle>
				{t("InterfaceGroupSettingsDialog.AccessSettingsOf")} <Group>{group}</Group>
			</DialogTitle>
			<DialogContent>
				<DialogContentText variant="h6">{t("InterfaceGroupSettingsDialog.MakeOwner")}</DialogContentText>
				<DialogContentText gutterBottom>
					<Trans
						i18nKey="InterfaceGroupSettingsDialog.MsgConfirmTransfer"
						ns="heatPumpManagement"
						components={{ italic: <i /> }}
						values={{
							groupName: groupNameFormatter(group),
						}}
					/>
				</DialogContentText>
				<Button variant="contained" fullWidth onClick={handleTransferOwnership} disabled={transferOwnershipLoading}>
					{t("TransferOwnership")}
				</Button>
			</DialogContent>
			<DialogContent>
				<DialogContentText variant="h6">{t("RevokeAccess")}</DialogContentText>
				<DialogContentText>
					<Trans
						i18nKey="InterfaceGroupSettingsDialog.MsgRevokeAccess"
						ns="heatPumpManagement"
						components={{ italic: <i /> }}
						values={{
							groupName: groupNameFormatter(group),
						}}
					/>
				</DialogContentText>
				<br />
				<Button variant="contained" color="error" fullWidth onClick={handleRevokeAccess}>
					{t("RevokeAccess")}
				</Button>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="contained">
					{t("general:Cancel")}
				</Button>
			</DialogActions>
		</TSDialog>
	)
}
