import { QrCode } from "@mui/icons-material"
import {
	Button,
	colors,
	DialogActions,
	DialogContent,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material"
import TSDialog from "components/TSDialog"
import useSize from "hooks/useSize"
import { useMessage } from "providers/MessageProvider"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import QrReader from "components/LazyQrReader"
import { styled } from "@mui/material/styles"

interface Props {
	required: boolean
	value?: string | null
	onChange: (value: string | null, source: "qrreader" | "input") => void
	onFocus?: () => void
	label?: string
	error?: boolean
	helperText?: string | null
	autoFocus?: boolean
	disabled?: boolean
	autoComplete?: string
	type?: string
	defaultValue?: string
}

export function parseInterfaceIdFromURL(url: string): string | null {
	const withoutHTTP = (url: string) => url.replace(/^https:\/\/|http:\/\//, "")
	if (url.startsWith("/")) {
		// convert to absolute path if relative path is given
		url = `${window.location.origin}${url}`
	}
	url = withoutHTTP(url)
	const startOfUrl = withoutHTTP(`${window.location.origin}/`)

	if (!url.startsWith(startOfUrl)) {
		return null
	}
	const interfaceId = url.replace(startOfUrl, "").split("/").at(1)

	if (!interfaceId) return null // this might happen if the interfaceId is missing (-> interfaceId === "")
	return interfaceId
}

const StyledQrReader = styled(QrReader)(({ smallScreen }: { smallScreen?: boolean }) => ({
	objectFit: "cover",
	width: "100%",
	height: smallScreen ? "100%" : 375,
	backgroundColor: colors.grey[300],
	[`& section`]: {
		paddingTop: "0!important",
		height: "100%",
	},
	[`& video`]: {
		position: "static!important",
	},
}))

export default function InterfaceIdInput({
	value,
	onChange,
	label,
	required,
	error,
	helperText,
	onFocus,
	autoFocus,
	autoComplete,
	disabled,
	type = "text",
	defaultValue = undefined,
}: Props) {
	const { t } = useTranslation()
	const smallScreen = useSize("down", "md")
	const message = useMessage()
	const [scannerOpen, setScannerOpen] = useState(false)
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value, "input")
	}

	const handleFocus = () => {
		// on focus, set value to default
		if (defaultValue != null && value == null) onChange(defaultValue, "input")
	}

	const handleClickQRCode = () => {
		setScannerOpen(true)
	}

	const handleCloseQRPopup = () => {
		setScannerOpen(false)
	}

	const handleQRResult = async (scanData: string | null) => {
		if (scanData && scanData !== "") {
			const interfaceId = parseInterfaceIdFromURL(scanData)

			if (!interfaceId) {
				message.warning(t("QRScanner.Errors.CodeNotTSInterface"))
				return
			}

			message.success(t("QRScanner.CodeFound"))
			onChange(interfaceId, "qrreader")
			setScannerOpen(false)
		}
	}

	// this function below was in the example, I have not found the reason why..
	const handleMouseDownQRScanner = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	return (
		<>
			<FormControl variant="outlined" fullWidth margin="normal">
				<InputLabel htmlFor="qr-code-input" required={required} error={error}>
					{label}
				</InputLabel>
				<OutlinedInput
					autoFocus={autoFocus}
					id="qr-code-input"
					type={type}
					disabled={disabled}
					fullWidth
					label={label}
					error={error}
					value={value ?? ""}
					onChange={handleChange}
					onFocus={onFocus ?? handleFocus}
					autoComplete={autoComplete}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								disabled={disabled}
								aria-label={t("QRScanner.OpenScanner")}
								onClick={handleClickQRCode}
								edge="end"
								onMouseDown={handleMouseDownQRScanner}
							>
								<QrCode />
							</IconButton>
						</InputAdornment>
					}
				/>
				<FormHelperText error={error}>{helperText}</FormHelperText>
			</FormControl>
			{scannerOpen && (
				<TSDialog open onClose={handleCloseQRPopup}>
					<DialogContent style={{ padding: 0 }}>
						<Grid container direction="column" justifyContent="space-between" sx={{ height: "100%" }}>
							<Grid item flexGrow={1} sx={{}}>
								<div style={{ position: "relative", height: "100%", overflow: "hidden" }}>
									<StyledQrReader
										delay={1000}
										onScan={handleQRResult}
										onError={(err) => console.log(err)}
										showViewFinder={false}
										smallScreen={smallScreen}
									/>
								</div>
							</Grid>
							<Grid item>
								<DialogActions>
									<Button onClick={handleCloseQRPopup} color="primary" variant="outlined">
										{t("Cancel")}
									</Button>
								</DialogActions>
							</Grid>
						</Grid>
					</DialogContent>
				</TSDialog>
			)}
		</>
	)
}
