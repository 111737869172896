import { Role, useInterfaceNameQuery, useSetInterfaceNameMutation } from "generated/graphql"
import { useState } from "react"
import EditableField from "./EditableField"
import { useMessage } from "providers/MessageProvider"
import useHasRole from "hooks/useHasRole"
import { useTranslation } from "react-i18next"

type Props = {
	shouldRender: boolean
	interfaceId: string
	prefix?: string
}
export default function EditableInterfaceName({ shouldRender, interfaceId, prefix }: Props) {
	const { t } = useTranslation("heatPumpSettingsPage")
	const message = useMessage()
	const internal = useHasRole([Role.Admin, Role.Mechanic])
	if (!shouldRender) return null
	const [name, setName] = useState(interfaceId)

	const { data } = useInterfaceNameQuery({
		variables: { interfaceId },
		onCompleted: (completedData) => setName(completedData?.interface?.name ?? interfaceId),
	})
	if (!internal)
		return (
			<>
				{prefix}
				{data?.interface?.name ?? interfaceId}
			</>
		)

	const [setInterfaceName] = useSetInterfaceNameMutation()
	const saveName = async () => {
		if (data?.interface?.name === name) {
			message.info(t("Errors.HPAlreadyHasName"))
			return
		}
		try {
			await setInterfaceName({ variables: { name, id: interfaceId } })
		} catch (e) {
			message.error(t("Errors.UnkownErrorChangingName"))
		}
	}
	return (
		<>
			{prefix}
			<EditableField value={name} onChange={(e) => setName(e.target.value)} onBlur={saveName} />
		</>
	)
}
