import { useMemo, useState } from "react"
import Table from "components/Table"
import { SearchProps } from "./Users"
import Fuse from "fuse.js"
import AddButton from "components/AddButton"
import { useTranslation } from "react-i18next"
import CreateNotifications from "components/forms/UpsertNotifications"
import { TFunction } from "i18next"
import { format, Locale } from "date-fns"
import { Language, useAdminNotificationsQuery, AdminNotificationFieldsFragment } from "generated/graphql"
import { Typography } from "@mui/material"
import { pickBestLanguage } from "tools/translation"
import { enGB, nl, de } from "date-fns/locale"

function getLabels(t: TFunction<["general", "heatPumpMonitoring"]>, language: Language) {
	let locale: Locale
	switch (language) {
		case "en":
			locale = enGB
			break
		case "nl":
			locale = nl
			break
		case "de":
			locale = de
			break
		default:
			locale = enGB
	}

	return [
		{
			key: "title",
			name: t("Title"),
			sortable: true,
			resolve: (data: AdminNotificationFieldsFragment) => pickBestLanguage(language, data.title),
		},
		{
			key: "createdAt",
			name: t("heatPumpMonitoring:date").charAt(0).toUpperCase() + t("heatPumpMonitoring:date").slice(1),
			sortable: true,
			resolve: (data: AdminNotificationFieldsFragment) => format(new Date(data?.createdAt), "PP", { locale }),
		},
		{
			key: "expirationDate",
			name: t("ExpireDate"),
			sortable: true,
			resolve: (data: AdminNotificationFieldsFragment) =>
				data.expirationDate ? format(new Date(data.expirationDate), "PP", { locale }) : "-",
		},
		{
			key: "type",
			name: t("tableHeadings.Type"),
			sortable: true,
			resolve: (data: AdminNotificationFieldsFragment) => (data.type === "banner" ? t("Banner") : t("Notification")),
		},
	]
}

export default function Notifications({ search }: SearchProps) {
	const { i18n, t } = useTranslation("general")
	const labels = useMemo(() => getLabels(t, i18n.language as Language), [i18n.language])

	const [addDialogOpen, setAddDialogOpen] = useState(false)
	const [notification, setNotification] = useState<AdminNotificationFieldsFragment | null>(null)

	const { data, loading, error } = useAdminNotificationsQuery({
		variables: { includeExpired: true },
	})

	const options = {
		includeScore: true,
		threshold: 0.4,
		keys: ["title.en", "title.de", "title.nl", "createdAt", "type", "expirationDate"],
		getFn: (obj: AdminNotificationFieldsFragment | null, path: string | string[]) => {
			const value = Fuse.config.getFn(obj, path)

			if (path.includes("type")) {
				return obj?.type === "banner" ? t("Banner") : t("Notification")
			}

			return value
		},
	}

	const fuse = new Fuse(data?.notifications ?? [], options)
	const currentNotification = search ? fuse.search(search).map(({ item }) => item) : data?.notifications ?? []

	const handleEdit = (id: string | number) => {
		const filteredNotifications = currentNotification.find((p: { id: string | number }) => p?.id === id) ?? null
		setNotification(filteredNotifications)
		setAddDialogOpen(true)
	}

	if (loading) return <Typography>{t("FetchingNotifications")}</Typography>
	if (error) return <Typography>{t("Errors.FetchingNotifications")}</Typography>

	return (
		<>
			<AddButton onClick={() => setAddDialogOpen(true)}>{t("AddNotification")}</AddButton>
			<Table defaultItemsPerPage={20} rows={currentNotification} labels={labels} onClickRow={handleEdit} />
			{addDialogOpen && (
				<CreateNotifications
					notification={notification}
					onClose={() => {
						setNotification(null)
						setAddDialogOpen(false)
					}}
				/>
			)}
		</>
	)
}
