import { Autocomplete, AutocompleteValue, TextField } from "@mui/material"
import { InterfaceIdsFieldsFragment, useInterfaceIDsQuery } from "generated/graphql"
import { useMemo } from "react"
import { Maybe } from "types"

type Props<T extends boolean = false> = {
	selectedInterfaces?: InterfaceIdsFieldsFragment[]
	multiple?: T
	value: Maybe<T extends true ? string[] : string>
	onChange: (value: T extends true ? string[] : string | null) => void
	label?: string
	required?: boolean
	error?: boolean
	helperText?: string | null
}

type Value<T> = AutocompleteValue<InterfaceIdsFieldsFragment, T, false, false>
type OnChangeValue<T> = T extends true ? string[] : string

export default function InterfaceIDfromDBInput<T extends boolean = false>({
	selectedInterfaces,
	multiple,
	value,
	onChange,
	label = "Interface IDs",
}: Props<T>) {
	const { data, loading } = useInterfaceIDsQuery()

	const autoCompleteValue = useMemo(() => {
		if (multiple && Array.isArray(value)) {
			const val = value?.map((x) => data?.interfaces.find((iface) => iface.id === x)) ?? null
			return val as Value<T>
		} else if (!multiple && !Array.isArray(value)) {
			const val = data?.interfaces.find((iface) => iface.id === value) ?? null
			return val as Value<T>
		}
	}, [value, data])

	const handleChange = (_: unknown, newValue: Value<T>) => {
		if (multiple && Array.isArray(newValue)) {
			// Handle multiple IDs
			onChange((newValue.map((val) => val?.id ?? "") ?? []) as OnChangeValue<T>)
		} else if (multiple && newValue === null) {
			// Handle this case separately, just return an emtpy array
			onChange([] as unknown as OnChangeValue<T>)
		} else if (!multiple && !Array.isArray(newValue)) {
			// Handle single ID
			onChange((newValue?.id ?? null) as OnChangeValue<T>)
		}
	}

	const filteredOptions = useMemo(() => {
		if (!data?.interfaces) return []
		return data.interfaces.filter(
			(iface) => !selectedInterfaces?.find((selectedIface) => selectedIface.id === iface.id),
		)
	}, [data, selectedInterfaces])

	return (
		<Autocomplete
			fullWidth
			multiple={multiple}
			id="interfaceIDInput"
			options={filteredOptions}
			getOptionLabel={(option) => {
				return option?.name ? option.id + " (" + option.name + ")" : option?.id ?? ""
			}}
			value={autoCompleteValue}
			renderInput={(params) => <TextField label={label} {...params} />}
			onChange={handleChange}
			loading={loading || !data}
		/>
	)
}
