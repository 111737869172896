import { Button, LinearProgress, Stack, Typography } from "@mui/material"
import {
	useUpdateInterfaceFirmwareMutation,
	useInterfaceNameQuery,
	useGetCurrentUpdateStatusQuery,
	GetCurrentUpdateStatusDocument,
	UpdateProgress,
	useGetCurrentInterfaceFirmwareVersionExpertQuery,
	useGetInterfaceFirmwareUpdatesExpertQuery,
	InterfaceFirmwareVersionExpertFragment,
} from "generated/graphql"
import { useConfirm } from "providers/ConfirmProvider"
import { useMessage } from "providers/MessageProvider"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import appendErrorMessage from "tools/appendErrorMessage"

type Props = {
	interfaceId: string
}
export default function InterfaceFirmwareUpdatesExpert({ interfaceId }: Props) {
	const { t } = useTranslation("pvtHeatPumpAdminPage")
	const message = useMessage()
	const confirm = useConfirm()
	const [availableFirmware, setAvailableFirmware] = useState<InterfaceFirmwareVersionExpertFragment>()

	const { loading } = useGetInterfaceFirmwareUpdatesExpertQuery({
		onCompleted: (data) => setAvailableFirmware(data.getInterfaceFirmwareUpdates.at(0)),
	})
	const { data: interfaceName } = useInterfaceNameQuery({
		variables: { interfaceId },
	})
	const [updateInterface] = useUpdateInterfaceFirmwareMutation({
		refetchQueries: [{ query: GetCurrentUpdateStatusDocument, variables: { interfaceId } }],
	})
	const { data: currentFirmware, refetch: refetchCurrentFirmware } = useGetCurrentInterfaceFirmwareVersionExpertQuery({
		variables: { interfaceId },
	})

	const { data: currentUpdateStatus } = useGetCurrentUpdateStatusQuery({
		variables: { interfaceId },
	})

	useEffect(() => {
		if (currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.Completed) {
			refetchCurrentFirmware()
		}
	}, [currentUpdateStatus])

	const isUpdateAvailable =
		currentFirmware?.interface?.firmwareVersion.timestamp &&
		availableFirmware &&
		availableFirmware.timestamp > currentFirmware?.interface?.firmwareVersion.timestamp

	const handleUpdateInterface = async () => {
		await confirm(
			t("MsgConfirmInterfaceUpdate", {
				interfaceName: interfaceName?.interface?.name ?? interfaceId,
				firmwareVersionName: availableFirmware?.version,
			}),
		)
		try {
			await updateInterface({
				variables: { interfaceIds: [interfaceId] },
			})
			message.success(t("InterfaceUpdateStarted"))
		} catch (e) {
			message.error(appendErrorMessage(t("Errors.UpdatingFirmware"), e))
		}
	}

	if (loading) return <LinearProgress />

	return (
		<Stack width="100%" alignItems="flex-start" spacing={2}>
			{currentFirmware?.interface?.firmwareVersion && (
				<Typography variant="h6">
					{t("CurrentVersion")}: {currentFirmware.interface.firmwareVersion.version}
				</Typography>
			)}
			{!isUpdateAvailable ? (
				<>
					<Typography variant="h5">{t("InterfaceUpToDate")}</Typography>
				</>
			) : (
				<>
					<Stack direction="row" spacing={1} display="flex" alignItems="center">
						<Typography>
							{t("NewInterfaceFirmwareAvailable")} {availableFirmware.version}
						</Typography>
						<Button
							variant="contained"
							disabled={
								(currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.InProgress ||
									currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.Requested) &&
								Date.now() - new Date(currentUpdateStatus?.interface?.updateStatus?.time ?? "").valueOf() < 300000
							}
							onClick={() => handleUpdateInterface()}
						>
							{t("Update")}
						</Button>
					</Stack>
				</>
			)}
		</Stack>
	)
}
