import { ApolloClient, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import createUploadLink from "apollo-upload-client/createUploadLink.mjs"
import { NotificationType } from "generated/graphql"
import { isBefore } from "date-fns"
import logPeriod from "localstate/logperiod"
import heatPumpPageFilter from "localstate/heatPumpListPageFilter"
import settingsPageFilter from "./localstate/settingsListPageFilter"
import Auth from "tools/auth"

const GRAPHQL_ENDPOINT: string | undefined = import.meta.env.PROD
	? window.env.GRAPHQL_ENDPOINT
	: import.meta.env.VITE_GRAPHQL_ENDPOINT?.toString()

interface Ref {
	__ref: string
}

type QueryCache = {
	queryCache?: Set<string>
}

type NotificationArgs = {
	includeExpired?: boolean
	types?: NotificationType[] | NotificationType
	limit?: number
}

export function setupApollo() {
	const auth = new Auth()

	const httpLink = createUploadLink({ uri: GRAPHQL_ENDPOINT })

	const authLink = setContext(async (_, { headers }) => {
		const token = await auth.getToken()
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : "",
			},
		}
	})

	const cache = new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					logPeriod: () => logPeriod(),
					heatPumpPageFilter: () => heatPumpPageFilter(),
					settingsPageFilter: () => settingsPageFilter(),
					notifications: { read: readNotifications },
				},
			},
			User: {
				fields: {
					roles: {
						merge: incoming,
					},
				},
			},
			Group: {
				fields: {
					interfaces: {
						merge: incoming,
					},
					users: {
						merge: incoming,
					},
					members: {
						merge: incoming,
					},
				},
			},
		},
	})

	const client = new ApolloClient({
		link: authLink.concat(httpLink),
		cache,
	})

	return { client, auth }
}

const incoming = (_: Ref[], incoming: Ref[]) => incoming

const readNotifications = (
	existing: Ref[] | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	props: { args: unknown; readField: any; storage: QueryCache },
) => {
	const { args, readField, storage } = props
	const notificationArgs = args as NotificationArgs

	if (storage.queryCache == null) storage.queryCache = new Set()

	// some made up key to track which queries have been done before
	const key = `${notificationArgs.types}-${notificationArgs.includeExpired}-${notificationArgs.limit}`

	if (!storage.queryCache.has(key) || !existing) {
		storage.queryCache.add(key) // next time we know this query
		return undefined // for now get from server
	}

	let types: NotificationType[] = Object.values(NotificationType) // if not given, all are queried
	if (typeof notificationArgs.types === "string") {
		types = [notificationArgs.types]
	}
	if (Array.isArray(notificationArgs.types)) {
		types = notificationArgs.types
	}

	let filtered = existing.filter((ref) => types.includes(readField("type", ref)))

	if (!notificationArgs.includeExpired) {
		filtered = filtered.filter((ref) => {
			const expirationDate = readField("expirationDate", ref)
			return !expirationDate || isBefore(new Date(), new Date(expirationDate))
		})
	}

	// reduce length based on limit
	if (notificationArgs.limit != null) {
		filtered.length = Math.min(filtered.length, notificationArgs.limit)
	}

	return filtered
}
