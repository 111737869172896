import { Box, Button, DialogContent, DialogTitle, Stack, styled, Typography } from "@mui/material"
import useForm from "hooks/useForm"
import { useNavigate, useSearchParams } from "react-router-dom"
import { HEAT_PUMP, INTERFACE_ID } from "settings/url"
import { useMessage } from "providers/MessageProvider"
import InterfaceIdInput from "inputs/InterfaceIdInput"
import { InterfacesDocument, useClaimInterfaceMutation, useInterfaceKnownQuery, useMeQuery } from "generated/graphql"
import { useEffect, useState } from "react"
import TSDialog from "components/TSDialog"
import appendErrorMessage from "tools/appendErrorMessage"
import { ErrIfaceClaimed, ErrIfaceUnclaimed } from "resources/backendErrors"
import InterfaceClaimedDialog from "./InterfaceClaimedDialog"
import { useTranslation } from "react-i18next"

const StyledStack = styled(Stack)`
	margin-top: 10vh;
`

type Props = {
	isDialog?: boolean
	onClose?: () => void
	onClaimInterface: (interfaceId: string) => void
	navigateToPage?: boolean
}

export default function SearchHeatPump({
	isDialog,
	onClose,
	onClaimInterface: onHeatPumpFound,
	navigateToPage = true,
}: Props) {
	const { t } = useTranslation(["searchHeatPump", "general"])
	const [searchParams, setSearchParams] = useSearchParams()

	const navigate = useNavigate()
	const message = useMessage()
	const { register, submit } = useForm<{ interfaceId: string }>({ interfaceId: "" }, handleGotoHeatPump)
	const { refetch: interfaceKnown } = useInterfaceKnownQuery({ skip: true }) // horrible hack (should be useInterfaceeKnownLazyQuery), see https://github.com/apollographql/apollo-client/issues/9354
	const [claimInterface] = useClaimInterfaceMutation({ refetchQueries: [{ query: InterfacesDocument }] })
	const [claimedInterfaceId, setClaimedInterfaceId] = useState<string | null>(null)
	const [loading, setLoading] = useState(false) // loading on the query behaves strangely, so use separate loading state
	const { data: meData, loading: meDataLoading } = useMeQuery()

	useEffect(() => {
		const interfaceId = searchParams.get(INTERFACE_ID)
		if (interfaceId == null) return
		handleGotoHeatPump({ interfaceId })
	}, [searchParams])

	async function handleGotoHeatPump({ interfaceId }: { interfaceId: string }) {
		if (encodeURIComponent(interfaceId) !== interfaceId) {
			message.error(t("Errors.InterfaceIDDoesNotExist"))
			return
		}

		try {
			setLoading(true)
			await interfaceKnown({ interfaceId })
			message.success(t("HeatPumpFound"))

			if (!navigateToPage) {
				onHeatPumpFound(interfaceId)
				if (onClose != null) onClose()
			} else {
				navigate(`/${HEAT_PUMP}/${interfaceId}`)
			}
		} catch (e) {
			setLoading(false)
			if (e instanceof Error && e.message.includes("interface not found")) {
				message.error(t("HeatPumpNotFound"))
				return
			}
			if (e instanceof Error && e.message.includes(ErrIfaceClaimed)) {
				setClaimedInterfaceId(interfaceId)
				message.warning(t("Errors.MsgNoAccess"))
				return
			}
			if (e instanceof Error && e.message.includes(ErrIfaceUnclaimed)) {
				try {
					if (!meData?.me.group?.id) {
						throw t("Errors.yourOrgNotFound")
					}
					await claimInterface({ variables: { groupId: meData.me.group.id, interfaceId } })
					message.success(t("HeatPumpClaimed"))
					onHeatPumpFound(interfaceId)
					if (onClose != null) onClose()
				} catch (e) {
					message.error(appendErrorMessage(t("Errors.UnkownClaiming"), e))
				}
				return
			}
			message.error(appendErrorMessage(t("Errors.UnkownSearching"), e))
			return
		} finally {
			if (searchParams.has(INTERFACE_ID)) {
				const newSearchParams = new URLSearchParams(searchParams)
				newSearchParams.delete(INTERFACE_ID)
				setSearchParams(newSearchParams)
			}

			if (!navigateToPage) {
				setClaimedInterfaceId(interfaceId)
			}
		}
	}

	const { onChange, ...props } = register("interfaceId")
	const handleQRChange = (value: string | null, source: "input" | "qrreader") => {
		onChange(value)
		if (source === "qrreader" && value) {
			handleGotoHeatPump({ interfaceId: value })
		}
	}
	const titleText = t("FindYourHeatPump")

	return (
		<>
			{isDialog ? (
				<TSDialog open onClose={onClose} sx={{ margin: 5 }}>
					<DialogTitle sx={{ typography: "h4" }}>{titleText}</DialogTitle>
					<DialogContent>
						<form onSubmit={submit}>
							<Stack direction="column">
								<InterfaceIdInput
									label={t("general:InterfaceID")}
									{...props}
									autoFocus
									onChange={handleQRChange}
									disabled={loading}
									required
								/>
								<Button variant="contained" type="submit" disabled={loading || meDataLoading}>
									{t("FindHeatPump")}
								</Button>
							</Stack>
						</form>
					</DialogContent>
				</TSDialog>
			) : (
				<form onSubmit={submit}>
					<StyledStack direction="column" alignItems="center" spacing={4}>
						<Typography variant="h2">{titleText}</Typography>
						<Box sx={{ width: 350 }}>
							<InterfaceIdInput
								label="Interface ID"
								{...props}
								autoFocus
								onChange={handleQRChange}
								disabled={loading}
								required
							/>
						</Box>
						<Button variant="contained" type="submit" disabled={loading}>
							{t("FindHeatPump")}
						</Button>
					</StyledStack>
				</form>
			)}
			{claimedInterfaceId && (
				<InterfaceClaimedDialog
					interfaceId={claimedInterfaceId}
					onClose={() => {
						onClose ? onClose() : setClaimedInterfaceId(null)
					}}
				/>
			)}
		</>
	)
}
